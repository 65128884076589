import { Wrapper, StyledCheckbox, StyledLabel } from './styles'
import { RadioProps } from './IRadio'
import { WDLabel, WDLabelBlackSemiBold } from '../../ui/WDLabel'
const Radio = ({
  title,
  name,
  checkBoxId,
  value,
  disabled,
  checked,
  defaultChecked,
  indeterminate,
  onChange,
  required,
  isBoldTitle
}: RadioProps) => {
  return (
    <Wrapper disabled={disabled}>
      <StyledCheckbox
        type={'radio'}
        id={checkBoxId}
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        indeterminate={indeterminate}
        onChange={onChange}
        name={name}
        required={required}
      />
      {title &&
        isBoldTitle ? (
        <WDLabelBlackSemiBold className="label_responsive">
          <StyledLabel htmlFor={checkBoxId}>{title}</StyledLabel>
        </WDLabelBlackSemiBold>
      ) : (
        <WDLabel className="label_responsive">
          <StyledLabel htmlFor={checkBoxId}>{title}</StyledLabel>
        </WDLabel>
      )}

    </Wrapper>
  )
}

export default Radio
