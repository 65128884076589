import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { useIdleTimer } from 'react-idle-timer'
import './App.css'
import AppRoutes from './AppRoutes'
import AuthContextProvider from './services/AuthContext'
import InteropContextProvider from './services/InteropContextProvider'
import { LobInfoContextProvider } from './services/LobOfficeCode/LobOfficeContext'
import { RetirementDetailsContextProvider } from './services/Retirement/RetirementContext'
import { ApplicationPageContextProvider } from './services/applicationContext/ApplicationPageContext'
import { ApprovalContextProvider } from './services/approvalScreenContext/ApprovalContext'
import { DisclosureContextProvider } from './services/disclosureContext/DisclosureContext'
import { RoleContextProvider } from './services/roleContext/RoleContext'
import { ToastContextProvider } from './services/toast/ToastContext'
import useIsInteropEnv from './services/useIsInteropEnv'
import { loginRequest } from './settings/authConfig'
import Interceptor from './composites/interceptor/Interceptor'
function App() {
  const authRequest = {
    ...loginRequest,
  }
  const { instance } = useMsal()
  const { isInteropEnv } = useIsInteropEnv()

  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      instance.setActiveAccount(account)
    }
  })

  const onIdle = () => {
    sessionStorage.clear()
    instance.logout()
  }

  useIdleTimer({
    onIdle,
    timeout: 1200000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })
  const Main = () => (
    <AuthContextProvider>
      <ToastContextProvider>
        <Interceptor />
        <RoleContextProvider>
          <ApprovalContextProvider>
            <LobInfoContextProvider>
              <RetirementDetailsContextProvider>
                <ApplicationPageContextProvider>
                  <DisclosureContextProvider>
                    <AppRoutes />
                  </DisclosureContextProvider>
                </ApplicationPageContextProvider>
              </RetirementDetailsContextProvider>
            </LobInfoContextProvider>
          </ApprovalContextProvider>
        </RoleContextProvider>
      </ToastContextProvider>
    </AuthContextProvider>
  )

  return (
    <>
      {isInteropEnv ? (
        <InteropContextProvider>
          <Main />
        </InteropContextProvider>
      ) : (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <Main />
        </MsalAuthenticationTemplate>
      )}
    </>
  )
}

export default App
