import React, { useContext, useEffect, useState } from 'react'
import {
  WDLabel,
  WDLabelClient,
  WDLabelTextBrand2,
} from '../../../../../../components/ui/WDLabel'
import { DomnCode } from '../../../../../../constant/DomnCode'
import { LobInfoContext } from '../../../../../../services/LobOfficeCode/LobOfficeContext'
import XrefContext from '../../../../../../store/XrefContext'
import { OptionProps } from '../../../../../selectDropdown/ISelectDropDown'
import {
  ItemListChildWrapper,
  ItemsListWrapper,
  ParentInvestmentWrapper,
  PersonalInfoWrapper,
} from './styles'
import { ApplicationInfoContext } from '../../../../../../services/applicationContext/ApplicationPageContext'

const ApplicantInvestment = () => {
  const {
    wipId,
    selectedClient,
    allClientData,
    newModelData,
    invProfileDetails,
    xrefDetails,
  } = useContext(ApplicationInfoContext)
  const xrefStore = useContext(XrefContext)
  const [render, setRender] = useState<boolean>(true)
  const [investmentTypes, setInvestmentTypes] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])

  const getXrefDatabyDomainCode = (response: any, code: string) => {
    const res = response?.data
    const xrefdData = []
    for (const prop in res) {
      if (
        res[prop].domncode == code &&
        res[prop].value !== '--Please Select--'
      ) {
        xrefdData.push(res[prop])
      }
    }
    return xrefdData
  }
  const bindAllDropDownsWithXref = () => {
    const investmentTyps = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.INVESTMENTEXPERIENCE
    )
    const localInvtypes: OptionProps[] = []
    investmentTyps.map((element) => {
      localInvtypes.push({
        label: element.value,
        value: element.code,
      })
    })
    setInvestmentTypes(localInvtypes)
  }

  useEffect(() => {
    if (selectedClient?.clientId && render && xrefDetails) {
      bindAllDropDownsWithXref()
      setRender((isChecked) => {
        // if (isChecked) {
        //   getClientInvestmentAPI({ partyId: selectedClient.clientId })
        //     .then((res) => {
        //       if (res.data) {
        //         setInvestmentProfileFieldValues(
        //           InvestmentProfileFieldValuesData
        //         )
        //       } else {
        //         setInvestmentProfileFieldValues(
        //           InvestmentProfileFieldValuesData
        //         )
        //       }
        //     })
        //     .catch(() => {
        //       setInvestmentProfileFieldValues(InvestmentProfileFieldValuesData)
        //     })
        // }
        return false
      })
    }
  }, [render, selectedClient, xrefDetails])

  const getValue = (invType: string) => {
    const InvestmentDetails = allClientData?.value?.InvestmentProfile
    if (invType === 'Stocks') {
      return InvestmentDetails?.investmentExperienceStock
    } else if (invType === 'Bonds') {
      return InvestmentDetails?.investmentExperienceBond
    } else if (invType === 'Options') {
      return InvestmentDetails?.investmentExperienceOptions
    } else if (invType === 'Mutual Funds') {
      return InvestmentDetails?.investmentExperienceMutualFunds
    } else if (invType === 'Annuities') {
      return InvestmentDetails?.investmentExperienceAnnuities
    } else if (invType === 'Partnerships') {
      return InvestmentDetails?.investExperiencePartnership
    } else if (invType === 'investment_knowledge') {
      return InvestmentDetails?.investmentKnowledge
    } else {
      return '--'
    }
  }

  const getSourceFundValue = (currLabel: string) => {
    const InvestmentDetails = allClientData?.value?.InvestmentProfile
    if (!wipId) return '--'
    if (InvestmentDetails) {
      const getSourceData = InvestmentDetails.sourceOfFund
      if (getSourceData) {
        return getSourceData.find((obj: any) => obj.label === currLabel)
          ? 'Yes'
          : 'No'
      }
    }
    return 'No'
  }

  return (
    <ParentInvestmentWrapper>
      <PersonalInfoWrapper>
        <ItemsListWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">{'Wages/Income'}</WDLabel>
            <WDLabelClient className="label_responsive">
              {getSourceFundValue('Wages/Income')}
            </WDLabelClient>
          </ItemListChildWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">
              {'Pension or Retirement'}
            </WDLabel>
            <WDLabelClient className="label_responsive">
              {getSourceFundValue('Pension or Retirement')}
            </WDLabelClient>
          </ItemListChildWrapper>
          {!wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">
                {'Funds from another account'}
              </WDLabel>
              <WDLabelClient className="label_responsive">{'--'}</WDLabelClient>
            </ItemListChildWrapper>
          )}
          {!wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">{'Savings'}</WDLabel>
              <WDLabelClient className="label_responsive">{'--'}</WDLabelClient>
            </ItemListChildWrapper>
          )}
        </ItemsListWrapper>
        <ItemsListWrapper>
          {wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">
                {'Funds from another account'}
              </WDLabel>
              <WDLabelClient className="label_responsive">
                {getSourceFundValue('Funds from another account')}
              </WDLabelClient>
            </ItemListChildWrapper>
          )}
          {wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">{'Savings'}</WDLabel>
              <WDLabelClient className="label_responsive">
                {getSourceFundValue('Savings')}
              </WDLabelClient>
            </ItemListChildWrapper>
          )}
        </ItemsListWrapper>
        <ItemsListWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">
              {'Sale of business or property'}
            </WDLabel>
            <WDLabelClient className="label_responsive">
              {getSourceFundValue('Sale of business or property')}
            </WDLabelClient>
          </ItemListChildWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">{'Insurance payout'}</WDLabel>
            <WDLabelClient className="label_responsive">
              {getSourceFundValue('Insurance payout')}
            </WDLabelClient>
          </ItemListChildWrapper>
          {!wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">
                {'Gift/inheritance'}
              </WDLabel>
              <WDLabelClient className="label_responsive">{'--'}</WDLabelClient>
            </ItemListChildWrapper>
          )}
          {!wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">{'Other'}</WDLabel>
              <WDLabelClient className="label_responsive">{'--'}</WDLabelClient>
            </ItemListChildWrapper>
          )}
        </ItemsListWrapper>
        <ItemsListWrapper>
          {wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">
                {'Gift/inheritance'}
              </WDLabel>
              <WDLabelClient className="label_responsive">
                {getSourceFundValue('Gift/inheritance')}
              </WDLabelClient>
            </ItemListChildWrapper>
          )}
          {wipId && (
            <ItemListChildWrapper>
              <WDLabel className="label_responsive">{'Other'}</WDLabel>
              <WDLabelClient className="label_responsive">
                {getSourceFundValue('Other')}
              </WDLabelClient>
            </ItemListChildWrapper>
          )}
        </ItemsListWrapper>
      </PersonalInfoWrapper>
      <PersonalInfoWrapper>
        <WDLabelTextBrand2 className="label_responsive">
          {'Account Investment Profile'}
        </WDLabelTextBrand2>
        <ItemsListWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">{'Annual Income'}</WDLabel>
            <WDLabelClient className="label_responsive">
              {/* {allClientData?.value?.InvestmentProfile?.annualIncome
                ? allClientData?.value?.InvestmentProfile.annualIncome
                : '--'} */}
              {invProfileDetails?.annualIncome
                ? invProfileDetails?.annualIncome
                : '--'}
            </WDLabelClient>
          </ItemListChildWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">{'Liquid Net Worth'}</WDLabel>
            <WDLabelClient className="label_responsive">
              {/* {allClientData?.value?.InvestmentProfile?.liquidNetWorth
                ? allClientData?.value?.InvestmentProfile.liquidNetWorth
                : '--'} */}
              {invProfileDetails?.liquidNetWorth
                ? invProfileDetails?.liquidNetWorth
                : '--'}
            </WDLabelClient>
          </ItemListChildWrapper>
        </ItemsListWrapper>
        <ItemsListWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">{'Total Net Worth'}</WDLabel>
            <WDLabelClient className="label_responsive">
              {/* {allClientData?.value?.InvestmentProfile?.totalNetWorth
                ? allClientData?.value?.InvestmentProfile.totalNetWorth
                : '--'} */}
              {invProfileDetails?.totalNetWorth
                ? invProfileDetails?.totalNetWorth
                : '--'}
            </WDLabelClient>
          </ItemListChildWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">{'Tax Bracket'}</WDLabel>
            <WDLabelClient className="label_responsive">
              {/* {allClientData?.value?.InvestmentProfile?.taxBracket
                ? allClientData?.value?.InvestmentProfile.taxBracket
                : '--'} */}
              {invProfileDetails?.taxBracket
                ? invProfileDetails?.taxBracket
                : '--'}
            </WDLabelClient>
          </ItemListChildWrapper>
        </ItemsListWrapper>
      </PersonalInfoWrapper>
      <PersonalInfoWrapper>
        <WDLabelTextBrand2 className="label_responsive">
          {'Investment Experience'}
        </WDLabelTextBrand2>
        {!wipId && (
          <ItemsListWrapper>
            {investmentTypes.map((invType, index) => {
              return (
                <ItemListChildWrapper key={index}>
                  <WDLabel className="label_responsive">
                    {invType.label}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {getValue(invType.label) ? getValue(invType.label) : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )
            })}
          </ItemsListWrapper>
        )}
        {wipId && (
          <React.Fragment>
            <ItemsListWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Stocks'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {invProfileDetails?.investmentExperienceStock
                    ? invProfileDetails?.investmentExperienceStock
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Bonds'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {invProfileDetails?.investmentExperienceBond
                    ? invProfileDetails?.investmentExperienceBond
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
            </ItemsListWrapper>
            <ItemsListWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Options'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {invProfileDetails?.investmentExperienceOptions
                    ? invProfileDetails?.investmentExperienceOptions
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Mutual Funds'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {invProfileDetails?.investmentExperienceMutualFunds
                    ? invProfileDetails?.investmentExperienceMutualFunds
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
            </ItemsListWrapper>
            <ItemsListWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Annuities'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {invProfileDetails?.investmentExperienceAnnuities
                    ? invProfileDetails?.investmentExperienceAnnuities
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Partnerships'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {invProfileDetails?.investExperiencePartnership
                    ? invProfileDetails?.investExperiencePartnership
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
            </ItemsListWrapper>
          </React.Fragment>
        )}
      </PersonalInfoWrapper>
      <PersonalInfoWrapper>
        <ItemsListWrapper>
          <ItemListChildWrapper>
            <WDLabel className="label_responsive">
              {'Investment Knowledge'}
            </WDLabel>
            <WDLabelClient className="label_responsive">
              {getValue('investment_knowledge')
                ? getValue('investment_knowledge')
                : '--'}
            </WDLabelClient>
          </ItemListChildWrapper>
          <ItemListChildWrapper></ItemListChildWrapper>
        </ItemsListWrapper>
      </PersonalInfoWrapper>
      {/* <SvgBgWrapper>
        <SvgBackgroundPrimaryApp />
      </SvgBgWrapper> */}
    </ParentInvestmentWrapper>
  )
}

export default ApplicantInvestment
