import GetResponsiveSvg from '../../composites/forms/accountInformation/GetResponsiveSvg'
import { ISvg } from './ISvg'

const SvgInfoCircle = ({ fillColor }: ISvg) => {
  const svgDimension = GetResponsiveSvg()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgDimension}
      height={svgDimension}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99998 1.33337C6.68144 1.33337 5.39251 1.72437 4.29618 2.45691C3.19985 3.18945 2.34537 4.23064 1.84079 5.44882C1.3362 6.66699 1.20418 8.00744 1.46141 9.30064C1.71865 10.5938 2.35359 11.7817 3.28594 12.7141C4.21829 13.6464 5.40617 14.2814 6.69938 14.5386C7.99259 14.7958 9.33303 14.6638 10.5512 14.1592C11.7694 13.6547 12.8106 12.8002 13.5431 11.7038C14.2757 10.6075 14.6666 9.31858 14.6666 8.00004C14.6647 6.23253 13.9617 4.53798 12.7119 3.28817C11.462 2.03835 9.76749 1.33534 7.99998 1.33337ZM7.99998 13.3334C6.94515 13.3334 5.914 13.0206 5.03694 12.4345C4.15988 11.8485 3.47629 11.0156 3.07263 10.041C2.66896 9.06648 2.56334 7.99412 2.76913 6.95956C2.97492 5.92499 3.48287 4.97468 4.22875 4.2288C4.97463 3.48292 5.92494 2.97497 6.9595 2.76919C7.99407 2.5634 9.06642 2.66902 10.041 3.07268C11.0155 3.47635 11.8485 4.15994 12.4345 5.037C13.0205 5.91406 13.3333 6.94521 13.3333 8.00004C13.3317 9.41403 12.7693 10.7696 11.7694 11.7695C10.7696 12.7693 9.41397 13.3318 7.99998 13.3334ZM7.99998 7.66671C7.82317 7.66671 7.6536 7.73695 7.52858 7.86197C7.40355 7.98699 7.33332 8.15656 7.33332 8.33337V10.3334C7.33332 10.5102 7.40355 10.6798 7.52858 10.8048C7.6536 10.9298 7.82317 11 7.99998 11C8.17679 11 8.34636 10.9298 8.47139 10.8048C8.59641 10.6798 8.66665 10.5102 8.66665 10.3334V8.33337C8.66665 8.15656 8.59641 7.98699 8.47139 7.86197C8.34636 7.73695 8.17679 7.66671 7.99998 7.66671ZM7.99998 5.00004C7.83517 5.00004 7.67405 5.04891 7.53701 5.14048C7.39997 5.23205 7.29316 5.3622 7.23008 5.51447C7.16701 5.66674 7.15051 5.8343 7.18266 5.99595C7.21482 6.1576 7.29418 6.30609 7.41073 6.42263C7.52727 6.53917 7.67576 6.61854 7.83741 6.65069C7.99906 6.68285 8.16661 6.66635 8.31889 6.60327C8.47116 6.5402 8.60131 6.43339 8.69287 6.29635C8.78444 6.15931 8.83332 5.99819 8.83332 5.83337C8.83332 5.61236 8.74552 5.4004 8.58924 5.24412C8.43296 5.08784 8.221 5.00004 7.99998 5.00004Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SvgInfoCircle
