import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import {
  CreateAccountFeature,
  GetAccFetureReqModel,
} from '../../shared/models/IAccountFeature'
import { ProductFeaturesRequestInterface, ProductFeaturesResponseInterface } from './IAccountFeatures'
import { UpdateWIPRequestInterface } from '../applicationContext/IApplicationPageContext'

export const getAccountDetails = (Wipaccountid: any, appid: any) => {
  // axios call here  and returtn promise

  const Payload = {
    appID: appid,
    wipacctid: Wipaccountid,
  }
  return axios.post<GetAccFetureReqModel>(
    ENDPOINTS.GET_ACCOUNTDETAILS_BY_WIPACCOUNTID,
    Payload
  )
}

export const getAccountConfig = (payload: any) => {
  // axios call here  and returtn promise

  return axios.post<any>(ENDPOINTS.GET_ACCOUNT_FEATURE_QSN_CONFIG, payload)

  return Promise.resolve([
    {
      sectionid: 1,
      sectionname: 'Credit Plus',
      objQuestions: [
        {
          sectionqsnid: 1,
          sectionid: 1,
          sectionseq: 1,
          controlid: 'ddlcrdplacct_1',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Credit Plus',
          qsnid: 1,
          qsntext: 'Credit Plus Account\n\n',
          controlerseqno: 1,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: null,
          dependantvalue: null,
          drivingqsnid: null,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: 'y',
        },
        {
          sectionqsnid: 2,
          sectionid: 1,
          sectionseq: 1,
          controlid: 'ddlchkwrtacc_2',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Credit Plus',
          qsnid: 2,
          qsntext: 'Check Writing Account',
          controlerseqno: 2,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 1,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 3,
          sectionid: 1,
          sectionseq: 1,
          controlid: 'ddlwbdbcrd_3',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Credit Plus',
          qsnid: 3,
          qsntext: 'Wedbush Debit Card',
          controlerseqno: 3,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 1,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: null,
        },
      ],
    },
    {
      sectionid: 2,
      sectionname: 'FDIC Sweep',
      objQuestions: [
        {
          sectionqsnid: 4,
          sectionid: 2,
          sectionseq: 2,
          controlid: 'ddlfdswpprg-4',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'FDIC Sweep',
          qsnid: 4,
          qsntext: 'FDIC Sweep program',
          controlerseqno: 1,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: null,
          dependantvalue: null,
          drivingqsnid: null,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: 'y',
        },
        {
          sectionqsnid: 5,
          sectionid: 2,
          sectionseq: 2,
          controlid: 'ddlprgbnk_5',
          controltype: 'multicheck',
          subcontroltype: null,
          sectionname: 'FDIC Sweep',
          qsnid: 5,
          qsntext: 'Program Bank',
          controlerseqno: 2,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 4,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'FDICBK',
          status: '1',
          value: null,
          isdriving: null,
        },
      ],
    },
    {
      sectionid: 3,
      sectionname: 'Household',
      objQuestions: [
        {
          sectionqsnid: 6,
          sectionid: 3,
          sectionseq: 3,
          controlid: 'ddladdaccexhh_6',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Household',
          qsnid: 6,
          qsntext: 'Add this account to an existing ishousehold',
          controlerseqno: 1,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: null,
          dependantvalue: null,
          drivingqsnid: null,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: 'y',
        },
        {
          sectionqsnid: 7,
          sectionid: 3,
          sectionseq: 3,
          controlid: 'ddlaccnum_7',
          controltype: 'input',
          subcontroltype: 'text',
          sectionname: 'Household',
          qsnid: 7,
          qsntext: 'Account Number',
          controlerseqno: 2,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 6,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
      ],
    },
    {
      sectionid: 4,
      sectionname: 'Managed Asset Account',
      objQuestions: [
        {
          sectionqsnid: 8,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlmngasprg_8',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 8,
          qsntext: 'Managed Assets program',
          controlerseqno: 1,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: null,
          dependantvalue: null,
          drivingqsnid: null,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: 'y',
        },
        {
          sectionqsnid: 9,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlelgastval_9',
          controltype: 'input',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 9,
          qsntext: 'Eligible Asset Value',
          controlerseqno: 2,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 10,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlchrgacc_10',
          controltype: 'input',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 10,
          qsntext: 'Charge Account',
          controlerseqno: 3,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 11,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlenvprid_11',
          controltype: 'input',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 11,
          qsntext: 'Envestet Proposal ID#',
          controlerseqno: 4,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 12,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlprogtyp_12',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 12,
          qsntext: 'Program Type',
          controlerseqno: 5,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'MAAPTY',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 13,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlffoptmngfee_13',
          controltype: 'input',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 13,
          qsntext: 'Flat Fee Option - Manager Fee%',
          controlerseqno: 6,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 14,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlffoptadvfee_14',
          controltype: 'input',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 14,
          qsntext: 'Flat Fee Option - Advisory Fee%',
          controlerseqno: 7,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 15,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlffopttotfee_15',
          controltype: 'input',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 15,
          qsntext: 'Flat Fee Option - Total Fee%',
          controlerseqno: 8,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 16,
          sectionid: 4,
          sectionseq: 4,
          controlid: 'ddlexcassetssyb_16',
          controltype: 'MultiArray',
          subcontroltype: null,
          sectionname: 'Managed Asset Account',
          qsnid: 16,
          qsntext: 'Excluded Assets (Symbol or CUSIP)',
          controlerseqno: 9,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'Y',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 8,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
      ],
    },
    {
      sectionid: 5,
      sectionname: 'Option Account',
      objQuestions: [
        {
          sectionqsnid: 17,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddloptacc_17',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Option Account',
          qsnid: 17,
          qsntext: 'Option Account',
          controlerseqno: 1,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: null,
          dependantvalue: null,
          drivingqsnid: null,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: 'y',
        },
        {
          sectionqsnid: 18,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddloptlvl_18',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Option Account',
          qsnid: 18,
          qsntext: 'Option Level',
          controlerseqno: 2,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 19,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddloptinvexp_19',
          controltype: 'SubHeader',
          subcontroltype: null,
          sectionname: 'Option Account',
          qsnid: 32,
          qsntext: 'Options Investment Experience',
          controlerseqno: 3,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: 'Y',
          grpqsnid: '[22,23,24,25,26,27]',
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },

        {
          sectionqsnid: 22,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddlcvrwrt_22',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 19,
          qsntext: 'Covered Writing',
          controlerseqno: 5,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTEXP',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 23,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddlpurptcls_23',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 20,
          qsntext: 'Purchasing Calls and Puts',
          controlerseqno: 6,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTEXP',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 24,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddlsprdptcl_24',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 21,
          qsntext: 'Spreads (Puts and Calls)',
          controlerseqno: 7,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTEXP',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 25,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddluncvrptwrt_25',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 22,
          qsntext: 'Uncovered Put Writing',
          controlerseqno: 8,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTEXP',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 26,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddluncvrclswrt_26',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 23,
          qsntext: 'Uncovered Call Writing',
          controlerseqno: 9,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTEXP',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 27,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddluncvridx_27',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 24,
          qsntext: 'Uncovered Index',
          controlerseqno: 10,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTEXP',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 20,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddloptinvknw_20',
          controltype: 'SubHeader',
          subcontroltype: null,
          sectionname: 'Option Account',
          qsnid: 33,
          qsntext: 'Options Investment Knowledge',
          controlerseqno: 4,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: 'Y',
          grpqsnid: '[28,29,30,31,32,33]',
          domaincode: null,
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 28,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddlcvrwrt_28',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 25,
          qsntext: 'Covered Writing',
          controlerseqno: 11,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTKNW',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 29,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddlpurptcls_29',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 26,
          qsntext: 'Purchasing Calls and Puts',
          controlerseqno: 12,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTKNW',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 30,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddlsprdptcl_30',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 27,
          qsntext: 'Spreads (Puts and Calls)',
          controlerseqno: 13,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTKNW',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 31,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddluncvrptwrt_31',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 28,
          qsntext: 'Uncovered Put Writing',
          controlerseqno: 14,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTKNW',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 32,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddluncvrclswrt_32',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 29,
          qsntext: 'Uncovered Call Writing',
          controlerseqno: 15,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTKNW',
          status: '1',
          value: null,
          isdriving: null,
        },
        {
          sectionqsnid: 33,
          sectionid: 5,
          sectionseq: 5,
          controlid: 'ddluncvridx_33',
          controltype: 'select',
          subcontroltype: '',
          sectionname: 'Option Account',
          qsnid: 30,
          qsntext: 'Uncovered Index',
          controlerseqno: 16,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: 'y',
          dependantvalue: '1',
          drivingqsnid: 17,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'OPTKNW',
          status: '1',
          value: null,
          isdriving: null,
        },
      ],
    },
    {
      sectionid: 6,
      sectionname: 'Margin Account',
      objQuestions: [
        {
          sectionqsnid: 21,
          sectionid: 6,
          sectionseq: 6,
          controlid: 'ddlmrgacc_21',
          controltype: 'select',
          subcontroltype: null,
          sectionname: 'Margin Account',
          qsnid: 31,
          qsntext: 'Margin Account',
          controlerseqno: 1,
          isrequired: 'Y',
          defaultvalue: null,
          ismultiarray: 'N',
          isdependant: null,
          dependantvalue: null,
          drivingqsnid: null,
          isgrpheader: null,
          grpqsnid: null,
          domaincode: 'TOGGLE',
          status: '1',
          value: null,
          isdriving: null,
        },
      ],
    },
  ])
}

// export const getProductFeaturesAPI = (payload:ProductFeaturesRequestInterface) => {
//   return axios.post<ProductFeaturesResponseInterface>(
//     ENDPOINTS.GET_VALUE_JSON,
//     payload
//   )
// }

export const updateAccountDetails = (Payload: any) => {
  // axios call here  and returtn promise
  return axios.post<CreateAccountFeature>(
    ENDPOINTS.UPDATE_ACCOUNTDETAILS,
    Payload
  )
}

export const createNewAccount = (Payload: any) => {
  // axios call here  and returtn promise
  return axios.post<CreateAccountFeature>(ENDPOINTS.CREATE_NEW_ACCOUNT, Payload)
}

export const createNewAccountFeature = (Payload: any) => {
  // axios call here  and returtn promise
  return axios.post<any>(ENDPOINTS.CREATE_NEW_ACCOUNT_FEATURE, Payload)
}

export const updateWIPAccount = (payload: UpdateWIPRequestInterface) => {
  return axios.post<any>(ENDPOINTS.UPDATE_WIP_ACCOUNT, payload)
}