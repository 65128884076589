import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 32% 68%;
  padding: 24px 12px;
  column-gap: 24px;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background-color: white;
  padding: 0.5rem;
  &#align {
    justify-content: center;
    align-items: center;
  }
`
export const Header = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-between;
  align-items: center;
`
export const StyledDiv = styled.div`
  display: flex;
  justify-content:end;
  align-items: flex-end;
  padding-left: 12rem;
  flex-direction: column;
`
export const ParentContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
`
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
`
export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* padding-top: 1rem; */
`
export const StyledDocumentPanel = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.Background.White};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border: 1px solid ${COLORS.Background.White};
  border-radius: 8px;
  width: 95%;
  padding: 0.5rem;
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  }
  @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const TemplateDocumentPanel = styled.div`
  display: flex;
  flex-direction: column;
  //background: ${COLORS.Background.White};
  //box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  //border: 1px solid ${COLORS.Background.White};
  width: 95%;
  padding: 0.5rem;
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  }
  @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const LoaderWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 100px;
`
export const MainSplitRoot = styled.div`
  display: grid;
  row-gap: 24px;
  height: 100vh;
`
export const ColGroupTab = styled.div`
  display: grid;
  height: fit-content;
`