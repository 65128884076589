import { ItemWrapper } from '../../../pages/accountApplicationPage/styles'
import PrimaryBeneficiary from '../retirementForm/PrimaryBeneficiary/PrimaryBeneficiary'
import { FormWipProps } from './IEntityCorpForm'

const EntityCorp = ({ stage, setStage }: FormWipProps) => {
  return (
    <ItemWrapper sm={6} md={4} lg={3} id={'full_col'}>
      <div id="left_grp_next">
        <PrimaryBeneficiary key={'beneOwner'} type={'beneOwner'} header='Beneficial Owners' />
        <PrimaryBeneficiary key={'addlPpt'} type={'addlPpt'} header='Additional Participants'/>
      </div>
    </ItemWrapper>
  )
}

export default EntityCorp
