import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const CombineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border: 1px solid #fe7765;
  background: #fef1ee;
  gap: 8px;
  border-radius: 4px;
`
export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  height: auto;
  align-items: center;
`
export const LabelWrapper = styled.div`
  order: 1;
  flex-grow: 1;
`
export const SvgCancelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  order: 2;
  cursor: pointer;
  align-self: stretch;
  flex-grow: 0;
`
export const ErrorText = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #475569;
`
