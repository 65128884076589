export const response = [
  {
    sectionid: 1,
    objQuestions: [
      {
        sectionqsnid: 79,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'iraaccountnumber',
        controltype: 'input',
        subcontroltype: 'text',
        sectionname: 'Credit Plus',
        qsnid: 94,
        qsntext: 'Primary Account Number',
        controlerseqno: 4,
        isrequired: 'n',
        defaultvalue: '2',
        ismultiarray: null,
        isdependant: null,
        dependantvalue: 'Yes',
        drivingqsnid: 71,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: null,
        status: '1',
        value: null,
        isdriving: '1',
        placeholder: null,
        isDisabled: null,
        isAdvisor: null,
        isClient: null,
      },
      {
        sectionqsnid: 109,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'iraaccounttypeid',
        controltype: 'select',
        subcontroltype: null,
        sectionname: 'Credit Plus',
        qsnid: 98,
        qsntext: 'Primary or Secondary IRA Account',
        controlerseqno: 3,
        isrequired: 'y',
        defaultvalue: '2',
        ismultiarray: null,
        isdependant: null,
        dependantvalue: 'Yes',
        drivingqsnid: 71,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: 'IRA-ACCT-TYPE',
        status: '1',
        value: null,
        isdriving: 'null',
        placeholder: null,
        isDisabled: null,
        isAdvisor: null,
        isClient: null,
      },
      {
        sectionqsnid: 110,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'irafeescheduleid',
        controltype: 'select',
        subcontroltype: '',
        sectionname: 'Credit Plus',
        qsnid: 99,
        qsntext: 'IRA Fee Schedule',
        controlerseqno: 5,
        isrequired: 'y',
        defaultvalue: '2',
        ismultiarray: null,
        isdependant: null,
        dependantvalue: null,
        drivingqsnid: null,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: 'IRA-FEE-OPT',
        status: '1',
        value: null,
        isdriving: 'null',
        placeholder: '',
        isDisabled: null,
        isAdvisor: null,
        isClient: null,
      },
      {
        sectionqsnid: 111,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'header',
        controltype: '',
        subcontroltype: 'text',
        sectionname: 'Credit Plus',
        qsnid: 100,
        qsntext: 'Person the account is inherited from :',
        controlerseqno: 6,
        isrequired: 'y',
        defaultvalue: '',
        ismultiarray: null,
        isdependant: null,
        dependantvalue: null,
        drivingqsnid: null,
        isgrpheader: 'true',
        grpqsnid: null,
        domaincode: null,
        status: '1',
        value: '',
        isdriving: 'null',
        placeholder: null,
        isDisabled: 'true',
        isAdvisor: null,
        isClient: null,
      },
      {
        sectionqsnid: 112,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'inheritedrelationshipid',
        controltype: 'select',
        subcontroltype: 'text',
        sectionname: 'Credit Plus',
        qsnid: 103,
        qsntext: 'Relationship',
        controlerseqno: 9,
        isrequired: 'y',
        defaultvalue: '2',
        ismultiarray: null,
        isdependant: null,
        dependantvalue: null,
        drivingqsnid: null,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: 'RELATION-OPT',
        status: '1',
        value: null,
        isdriving: 'null',
        placeholder: null,
        isDisabled: 'false',
        isAdvisor: null,
        isClient: null,
      },
      {
        sectionqsnid: 113,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'deceaseddate',
        controltype: 'date',
        subcontroltype: 'text',
        sectionname: 'Credit Plus',
        qsnid: 104,
        qsntext: 'Deceased Date',
        controlerseqno: 10,
        isrequired: 'y',
        defaultvalue: '2',
        ismultiarray: null,
        isdependant: null,
        dependantvalue: null,
        drivingqsnid: null,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: '',
        status: '1',
        value: null,
        isdriving: 'null',
        placeholder: null,
        isDisabled: 'false',
        isAdvisor: null,
        isClient: null,
      },
      // {
      //   sectionqsnid: 114,
      //   sectionid: 1,
      //   sectionseq: 1,
      //   controlid: 'firstName',
      //   controltype: 'input',
      //   subcontroltype: null,
      //   sectionname: 'Credit Plus',
      //   qsnid: 96,
      //   qsntext: 'First Name',
      //   controlerseqno: 1,
      //   isrequired: 'n',
      //   defaultvalue: null,
      //   ismultiarray: null,
      //   isdependant: null,
      //   dependantvalue: null,
      //   drivingqsnid: null,
      //   isgrpheader: null,
      //   grpqsnid: null,
      //   domaincode: null,
      //   status: '1',
      //   value: null,
      //   isdriving: 'null',
      //   placeholder: null,
      //   isDisabled: 'true',
      //   isAdvisor: null,
      //   isClient: null,
      // },
      // {
      //   sectionqsnid: 115,
      //   sectionid: 1,
      //   sectionseq: 1,
      //   controlid: 'lastName',
      //   controltype: 'input',
      //   subcontroltype: null,
      //   sectionname: 'Credit Plus',
      //   qsnid: 97,
      //   qsntext: 'Last Name',
      //   controlerseqno: 2,
      //   isrequired: 'n',
      //   defaultvalue: '',
      //   ismultiarray: null,
      //   isdependant: null,
      //   dependantvalue: null,
      //   drivingqsnid: null,
      //   isgrpheader: null,
      //   grpqsnid: null,
      //   domaincode: null,
      //   status: '1',
      //   value: null,
      //   isdriving: 'null',
      //   placeholder: null,
      //   isDisabled: 'true',
      //   isAdvisor: null,
      //   isClient: null,
      // },
      {
        sectionqsnid: 116,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'inheritedfirstname',
        controltype: 'input',
        subcontroltype: null,
        sectionname: 'Credit Plus',
        qsnid: 96,
        qsntext: 'First Name',
        controlerseqno: 7,
        isrequired: 'n',
        defaultvalue: null,
        ismultiarray: null,
        isdependant: null,
        dependantvalue: null,
        drivingqsnid: null,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: null,
        status: '1',
        value: null,
        isdriving: 'null',
        placeholder: null,
        isDisabled: 'true',
        isAdvisor: null,
        isClient: null,
      },
      {
        sectionqsnid: 117,
        sectionid: 1,
        sectionseq: 1,
        controlid: 'inheritedlastname',
        controltype: 'input',
        subcontroltype: null,
        sectionname: 'Credit Plus',
        qsnid: 97,
        qsntext: 'Last Name',
        controlerseqno: 8,
        isrequired: 'n',
        defaultvalue: null,
        ismultiarray: null,
        isdependant: null,
        dependantvalue: null,
        drivingqsnid: null,
        isgrpheader: null,
        grpqsnid: null,
        domaincode: null,
        status: '1',
        value: null,
        isdriving: 'null',
        placeholder: null,
        isDisabled: 'true',
        isAdvisor: null,
        isClient: null,
      },
    ],
  },
]
