import React from 'react'

const SvgMessageSent = () => {
  return (
    <svg
      width="136"
      height="136"
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_931_42969)">
        <rect
          x="9.71387"
          y="8.5"
          width="118.691"
          height="118.691"
          rx="59.3455"
          fill="#DBEAFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.493 60.5857C121.525 60.3013 121.541 60.0122 121.541 59.7193C121.541 55.456 118.085 52 113.822 52C109.558 52 106.102 55.456 106.102 59.7193C106.102 59.9819 106.115 60.2415 106.141 60.4973C104.111 60.6107 102.5 62.2926 102.5 64.3509C102.5 66.4825 104.228 68.2105 106.36 68.2105H120.64C122.772 68.2105 124.5 66.4825 124.5 64.3509C124.5 62.5122 123.214 60.9738 121.493 60.5857Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.3993 75.7077C34.4426 75.3199 34.4649 74.9257 34.4649 74.5263C34.4649 68.7128 29.7521 64 23.9385 64C18.125 64 13.4122 68.7128 13.4122 74.5263C13.4122 74.8844 13.4301 75.2383 13.465 75.5872C10.697 75.7418 8.5 78.0354 8.5 80.8421C8.5 83.7488 10.8564 86.1052 13.7632 86.1052H33.2368C36.1436 86.1052 38.5 83.7488 38.5 80.8421C38.5 78.3348 36.7467 76.2369 34.3993 75.7077Z"
          fill="white"
        />
        <path
          d="M1.61608 82.7368C-1.27982 69.6919 -0.268143 56.0829 4.52475 43.6096C9.31764 31.1362 17.6793 20.3515 28.5653 12.6023L31.4294 16.6257C21.334 23.8121 13.5796 33.8136 9.13482 45.381C4.69003 56.9484 3.75183 69.5691 6.4374 81.6665L1.61608 82.7368Z"
          fill="#F1F5F9"
        />
        <path
          d="M10.1599 103.756C6.87954 98.4501 4.34499 92.7176 2.62757 86.7202L7.37543 85.3606C8.96812 90.9224 11.3186 96.2385 14.3607 101.16L10.1599 103.756Z"
          fill="#F1F5F9"
        />
        <path d="M43.2667 92L35 61.7931L97 19L43.2667 92Z" fill="#60A5FA" />
        <path
          d="M48.6124 68.8155L43.5 92L97.5 19L48.6124 68.8155Z"
          fill="#2563EB"
        />
        <path
          d="M105.822 5.72281C107.285 5.03464 108.917 6.27656 108.644 7.87007L93.9766 93.5435C93.4848 96.4162 90.1844 97.8154 87.7778 96.1715L48.0002 69L88.4651 24.9172L35.0002 62L16.8082 55.0857C13.5085 53.8315 13.3325 49.2296 16.5267 47.7271L105.822 5.72281Z"
          fill="#BFDBFE"
        />
        <mask
          id="mask0_931_42969"
          //style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="14"
          y="5"
          width="95"
          height="92"
        >
          <path
            d="M105.822 5.72281C107.285 5.03464 108.917 6.27656 108.644 7.87007L93.9766 93.5435C93.4848 96.4162 90.1844 97.8154 87.7778 96.1715L48.0002 69L88.4651 24.9172L35.0002 62L16.8082 55.0857C13.5085 53.8315 13.3325 49.2296 16.5267 47.7271L105.822 5.72281Z"
            fill="#BFDBFE"
          />
        </mask>
        <g mask="url(#mask0_931_42969)">
          <path
            d="M93.0576 92.3608C93.6323 92.7508 93.9292 93.4383 93.8189 94.1241C93.4028 96.712 90.4359 97.9875 88.2715 96.5091L47.9998 69.0001L51.9998 64.5L93.0576 92.3608Z"
            fill="#93C5FD"
          />
          <path
            d="M93.0576 92.3608C93.6323 92.7508 93.9292 93.4383 93.8189 94.1241C93.4028 96.712 90.4359 97.9875 88.2715 96.5091L47.9998 69.0001L51.9998 64.5L93.0576 92.3608Z"
            fill="#93C5FD"
          />
          <path
            d="M44.3131 60.493C45.9362 61.0772 46.1079 63.3046 44.5935 64.1306L44.4871 64.1887C43.5364 64.7073 42.4161 64.8182 41.3821 64.4961L12.4998 55.5L9.49965 47.9623L44.3131 60.493Z"
            fill="#93C5FD"
          />
          <path
            d="M44.3131 60.493C45.9362 61.0772 46.1079 63.3046 44.5935 64.1306L44.4871 64.1887C43.5364 64.7073 42.4161 64.8182 41.3821 64.4961L12.4998 55.5L9.49965 47.9623L44.3131 60.493Z"
            fill="#93C5FD"
          />
        </g>
        <path
          d="M43.0032 92C37.3456 98.5451 28.4552 116.501 48.0258 125.71C56.541 129.717 63.7447 127.869 68.8809 124.144M68.8809 124.144C75.5493 119.308 78.7325 111.309 76.7757 108.855C76.0829 107.465 73.8314 104.789 70.3675 105.206C66.2123 105.706 57.9099 111.968 68.8809 124.144ZM68.8809 124.144C69.3418 124.656 69.8368 125.178 70.3675 125.71C80.8976 136.275 91.8434 128.606 96 123.451"
          stroke="#2563EB"
          strokeWidth="2"
          strokeDasharray="3 3"
        />
      </g>
      <defs>
        <clipPath id="clip0_931_42969">
          <rect width="136" height="136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgMessageSent
