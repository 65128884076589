import axios from 'axios'
import appConfig from '../../settings/appConfig'

let baseURL = ''
//https://dev.api.wedbush.com/apiportaldbapi/ClearingAPIPortalDB/ApiAccountOnboaringRequests/{fromDate}/{toDate}/{status}/{requestedBy}
//https://dev.api.wedbush.com/apiportaldbapi/ClearingAPIPortalDB/ApiAccountOnboaringRequests/{requestId}

export interface RequestUpdateInterface {
  action: number
  request: {
    requestId: number
    approvedBy?: string
    comment?: string
    kycCompletedBy?: string
    kycComment?: string
  }
}

export interface RemoveRestrictionInterface {
  accountNumber: string
  isRestricted: boolean
}

export const GetDashboardDetails = (
  fromDate: string,
  toDate: string,
  status: string,
  requestedBy: string
) => {
  if (appConfig.apiEndPoint?.toLocaleLowerCase().includes('dev')) {
    baseURL = 'https://dev.api.wedbush.com/'
  } else if (appConfig.apiEndPoint?.toLocaleLowerCase().includes('uat')) {
    baseURL = 'https://uat.api.wedbush.com/'
  } else {
    baseURL = 'https://api.wedbush.com/'
  }

  return axios.get(
    `${baseURL}apiportaldbapi/ClearingAPIPortalDB/ApiAccountOnboaringRequests?fromDate=${fromDate}&toDate=${toDate}&status=${status}&requestedBy=${requestedBy}`
  )
}
export const CompleteApiCall = (requestId: number) => {
  return axios.put(
    `${baseURL}apiportaldbapi/ClearingAPIPortalDB/ApiAccountOnboaringRequests/${requestId}`
  )
}

export const AccountRequestUpdate = (payload: RequestUpdateInterface) => {
  return axios.put(
    `${baseURL}apiportaldbapi/ClearingAPIPortalDB/ApiAccountOnboaringRequestUpdate`,
    payload
  )
}

export const RemoveAccountRestriction = (
  payload: RemoveRestrictionInterface
) => {
  return axios.patch(
    `${baseURL}AccountOnboardingApi/Account/RemoveAccountRestriction`,
    payload
  )
}
