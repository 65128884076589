export interface ClientByIdPayloadInterface {
  partyId: number
}
export interface UpdateTrustedContactInterface {
  parties: Party[]
  relationshipTypeId: number
  wipacctid: number
  istrustedcontact: boolean
  trustedcontactrelationship: string
  partydetails: string | null
  createdBy: string
  updateBy: string
  status: string
  issubmitted: boolean
}

export interface Party {
  partyId: number
  name: string
  percentageOfBenefits: number | number
  relationship: string
}

export interface GetTrustedContactReqInterface {
  wipid: number
}
export interface GetTrustedContactResponseInterface {
  wipid: number
  relationShip: string
  trustedcontactid: number
  individualdata: string
  trustedcontactvalue: string
  istrustedcontact: boolean
}
export interface IndividualDetailsResponseInterface {
  partyId: number
  partyTypeId: number
  prefix: string
  firstName: string
  middleName: string
  lastName: string
  suffix: string | null
  fullName: string
  preferredPronoun: null
  socialSecurityNumber: null
  nameOfEntityOne: null
  nameOfEntityTwo: null
  businessType: null
  organizationStructure: null
  taxIDNumber: string
  clientType: string
  updateBy: null
  partyDemographic: {
    partyDemographicId: number
    dateOfBirth: string
    countryOfResidence: null
    countryOfTaxResidence: null
    citizenshipCountry: null
    maritalStatus: null
    numberOfDependents: null
    employmentStatus: null
    employerName: null
    occupation: null
    occupationCategoryId: null
    typeOfBusiness: null
    businessPhone: string
    employerPhone: null
    contactId: number
    contactTypeId: number
    businessAddress: string
    city: string
    cityId: number
    stateProvinceId: number
    stateName: string
    stateCode: string
    countryName: string
    countryAlpha2Code: string
    zipCode: string
    addressTypeId: number
    addressId: number
    deceasedFlag: null
    deceasedDate: null
    parent: string
    emailAddress?: string
  }
  clientIds: ClientIDInterface[]
}
export interface ClientIdPayloadInterface {
  clientId: number
}

export interface WIPAcctIdPayloadInterface {
  wipacctid: number
}

export interface OptionsProp {
  label: string
  value: string
}

export interface ClientSearchByNamePayloadInterface {
  repCode: string[]
  searchName: string
}

export interface AccountSearchByNumberPayloadInterface {
  repCode: string[]
  accountList: string[]
}

export interface AccountDetailsInterface {
  accountNumber: string
  accountId: number
  accountName: string
  nickName: string
  accountType: string
  managed: string
  managedType: string
  notes: string
  household: string
  iraType: string
  accountValue: number
  repcode: string
  wrap: string
}

export interface ClientEmailInterface {
  emailAddress: string
}

export interface ClientContactInterface {
  partyContactValue: string
}

export interface ClientAddressInterface {
  addressLine1: string
  addressLine2: string
  addressLine3: string
}

export interface ClientSearchByNameResponseInterface {
  clientId: number
  primaryUserName: string
  clientName: string
  notes: string
  repcode: string
  houseHoldCOB: number
  clientAddressList: ClientAddressInterface[]
  clientContactList: ClientContactInterface[]
  clientEmailList: ClientEmailInterface[]
  detailsByAccounts: AccountDetailsInterface[]
}

export interface ClientIDInterface {
  idType: string
  idNumber: string
  issueDate: string
  expiryDate: string
  countryOfIssuance: string
}

export interface PartyDemographicInterface {
  partyDemographicId: number
  dateOfBirth: string
  countryOfResidence: string
  countryOfTaxResidence: string
  citizenshipCountry: string
  maritalStatus: string
  numberOfDependents: string
  employmentStatus: string
  employerName: string
  occupation: string
  occupationCategoryId: number
  typeOfBusiness: string
  businessPhone: string
  employerPhone: null
  contactId: number
  contactTypeId: number
  businessAddress: string
  city: string
  cityId: number
  stateProvinceId: number
  stateName: string
  stateCode: string
  countryName: string
  countryAlpha2Code: string
  zipCode: string
  addressTypeId: number
  addressId: number
  deceasedFlag: boolean
  deceasedDate: string | null
  parent: string
  emailAddress?: string
}

export interface IndividualClientResponseInterface {
  partyId: number
  partyTypeId: number
  prefix: string
  firstName: string
  middleName: string
  lastName: string
  suffix: string | null
  fullName: string
  salutation: string
  socialSecurityNumber: string | null
  updateBy: string | null
  partyDemographic: PartyDemographicInterface
  clientIds: ClientIDInterface[]

}

//New Party Get Response Interface starts

export interface FinalIndividualPartyResponseInterface {
  Address: AddressDetailsInterface
  GovernmentID: GovernmentIdInterface
  IndividualDetails: IndividualDetailsInterface
  InvestmentDetails: InvestmentDetailsInterface
  sourceoffundsresponse: string | null
}
export interface AddressDetailsInterface {
  city: string
  country: string
  line1: string
  line2: string
  line3: string
  state: string
  zip: string
}
export interface GovernmentIdInterface {
  expirydate: string
  issuedate: string
  number: string
  type: string
}
export interface IndividualDetailsInterface {
  citizenshipCountry: string
  city: string
  cityId: number
  clientType: string
  countryAlpha2Code: string
  countryName: string
  countryOfDualCitizenship: string
  countryOfResidence: string
  countryOfTaxResidence: string
  dateOfBirth: string
  deceasedDate: string
  deceasedFlag: string
  employerName: string
  employmentStatus: string
  firstName: string
  fullName: string
  lastName: string
  maritalStatus: string
  middleName: string
  nameOfEntityOne: string
  nameOfEntityTwo: string
  numberOfDependents: string
  occupation: string
  partyId: number
  partyTypeId: number
  prefix: string
  socialSecurityNumber: string
  stateCode: string
  stateName: string
  stateProvinceId: number
  suffix: string
  taxIDNumber: string
  typeOfBusiness: string
  usStatus: string
  zipCode: string
}

export interface InvestmentDetailsInterface {
  annualIncome: string
  anualIncomeSTranslation: string
  liquidNetWorth: string
  liquidNetWorthSTranslation: string
  totalNetWorth: string
  totalNetWorthStranslation: string
  taxBracket: string
  investmentExperienceStock: string
  investmentExperienceStockTranslation: string
  investmentExperienceBond: string
  investmentExperienceBondTranslation: string
  investmentExperienceOptions: string
  investmentExperienceOptionsTranslation: string
  investmentExperienceMutualFunds: string
  investmentExperienceMutualFundsTranslation: string
  investmentExperienceAnnuities: string
  investmentExperienceAnnuitiesTranslation: string
  investExperiencePartnership: string
  investExperiencePartnershipTranslation: string
  investExperienceOther: string
  investExperienceOtherTranslation: string
  investmentObjectiveIncome: string
  investmentKnowledge: string
  investmentKnowledgeTranslation: string
}
export const NewPartyData: FinalIndividualPartyResponseInterface = {
  Address: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    line3: "",
    state: "",
    zip: "",
  },
  GovernmentID: {
    expirydate: "",
    issuedate: "",
    number: "",
    type: "",
  },
  IndividualDetails: {
    citizenshipCountry: "",
    city: "",
    cityId: 0,
    clientType: "",
    countryAlpha2Code: "",
    countryName: "",
    countryOfDualCitizenship: "",
    countryOfResidence: "",
    countryOfTaxResidence: "",
    dateOfBirth: "",
    deceasedDate: "",
    deceasedFlag: "",
    employerName: "",
    employmentStatus: "",
    firstName: "",
    fullName: "",
    lastName: "",
    maritalStatus: "",
    middleName: "",
    nameOfEntityOne: "",
    nameOfEntityTwo: "",
    numberOfDependents: "",
    occupation: "",
    partyId: 0,
    partyTypeId: 0,
    prefix: "",
    socialSecurityNumber: "",
    stateCode: "",
    stateName: "",
    stateProvinceId: 0,
    suffix: "",
    taxIDNumber: "",
    typeOfBusiness: "",
    usStatus: "",
    zipCode: "",
  },
  InvestmentDetails: {
    annualIncome: "",
    anualIncomeSTranslation: "",
    liquidNetWorth: "",
    liquidNetWorthSTranslation: "",
    totalNetWorth: "",
    totalNetWorthStranslation: "",
    taxBracket: "",
    investmentExperienceStock: "",
    investmentExperienceStockTranslation: "",
    investmentExperienceBond: "",
    investmentExperienceBondTranslation: "",
    investmentExperienceOptions: "",
    investmentExperienceOptionsTranslation: "",
    investmentExperienceMutualFunds: "",
    investmentExperienceMutualFundsTranslation: "",
    investmentExperienceAnnuities: "",
    investmentExperienceAnnuitiesTranslation: "",
    investExperiencePartnership: "",
    investExperiencePartnershipTranslation: "",
    investExperienceOther: "",
    investExperienceOtherTranslation: "",
    investmentObjectiveIncome: "",
    investmentKnowledge: "",
    investmentKnowledgeTranslation: "",
  },
  sourceoffundsresponse: "",
}

export const PartyInvestmentDetailsData: InvestmentDetailsInterface = {
  annualIncome: "",
  anualIncomeSTranslation: "",
  liquidNetWorth: "",
  liquidNetWorthSTranslation: "",
  totalNetWorth: "",
  totalNetWorthStranslation: "",
  taxBracket: "",
  investmentExperienceStock: "",
  investmentExperienceStockTranslation: "",
  investmentExperienceBond: "",
  investmentExperienceBondTranslation: "",
  investmentExperienceOptions: "",
  investmentExperienceOptionsTranslation: "",
  investmentExperienceMutualFunds: "",
  investmentExperienceMutualFundsTranslation: "",
  investmentExperienceAnnuities: "",
  investmentExperienceAnnuitiesTranslation: "",
  investExperiencePartnership: "",
  investExperiencePartnershipTranslation: "",
  investExperienceOther: "",
  investExperienceOtherTranslation: "",
  investmentObjectiveIncome: "",
  investmentKnowledge: "",
  investmentKnowledgeTranslation: "",
}

export interface IGovtIdsInterface {
  expirydate: string,
  number: string,
  type: string,
  issuedate: string,
}
//New Party Get Response Interface ends

export const IndividualClientDetails: IndividualClientResponseInterface = {
  partyId: 14673,
  partyTypeId: 32,
  prefix: 'Mr',
  firstName: 'BON',
  middleName: 'Y',
  lastName: 'MAY',
  suffix: 'Mr',
  fullName: 'BON Y MAY',
  salutation: 'Mr',
  socialSecurityNumber: '123232345',
  updateBy: '',
  partyDemographic: {
    partyDemographicId: 1,
    dateOfBirth: '02/12/1956',
    countryOfResidence: 'USA',
    countryOfTaxResidence: 'United States',
    citizenshipCountry: 'USA',
    maritalStatus: 'Unmarried',
    numberOfDependents: '1',
    employmentStatus: 'NA',
    employerName: 'Employee',
    occupation: 'Pilot',
    occupationCategoryId: 21,
    typeOfBusiness: '',
    businessPhone: '+1-123-23456',
    employerPhone: null,
    contactId: 1,
    contactTypeId: 2,
    businessAddress: '21 street, San Francisco',
    city: 'USA',
    cityId: 33,
    stateProvinceId: 1,
    stateName: 'Alaska',
    stateCode: '01',
    countryName: 'Brazil',
    countryAlpha2Code: '03',
    zipCode: '33555',
    addressTypeId: 1,
    addressId: 3,
    deceasedFlag: false,
    deceasedDate: null,
    parent: 'ABC',
    emailAddress: 'may12@gmail.com',
  },
  clientIds: [
    {
      idType: `Driver's License`,
      idNumber: '24',
      issueDate: '05/08/2022',
      expiryDate: '08/01/2025',
      countryOfIssuance: 'USA',
    },
  ],
}

export interface ClientAddressResponseInterface {
  addressId: number
  streetAddress1: string
  streetAddress2: string
  city: string
  stateProvinceId: number
  state: string
  stateCode: string
  countryAlpha2Code: string
  country: string
  zipCode: string
  addressTypeId: number
  addressType: string
  addressNickName: string
  startDate: string
  endDate: string
}

export const mockAddressData: ClientAddressResponseInterface[] = [
  {
    addressId: 90125,
    streetAddress1: '36,Wolf of Wall Street',
    streetAddress2: 'Apt 1A',
    city: 'New York',
    stateProvinceId: 1234,
    state: 'New York',
    stateCode: 'NY',
    countryAlpha2Code: '',
    country: 'USA',
    zipCode: '567234',
    addressTypeId: 1,
    addressType: 'Mailing Address',
    addressNickName: '',
    startDate: '',
    endDate: '',
  },
  {
    addressId: 90126,
    streetAddress1: '24,ABC Street',
    streetAddress2: 'Apt 2A',
    city: 'LA',
    stateProvinceId: 2345,
    state: 'California',
    stateCode: 'CA',
    countryAlpha2Code: '',
    country: 'USA',
    zipCode: '567235',
    addressTypeId: 2,
    addressType: 'Legal Address',
    addressNickName: '',
    startDate: '',
    endDate: '',
  },
]

export const PartyData: IndividualClientResponseInterface = {
  partyId: 0,
  partyTypeId: 0,
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  fullName: '',
  salutation: '',
  socialSecurityNumber: '',
  updateBy: '',
  partyDemographic: {
    partyDemographicId: 0,
    dateOfBirth: '',
    countryOfResidence: '',
    countryOfTaxResidence: '',
    citizenshipCountry: '',
    maritalStatus: '',
    numberOfDependents: '',
    employmentStatus: '',
    employerName: '',
    occupation: '',
    occupationCategoryId: 0,
    typeOfBusiness: '',
    businessPhone: '',
    employerPhone: null,
    contactId: 0,
    contactTypeId: 0,
    businessAddress: '',
    city: '',
    cityId: 0,
    stateProvinceId: 0,
    stateName: '',
    stateCode: '',
    countryName: '',
    countryAlpha2Code: '',
    zipCode: '',
    addressTypeId: 0,
    addressId: 0,
    deceasedFlag: false,
    deceasedDate: null,
    parent: '',
  },
  clientIds: [
    {
      idType: '',
      idNumber: '',
      issueDate: '',
      expiryDate: '',
      countryOfIssuance: '',
    },
  ],
}

export interface UpdateAppStatusRequestInterface {
  wipid: number
  wipstatus: number
}

export interface UpdateAppStatusResponseInterface {
  wipid: number
  partyid: number
  faid: string
  repcode: string
  wipstatus: string
  noofprods: number
  createdby: string
  createdtimestamp: string
  updatedby: string
  updatedtimestamp: string
  appid: string
  firstname: string
  lastname: string
  advisor: string
  applicationnickname: string
  appstatus: string
  trustedcontactid: string
  trustedcontactrelation: string
  istrustedcontact: boolean
}
export interface UpdateCommentSection {
  wipid: number
  comments: string
  wipstatus: string
  createdby: string
  updatedby: string
  createdtimestamp: string
  updatedtimestamp: string
}

export interface PartyContactResponseInterface {
  contactId: number
  phone: string
  phoneType: string
  contactTypeId: number
}

export interface PartyEmailResponseInterface {
  partyEmailId: number
  emailId: number
  emailAddress: string
  emailAddressType: string
  emailTypeId: number
}

export interface IWipFormData {
  partyId: number
  repCode: string
  applicationNickname: string
  accountTypes: string[]
  advisor: string
}

export interface SubsidiaryDetailsInterface {
  subEntityCode: string
  subEntityName: string
}

export interface EntityDataRequestInterface {
  adUserName: string
}

export interface EntityDataResponseInterface {
  advisorName: string
  subsidiaryDetails: SubsidiaryDetailsInterface[]
}

export interface AddDisclosureDashboard {
  wipacctid: number
  partydetails: Array<{ partyid: number; userid: string }>
  ipaddress: string
  deviceid: string
  signtimestamp: string
  createdby: string
  accttypeid: number
  acctsubtypeid: number
  lobid: number 
}

export interface IGetIndustryPayload {
  wipid: number
}
export interface IGetDepoBankPayload {
  domncode: string
}
