import { BaseSyntheticEvent } from 'react'
import { TextboxProps } from './ITextbox'
import { Main, Wrapper } from './styles'

export const Textbox = ({
  backgroundColor,
  disabled,
  type,
  placeholder,
  required,
  pattern,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  defaultValue,
  name,
  value,
  id,
  max,
  borderColor,
  innerRef,
  readOnly,
  disableBorder,
  padding,
  className,
  onKeyDown,
  border,
}: TextboxProps) => {
  const handleChange = (e: BaseSyntheticEvent) => {
    onChange && onChange(e.target.value)
  }

  return (
    <Wrapper>
      <Main
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
        pattern={pattern}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        max={max}
        onChange={handleChange}
        ref={innerRef}
        border={border}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        readOnly={readOnly}
        disableBorder={disableBorder}
        padding={padding}
        className={className}
        onKeyDown={onKeyDown}
      />
    </Wrapper>
  )
}
