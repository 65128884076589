import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: center;
  gap: 0.25rem;
`
export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
`
export const FileViewWrapper = styled.div`
  width: 100%;
  display: flex;
  /* margin-bottom: 2px; */
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: 1201px) and (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
`
export const DownloadFileWrapper = styled.div`
  display: flex;
  overflow-wrap: normal;
  gap: 0.5rem;
  border: 2px dashed ${COLORS.Border.Success2};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
`
export const DownloadFileCallingApiWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  border: 2px dashed ${COLORS.Border.PrimaryBorderStrong};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
`
export const FileNameWrapper = styled.div`
  gap: 1.5rem;
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 100%;
  width: 100%;
  gap: 0.5rem;
  padding: 0 4px;
  justify-content: flex-end;
  align-items: center;
`
export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f1f5f9;
  padding: 8px;
  gap: 0.5rem;
  border-radius: 0.25rem;
`
export const MasterWrapper = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 35%;
  top: 15%;
  right: 20%;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
  background-color: ${COLORS.Background.White};
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 40%;
  border-radius: 4px;
  border: 1px solid ${COLORS.Border.Neutral};
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  gap: 0.6rem;
`
export const ModelButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const DropDownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 50%;
  gap: 0.5rem;
`
export const CloseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  gap: 0.25rem;
  justify-content: flex-start;
  &:hover {
    text-decoration: underline;
  }
`
export const UploadBtnWrapper = styled.div`
  display: flex;
  margin-right: 24px;
`
export const MasterWrapperModel = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrappers = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrappers = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 30%;
  top: 40%;
  right: 30%;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #fff7ed;
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 40%;
  border: 1px solid #fb923c;
`
export const ModalContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.5rem;
`
export const ContentWrapperPop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`
export const ButtonWrappers = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
`

export const SelectButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
