import { useContext, useState } from 'react'
import {
  WDLabelPrimaryText,
  WDStyledApprovalHeader,
} from '../../../../components/ui/WDLabel'
import {
  TabRootContainer,
  STabs,
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledLabel,
  Wrapper1,
  ButtonWrapper,
} from './styles'
import FormApprovals from './FormApprovals/FormApprovals'
import FormAuditTrails from './FormAuditTrails/FormAuditTrails'
import FormComments from './FormComments/FormComments'
import { WDButtonOutLine } from '../../../../components/ui/WDButton'
import Button from '../../../../components/button/Button'
import { ApplicationInfoContext } from '../../../../services/applicationContext/ApplicationPageContext'
import ToastContext from '../../../../services/toast/ToastContext'
import { useNavigate } from 'react-router-dom'
import { LottieWrapper, SpinnerWrapper } from '../../../interceptor/styles'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'

const FormDisclosure = () => {
  const tabs = ['Approvals', 'Comments', 'Audit Trails']
  const navigate = useNavigate()
  const { submitAccountApplication, setApplicationResponse, wipId } =
    useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const [selectedTab, setSelectedTab] = useState(0);
  const [loaderIcon, setLoaderIcon] = useState(false)

  const submitApplication = () => {
    submitAccountApplication(Number(wipId))
      .then((res:string) => {
        setLoaderIcon(true)
        if (res) {
          setApplicationResponse(JSON.parse(res))
          setLoaderIcon(false)
          setToast(
            'success',
            'Application created with Account Number: ' + JSON.parse(res).accountNumber
          )
          
          navigate('/home')
        }
      })
      .catch(() => {
        setLoaderIcon(false)
        setToast('error', 'Account creation failed. Please try again.')
      })
  }

  return (
    <Wrapper1>
      {loaderIcon ? (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      ) : (
        <>
          <TabRootContainer>
            <STabs>
              <StyledTabList>
                {tabs.map((item: any, index: number) => {
                  return (
                    <StyledTab key={index} onClick={() => setSelectedTab(index)} className={selectedTab === index? 'is-selected' : ''}>
                      <StyledLabel>
                        <WDStyledApprovalHeader className="label_responsive">
                          {item}
                        </WDStyledApprovalHeader>
                      </StyledLabel>
                    </StyledTab>
                  )
                })}
              </StyledTabList>
              {selectedTab === 0 && <StyledTabPanel>
                <FormApprovals />
              </StyledTabPanel>}
              {selectedTab === 1 && <StyledTabPanel>
                <FormComments />
              </StyledTabPanel>}
              {selectedTab === 2 && <StyledTabPanel>
                <FormAuditTrails />
              </StyledTabPanel>}
            </STabs>
          </TabRootContainer>
          <ButtonWrapper>
            <WDButtonOutLine>
              <Button
                type="button"
                onClick={() => {
                  submitApplication()
                }}
              >
                <WDLabelPrimaryText className="label_responsive">
                  {'Submit Application'}
                </WDLabelPrimaryText>
              </Button>
            </WDButtonOutLine>
          </ButtonWrapper>
        </>
      )}
    </Wrapper1>
  )
}

export default FormDisclosure
