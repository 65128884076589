import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import Button from '../../../components/button/Button'
import { Textbox } from '../../../components/textbox/Textbox'
import { WDButtonOrange } from '../../../components/ui/WDButton'
import ENDPOINTS from '../../../services/httpEndpoints'
import { BoxGroup, ButtonWrapper, Wrapper } from './style'
import { RowWrapper } from './style'
import {
  WDLabel,
  WDLabelClient,
  WDLabelError,
} from '../../../components/ui/WDLabel'
import { WDTextbox } from '../../../components/ui/WDTextbox'
import { LabelWrapSpaced } from '../accountFeatures/styles'
import {
  DynamicFormResponseInterface,
  FormErrorInterface,
} from '../../dynamicForm/IDynamicFormControls'
import ToastContext from '../../../services/toast/ToastContext'
import { IReserveAcctNumProps } from './IReserveAcctNumProps'
import AccountFeatureContext from '../../../services/AccountFeature/AccountFeatureContext'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'

const ReservedAccountNumberPage = ({
  resNo,
  setResNo,
  setQuestionValue,
  config,
  formError,
  setFormError,
}: IReserveAcctNumProps) => {
  const {
    readOnly,
    newModelData,
    setDetectChangeProduct,
    setAccountNumber,
    setReserveError,
  } = useContext(ApplicationInfoContext)
  const { productFeatures } = useContext(AccountFeatureContext)

  const [reservedAccNum, setReservedAccNum] = useState('')
  const [currId, setCurrId] = useState(0)
  const { setToast } = useContext(ToastContext)

  useEffect(() => {
    const setReserveId = config[0].objQuestions.find(
      (item: DynamicFormResponseInterface) => item.controlerseqno === 11
    )
    if (setReserveId) setCurrId(setReserveId.sectionqsnid)
    if (resNo) {
      setReservedAccNum(resNo)
      setAccountNumber(resNo)
      setReserveError(false)
      if (setReserveId) {
        setQuestionValue((prevState: DynamicFormResponseInterface) => {
          return {
            ...prevState,
            [setReserveId.sectionqsnid]: {
              label: resNo,
              value: '',
              type: setReserveId.controlid,
            },
          }
        })
      }
    } else {
      setReserveError(false)
      if (setReserveId) {
        setQuestionValue((prevState: DynamicFormResponseInterface) => {
          return {
            ...prevState,
            [setReserveId.sectionqsnid]: {
              label: resNo,
              value: '',
              type: setReserveId.controlid,
            },
          }
        })
      }
    }
  }, [resNo])

  async function createResAccNum() {
    if (
      newModelData.wipAccountDetailslist &&
      newModelData.wipAccountDetailslist.length > 0
    ) {
      const payload = {
        officecode: 'AW',
        wipacctid: newModelData.wipAccountDetailslist[0].wipacctid,
      }
      await axios
        .post(ENDPOINTS.GET_RESERVEDACCTNUM, payload)
        .then(async (res) => {
          setDetectChangeProduct(true)
          setReservedAccNum(res.data.resacctnumber)
          setResNo(res.data.resacctnumber)
          setAccountNumber(res.data.resacctnumber)
          setReserveError(false)
          const setReserveId = config[0].objQuestions.find(
            (item: DynamicFormResponseInterface) => item.controlerseqno === 11
          )
          if (setReserveId) {
            setQuestionValue((prevState: DynamicFormResponseInterface) => {
              return {
                ...prevState,
                [setReserveId.sectionqsnid]: {
                  label: res.data.resacctnumber,
                  value: '',
                  type: setReserveId.controlid,
                },
              }
            })
            setFormError((prevState: FormErrorInterface[]) => {
              return {
                ...prevState,
                [setReserveId.sectionqsnid]: '',
              }
            })
          }
        })
        .catch(() => {
          setToast('error', 'Error while generating reserved account number')
        })
    }
  }

  function getDisabled() {
    if (!reservedAccNum) {
      return false
    }
    return reservedAccNum ? true : false
  }

  return (
    <Wrapper>
      <LabelWrapSpaced>
        <WDLabel className="label_responsive">
          {'Reserve Account Number'}
        </WDLabel>
        {!readOnly && formError[currId] && (
          <WDLabelError>{'Required'}</WDLabelError>
        )}
      </LabelWrapSpaced>
      <BoxGroup>
        {!readOnly && !reservedAccNum && (
          <ButtonWrapper
            id="rounded"
            className={reservedAccNum ? 'fullWidth' : ''}
          >
            <WDButtonOrange>
              <Button
                type={'button'}
                disabled={getDisabled()}
                onClick={createResAccNum}
              >
                {'Generate'}
              </Button>
            </WDButtonOrange>
          </ButtonWrapper>
        )}
        {readOnly ? (
          <WDLabelClient className="label_responsive">
            {reservedAccNum ? reservedAccNum : '--'}
          </WDLabelClient>
        ) : (
          <RowWrapper>
            {reservedAccNum ? (
              <WDTextbox>
                <Textbox disabled={true} type="text" value={reservedAccNum} />
              </WDTextbox>
            ) : (
              <Textbox disabled={true} type="text" value={reservedAccNum} />
            )}
          </RowWrapper>
        )}
      </BoxGroup>
    </Wrapper>
  )
}
export default ReservedAccountNumberPage
