import { useContext, useEffect, useState } from 'react'
import {
  CheckBoxWrapper,
  ColumnOne,
  FeatureInfoColumn,
  FeatureInfoRow,
  FormContainer,
  ImageWrapper,
  RadioOptionWrap,
  RadioSection,
  RadioTitle,
  RowWrapper,
  SectionWrap,
} from './style'
import { Data } from '../RetirementData'
import { ResponsiveWrapper } from '../../../../pages/accountApplicationPage/styles'
import { StyledDocumentPanel } from '../../accountInformation/styles'
import { TitleGrouping, WipHeadingWrapper } from '../../formWip/styles'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../components/svg/SvgChevron'
import { COLORS } from '../../../../theme/Colors'
import {
  WDLabel,
  WDLabelBold,
  WDLabelClient,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../components/ui/WDLabel'
import { ApplicationInfoContext } from '../../../../services/applicationContext/ApplicationPageContext'
import { SvgProductType } from '../../../../components/svg/SvgHeaders'
import { DividerWrapper } from '../../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import Divider from '../../../../components/divider/Divider'
import Label from '../../../../components/label/Label'
import { WDTextboxBorderRadius } from '../../../../components/ui/WDTextbox'
import { Textbox } from '../../../../components/textbox/Textbox'
import SelectDropdown from '../../../selectDropdown/SelectDropdown'
import {
  AccFeatureWrapper,
  AccountWrapper,
} from '../../accountForms/formIndividual/tabs/AccountFeature/styles'
import { RowsWrapper } from '../../../../components/customCalendar/styles'
import { SectionWrapper } from '../styles'
import CheckBoxNew from '../../../../components/checkbox/CheckBoxNew'
import { Textarea } from '../../../../components/textArea/Textarea'
import { OptionProps } from '../../../selectDropdown/ISelectDropDown'
import {
  FundingButtonWrapper,
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../electronicPrefs/styles'
import { WDButtonOutLine } from '../../../../components/ui/WDButton'
import Button from '../../../../components/button/Button'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import CustomCalendar from '../../../../components/customCalendar/CustomCalendar'
import { DocumentRowContainer } from '../PersonalInformation/style'
import Radio from '../../../../components/extra/radio/Radio'
import ToastContext from '../../../../services/toast/ToastContext'
import { SpinnerWrapper } from '../../accountForms/formIndividual/tabs/ProductFeatures/style'
import { LottieWrapper } from '../../../interceptor/styles'
import { GetWipRequestInterface } from '../../formWip/IFormWip'
import { getWipDetails } from '../../../../services/formRequest/FormRequestAPI'

const BestInterest = () => {
  const {
    readOnly,
    bestOfInterest,
    setBestOfInterest,
    autoSaveFunction,
    newModelData,
    wipId,
    setNewModelData,
  } = useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const [confirmation, setConfirmation] = useState<OptionProps>({
    label: '',
    value: '',
  })
  const [toggleHeader, setToggleHeader] = useState(true)
  const [loader, setLoader] = useState(false)
  const [rollOver, setRollOver] = useState<OptionProps>({
    label: '',
    value: '',
  })
  const [radioOptions] = useState<OptionProps[]>([
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ])
  const [RollOverOptions] = useState<OptionProps[]>([
    {
      label: 'Broker Account to advisory Account​',
      value: '0',
    },
    {
      label: 'Change of Employment​',
      value: '1',
    },

    {
      label: 'Retirement​',
      value: '2',
    },
    {
      label: 'Termination of Retirement Plan​',
      value: '3',
    },
    {
      label: 'In-Service Distribution',
      value: '4',
    },
    {
      label: 'Other',
      value: '5',
    },
  ])

  const getValues = (key: string) => {
    const data: any = bestOfInterest
    return data[key] || ''
  }

  useEffect(() => {
    const response = newModelData?.wipAccountDetailslist[0]?.bestofinterest
      ? JSON.parse(newModelData?.wipAccountDetailslist[0]?.bestofinterest)
      : []
    if (response) {
      setBestOfInterest(response)
      setConfirmation({
        value: response?.AccountOpenDecision,
        label: response?.AccountOpenDecision,
      })
      setRollOver({
        value: response?.RolloverReasonId,
        label: response?.RolloverReason,
      })
    }
  }, [newModelData])

  const onChangeValue = (key: string, value: string | Date | number) => {
    setBestOfInterest((prevBestOfInterest) => ({
      ...prevBestOfInterest,
      [key]: value,
    }))
  }

  const onUpdateInfo = () => {
    setLoader(true)
    autoSaveFunction()
      .then((res) => {
        setLoader(false)
        if (res && res === 200) {
          const payload: GetWipRequestInterface = {
            appId: '1',
            wipId: parseInt(wipId),
          }
          getWipDetails(payload).then((getWipRecord) => {
            setNewModelData(getWipRecord.data)
          })
          setToast('success', 'Best Interest data saved')
        } else {
          setToast('error', 'Failed to update the record.')
        }
      })
      .catch(() => {
        setLoader(false)
        setToast('error', 'An error occurred while updating the record.')
      })
  }

  const formattingDate = (paramDate: string) => {
    let formattedDate = ''
    if (paramDate) {
      formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
    return formattedDate
  }

  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel>
        <WipHeadingWrapper>
          <TitleGrouping
            id="red"
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            <SvgProductType />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Best Interest'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>

          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            {!toggleHeader && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            {loader ? (
              <SpinnerWrapper>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
              </SpinnerWrapper>
            ) : (
              <AccFeatureWrapper>
                <AccountWrapper>
                  <FormContainer id="FormContainer">
                    <RowsWrapper>
                      <RowWrapper>
                        <ColumnOne>
                          <WDLabelBold className="label_responsive">
                            Was the decision to open this account the result of
                            a recommendation or solicitation by a Financial
                            Advisor or Registered Representative?
                          </WDLabelBold>
                          <WDLabel className="label_responsive">
                            *By selecting ‘No’, you are acknowledging that you
                            have confirmed the decision to open this account was
                            not the result of a recommendation or solicitation.
                          </WDLabel>
                          {readOnly ? (
                            <WDLabel className="label_responsive">
                              {confirmation.label ?? '--'}
                            </WDLabel>
                          ) : (
                            <SelectDropdown
                              value={confirmation}
                              width={'25%'}
                              options={radioOptions}
                              borderColor={COLORS.Border.Gray50}
                              borderRadius={'0.5rem'}
                              placeholderColor={COLORS.Text.NeutralTextWeak}
                              padding={'0 8px'}
                              onChange={(option) => {
                                if (option) {
                                  setConfirmation(option)
                                  setBestOfInterest(
                                    (prevBestOfInterest: any) => {
                                      if (option.value === 'No') {
                                        setRollOver({
                                          label: '',
                                          value: '',
                                        })
                                        return {
                                          AccountOpenDecision: option.value,
                                        }
                                      } else {
                                        return {
                                          ...prevBestOfInterest,
                                          AccountOpenDecision: option.value,
                                        }
                                      }
                                    }
                                  )
                                }
                              }}
                            />
                          )}
                        </ColumnOne>

                        {confirmation.label === 'Yes' ? (
                          <ColumnOne>
                            <SectionWrap
                              id={readOnly ? 'best_of_interest_read_only' : ''}
                            >
                              <DocumentRowContainer>
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    <Label>{'Date of Recommendation'}</Label>
                                  </WDLabelClient>
                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {bestOfInterest?.RecommendationDate
                                            ? formattingDate(
                                                bestOfInterest?.RecommendationDate
                                              )
                                            : '--'}
                                        </Label>
                                      </WDLabelClient>
                                    ) : (
                                      <CustomCalendar
                                        value={
                                          bestOfInterest?.RecommendationDate
                                        }
                                        onChange={(value) => {
                                          onChangeValue(
                                            'RecommendationDate',
                                            value
                                          )
                                        }}
                                      />
                                    )}
                                  </WDTextboxBorderRadius>
                                </FeatureInfoColumn>
                              </DocumentRowContainer>
                              <DocumentRowContainer>
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    <Label>{'Plan Name'}</Label>
                                  </WDLabelClient>
                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {bestOfInterest?.PlanName ?? '--'}
                                        </Label>
                                      </WDLabelClient>
                                    ) : (
                                      <Textbox
                                        type={'text'}
                                        value={bestOfInterest?.PlanName}
                                        onChange={(value) => {
                                          onChangeValue('PlanName', value)
                                        }}
                                      />
                                    )}
                                  </WDTextboxBorderRadius>
                                </FeatureInfoColumn>
                              </DocumentRowContainer>
                              <DocumentRowContainer>
                                <FeatureInfoColumn id="nowrap">
                                  <WDLabelClient className="label_responsive">
                                    <Label>
                                      {
                                        'Amount of Rollover/Transfer/Cpmversions($)'
                                      }
                                    </Label>
                                  </WDLabelClient>
                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {bestOfInterest?.RolloverAmount ??
                                            '--'}
                                        </Label>
                                      </WDLabelClient>
                                    ) : (
                                      <Textbox
                                        type={'text'}
                                        value={bestOfInterest?.RolloverAmount}
                                        onChange={(value) => {
                                          onChangeValue('RolloverAmount', value)
                                        }}
                                      />
                                    )}
                                  </WDTextboxBorderRadius>
                                </FeatureInfoColumn>
                              </DocumentRowContainer>
                              <DocumentRowContainer>
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    <Label>{'Reason for Rollover'}</Label>
                                  </WDLabelClient>
                                  {readOnly ? (
                                    <WDLabelClient className="label_responsive">
                                      <Label>{rollOver.label ?? '--'}</Label>
                                    </WDLabelClient>
                                  ) : (
                                    <SelectDropdown
                                      width={'100%'}
                                      options={RollOverOptions}
                                      borderColor={COLORS.Border.Gray50}
                                      borderRadius={'0.5rem'}
                                      placeholderColor={
                                        COLORS.Text.NeutralTextWeak
                                      }
                                      padding={'0 8px'}
                                      onChange={(e: any) => {
                                        setRollOver(e)
                                        setBestOfInterest(
                                          (prevBestOfInterest) => ({
                                            ...prevBestOfInterest,
                                            RolloverReasonId: +e.value,
                                            RolloverReason: e.label,
                                          })
                                        )
                                      }}
                                      value={rollOver}
                                    />
                                  )}
                                </FeatureInfoColumn>
                              </DocumentRowContainer>
                            </SectionWrap>
                            <SectionWrapper>
                              <FeatureInfoColumn className="distribution">
                                {rollOver.label ===
                                'In-Service Distribution' ? (
                                  <>
                                    <SectionWrapper>
                                      <WDLabel className="label_responsive">
                                        <Label>
                                          {
                                            'If In-Service Distribution is selected, has the Client reached age 59 1/2'
                                          }
                                        </Label>
                                      </WDLabel>
                                    </SectionWrapper>
                                    <CheckBoxWrapper>
                                      <Radio
                                        defaultChecked={true}
                                        title={'Yes'}
                                        key={'Yes'}
                                        name={`answer-${'IsServiceDistribution'}`}
                                        value={'Yes'}
                                        onChange={() =>
                                          setBestOfInterest({
                                            ...bestOfInterest,
                                            ['IsServiceDistribution']: 'Yes',
                                          })
                                        }
                                        checkBoxId={`answer-IsServiceDistribution-yes`}
                                        checked={
                                          bestOfInterest.IsServiceDistribution ==
                                          'Yes'
                                        }
                                        disabled={readOnly}
                                      />
                                      <Radio
                                        title={'No'}
                                        key={'No'}
                                        name={`answer-${'IsServiceDistribution'}`}
                                        value={'No'}
                                        onChange={() =>
                                          setBestOfInterest({
                                            ...bestOfInterest,
                                            ['IsServiceDistribution']: 'No',
                                          })
                                        }
                                        checkBoxId={`answer-IsServiceDistribution-no`}
                                        checked={
                                          bestOfInterest.IsServiceDistribution ==
                                          'No'
                                        }
                                        disabled={readOnly}
                                      />
                                    </CheckBoxWrapper>
                                  </>
                                ) : null}
                                {rollOver.label === 'Other' ? (
                                  <SectionWrapper id="width">
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'If Other is selected:'}</Label>
                                      </WDLabelClient>
                                      <WDTextboxBorderRadius className="inputBox_responsive">
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            <Label>
                                              {bestOfInterest?.RolloverReasonOthers ??
                                                '--'}
                                            </Label>
                                          </WDLabelClient>
                                        ) : (
                                          <Textbox
                                            value={
                                              bestOfInterest?.RolloverReasonOthers
                                            }
                                            onChange={(value) => {
                                              onChangeValue(
                                                'RolloverReasonOthers',
                                                value
                                              )
                                            }}
                                          />
                                        )}
                                      </WDTextboxBorderRadius>
                                    </FeatureInfoColumn>
                                  </SectionWrapper>
                                ) : null}
                                <SectionWrapper>
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Current Portfolio'}</Label>
                                      </WDLabelClient>
                                      {readOnly ? (
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {bestOfInterest?.CurrentPortfolio ??
                                              '--'}
                                          </Label>
                                        </WDLabelClient>
                                      ) : (
                                        <Textarea
                                          value={
                                            bestOfInterest?.CurrentPortfolio
                                          }
                                          onChange={(value) => {
                                            onChangeValue(
                                              'CurrentPortfolio',
                                              value
                                            )
                                          }}
                                          placeholder={''}
                                        />
                                      )}
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Recommendations'}</Label>
                                      </WDLabelClient>
                                      <WDTextboxBorderRadius className="inputBox_responsive">
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            <Label>
                                              {bestOfInterest?.Recommendations ??
                                                '--'}
                                            </Label>
                                          </WDLabelClient>
                                        ) : (
                                          <Textarea
                                            value={
                                              bestOfInterest?.Recommendations
                                            }
                                            onChange={(value) => {
                                              onChangeValue(
                                                'Recommendations',
                                                value
                                              )
                                            }}
                                            placeholder={''}
                                          />
                                        )}
                                      </WDTextboxBorderRadius>
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                </SectionWrapper>
                                <SectionWrapper>
                                  <RadioSection>
                                    {Data.map((item, index) => {
                                      return (
                                        <SectionWrapper
                                          key={item.keyName}
                                          className="radio_space"
                                        >
                                          <RadioTitle>
                                            <WDLabel className="label_responsive">
                                              {item.question}
                                            </WDLabel>
                                          </RadioTitle>
                                          <RadioOptionWrap
                                            style={{
                                              display: 'flex',
                                              gap: '1rem',
                                            }}
                                          >
                                            <Radio
                                              title={'Yes'}
                                              key={'Yes'}
                                              name={`answer-${item.keyName}`}
                                              value={'Yes'}
                                              onChange={() =>
                                                setBestOfInterest({
                                                  ...bestOfInterest,
                                                  [item.keyName]: 'Yes',
                                                })
                                              }
                                              checkBoxId={`answer-${item.keyName}-yes`}
                                              checked={
                                                getValues(item.keyName) == 'Yes'
                                              }
                                              disabled={readOnly}
                                            />
                                            <Radio
                                              defaultChecked
                                              title={'No'}
                                              key={'No'}
                                              name={`answer-${item.keyName}`}
                                              value={'No'}
                                              onChange={() =>
                                                setBestOfInterest({
                                                  ...bestOfInterest,
                                                  [item.keyName]: 'No',
                                                })
                                              }
                                              checkBoxId={`answer-${item.keyName}-no`}
                                              checked={
                                                getValues(item.keyName) == 'No'
                                              }
                                              disabled={readOnly}
                                            />
                                            <Radio
                                              title={'N/A'}
                                              key={'N/A'}
                                              name={`answer-${item.keyName}`}
                                              value={'N/A'}
                                              onChange={() =>
                                                setBestOfInterest({
                                                  ...bestOfInterest,
                                                  [item.keyName]: 'N/A',
                                                })
                                              }
                                              checkBoxId={`answer-${item.keyName}-n/a`}
                                              checked={
                                                getValues(item.keyName) == 'N/A'
                                              }
                                              disabled={readOnly}
                                            />
                                          </RadioOptionWrap>
                                        </SectionWrapper>
                                      )
                                    })}
                                  </RadioSection>
                                </SectionWrapper>
                                <SectionWrapper id="width">
                                  <FeatureInfoColumn>
                                    <WDLabel className="label_responsive">
                                      {'Other (describe below)'}
                                    </WDLabel>
                                    <WDTextboxBorderRadius className="inputBox_responsive">
                                      {readOnly ? (
                                        <WDLabel className="label_responsive">
                                          {bestOfInterest?.Other ?? '--'}
                                        </WDLabel>
                                      ) : (
                                        <Textbox
                                          value={bestOfInterest?.Other}
                                          onChange={(value) => {
                                            onChangeValue('Other', value)
                                          }}
                                        />
                                      )}
                                    </WDTextboxBorderRadius>
                                  </FeatureInfoColumn>
                                </SectionWrapper>
                              </FeatureInfoColumn>
                            </SectionWrapper>
                          </ColumnOne>
                        ) : null}
                      </RowWrapper>
                    </RowsWrapper>
                  </FormContainer>
                </AccountWrapper>
              </AccFeatureWrapper>
            )}

            <FundingButtonWrapper>
              {!readOnly && (
                <WDButtonOutLine>
                  <Button type={'button'} onClick={onUpdateInfo}>
                    {loader ? (
                      <SpinnerButtonWrapper>
                        <LottieButtonWrapper>
                          <Lottie animationData={Loader} loop={true} />
                        </LottieButtonWrapper>
                      </SpinnerButtonWrapper>
                    ) : (
                      <WDLabelPrimaryText className="label_responsive">
                        {'Save'}
                      </WDLabelPrimaryText>
                    )}
                  </Button>
                </WDButtonOutLine>
              )}
            </FundingButtonWrapper>
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default BestInterest
