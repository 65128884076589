import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { TextSizes, TextWeight } from '../../theme/Sizes'

export const ContentWrapper = styled.div`
  display: flex;
  /* background-color: ${COLORS.Background.White}; */
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`

export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  flex: 70%;
`
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  padding: 0.5rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 2rem;
  background-color: #cdefc4;
  color: ${COLORS.Text.NeutralText};
  cursor: default;
  &#secondary {
    background-color: ${COLORS.Background.OffWhite7};
  }
`
export const ContentReviewWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  //box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  max-height: 76vh;
  overflow-y: auto;
  padding: 1.5rem;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`

export const FileUploadWrapper = styled.div`
  display: flex;
  flex: 0 1 40%;
  gap: 1rem;
  flex-direction: column;
`
export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  width: 100%;
  justify-content: flex-start;
`
export const UploadedFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  width: 100%;
  justify-content: flex-start;
  gap: 0.25rem;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SaveButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-end;
`
export const RemoveButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-start;
`
export const FirstParagraph = styled.div`
  display: flex;
  gap: 0.25rem;
`
export const SecondParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const DocListContainer = styled.ul`
  list-style-type: disc;
  color: ${COLORS.Text.Gray};
  padding: 0 2rem;
`
export const DoclistItem = styled.li``
export const FileNameWrapper = styled.div`
  text-overflow: ellipsis;
  display: flex;
  flex: 0 1 100%;
  /* width: 610px; */
  padding: 4px;
  gap: 0.5rem;
  border-radius: 0.25rem;
  justify-content: flex-start;
  gap: 0.25rem;
  -webkit-box-align: center;
  align-items: center;
  background-color: #f1f5f9;
`
export const FileViewWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  /* padding: 0 0.25rem; */
  flex-wrap: wrap;
  flex-direction: column;
`

export const MainWrapper = styled.div`
  background: ${COLORS.Background.White};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* border: 1px solid ${COLORS.Border.Neutral}; */
  border-radius: 4px;
  justify-content: center;
  align-content: center;
`
export const UploadedFilesWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`
export const DocumentReviewWrapper = styled.div`
  background: ${COLORS.Background.Gray30};
`
export const MasterWrapper = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 30%;
  top: 40%;
  right: 30%;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #fff7ed;
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 40%;
  border: 1px solid #fb923c;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
`
export const ModalContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.5rem;
`
export const ModelButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const RequiredWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  &#List-doc {
    font-family: 'SourceSansPro-Regular';
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }
`
export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0px;
`
export const SuperScript = styled.sup`
  font-size: 1rem;
  color: ${COLORS.Text.Danger};
`
export const FormDocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`
export const HeadingTop = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`
export const DocNameWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`
export const DocWrapper = styled.div`
  display: flex;
  flex: 0 1 35%;
  gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
  &#upload {
    flex: 0 1 50%;
  }
  &#pending_doc {
    flex: 0 1 42.5%;
  }
`
export const DropDownWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: center;
`
export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 85rem;
  gap: 0.5rem;
  padding: 0.2rem 0 0;
`
export const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  height: 48px;
  padding: 7px;
  /* border: 1px solid ${COLORS.Background.Gray50}; */
  border-radius: 0.25rem;
  background: #f1f5f9;
  align-items: center;
`
export const CollapseReviewHeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
export const CollapseHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 90%;
  width: 100%;
  gap: 8px;
  align-self: center;
  align-items: center;
`
export const CollapseHeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40%;
`
export const CollapseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  align-self: center;
  justify-content: flex-end;
  width: 100%;
`
export const ReviewWrapper = styled.div`
  display: flex;
  flex: 0 1 56.5%;
  justify-content: space-between;
  width: 100%;
`
export const ChevronWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  order: 2;
  flex-grow: 0;
  color: inherit;
  gap: 0.5rem;
  button {
    z-index: 99;
    position: relative;
  }
`
export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  order: 2;
  flex-grow: 0;
  color: inherit;
  gap: 0.25rem;
`
export const AcknowledgementParagraph = styled.div`
  display: flex;
  gap: 0.25rem;
`
export const ButtonBorderOutline = styled.div`
  border: 1px solid #1f5eb7;
  border-radius: 8px;
`
export const DocumentNameWrapper = styled.div`
  font-weight: ${TextWeight[0]};
  font-size: ${TextSizes[3]};
  line-height: ${TextSizes[3]};
  color: ${COLORS.UI.Gray};
  font-style: normal;
`
export const DocumentHoverWrapper = styled.div`
  &:hover {
    text-decoration: underline;
  }
`
export const DocumentTitleWrapper = styled.div`
  width: 100%;
`
export const LottieButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 2rem;
  padding: 0.5rem;
`
export const StyledDocumentPanel = styled.div`
  display: grid;
  grid-column: 1/13;
  //flex-direction: column;
  background: ${COLORS.Background.White};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border: 1px solid ${COLORS.Background.White};
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  &#remove {
    box-shadow: none !important;
    background: none !important;
    border: 0px !important;
  }
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  }
  @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const SVGButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
  position: relative;
  &#red {
    bottom: 52px;
    right: -28px;
  }
`
export const ListDocsWrapper = styled.div`
  display: flex;
  /* width: 240px; */
  flex: 0 1 25%;
  gap: 0.3rem;
  height: fit-content;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  /* white-space: nowrap; */
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
`
export const JointWrapper = styled.div`
  display: flex;
  width: 35%;
  flex-direction: column;
  gap: 1rem;
`
export const TitleDoc = styled.div`
  color: ${COLORS.Text.PrimaryTextStrong};
  &#title {
    font-weight: 600;
  }
  &#List-doc {
    font-weight: 600;
    font-family: 'SourceSansPro-Regular';
  }
`
export const DocParentWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
export const ListUploadWrapper = styled.div`
  margin-top: 2px;
  visibility: hidden;
`
