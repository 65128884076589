const appConfig = {
  envName: "DEV",
  appName: "AOP UI",
  appDomain: "https://aopui.dev.wedbush.com",
  apiEndPoint: "https://dev.api.wedbush.com/aopexperienceapi",
  extapiEndPoint: "https://dev.api.wedbush.com",
  b2cClientId: "f71f902f-d31e-4af6-9166-d5a5bfb97915",
  b2cScope: "https://wedbushclientsdev.onmicrosoft.com/3a0d6004-ab60-4056-b6eb-3d14aa33b105/api_access",
  b2cSecret: "epQ8Q~mRV.~SxJJvZe6xoPYkzeR0tiFxkMrtFacD",
  b2cDomain: "https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_SUSI_ADFED"
}

export default appConfig
