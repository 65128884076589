import styled from 'styled-components'
import { textboxProps } from './ITheming'

export const StyledTextbox = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  border-radius: 8px;
  width: 100%;
  input {
    width: 100%;
    height: 2rem;
    border-radius: 8px;
  }
`
export const StyledTextboxBorderRadius = styled.div`
  display: grid;
  align-items: center;
  height: 2rem;
  width: 100%;
  input {
    padding: 0 0.8rem;
    width: 100%;
    border-radius: 8px;
    height: 2rem;
    &.liquid_Block_input{
      padding: 20px 0.8rem !important;
    }
  }
`
export const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  border-radius: 0.25rem;
  width: 100%;
  z-index: 9999;
`
export const StyledCalendar = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  border-radius: 8px;
  max-width: 9rem;
  input {
    padding: 0.25rem 0.5rem;
    width: 100%;
    height: 2rem;
  }
`
export const WDTextbox = ({ children }: textboxProps) => {
  return <StyledTextbox>{children}</StyledTextbox>
}

export const WDTextboxBorderRadius = ({
  children,
  className,
}: textboxProps) => {
  return (
    <StyledTextboxBorderRadius className={className ? className : ''}>
      {children}
    </StyledTextboxBorderRadius>
  )
}

export const WDSelect = ({ children }: textboxProps) => {
  return <StyledSelect>{children}</StyledSelect>
}

export const WDCalendar = ({ children }: textboxProps) => {
  return <StyledCalendar>{children}</StyledCalendar>
}
