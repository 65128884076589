import styled from 'styled-components'
import { COLORS } from '../../../../../../theme/Colors'

export const BoxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
`
export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TableWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
export const DisabledWrapper = styled.div`
  //
  ${({ readOnly, checked }: any) =>
    readOnly &&
    `
      span {
       background: ${COLORS.Text.Gray40} !important;
        cursor: no-drop;
       
          background: ${
            checked ? COLORS.Background.Primary5 : '#FFFF'
          } !important;
 } }
    `}
`
