import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${COLORS.UI.Text};
  background-color: ${COLORS.Background.NeutralBackgroundMedium};
  padding: 0px 40px 0px 64px;
  border-bottom: 1px solid ${COLORS.UI.NeutralBorderWeak};
  button {
    background-color: transparent;
  }
`
export const LabelDropDownWrapper = styled.div`
  padding-bottom: 8px;
`
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  padding-bottom: 12px;
`
export const AccountsDropdownWrapper = styled.div`
  display: flex;
  .dropdown__menu-list {
    max-height: 712px;
    width: 504px;
  }
`
export const StyledHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.25rem;
  padding: 0.5rem;
  background: #d5f8ff;
  border: rgb(0, 169, 206) 1px solid;

  font-size: 16px;
  font-weight: 600;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 50%;
  gap: 0.5rem;
  padding: 0 0 1rem;
`
export const ContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.Background.NeutralBackgroundMedium};
  padding: 1rem 1rem 0;
`
export const HeaderWrapper = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: #0f172a;
`
