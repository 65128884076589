import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .dropdown__menu {
    z-index: 9999999 !important;
    ::-webkit-scrollbar {
      background: ${COLORS.Background.NeutralBackgroundMedium};
      width: 5px;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      height: 100px;
      background: ${COLORS.UI.Brand1};
      top: 8px;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(167, 175, 188, 0.3);
    }
    ::-webkit-scrollbar-track {
      border-radius: 8px;
    }
  }
  @media screen and (min-width: 1440px) {
    .dropdown__control {
      height: 3rem !important;
      border-radius: 0.5rem !important;
      font-size: 16px !important;
      .dropdown__value-container {
        padding: 0px;
      }
    }
  }
  @media (min-width: 1280px) and (min-width: 1440px) {
    .dropdown__control {
      .dropdown__value-container {
        padding: 0px;
      }
    }
  }
  @media (min-width: 1024px) and (min-width: 1280px) {
    .dropdown__control {
      .dropdown__value-container {
        padding: 0px;
      }
    }
  }
`
