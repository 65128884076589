import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

interface TableProps {
  maxHeight: string
}

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  &#client_search {
    tbody tr:hover {
      background-color: ${COLORS.Background.Primary20};
      .clientName {
        color: black !important;
      }
    }
  }
  table {
    text-align: left;
    width: 100%;
    border-spacing: 0;
    color: ${() => COLORS.Text.NeutralText};
    border: 1px solid ${COLORS.Border.Gray50};
    tbody {
     z-index: 1;
      height: 2rem;
      width: 100%;
      overflow: auto;
      tr {
        position:relative;
        &.selected {
          background-color: ${COLORS.Background.Neutral};
          td {
            color: ${COLORS.Text.PrimaryText};
            font-family: 'SourceSansPro-SemiBold';
          }
        }
        td {
          border: none;
          /* border-bottom: 1px solid ${COLORS.Border.Gray50}; */
          text-align: left;
          width: fit-content;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 1rem 0.2rem;
        }
      }
    }

    th {
      margin: 0rem 0rem;
      font-family: 'SourceSansPro-SemiBold';
      font-size: 0.75rem;
      background-color: #f8f8f8;
      text-align: left;
      padding: 0.025rem 0.25rem;
      &:not(:last-of-type) {
        /* border-right: 1px solid ${() => COLORS.Border.NeutralWeak}; */
      }
      border-bottom: 1px solid ${COLORS.Border.Gray50};
    }

    tr {
      width: 100%;
      &.Gray {
        background-color: ${COLORS.Background.NeutralMedium};
      }
      td {
        margin: 0;
        font-size: 0.875rem;
        text-align: left;
        padding: 0.125rem 0.25rem;
        border-right: 1px solid ${() => COLORS.Border.Neutral};
        width: fit-content;
      }
    }

    tfoot {
      tr {
        display: table-row;
        align-items: center;
        td {
          display: table-cell;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
    th {
      font-style: normal;
      text-align: left;
      &:not(:last-of-type) {
        text-align: left;
      }
    }
  }
`
export const SelectDropdownCSS = styled.div`
  /* border-bottom: 1px solid ${COLORS.Border.Gray50}; */
  padding: 0.1rem;
  font-size: 0.775rem;
`
export const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const ThContentWrapper = styled.div`
  width: 100%;
  font-family: SourceSansPro-SemiBold;
`
export const ReactTable = styled.div<TableProps>`
  height: fit-content;
  display: flex;
  background-color: ${COLORS.Text.White};
  width: 100%;
  overflow-y: auto;
  max-height: ${(props: TableProps) =>
    props.maxHeight ? props.maxHeight : `calc(100vh - 16rem)`};
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${COLORS.Background.White};
  }
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.Background.Primary10};
    border-radius: 4px;
  }
`
export const TableHead = styled.thead`
  /* position: sticky; */
  top: 0rem;
  margin: 0;
  background-color: ${COLORS.Text.White};
  th {
    padding-left: 4px !important;
    height: 32px;
  }
`
export const TableWrapper = styled.table``
export const TableRow = styled.tr`
  &.Gray {
    background-color: ${COLORS.Background.NeutralMedium};
  }
`
export const TableHeaderCell = styled.th`
  height: 1.5rem;
  /* background-color: ${COLORS.Background.Gray110}; */
`
export const TableBody = styled.tbody`
  tr {
    &.Gray {
      background-color: ${COLORS.Background.NeutralMedium};
    }
    td {
      padding: 8px !important;
      height: 32px;
      text-align: left;
      /* :nth-child(8),:nth-child(9) {
        width: 2.5%;
      }
      :nth-child(2){
        width: 20%;
      }
      :nth-child(3),:nth-child(4){
        width: 15%;
      } */
      /* :nth-child(4),
      :nth-child(5) {
        width: 10%;
        max-width: 210px;
      }
      :nth-child(6),
      :nth-child(7) {
        width: 7%;
        max-width: 120px;
      }
      :nth-child(8),
      :nth-child(9) {
        width: 6%;
      }
      :nth-child(10) {
        width: 5%;
      } */
    }
  }
`
export const TableFooter = styled.tfoot``
export const TableDataCell = styled.td`
  cursor: pointer;
  text-align: left;
  font-size: 0.775rem;
`
export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem;
  svg {
    height: 8.5rem;
    width: 8.5rem;
  }
`
export const TotalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 8px;
  padding-right: 12px;
`
export const TableStatusWrapper = styled.div`
  display: flex;
`
export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 16px;
  background: ${COLORS.Background.Border} !important;
  border-radius: 1px;
`
export const TableDropdownWrapper = styled.div`
 z-index: 9999999 !important;
 position: absolute;
 margin: 1;
`