//Global styles
import SRPLogo from '../assets/logos/SRPLogo.svg'
import Notifications from '../assets/icons/Notifications.svg'
import Bell from '../assets/icons/Bell.svg'
import Calendar from '../assets/icons/Calendar.svg'
import ChevronLeft from '../assets/icons/ChevronLeft.svg'
import AttachmentFile from '../assets/icons/Attachment.svg'
import ChevronLeftBlue from '../assets/icons/ChevronLeftBlue.svg'
import ChevronRight from '../assets/icons/ChevronRight.svg'
import ChevronDown from '../assets/icons/ChevronDown.svg'
import ChevronUp from '../assets/icons/ChevronUp.svg'
import Profile from '../assets/icons/Profile.svg'
import UploadFile from '../assets/icons/UploadFile.svg'
import SaveDraft from '../assets/icons/SaveDraft.svg'
import Cancel from '../assets/icons/Cancel.svg'
import QuestionMark from '../assets/icons/QuestionMark.svg'
import Success from '../assets/icons/Success.svg'
import Critical from '../assets/icons/Critical.svg'
import Warning from '../assets/icons/Warning.svg'
import Default from '../assets/icons/Default.svg'
import LogOut from '../assets/icons/Logout.svg'
import Neutral_Search from '../assets/icons/SearchGray.svg'
import White_Search from '../assets/icons/SearchWhite.svg'
import White_Plus from '../assets/icons/Plus.svg'
import ArrowDown from '../assets/icons/ArrowDown.svg'
import Exclamation from '../assets/icons/ExclamationWarning.svg'
import BlueChevronDown from '../assets/icons/ChevronDownPrimary.svg'
import BlueChevronUp from '../assets/icons/ChevronUpPrimary.svg'
import RightMark from '../assets/icons/RightMark.svg'
import Document from '../assets/icons/Document.svg'
import Download from '../assets/icons/Download.svg'
import DownloadFile from '../assets/icons/DownloadFile.svg'
import Search from '../assets/icons/Search.svg'
import RightSymbol from '../assets/icons/RightSymbol.svg'
import TwoNumber from '../assets/icons/TwoNumber.svg'
import ThreeNumber from '../assets/icons/ThreeNumber.svg'
import FourNumber from '../assets/icons/FourNumber.svg'
import AddIcon from '../assets/icons/AddIcon.svg'
import PlusIcon from '../assets/icons/Plus.svg'
import Clock from '../assets/icons/Clock.svg'
import Info from '../assets/icons/Info.svg'
import Edit from '../assets/icons/Edit.svg'
import FileExport from '../assets/icons/FileExport.svg'
import Accounts from '../assets/icons/Accounts.svg'
import Bold from '../assets/icons/Bold.svg'
import Italic from '../assets/icons/Italic.svg'
import Underline from '../assets/icons/Underline.svg'
import TextAlign from '../assets/icons/TextAlign.svg'
import Tag from '../assets/icons/Tag.svg'
import Link from '../assets/icons/Link.svg'
import Attachment from '../assets/icons/Attachment.svg'
import Emoji from '../assets/icons/Emoji.svg'
import Compress from '../assets/icons/Compress.svg'
import Expand from '../assets/icons/Expand.svg'
import CancelLarge from '../assets/icons/CancelLarge.svg'
import WedbushLogo from '../assets/icons/Wedbush-Logo.png'
import SendEmail from '../assets/images/SendEmail.png'
import RightArrow from '../assets/images/right-arrow.png'
import acct_Feature from '../assets/icons/acct_Feature.png'
import TrustedContact from '../assets/icons/TrustedContact.png'
import OnlineID from '../assets/icons/OnlineID.png'
import funding from '../assets/icons/funding.png'
import ApplicantInfo from '../assets/icons/ApplicantInfo.png'
import Individual_AcctType from '../assets/icons/Individual_AcctType.png'
import collapse from '../assets/icons/collapse.png'
import expand from '../assets/icons/expand.png'
import Documents from '../assets/icons/Documents.png'
import individual from '../assets/icons/individual.png'
import investmentprofile from '../assets/icons/investmentprofile.png'
import Review from '../assets/icons/REview.png'
import WIPdetails_accountinformation from '../assets/icons/WIPdetails_accountinformation.png'
import review from '../assets/icons/review.svg'
import DeleteIcon from '../assets/icons/DeleteIcon.svg'
import PencilIcon from '../assets/icons/Pencil.svg'
import EditIcon from '../assets/images/edit.jpg'
import SearchIcon from '../assets/icons/Search.svg'
import SearchGreyIcon from '../assets/icons/SearchGrey.svg'
import ToastError from '../assets/icons/Error.svg'
import ToastWarning from '../assets/icons/WarningToast.svg'
import ToastInfo from '../assets/icons/InfoToast.svg'


export const Icons = {
  EditIcon,
  PencilIcon,
  WedbushLogo,
  SRPLogo,
  SendEmail,
  RightArrow,
  Notifications,
  Bell,
  Calendar,
  ChevronLeft,
  ChevronRight,
  ThreeNumber,
  TwoNumber,
  FourNumber,
  ChevronDown,
  Profile,
  UploadFile,
  SaveDraft,
  Cancel,
  Document,
  Download,
  DownloadFile,
  DeleteIcon,
  QuestionMark,
  Success,
  Critical,
  Warning,
  Default,
  RightSymbol,
  Search,
  LogOut,
  ArrowDown,
  Exclamation,
  RightMark,
  AddIcon,
  PlusIcon,
  ChevronUp,
  Clock,
  Info,
  Edit,
  FileExport,
  Accounts,
  Bold,
  Italic,
  Underline,
  TextAlign,
  Tag,
  Attachment,
  AttachmentFile,
  ChevronLeftBlue,
  Link,
  Emoji,
  Compress,
  Expand,
  CancelLarge,
  acct_Feature,
  TrustedContact,
  OnlineID,
  funding,
  ApplicantInfo,
  Individual_AcctType,
  collapse,
  expand,
  Documents,
  individual,
  investmentprofile,
  Review,
  WIPdetails_accountinformation,
  review,
  SearchIcon,
  SearchGreyIcon,
  ToastError,
  ToastWarning,
  ToastInfo
}

export const NeutralIcons = {
  Cancel,
  Neutral_Search,
  ChevronRight,
  ChevronLeft,
}

export const WhiteIcons = {
  White_Search,
  White_Plus,
}

export const BlueIcons = {
  BlueChevronDown,
  BlueChevronUp,
}
