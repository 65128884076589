import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const WipHeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`
export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 5%;
  cursor: pointer;
  /* z-index: 99999; */
  position: relative;
`
export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 50%;
  white-space: nowrap;
`
export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 0.5rem;
  position: relative;
  //padding: 0.25rem;
  //overflow-y: auto;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0px;
`
export const TopDividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0 16px 0;
`
export const PersonalInfoWrapper = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem 0;
`
export const ItemWrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`
export const RadioWrapper = styled.div`
  display: flex;
  gap: 24px;
`
export const FundingButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
`
export const CheckboxInput = styled.input`
  &#decline_state {
    margin-top: 1px;
  }
`
export const CheckMark = styled.span``
export const CheckBoxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${COLORS.UI.PrimaryText};
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${COLORS.UI.PrimaryText};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export const DocumentRowContainer = styled.div`
  display: flex;
  gap: 16px;
  &#secondRow {
    margin-top: 8px;
    flex: 0 1 50%;
  }
`
export const FeatureInfoRow = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 4px;
  flex: 0 1 50%;
  width: 100%;
  flex-direction: column;
  &.fullWidth {
    flex: 0 1 100%;
  }
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  position: relative;
  z-index: 99;
  padding: 0.5rem;
  button {
    z-index: 99999;
    position: relative;
    padding: 0.5rem 1.5rem;
  }
`
export const FormFieldWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
`
export const CountryRow = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  /* background: rgba(96, 165, 250, 0.25); */
  padding: 16px;
  border-radius: 5px;
  &#bottomMargin {
    margin-bottom: 16px;
  }
  &#TrustedWrap {
    flex-wrap: wrap;
  }
`
export const IDInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 33.3%;
  &#Trusted {
    flex: 0 1 48%;
  }
  &#relation {
    flex: 0 1 48%;
  }
  gap: 4px;
`
export const CombineLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const IDInfoHalfRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  gap: 4px;
`
export const CodeRow = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`
export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  bottom: 1;
  background-color: ${COLORS.Background.White};
  */ //box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
    input {
    padding: 0.25rem 2rem;
    ::placeholder {
      color: ${COLORS.Text.Gray};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
`
export const ClientSearchCard = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 6%;
  left: 0px;
  width: 100%;
  gap: 0.25rem;
  align-items: center;
  background: rgb(248, 250, 252);
  box-shadow: rgba(0, 36, 93, 0.3) 0px 2px 6px 1px;
  justify-content: center;
  align-self: center;
  height: fit-content;
  z-index: 9999;
`
export const AddTrustButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 65%;
`
export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  button {
    color: ${COLORS.UI.Primary60};
    :hover {
      background-color: white;
    }
  }
`
