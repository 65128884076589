export const SIZES = [
  '0.25rem', //SIZES[0]
  '0.5rem',
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '2.625rem',
  '3rem',
  '3.5rem',
  '4rem',
  '4.25rem',
]

export const TextSizes = [
  '0.25rem',
  '0.5rem',
  '0.875rem',
  '1rem',
  '1.125rem',
  '1.25rem',
  '1.5rem',
  '1.625rem',
  '2rem',
  '2.5rem',
  '2.625rem',
  '3rem',
  '3.5rem',
  '4rem',
  '4.25rem',
  '4.5rem',
]
export const REMSizes = [
  '0.5rem',
  '0.625rem',
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.125rem',
  '1.25rem',
  '1.375rem',
  '1.5rem',
  '1.625rem',
  '1.75rem',
  '1.875rem',
  '2rem',
  '2.25rem',
  '2.5rem',
  '2.75rem',
  '3rem',
]

export const BaseFontSize = {
  xsm: '8px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '18px',
  xxl: '20px',
}
export const TextWeight = ['400', '500', '600', '700']
export const TextAlign = ['top', 'right', 'center', 'left']
