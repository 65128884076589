import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'
import { TextareaProps } from './ITextarea'

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
`

export const StyledTextarea = styled.textarea`
  padding: 0.4rem 0.4rem;
  flex: 0 1 100%;
  background: ${COLORS.Background.White};
  border: 1px solid
    ${(props: TextareaProps) =>
      props.border ? props.border : COLORS.Border.Gray50};
  &:hover {
    border: 1px solid ${COLORS.Border.Primary5};
    color: ${COLORS.Text.Gray20};
  }
  &:active {
    border: 1px solid ${COLORS.Border.Primary60};
  }
  &:disabled {
    border: 1px solid ${COLORS.Border.Gray50};
    color: ${COLORS.Text.Gray};
    cursor: no-drop;
  }
  &:focus {
    border: 1px solid ${COLORS.Border.Primary50};
    color: ${COLORS.Text.Black};
  }
  &:required {
    &.error {
      border: 1px solid ${COLORS.Border.Danger};
    }
  }
  &:required:focus {
    &.valid {
      border: 1px solid ${COLORS.Border.Success2};
    }
  }
  box-sizing: border-box;
  border-radius: 4px;
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${COLORS.Text.NeutralText};
  outline: none;
  resize:none;
`
