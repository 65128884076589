import axios from "axios"
import appConfig from "../../settings/appConfig"
import ENDPOINTS from "../httpEndpoints"
import { GetAdvisorDataRepCodeRequest, GetAdvisorDataRepCodeResponse, LobOfcCodeRequest, LobOfcCodeResponse } from "./ILobOfficeContext"
const host = appConfig.extapiEndPoint
export const GetLobOfficeCodeAPI = (
  payload: LobOfcCodeRequest
) => {
  return axios.post<LobOfcCodeResponse[]>(host + ENDPOINTS.GET_OFFICE_CODE_LOB, payload)
}
export const getAdvisorDataByRepcode = (payload: any) => {
  return axios.post<GetAdvisorDataRepCodeResponse>(
    host + ENDPOINTS.GET_ADVISORDATA_REPCODE,
    payload
  )
}