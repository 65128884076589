import { useContext, useEffect, useState } from 'react'
import Label from '../../../../../../components/label/Label'
import {
  WDLabel,
  WDLabelClient,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../../../components/ui/WDLabel'
import { COLORS } from '../../../../../../theme/Colors'
import { OptionProps } from '../../../../../../composites/selectDropdown/ISelectDropDown'
import XrefContext from '../../../../../../store/XrefContext'
import { WDButtonOutLine } from '../../../../../../components/ui/WDButton'
import Button from '../../../../../../components/button/Button'
import { Textbox } from '../../../../../../components/textbox/Textbox'
import {
  TitleGrouping,
  ToastWrapper,
  WipHeadingWrapper,
} from '../../../../formWip/styles'
import { Toast } from '../../../../../../components/toast/Toast'
import { DomnCode } from '../../../../../../constant/DomnCode'
import {
  CheckBoxContainer,
  CheckBoxWrapper,
  DocumentRowContainer,
  FeatureInfoColumn,
  FeatureInfoRow,
  FormContainer,
  FundingButtonWrapper,
  FundingWrapper,
  FundWrapper,
  ImageWrapper,
} from './styles'
import { StyledDocumentPanel } from '../../../../accountInformation/styles'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../../../components/svg/SvgChevron'
import { WDTextboxBorderRadius } from '../../../../../../components/ui/WDTextbox'
import {
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../../../electronicPrefs/styles'
import { LottieWrapper } from '../../../../../interceptor/styles'
import Lottie from 'lottie-react'
import Loader from '../../../../../../assets/lottiefiles/loader.json'
import { SpinnerWrapper } from '../ProductFeatures/style'
import { Row } from '../AccountFeature/styles'
import { ResponsiveWrapper } from '../../../../../../pages/accountApplicationPage/styles'
import SelectDropdown from '../../../../../selectDropdown/SelectDropdown'
import { SvgInvestmentType } from '../../../../../../components/svg/SvgCall'
import { DividerWrapper } from '../ApplicantInfo/styles'
import Divider from '../../../../../../components/divider/Divider'
import {
  GetClientJSON,
  getWipDetails,
} from '../../../../../../services/formRequest/FormRequestAPI'
import ToastContext from '../../../../../../services/toast/ToastContext'
import CheckBoxNew from '../../../../../../components/checkbox/CheckBoxNew'
import { RoleContext } from '../../../../../../services/roleContext/RoleContext'
import { ApplicationInfoContext } from '../../../../../../services/applicationContext/ApplicationPageContext'
import { GetWipRequestInterface } from '../../../../formWip/IFormWip'

const InvestmentInfo = (props: any) => {
  const { setToast } = useContext(ToastContext)
  const { accessList } = useContext(RoleContext)
  const {
    wipId,
    selectedClient,
    setAllClientData,
    getWipResponse,
    newModelData,
    readOnly,
    allClientData,
    progressVerify,
    setProgressVerify,
    risktoleranceValue,
    setRisktoleranceValue,
    LiquidNeedsValue,
    setLiquidityNeedsValue,
    sourceOfFundValues,
    setSourceOfFundValues,
    investObjectiveValue,
    setInvestObjectiveValue,
    timeHorizonValue,
    setTimeHorizonValue,
    liquidityPercentage,
    setLiquidityPercentage,
    autoSaveFunction,
    setDetectChangeIp,
    xrefDetails,
    setNewModelData,
  } = useContext(ApplicationInfoContext)
  const [loader, setLoader] = useState(false)
  const [sfList, setSFList] = useState<any>()

  const [INVESMENTHO, setINVESMENTHO] = useState<OptionProps[]>([])

  const [ANNUALINCOME, setANNUALINCOME] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])

  const [investmentTypes, setInvestmentTypes] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])
  const [sourceOfFunds, setSourceOfFunds] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])
  const [toggle, setToggle] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])
  const [optionsTrading, setOptionsTrading] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])

  const [investmentObjective, setInvestmentObjective] = useState<OptionProps[]>(
    []
  )
  const [riskTolerance, setRiskTolerance] = useState<OptionProps[]>([])
  const [invExpTime, setInvExpTime] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])
  const [dividendPayment, setDividendPayment] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])
  const [liquidNeeds, setLiquidNeeds] = useState<OptionProps[]>([])
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  //for maintaining real time state of chekced boxes in source of fund
  const [checkedSrcFund, setChckedSrcFund] = useState<{
    [key: string]: boolean
  }>({})
  //to check the data is coming in get api call or not
  const [isUpdate, setIsUpdate] = useState(true)
  //to check if data is updated or not
  const [isDirtyChange, setIsDirtyChange] = useState(false)
  //form data
  const [toggleHeader, setToggleHeader] = useState(true)
  const [sfSelectedValuesFromResp, setSFSelectedValuesFromResp] =
    useState<any>()

  const getXrefDatabyDomainCode = (response: any, code: string) => {
    if (response) {
      const res = response.data
      const xrefdData = []
      for (const prop in res) {
        if (
          res[prop].domncode == code &&
          res[prop].value !== '--Please Select--'
        ) {
          xrefdData.push(res[prop])
        }
      }
      return xrefdData
    } else return []
  }

  const bindAllDropDownsWithXref = () => {
    // progressVerify[0].validateCards[2] = {
    //   investment: true,
    // }
    // setProgressVerify([...progressVerify])
    const ANNINC = getXrefDatabyDomainCode(xrefDetails, DomnCode.ANNUALINCOME)
    const LIQNET = getXrefDatabyDomainCode(xrefDetails, DomnCode.LIQNETWORTH)
    const INVTIM = getXrefDatabyDomainCode(xrefDetails, DomnCode.INVESTMENTTIME)
    const TOTNET = getXrefDatabyDomainCode(xrefDetails, DomnCode.TOTALNETWORTH)
    const INVOBJ = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.INVESTMENTOBJECTIVE
    )
    const RISKTOLOBJ = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.RISKTOLERANCE
    )
    const investmentTyps = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.INVESTMENTEXPERIENCE
    )
    const SourceOfFund = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.SOURCEOFFUND
    )
    const toggle = getXrefDatabyDomainCode(xrefDetails, DomnCode.TOGGLE)
    const InvestmentExpTime = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.INVESTMENTEXPERIENCETIME
    )
    const DividendPay = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.DIVIDENDPAYMENT
    )
    const OpsTrade = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.OPTIONQUESTIONS
    )
    const OpsTradeKno = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.OPTIONKNOWLEDGE
    )
    const OpsTradeExp = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.OPTIONEXPERIENCE
    )
    const LiquidNeeds = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.LIQUIDITYNEEDS
    )

    const localANNINC: OptionProps[] = []
    const localLIQNET: OptionProps[] = []
    const localINVTIM: OptionProps[] = []
    const localTOTNET: OptionProps[] = []
    const localINVOBJ: OptionProps[] = []
    const localRiskTolerance: OptionProps[] = []
    const localInvtypes: OptionProps[] = []
    const localSourceofFund: OptionProps[] = []
    const localToggle: OptionProps[] = []
    const localInvExpTime: OptionProps[] = []
    const localDiviPay: OptionProps[] = []
    const localOpsTrade: OptionProps[] = []
    const localOpsTradeKno: OptionProps[] = []
    const localOpsTradeExp: OptionProps[] = []
    const localLiquidNeeds: OptionProps[] = []

    LiquidNeeds.map((element) => {
      setLiquidNeeds([
        ...liquidNeeds,
        {
          label: element.value,
          value: element.code,
        },
      ])
      localLiquidNeeds.push({
        label: element.value,
        value: element.code,
      })
    })
    setLiquidNeeds(localLiquidNeeds)

    OpsTradeExp.map((element) => {
      localOpsTradeExp.push({
        label: element.value,
        value: element.code,
      })
    })

    OpsTradeKno.map((element) => {
      localOpsTradeKno.push({
        label: element.value,
        value: element.code,
      })
    })
    OpsTrade.map((element) => {
      localOpsTrade.push({
        label: element.value,
        value: element.code,
      })
    })
    setOptionsTrading(localOpsTrade)

    DividendPay.map((element) => {
      localDiviPay.push({
        label: element.value,
        value: element.code,
      })
    })
    InvestmentExpTime.map((element) => {
      localInvExpTime.push({
        label: element.value,
        value: element.code,
      })
    })

    INVOBJ.map((element) => {
      localINVOBJ.push({
        label: element.value,
        value: element.code,
      })
    })
    RISKTOLOBJ.map((element) => {
      localRiskTolerance.push({
        label: element.value,
        value: element.code,
      })
    })
    ANNINC.map((element) => {
      localANNINC.push({
        label: element.value,
        value: element.code,
      })
    })

    LIQNET.map((element) => {
      localLIQNET.push({
        label: element.value,
        value: element.code,
      })
    })

    INVTIM.map((element) => {
      localINVTIM.push({
        label: element.value,
        value: element.code,
      })
    })

    TOTNET.map((element) => {
      localTOTNET.push({
        label: element.value,
        value: element.code,
      })
    })

    investmentTyps.map((element) => {
      localInvtypes.push({
        label: element.value,
        value: element.code,
      })
    })

    SourceOfFund.map((element) => {
      localSourceofFund.push({
        label: element.value,
        value: element.code,
      })
    })

    toggle.map((element) => {
      localToggle.push({
        label: element.value,
        value: element.code,
      })
    })

    setANNUALINCOME(localANNINC)
    setINVESMENTHO(localINVTIM)
    setINVESMENTHO(localINVTIM)
    setInvestmentObjective(localINVOBJ)
    setRiskTolerance(localRiskTolerance)
    setInvestmentTypes(localInvtypes)
    setSourceOfFunds(localSourceofFund)
    setToggle(localToggle)
    setInvExpTime(localInvExpTime)
    setDividendPayment(localDiviPay)
  }
  useEffect(() => {
    bindAllDropDownsWithXref()
  }, [xrefDetails])

  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0]) {
      setAllClientData(newModelData?.wipAccountDetailslist?.[0])
      setLiquidityNeedsValue(
        liquidNeeds?.find(
          (element) =>
            element.value ===
            newModelData?.wipAccountDetailslist?.[0]?.liquidityneedsid?.toString()
        ) ?? { label: 'Select', value: '' }
      )
      setInvestObjectiveValue(
        investmentObjective?.find(
          (element) =>
            element.value ===
            newModelData?.wipAccountDetailslist?.[0]?.investmentobjectiveid?.toString()
        ) ?? { label: 'Select', value: '' }
      )
      setRisktoleranceValue(
        riskTolerance?.find(
          (element) =>
            element.value ===
            newModelData?.wipAccountDetailslist?.[0]?.risktoleranceid?.toString()
        ) ?? { label: 'Select', value: '' }
      )
      setTimeHorizonValue(
        INVESMENTHO?.find(
          (element) =>
            element.value ===
            newModelData?.wipAccountDetailslist?.[0]?.investmenttimehorizonid?.toString()
        ) ?? { label: 'Select', value: '' }
      )
      setSFSelectedValuesFromResp(
        newModelData?.wipAccountDetailslist?.[0]?.sourceoffunds
          ? JSON.parse(newModelData?.wipAccountDetailslist?.[0]?.sourceoffunds)
          : []
      )
      setLiquidityPercentage(
        newModelData?.wipAccountDetailslist?.[0]?.liquidityneedspercentage?.toString() ??
          ''
      )
    }
  }, [
    newModelData,
    liquidNeeds,
    investmentObjective,
    riskTolerance,
    INVESMENTHO,
  ])

  const callAPIs = () => {
    setLoader(true)
    autoSaveFunction().then((res) => {
      setLoader(false)
      if (res && res === 200) {
        setToast('success', 'Investment Information saved')
        const payload: GetWipRequestInterface = {
          appId: '1',
          wipId: parseInt(wipId),
        }
        getWipDetails(payload).then((getWipRecord) => {
          setNewModelData(getWipRecord.data)
        })
      } else {
        setToast('error', 'Failed to update the record.')
      }
    })
  }

  const handleSourceOfFundChange = (e: any) => {
    const { checked, value } = e.target
    const temp: any = sfList?.map((element: any) => {
      if (element.label === value) {
        element.checked = checked
      }
      return element
    })

    setSourceOfFundValues(
      [...temp]
        ?.filter((element) => element.checked === true)
        .map((element) => {
          return { Value: element.label, Id: element.value }
        })
    )
  }

  useEffect(() => {
    const temp = new Array<any>()
    sourceOfFunds.map((element) => {
      temp.push({
        ...element,
        checked: sfSelectedValuesFromResp?.find(
          (item: any) => item.Value === element.label
        )
          ? true
          : false,
      }) //element["checked"] = false
    })

    setSFList([...temp])

    setSourceOfFundValues(
      [...temp]
        .filter((element) => element.checked === true)
        .map((element) => {
          return { Value: element.label, Id: element.value }
        })
    )
  }, [sourceOfFunds, sfSelectedValuesFromResp])
  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel id={props.isPdf ? 'remove' : ''}>
        <WipHeadingWrapper>
          <TitleGrouping
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            <SvgInvestmentType />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Investment Profile'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>
          {/* {!readOnly && toggleHeader && (
          <EditWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() => {
                  setIsEdit(true)
                  setToggleHeader(true)
                }}
                prefixedIcon={<SvgPencil fillColor={COLORS.UI.PrimaryText} />}
              >
                <WDLabelPrimaryText>{'Edit'}</WDLabelPrimaryText>
              </Button>
            </WDButtonTransparent>
          </EditWrapper>
        )} */}
          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            {!toggleHeader && !props.isPdf && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && !props.isPdf && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            {loader ? (
              <SpinnerWrapper>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
              </SpinnerWrapper>
            ) : (
              <FundWrapper>
                <FundingWrapper>
                  <FormContainer id="FormContainer">
                    <DocumentRowContainer>
                      <FeatureInfoRow className={'fullWidth'}>
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>{'Investment Objective'}</Label>
                            </WDLabel>
                          </Row>
                        </FeatureInfoColumn>

                        <FeatureInfoColumn>
                          {readOnly ? (
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {investObjectiveValue.value
                                  ? investObjectiveValue.label
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          ) : (
                            <SelectDropdown
                              width={'100%'}
                              options={investmentObjective}
                              onChange={(value) => {
                                const optionVal = value as OptionProps
                                setInvestObjectiveValue(optionVal)
                                setDetectChangeIp(true)
                              }}
                              value={investObjectiveValue}
                              name={'Investment Objective'}
                              borderColor={COLORS.Border.Gray50}
                              borderRadius={'0rem'}
                              placeholderColor={COLORS.Text.NeutralTextWeak}
                              padding={'0 8px'}
                              height="2rem"
                              isDisabled={readOnly ? true : false}
                            />
                          )}
                        </FeatureInfoColumn>
                      </FeatureInfoRow>
                      <FeatureInfoRow>
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>{'Risk Tolerance'}</Label>
                            </WDLabel>
                          </Row>
                        </FeatureInfoColumn>

                        <FeatureInfoColumn>
                          {readOnly ? (
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {risktoleranceValue.value
                                  ? risktoleranceValue.label
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          ) : (
                            <SelectDropdown
                              width={'100%'}
                              isDisabled={readOnly ? true : false}
                              options={riskTolerance}
                              onChange={(option: OptionProps | undefined) => {
                                setRisktoleranceValue(option as OptionProps)
                                setDetectChangeIp(true)
                              }}
                              value={risktoleranceValue}
                              name={'Investment Time Horizon'}
                              borderColor={COLORS.Border.Gray50}
                              borderRadius={'0rem'}
                              placeholderColor={COLORS.Text.NeutralTextWeak}
                              padding={'0 8px'}
                              height="2rem"
                            />
                          )}
                        </FeatureInfoColumn>
                      </FeatureInfoRow>
                    </DocumentRowContainer>
                    <DocumentRowContainer>
                      <FeatureInfoRow>
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>{`Source of Funds(${sourceOfFundValues.length}/${sourceOfFunds.length})`}</Label>
                            </WDLabel>
                          </Row>
                        </FeatureInfoColumn>
                        <FeatureInfoColumn>
                          <CheckBoxContainer>
                            {sfList?.map((element: any, index: number) => {
                              return (
                                <CheckBoxWrapper
                                  key={index}
                                  readOnly={readOnly}
                                  checked={element.checked}
                                >
                                  <CheckBoxNew
                                    checkBoxId={`chkSF_${index}`}
                                    value={element.label}
                                    checked={element.checked}
                                    onChange={(e) => {
                                      handleSourceOfFundChange(e)
                                      setDetectChangeIp(true)
                                    }}
                                    disabled={readOnly}
                                  />
                                  <WDLabel
                                    className="label_responsive"
                                    htmlFor={`chkSF_${index}`}
                                  >
                                    {element.label}
                                  </WDLabel>
                                </CheckBoxWrapper>
                              )
                            })}
                          </CheckBoxContainer>
                        </FeatureInfoColumn>
                      </FeatureInfoRow>
                    </DocumentRowContainer>

                    <DocumentRowContainer>
                      <FeatureInfoRow>
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>{'Investment Time Horizon'}</Label>
                            </WDLabel>
                          </Row>
                        </FeatureInfoColumn>

                        {readOnly ? (
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {timeHorizonValue.value
                                ? timeHorizonValue.label
                                : '--'}
                            </Label>
                          </WDLabelClient>
                        ) : (
                          <SelectDropdown
                            isDisabled={readOnly ? true : false}
                            options={INVESMENTHO}
                            onChange={(option: OptionProps | undefined) => {
                              setTimeHorizonValue(option as OptionProps)
                              setDetectChangeIp(true)
                            }}
                            value={timeHorizonValue}
                            name={'Investment Time Horizon'}
                            borderColor={COLORS.Border.Gray50}
                            borderRadius={'0rem'}
                            placeholderColor={COLORS.Text.NeutralTextWeak}
                            padding={'0 8px'}
                            width={'100%'}
                          />
                        )}
                      </FeatureInfoRow>
                      <FeatureInfoRow>
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>{'Liqudity Needs'}</Label>
                            </WDLabel>
                          </Row>
                        </FeatureInfoColumn>

                        <FeatureInfoColumn>
                          {readOnly ? (
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {LiquidNeedsValue.value
                                  ? LiquidNeedsValue.label
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          ) : (
                            <SelectDropdown
                              isDisabled={readOnly ? true : false}
                              options={liquidNeeds}
                              onChange={(option: OptionProps | undefined) => {
                                if (
                                  option?.label === 'Unknown/Not applicable'
                                ) {
                                  setLiquidityPercentage('')
                                }
                                setLiquidityNeedsValue(option as OptionProps)
                                setDetectChangeIp(true)
                              }}
                              value={LiquidNeedsValue}
                              name={'Investment Time Horizon'}
                              borderColor={COLORS.Border.Gray50}
                              borderRadius={'0rem'}
                              placeholderColor={COLORS.Text.NeutralTextWeak}
                              padding={'0 8px'}
                              height="2rem"
                              width={'100%'}
                            />
                          )}
                        </FeatureInfoColumn>
                      </FeatureInfoRow>
                      {LiquidNeedsValue.value &&
                        LiquidNeedsValue.label !== 'Unknown/Not applicable' && (
                          <FeatureInfoRow>
                            <FeatureInfoColumn className="liquid_Block">
                              <Row>
                                <WDLabel className="label_responsive">
                                  <Label>{'Liqudity Needs %'}</Label>
                                </WDLabel>
                              </Row>
                            </FeatureInfoColumn>

                            <FeatureInfoColumn>
                              {readOnly ? (
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {liquidityPercentage
                                      ? liquidityPercentage
                                      : ''}
                                  </Label>
                                </WDLabelClient>
                              ) : (
                                <WDTextboxBorderRadius className="inputBox_responsive">
                                  <Textbox
                                    className={'liquid_Block_input'}
                                    placeholder=""
                                    type={'text'}
                                    name="liquidPercent"
                                    value={liquidityPercentage}
                                    onChange={(e) => {
                                      if (/^(\d|[1-9]\d|100)?$/.test(e)) {
                                        setLiquidityPercentage(e)
                                        setDetectChangeIp(true)
                                      }
                                    }}
                                    disabled={readOnly ? true : false}
                                  />
                                </WDTextboxBorderRadius>
                              )}
                            </FeatureInfoColumn>
                          </FeatureInfoRow>
                        )}
                    </DocumentRowContainer>
                    {apiPreviewStatus.status !== '' && (
                      <ToastWrapper>
                        <Toast
                          text={apiPreviewStatus.text}
                          type={apiPreviewStatus.status}
                          openStatusCallback={(status: boolean) =>
                            setAPIPreviewStatus({
                              status: '',
                              text: '',
                            })
                          }
                        />
                      </ToastWrapper>
                    )}
                  </FormContainer>
                </FundingWrapper>
                {/* <FundingButtonWrapper>
          
                  {accessList.invProfile && !readOnly && (
                    <WDButtonOutLine>
                      <Button
                        type={'button'}
                        onClick={() => {
                          callAPIs()
                        }}
                      >
                        {loader ? (
                          <SpinnerButtonWrapper>
                            <LottieButtonWrapper>
                              <Lottie animationData={Loader} loop={true} />
                            </LottieButtonWrapper>
                          </SpinnerButtonWrapper>
                        ) : (
                          <WDLabelPrimaryText className="label_responsive">
                            {'Save'}
                          </WDLabelPrimaryText>
                        )}
                      </Button>
                    </WDButtonOutLine>
                  )}
                </FundingButtonWrapper> */}
              </FundWrapper>
            )}
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default InvestmentInfo
