import {
  BaseSyntheticEvent,
  DragEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Wrapper,
  File,
  UploadWrapper,
  InputWrapper,
  WDLabelErrorWrap,
  ChooseWrapper,
  HintWrapper,
} from './styles'
import Button from '../../components/button/Button'
import { WDLabelLarge } from '../../components/ui/WDLabel'
import { AttachDocumentProps } from './IAttachDocument'

import { Toast } from '../../components/toast/Toast'
import { WDButtonPrimaryText } from '../../components/ui/WDButton'
import { ToastWrapper } from '../../components/toast/styles'
import { DownloadFile } from '../../shared/models/IFormDocuments'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import { COLORS } from '../../theme/Colors'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'

export const AttachDocument: React.FC<AttachDocumentProps> = ({
  files,
  onFileSelect,
  EXTENSIONS,
  uploadedFiles,
  maxfileuploadsize,
  isdisable,
  showPopup,
  onDisclosureSelect,
  isDisclosure,
}: AttachDocumentProps) => {
  const { uploadError, setUploadError, newModelData } = useContext(
    ApplicationInfoContext
  )
  const [fileList, setFileList] = useState<File[]>([])
  // const [isCSV, setIsCSV] = useState(true)
  const [fileSubmit, setFileSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })
  const fileUpload = useRef<HTMLInputElement>(null)

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    const isAcceptedFile = EXTENSIONS.includes(extension.toLowerCase())
    // setIsCSV(isAcceptedFile)
    return isAcceptedFile
  }

  const checkFileSize = (file: File) => {
    if (file.size > maxfileuploadsize * 1000000) {
      return false
    }
    return true
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    if (isdisable) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
  }

  const browseFile = () => {
    fileUpload.current ? (fileUpload.current.value = '') : ''
    fileUpload.current?.click()
  }

  const isFileNameUnique = (file: File) => {
    return (
      !files.some((f: File) => f.name === file.name) &&
      !uploadedFiles.filter(file => file.isdisclosure).some(
        (f: DownloadFile) => f.file.split('/').pop() == file.name.trim()
      )
    )
  }

  const handleFileSelect = (FileList: FileList) => {
    const files = FileList
    if (files) {
      const selectedFiles: File[] = Array.from(files)
      const incorrectExtensionFiles: File[] = selectedFiles.filter(
        (file: File) => {
          return !getExtension(file)
        }
      )
      const incorrectSizeFiles: File[] = selectedFiles.filter((file: File) => {
        return !checkFileSize(file)
      })
      let sameNamedFiles: File[] = []
      if (newModelData?.wipAccountDetailslist?.[0]?.parties.length === 1) {
        sameNamedFiles = selectedFiles.filter((file: File) => {
          return !isFileNameUnique(file)
        })
      }

      let uploadError = ''
      if (incorrectExtensionFiles.length > 0) {
        uploadError = `The following files have incorrect extensions : ${incorrectExtensionFiles
          .map((file: File) => file.name)
          .join(', ')} `
        setUploadError(uploadError)
      }
      if (sameNamedFiles.length > 0) {
        uploadError = `You have already uploaded files with name: ${sameNamedFiles
          .map((file: File) => file.name)
          .join(', ')} `
        setUploadError(uploadError)
      }
      if (incorrectSizeFiles.length > 0) {
        uploadError += `The following files are greater than ${maxfileuploadsize} MB : ${incorrectSizeFiles
          .map((file: File) => file.name)
          .join(', ')}`
        setUploadError(uploadError)
      }
      // if (uploadError != '') {
      //   showAlertMessage(uploadError, toastType.DANGER)
      // }

      const allowedFiles: File[] = selectedFiles.filter((file: File) => {
        return (
          getExtension(file) &&
          checkFileSize(file) &&
          sameNamedFiles.length === 0
        )
      })
      setFileList((prevList) => {
        const newFiles = allowedFiles.filter((file) =>
          prevList.some((prevFile) => prevFile.name == file.name)
        )
        return [...prevList, ...newFiles]
      })

      onFileSelect([...fileList, ...allowedFiles])
      setUploadError(uploadError)
    }
  }
  const handleDisclosureSelect = (disclosureList: FileList) => {
    const files = disclosureList
    if (files) {
      const selectedFiles: File[] = Array.from(files)
      const incorrectExtensionFiles: File[] = selectedFiles.filter(
        (file: File) => {
          return !getExtension(file)
        }
      )
      const incorrectSizeFiles: File[] = selectedFiles.filter((file: File) => {
        return !checkFileSize(file)
      })
      const sameNamedFiles: File[] = selectedFiles.filter((file: File) => {
        return !isFileNameUnique(file)
      })

      let uploadError = ''
      if (incorrectExtensionFiles.length > 0) {
        uploadError = `The following files have incorrect extensions : ${incorrectExtensionFiles
          .map((file: File) => file.name)
          .join(', ')} `
        setUploadError(uploadError)
      }
      if (sameNamedFiles.length > 0) {
        uploadError = `You have already uploaded files with name: ${sameNamedFiles
          .map((file: File) => file.name)
          .join(', ')} `
        setUploadError(uploadError)
      }
      if (incorrectSizeFiles.length > 0) {
        uploadError += `The following files are greater than ${maxfileuploadsize} MB : ${incorrectSizeFiles
          .map((file: File) => file.name)
          .join(', ')}`
        setUploadError(uploadError)
      }
      const allowedFiles: File[] = selectedFiles.filter((file: File) => {
        return (
          getExtension(file) && checkFileSize(file) && isFileNameUnique(file)
        )
      })
      setFileList((prevList) => {
        const newFiles = allowedFiles.filter((file) =>
          prevList.some((prevFile) => prevFile.name == file.name)
        )
        return [...prevList, ...newFiles]
      })
      onDisclosureSelect([...fileList, ...allowedFiles])
    }
  }
  const handleDropFile = (event: DragEvent) => {
    if (isdisable) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
    handleFileSelect(event.dataTransfer.files)
  }
  const handleFiles = (event: BaseSyntheticEvent) => {
    setUploadError('')
    if (isDisclosure) {
      handleDisclosureSelect(event.target.files)
    } else {
      handleFileSelect(event.target.files)
    }
  }
  useEffect(() => {
    setUploadError('')
  }, [uploadedFiles])

  return (
    <Wrapper>
      <File>
        <UploadWrapper
          className="dashed-border"
          id="drop-area"
          onDragEnter={preventDefaults}
          onDragOver={preventDefaults}
          onDragLeave={preventDefaults}
          onDrop={handleDropFile}
        >
          <InputWrapper
            type="file"
            id="fileElement"
            ref={fileUpload}
            multiple
            hidden
            onChange={handleFiles}
          />
          {/* <RowWrapper> */}
          {/* <WDButtonNeutralTransparent>
              <SvgDocumentUpload />
            </WDButtonNeutralTransparent> */}
          <WDLabelLarge className="label_responsive">
            {'Drag files here to upload or...'}
          </WDLabelLarge>
          {/* <MiddleWrapper>
              <TextWrapper>{'or'}</TextWrapper>
            </MiddleWrapper> */}
          <ChooseWrapper id={showPopup ? '' : 'zIndex'}>
            <WDButtonPrimaryText>
              {/* <ChooseWrapper> */}
              <Button onClick={browseFile} type={'button'} disabled={isdisable}>
                {'Choose File'}
              </Button>
              {/* </ChooseWrapper> */}
            </WDButtonPrimaryText>
          </ChooseWrapper>
          {/* <SampleFile>
              <IconWrapper>
                <WDLabelSmall>
                  <TextLabel>
                    {'Max file size is ' +
                      maxfileuploadsize +
                      ' MB. Supported file types ' +
                      (EXTENSIONS.length == 1 ? 'is ' : 'are ') +
                      EXTENSIONS.join(', ')}
                  </TextLabel>
                </WDLabelSmall>
              </IconWrapper>
            </SampleFile> */}
          {/* </RowWrapper> */}
        </UploadWrapper>
        <HintWrapper>
          {'Max file size is 5 MB. Supported file types are'.concat(
            EXTENSIONS.map((ext) => ` ${ext}`).join(', ')
          )}
        </HintWrapper>
        {uploadError ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'self-end',
              // justifyContent: 'end',
            }}
          >
            <SvgExclamation fillColor={COLORS.Text.Danger} />
            <WDLabelErrorWrap className="label_responsive">
              {uploadError}
            </WDLabelErrorWrap>
          </div>
        ) : (
          ''
        )}
      </File>
      {fileSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={fileSubmit.text}
            openStatusCallback={(status: boolean) =>
              setFileSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </Wrapper>
  )
}
