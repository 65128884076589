// export const formatDate = (paramDate: string) => {
//     let formattedDate = "";
//     if (paramDate) {
//         formattedDate = new Date(paramDate).toLocaleDateString("en-US", {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//         });
//     }
//     formattedDate = formattedDate !== "Invalid Date" ? formattedDate : "--";
//     return formattedDate;
// };

export const formatSSN = (paramSSN: string) => {
  let formattedSSN = ''
  if (paramSSN) {
    formattedSSN = paramSSN.replace(/\D/g, '')
    formattedSSN = formattedSSN.replace(/^(\d{3})/, '$1-')
    formattedSSN = formattedSSN.replace(/-(\d{2})/, '-$1-')
    formattedSSN = formattedSSN.replace(/(\d)-(\d{4}).*/, '$1-$2')
  }
  return formattedSSN
}

export const formatDate = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    const [year, month, day] = paramDate.split('-')
    formattedDate = `${day}-${month}-${year}`
  }
  formattedDate = formattedDate !== 'Invalid Date' ? formattedDate : '--'
  return formattedDate
}
//mm-dd-yyyy
export const formatDateMonth = (dateString: string | Date) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const formattedDate = `${month}-${day}-${year}`
  return formattedDate
}
