import { useState, useEffect } from 'react'

const GetResponsiveSvg = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getSvgDimension = (width: number) => {
    if (width >= 1440) {
      return '24'
    } else if (width >= 1280 && width < 1440) {
      return '20'
    } else if (width >= 1024 && width < 1440) {
      return '18'
    } else return '16'
  }

  const svgDimension = getSvgDimension(screenWidth)
  return svgDimension
}

export default GetResponsiveSvg
