import styled from 'styled-components'
import { COLORS } from '../../../../../../theme/Colors'

export const DdlRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`
export const DdlColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const FeatureInfoRow = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 4px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
  &#halfWidth {
    flex: 0 1 49%;
  }
  &.fullWidth {
    flex: 0 1 100%;
  }
  @media screen and (min-width: 1440px) {
    gap: 12px !important;
    &#cusip-space {
      gap: 32px !important;
    }
  }
  &#red {
    flex-direction: row;
  }
`
export const RowWrapper = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 4px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  &#cusip {
    flex-direction: row;
    gap: 0.25rem;
    flex: 0 1 50%;
  }
  flex: 0 1 100%;
  /* input {
    border-radius: 8px;
    width: 50px;
    padding: 3px 5px;
    border: 1px solid ${COLORS.Text.NeutralTextDisabled};
  } */
  /* &#recurring {
    z-index: 99999;
  } */
  &.liquid_Block {
    flex: none !important;
  }
`
export const CusipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 100%;
  gap: 16px;
  column-gap: 18px;
  &#readOnly {
    gap: 19rem;
    row-gap: 16px;
  }
`
export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 78vh;
  overflow-y: scroll;
`

export const FundingButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem !important;
  gap: 0.5rem;
  position: relative;
  /* z-index: 99; */
  button {
    z-index: 99999;
    position: relative;
    padding: 0.5rem 1.5rem;
  }
`

export const FundingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: calc(67vh-2rem);
  /* padding: 0.25rem; */
  //overflow-y: scroll;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  gap: 0.6rem;
`

export const FundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem 0;
  gap: 1rem;
`
export const DocumentRowContainer = styled.div`
  display: flex;
  gap: 16px;
  flex: 0 1 25%;
  /* flex-grow: 0;
  flex-shrink: 1; */
  /* flex-basis: calc(43% - 5.5rem); */
  &#secondRow {
    margin-top: 8px;
    flex: 0 1 25%;
  }
`
export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
`
export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const ImageWrapper = styled.div``
export const CheckBoxContainer = styled.div`
  column-gap: 6px;
  column-count: 2;
`
export const CheckBoxWrapper = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 6px;
  align-items: center;
  label {
    margin-top: -2px;
  }
  ${({ readOnly, checked }: any) =>
    readOnly &&
    `
      span {
       background: ${COLORS.Text.Gray40} !important;
        cursor: no-drop;
       
          background: ${
            checked ? COLORS.Background.Primary5 : '#FFFF'
          } !important;
 } }
    `}
`

export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  /* flex-wrap: wrap; */
  @media screen and (min-width: 1440px) {
    row-gap: 30px !important;
  }
`
