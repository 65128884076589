import { createContext } from 'react'
import { getXrefDetails } from '../services/xrefRequest/XrefRequestAPI'
import { XrefRequestModel, XrefResponseModel } from '../shared/models/IXREF'
import { ContextProps } from '../composites/forms/formWip/IFormWip'

// const xrefRequest: XrefRequestModel[] = []
// const xrefResponseDetails: XrefResponseModel[] = []

export interface IXrefStore {
  appid: XrefRequestModel | null
  xrefDetails: XrefResponseModel | null

  getXrefDetails: (payload: XrefRequestModel) => Promise<any>
  setXrefDetails: (xrefDetails: any) => void
}

const XrefStore: IXrefStore = {
  appid: null,
  xrefDetails: null,

  getXrefDetails: async (payload: XrefRequestModel) => {
    if (!XrefStore.xrefDetails && payload.lob) {
      const xrefDetailResponse = await getXrefDetails(payload)
      XrefStore.setXrefDetails(xrefDetailResponse)
      return xrefDetailResponse
    }
    return XrefStore.xrefDetails
  },
  setXrefDetails: (xrefDetails: any) => {
    XrefStore.xrefDetails = xrefDetails
  },
}

const XrefContext = createContext(XrefStore)

export const XrefContextProvider = (props: ContextProps) => {
  return (
    <XrefContext.Provider value={XrefStore}>
      {props.children}
    </XrefContext.Provider>
  )
}

export default XrefContext
