import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import {
  GetApprovalRequest,
  GetCardsResponseInterface,
  UpdateApprovalsRequest,
  UpdateCommentsRequest,
  UpdateCommentsResponse,
} from './IApproval'

const host = ''
export const getApprovalsAPI = (payload: GetApprovalRequest) => {
  return axios.get(
    host + ENDPOINTS.GET_APPROVAL.replace('{wipid}', payload.wipid.toString())
  )
}
export const getAuditTrialsAPI = (payload: GetApprovalRequest) => {
  return axios.get(
    host +
      ENDPOINTS.GET_AUDIT_TRAILS.replace('{wipid}', payload.wipid.toString())
  )
}
export const getCommentsAPI = (payload: GetApprovalRequest) => {
  return axios.get(
    host + ENDPOINTS.GET_COMMENTS.replace('{wipid}', payload.wipid.toString())
  )
}
export const updateApprovalAPI = (payload: UpdateApprovalsRequest) => {
  return axios.post(host + ENDPOINTS.UPDATE_APPROVAL, payload)
}
export const updateCommentsAPI = (payload: UpdateCommentsRequest) => {
  return axios.post<UpdateCommentsResponse[]>(
    host + ENDPOINTS.ADD_COMMENTS,
    payload
  )
}

export const getCardsAPI = (payload: GetApprovalRequest) => {
  return axios.get(
    host +
      ENDPOINTS.GET_CARDS_STATUS.replace('{wipid}', payload.wipid.toString())
  )
}

export const updateCardsAPI = (payload: GetApprovalRequest) => {
  return axios.post<GetCardsResponseInterface>(host + ENDPOINTS.UPDATE_CARDS_STATUS, payload)
}
