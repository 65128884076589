import { useContext, useEffect, useState } from 'react'
import { TableHistoryWrapper, TitleGrouping } from '../../styles'
import { COLORS } from '../../../../../theme/Colors'
import RejectAccount from './RejectAccount'
import ApprovalTable from '../../../../../components/table/ApprovalTable'
import { HeaderWrapper, ModalWrapper, OverlayWrapper } from './styles'
import {
  WDLabelBold,
  WDLabelPrimaryText,
} from '../../../../../components/ui/WDLabel'
import {
  WDButton,
  WDButtonGrayTransparent,
  WDButtonOutLine,
} from '../../../../../components/ui/WDButton'
import Button from '../../../../../components/button/Button'
import SvgClose from '../../../../../components/svg/SvgClose'
import { Textarea } from '../../../../../components/extra/textArea/Textarea'
import { AccountInfoBtnWrapper } from '../../../../../pages/accountApplicationPage/styles'
import ToastContext from '../../../../../services/toast/ToastContext'
import { LottieWrapper } from '../../../../interceptor/styles'
import Lottie from 'lottie-react'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { ApprovalContext } from '../../../../../services/approvalScreenContext/ApprovalContext'
import { Data } from '../../../../../../src/components/table/ITable'
import { ApplicationInfoContext } from '../../../../../services/applicationContext/ApplicationPageContext'
import { AuthContext } from '../../../../../services/AuthContext'
import { getApprovalsAPI } from '../../../../../services/approvalScreenContext/ApprovalAPI'

const FormApprovals = () => {
  const { setToast } = useContext(ToastContext)
  const { updateApprovals } = useContext(ApprovalContext)
  const [showRejectAccount, setRejectAccount] = useState<boolean>(false)
  const [showCommentBox, setCommentBox] = useState<string>('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState<boolean>(false)
  const { tableLoader } = useContext(ApprovalContext)
  const { wipId, newModelData } = useContext(ApplicationInfoContext)
  const { loggedUser } = useContext(AuthContext)
  const [tableData, setTableData] = useState<any>([])
  useEffect(() => {
    if (!wipId) return
    getApprovalsAPI({ wipid: Number(wipId) }).then((res) => {
      setTableData(res.data)
    })
  }, [wipId])

  const tableColumns = [
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: 'Signer',
      accessor: 'partyname',
    },
    {
      Header: 'Status/Action',
      accessor: 'status',
    },
    {
      Header: 'Updated',
      accessor: 'updatedtimestamp',
    },
  ]

  const handleMessage = (e: string) => {
    setMessage(e)
    setError(false)
  }
  const handleSubmit = () => {
    if (message.trim() === '' || !message) {
      setError(true)
    } else {
      const payload = {
        wipid: Number(wipId),
        reqid: 1,
        reqstatus: newModelData?.wipDetails?.wipstatusid
          ? newModelData?.wipDetails?.wipstatusid.toString()
          : '0',
        comments: message,
        action: '',
        createdby: loggedUser,
        updatedby: loggedUser,
        createdtimestamp: new Date(),
        updatedtimestamp: new Date(),
      }
      setCommentBox('')
      setError(false)
      updateApprovals(payload).then(() => {
        setToast('success', 'Updated Successfully')
      })
    }
  }
  useEffect(() => {
    if (!showCommentBox) {
      setMessage('')
      setError(false)
    }
  }, [showCommentBox])
  return (
    <>
      <TableHistoryWrapper className={tableLoader ? 'table_loader' : ''}>
        {!tableLoader ? (
          <ApprovalTable
            tableColumns={tableColumns}
            tableData={tableData as unknown as Data[]}
            onClick={() => ''}
            rejectionCheck={(val) => setCommentBox(val)}
            approveCheck={(val) => setCommentBox(val)}
          />
        ) : (
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        )}
      </TableHistoryWrapper>
      {showRejectAccount && (
        <RejectAccount setRejectAccount={setRejectAccount} />
      )}
      {showCommentBox && (
        <OverlayWrapper>
          <ModalWrapper>
            <HeaderWrapper>
              <TitleGrouping>
                <WDLabelBold className="header_responsive">
                  {'Add Comments'}
                </WDLabelBold>
              </TitleGrouping>
              <WDButtonGrayTransparent>
                <Button
                  type={'button'}
                  onClick={() => {
                    setCommentBox('')
                    setError(false)
                  }}
                >
                  <SvgClose fillColor={COLORS.Icons.Neutral} />
                </Button>
              </WDButtonGrayTransparent>
            </HeaderWrapper>
            <Textarea
              onChange={handleMessage}
              value={message}
              placeholder={'Please enter here'}
              required
              className={error ? 'error' : 'label_responsive'}
            ></Textarea>
            <AccountInfoBtnWrapper>
              <WDButton>
                <WDButtonOutLine>
                  <Button
                    type={'button'}
                    onClick={() => {
                      setCommentBox('')
                      setError(false)
                    }}
                  >
                    <WDLabelPrimaryText className="label_responsive">
                      {'Cancel'}
                    </WDLabelPrimaryText>
                  </Button>
                </WDButtonOutLine>
              </WDButton>
              <WDButton>
                <Button type="button" onClick={handleSubmit}>
                  {'Submit'}
                </Button>
              </WDButton>
            </AccountInfoBtnWrapper>
          </ModalWrapper>
        </OverlayWrapper>
      )}
    </>
  )
}
export default FormApprovals
