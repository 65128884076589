import styled from 'styled-components'
import { css } from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const FormControlWrapper = styled.div`
  display: flex;
  padding: 0.25rem 0.15rem;
  gap: 0.5rem;
  ${({ isParentQuestion }: { isParentQuestion: boolean }) =>
    isParentQuestion
      ? css`
          width: 100%; // 100% use this multi coulmn and child in two column
        `
      : css`
          width: 100%; // 50%
        `}
`
export const LeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 30%;
  width: 100%;
  text-align: start;
`
export const RightColumnWrapper = styled.div`
  display: flex;
  ${({ isRadio }: { isRadio: string }) =>
    isRadio === 'radio'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
        `}
  flex: 70%;
  width: 100%;
  justify-content: flex-start;
  gap: 0.5rem;
`
export const SectionHeader = styled.div`
  display: flex;
  ${({ hasHeader }: { hasHeader: boolean }) =>
    hasHeader
      ? css`
          flex-direction: row;
          gap: 0.25rem;
          align-items: center;
          border-bottom: 1px solid ${COLORS.Border.Gray50};
        `
      : css`
          flex-direction: column;
        `}
  flex: 50%;
  justify-content: flex-start;
`
export const QuestionGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  gap: 1rem;
`
export const SectionHeaderWrap = styled.div`
  display: flex;
  flex: 50%;
  justify-content: flex-start;
`
export const OptionHalfItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding: 0.3rem;
  align-items: center;
  justify-content: flex-start;
`
export const InlineErrorWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${COLORS.Text.Danger};
`
export const LabelTextWrapper = styled.div`
  display: flex;
  flex: 0 1 1000%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  color: ${COLORS.Text.Danger};
  gap: 0.15rem;
`
export const ToolTipWrapperOne = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`
export const ToolTipWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  margin-left: 18px;
  z-index: 99999;
`
export const ButtonWrapper = styled.div`
display: flex;
  :hover + .ToolTip {
    position: absolute;
    visibility: visible;
    z-index: 10;
  }
  button {
    padding: 0.25rem;
    max-width: 80%;
  }
`
export const SuperScript = styled.sup`
  color: ${COLORS.Text.Black};
`
