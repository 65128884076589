import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const BoxWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  padding-left: 0.25rem;
  flex-wrap: wrap;
  flex-direction: initial;
`
export const FormControlWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  padding: 0.25rem 0.15rem;
  gap: 1rem;
  width: 100%;
  align-items: flex-start;
`
export const LeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 0 1 100%;
`
export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  justify-content: flex-start;
`
export const QuestionGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`
export const QuestionGroupTemplate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
  flex-wrap: wrap;
`
export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-top: 1.5rem;
`
export const SectionHeaderWrap = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.Border.Gray50};
  flex: 50%;
  justify-content: flex-start;
`
export const OptionHalfItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding: 0.3rem;
  align-items: center;
  justify-content: flex-start;
`
export const InlineErrorWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${COLORS.Text.Danger};
`
export const LabelTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 1000%;
  width: 100%;
  gap: 0.25rem;
  .dropdown__indicators {
    position: relative;
    z-index: 99999;
  }
  &#optional{
    p{
      white-space: nowrap;
    }
  }
`
export const ToolTipWrapperOne = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`
export const ToolTipWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  margin-left: 18px;
  z-index: 99999;
`
export const ButtonWrapper = styled.div`
  :hover + .ToolTip {
    position: absolute;
    visibility: visible;
    z-index: 10;
  }
  button {
    padding: 0.25rem;
    max-width: 80%;
  }
`
export const SuperScript = styled.sup`
  color: ${COLORS.Text.Black};
`
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0.5rem 0;
`
export const PersonalInfoWrapperAF = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  flex: 100%;
`
export const PersonalInfoWrapperSecOne = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  flex: 33%;
  &#left_pane{
    padding-right: 16px;
  }
`

export const ItemListChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`
export const SingleCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`
export const ItemParentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`
export const LabelWrap = styled.div`
  display: flex;
  flex-direction: row;
`
export const LabelWrapSpaced = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`