import Lottie from 'lottie-react'
import Table from '../../../../../components/table/Table'
import { LottieWrapper } from '../../../../interceptor/styles'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { TableHistoryWrapper } from '../../styles'
import { useContext, useEffect } from 'react'
import { ApprovalContext } from '../../../../../services/approvalScreenContext/ApprovalContext'
import { Data } from '../../../../../components/table/ITable'
import { ApplicationInfoContext } from '../../../../../services/applicationContext/ApplicationPageContext'

const FormComments = () => {
  const { comments, tableLoader, getComments } = useContext(ApprovalContext)
  const { wipId } = useContext(ApplicationInfoContext)
  useEffect(() => {
    if (!wipId) return
    getComments({ wipid: Number(wipId) })
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [wipId])

  const tableColumns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'User',
      accessor: 'user',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: 'Comments',
      accessor: 'comments',
    },
  ]

  return (
    <TableHistoryWrapper className={tableLoader ? 'table_loader' : ''}>
      {!tableLoader ? (
        <Table
          tableColumns={tableColumns}
          tableData={comments as unknown as Data[]}
          onClick={() => ''}
        />
      ) : (
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
      )}
    </TableHistoryWrapper>
  )
}
export default FormComments
