export const Data = [
  {
    question:
      'Do not wish to leave assets with former employer is terminating the plan',
    keyName: 'LeaveAssets',
  },
  {
    question: 'Plan investment options are too limited',
    keyName: 'PlanInvestment',
  },
  {
    question: 'Investment alternatives performance limitations',
    keyName: 'InvestmentAlternatives',
  },
  {
    question: 'Would prefer a lifetime income option',
    keyName: 'LifetimeIncome',
  },
  {
    question: 'Would like to consolidate assets',
    keyName: 'ConsolidateAssets',
  },
  {
    question: 'Wants more flexibility and control over asset management',
    keyName: 'Flexibility',
  },
  {
    question: 'Prefers to have individualized professional advice/management',
    keyName: 'IndividualizedProfessional',
  },
  {
    question: 'Prefers integrated investment planning across all accounts',
    keyName: 'IntegratedInvestment',
  },
]
