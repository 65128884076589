import { useContext, useEffect, useState } from 'react'
import { ItemWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { DisclosureContext } from '../../../services/disclosureContext/DisclosureContext'
import { GetDisclosureRequestInterface } from '../../../services/disclosureContext/IDisclosure'
import { LobInfoContext } from '../../../services/LobOfficeCode/LobOfficeContext'
import ApplicantInfoPage from '../accountForms/formIndividual/tabs/ApplicantInfo/ApplicantInfo'
import FundingInfo from '../accountForms/formIndividual/tabs/FundingInfo/FundingInfo'
import InvestmentInfo from '../accountForms/formIndividual/tabs/InvestmentInfo/InvestmentCard'
import ProductFeatures from '../accountForms/formIndividual/tabs/ProductFeatures/ProductFeatures'
import ClientReviewCommentSection from '../commentSection/ClientReviewCommentSection'
import ContactInformation from '../contactInfo/ContactInfo'
import ElectronicPreferences from '../electronicPrefs/ElectronicPreferences'
import IndustryAffiliation from '../finalReviewInformation/IndustryAffiliation'
import FormDocumentsReview from '../formDocuments/FormDocumentReview'
import DisclosureReview from '../formReviewDisclosure/DisclosureReview'
import { DisclosureGrid } from '../formReviewDisclosure/styles'
import FormWip from '../formWip/FormWip'
import TrustedContact from '../trustedContact/TrustedContact'
import PersonalInformation from '../retirementForm/PersonalInformation/PersonalInfo'
import SourceOfContribution from '../retirementForm/SourceOfContribution/SourceOfContribution'
import BestInterest from '../retirementForm/BestInterest/BestInterest'
import PrimaryBeneficiary from '../retirementForm/PrimaryBeneficiary/PrimaryBeneficiary'

interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
  downloadPdf?: boolean
  isClientReview?: boolean
  isFinalReview?: boolean
  saveRecall?: boolean | undefined
  resetSave?: (val: boolean) => void
  setIsUploadSend?: (val: boolean) => void
}

const ReviewInformation = ({
  setStage,
  isClientReview,
  isFinalReview,
  saveRecall,
  resetSave,
  setIsUploadSend,
}: FormWipProps) => {
  const { wipId, setReadOnly, getWipResponse, newModelData } = useContext(
    ApplicationInfoContext
  )
  const { lobOfcCode } = useContext(LobInfoContext)

  const { getResponse } = useContext(DisclosureContext)
  const [disclosures, setDisclosures] = useState(false)
  useEffect(
    () => {
      setReadOnly(true)
      return () => {
        setReadOnly(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    if (getResponse && getResponse.length > 0) {
      getResponse.filter((disclosure) => {
        disclosure.ischecked
      }).length === 4
        ? setDisclosures(true)
        : setDisclosures(false)
    }
  }, [])
  return (
    <>
      <ItemWrapper sm={6} md={4} lg={3} id={!wipId ? 'full_col' : 'left_col'}>
        <div id="left_grp_next">
          <FormWip />
          <ApplicantInfoPage />
        </div>
      </ItemWrapper>
      <ItemWrapper
        sm={6}
        md={4}
        lg={3}
        id="right_col"
        className={'enable_scroll'}
      >
        <div id="left_grp_next">
          {wipId && <ProductFeatures />}
          {wipId && <ContactInformation />}
          {wipId && <ElectronicPreferences />}
          {wipId && <FundingInfo />}
          {wipId && <InvestmentInfo />}
          {wipId && <IndustryAffiliation />}
          {wipId && getWipResponse?.wipDTO?.trustedcontactid !== 0 && (
            <TrustedContact />
          )}
          {wipId &&
            newModelData?.wipAccountDetailslist &&
            newModelData?.wipAccountDetailslist.length > 0 &&
            newModelData?.wipAccountDetailslist[0]?.accttitle ===
              'Retirement' && (
              <>
                <PersonalInformation />
                <SourceOfContribution />
                <BestInterest />
                <PrimaryBeneficiary
                  key={'primary'}
                  type={'primary'}
                  header="Primary Beneficiary"
                />
                <PrimaryBeneficiary
                  key={'contingent'}
                  type={'contingent'}
                  header="Contingent Beneficiary"
                />
              </>
            )}
          {wipId &&
            newModelData?.wipAccountDetailslist &&
            newModelData?.wipAccountDetailslist.length > 0 &&
            newModelData?.wipAccountDetailslist[0]?.accttitle ===
              'Entity/Corporation' && (
              <>
                <PrimaryBeneficiary
                  key={'beneOwner'}
                  type={'beneOwner'}
                  header="Beneficial Owners"
                />
                <PrimaryBeneficiary
                  key={'addlPpt'}
                  type={'addlPpt'}
                  header="Additional Participants"
                />
              </>
            )}
          {wipId && <FormDocumentsReview setStage={setStage} />}
          {/* {wipId &&
            newModelData?.wipDetails &&
            newModelData?.wipDetails?.wipstatusid === 8 &&
            isFinalReview && (
              <DisclosureGrid>
                <IndustryAffiliation />
              </DisclosureGrid>
            )} */}
          {wipId &&
            newModelData &&
            newModelData?.wipDetails &&
            (newModelData?.wipDetails?.wipstatusid === 8 ||
              newModelData?.wipDetails?.wipstatusid === 9 ||
              newModelData?.wipDetails?.wipstatusid === 10) && (
              <DisclosureGrid>
                <DisclosureReview />
              </DisclosureGrid>
            )}

          {wipId &&
            (isFinalReview ||
              (isClientReview &&
                newModelData?.wipDetails?.wipstatusid === 7)) && (
              <ClientReviewCommentSection
                saveRecall={saveRecall ? saveRecall : false}
                resetSave={(val) => {
                  if (resetSave) resetSave(val)
                }}
                setIsUploadSend={(val: boolean) => {
                  if (setIsUploadSend) {
                    setIsUploadSend(val)
                  }
                }}
              />
            )}
        </div>
      </ItemWrapper>
    </>
  )
}

export default ReviewInformation
