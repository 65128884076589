import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import {
  DelteFile,
  DocumentsResponseInterface,
  DownloadFile,
  GetDocumentsListResponseInterface,
  GetFileDetails,
  UpdateFiles,
} from '../../shared/models/IFormDocuments'

export const UploadDocs = (payload: FormData) => {
  return axios.post<FormData>(`${ENDPOINTS.UPLOAD_NEW_DOCS}`, payload)
}
export const UpdateDocs = (payload: UpdateFiles) => {
  return axios.post<UpdateFiles>(`${ENDPOINTS.UPDATE_DOCS}`, payload)
}
export const GetDocDetails = (payload: GetFileDetails) => {
  return axios.post<DocumentsResponseInterface[]>(
    `${ENDPOINTS.GET_DOC_DETAILS}`,
    payload
  )
}
export const DownloadDoc = (payload: DownloadFile) => {
  return axios.post<DownloadFile>(`${ENDPOINTS.DOWNLOAD_DOCUMENT}`, payload)
}
export const DeleteDoc = (payload: DelteFile) => {
  return axios.delete(ENDPOINTS.DELTE_DOC, {
    data: {
      fileName: payload.fileName,
      folderPath: payload.folderPath,
      containerName: payload.containerName,
      wipdocreqid: payload.wipdocreqid,
    },
  })
}
export const FetchReqDocs = (payload: GetFileDetails) => {
  return axios.post<GetDocumentsListResponseInterface[]>(
    `${ENDPOINTS.FETCH_REQ_DOCS}`,
    payload
  )
}
