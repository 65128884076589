import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { ToastProps } from './IToast'
import { keyframes } from 'styled-components'

const handleBgColor = (type: string) => {
  switch (type) {
    case 'success':
      return `background:${COLORS.Background.OffWhite4}; border:1px solid ${COLORS.Border.SuccessStrong};`
    case 'danger':
      return `background:${COLORS.Background.OffWhite5}; border:1px solid ${COLORS.Border.Salmon1}`
    case 'warning':
      return `background:${COLORS.Background.OffWhite6}; border:1px solid ${COLORS.Border.Orange}`
    default:
      return `background:${COLORS.Background.PrimaryText}; border:1px solid ${COLORS.Border.PrimaryBorderStrong}`
  }
}

export const Master = styled.div<ToastProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Main = styled.div<ToastProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  animation: fadeOut ease 12s;
  padding: 12px;
  width: 300px;
  /* ${(Props: { type: string }) => handleBgColor(Props.type)}; */
  gap: 0.5rem;
  /* box-shadow: 0rem 0.125rem 0.375rem -0.125rem ${COLORS.BoxShadow.Shadow1}; */
  border-radius: 0.25rem;
  background: ${COLORS.Background.White};
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
`
export const ToastWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  &#gap{
    gap:   0.5rem;
  }
  &#flexend{
    justify-content: flex-end;
    padding: 6px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
  }
`
export const ToastIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
export const ToastTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 25rem;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0rem 0.5rem;
`
export const ToastImage = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  align-items: center;
`
export const ToastText = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: flex-start;
  margin-top: 2px;
  color: #757575;
`
const widthDecreaseAnimation = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`
export const DividerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 999;
  opacity: 0.7;
  transform-origin: left;
  animation: ${({ animateWidth }: any) =>
      animateWidth ? widthDecreaseAnimation : 'none'}
    3s linear;
`