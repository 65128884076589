import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`
export const BoxGroup = styled.div`
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  justify-content: flex-start;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  .fullWidth {
    width: 50%;
  }
  input {
    width: 100%;
    height: 38px;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  > div {
    width: 100%;
    border-radius: 0.5rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }
  .fullWidth {
    width: 50%;
  }
  &#rounded button {
    border: 1px solid ${COLORS.UI.Primary10};
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    line-height: 1.5rem;
    height: 38px;
  }
  button {
    position: relative;
    z-index: 999;
  }
`
