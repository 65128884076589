import { AxiosError } from 'axios'
import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { DownloadFile } from '../../shared/models/IFormDocuments'

export interface UpdateETBProps {
  setModalCallBack: (status: boolean) => void
  rowData?: DownloadFile
  index?: number
  apiSuccessCallBack: (status: boolean, error?: AxiosError | null) => void
}

export const MasterWrapper = styled.div`
  display: flex;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 4%;
  width: 100%;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 40px;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999 !important;
  cursor: pointer;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  align-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  color: ${COLORS.UI.Gray100};
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: right;
  padding: 0.5rem;
  width: 99%;
  height: 99%;
  overflow-y: auto;
  animation: popup 100ms ease-out;
  @keyframes popup {
    from {
      transform: scale(0.75);
    }
    to {
      transform: scale(1);
    }
  }
`
export const ModalHeaderWrapper = styled.div`
  display: flex;
  flex: none;
  order: 0;
  flex-grow: 1;
  width: 100%;
  margin-top: 0.3125rem;
  margin-bottom: 0rem;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-start;
  padding: 0.5rem 0rem 0rem 0.5rem;
  text-align: left;
`
export const CancelWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-end;
  height: 0.75rem;
  margin: 1.25rem 0.875rem;
`
export const ToastImage = styled.image`
  display: flex;
  justify-content: flex-end;
  width: 0.9375rem;
  height: 0.9375rem;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
`
export const ImageWrapper = styled.div`
  margin: 0.25rem;
`
export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const FormWrapper = styled.form`
  display: flex;
  margin-left: 16px;
`
export const FieldsetWrapper = styled.fieldset`
  border: none;
  padding: 0;
  width: 100%;
  margin-left: 0.5rem;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;
`
export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row no-wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 0 1 100%;
  gap: 1rem;
`
export const FirstTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 50%;
  color: ${COLORS.UI.White};
  padding: 0.5rem 0.5rem 0.5rem 0;
`
export const SecondTextBox = styled.div`
  color: ${COLORS.UI.White};
  padding: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 0 1 50%;
`
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1rem;
  flex: 0 1 100%;
`
export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 100%;
`

export const ThirdTextBox = styled.div`
  color: ${COLORS.UI.White};
  padding: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 0 1 100%;
`
export const TextBoxWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
`
export const SourceWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 3rem;
`

export const AllocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 1rem 0.3125rem;
  height: 80px;
  border-radius: 8px;
  border: 1px solid ${COLORS.Border.Gray50};
  padding: 0.5rem;
`
export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 33%;
  justify-content: center;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${COLORS.UI.Black};
`
export const LabelText = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 50%;
  margin-top: 0.625rem;
  font-weight: 400;
`
export const QuantityValue = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 50%;
  margin-bottom: 0.625rem;
  font-weight: bold;
`
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${COLORS.Background.White};
  width: 100%;
  margin: 0rem 0rem 0.3125rem 0rem;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0.5rem 0.5rem;
`
export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(25% - 1rem);
`
export const InlineErrorWrapper = styled.div`
  color: ${COLORS.UI.Negative2};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  font-size: 1.25rem;
`
export const AllocationWrapperReview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  flex-direction: row;
  height: 80px;
  background: ${COLORS.Background.NeutralMedium};
  opacity: 0.8;
  border-radius: 8px;
  width: 100%;
`
export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  color: ${COLORS.UI.Black};
`
export const SpinnerWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${COLORS.Background.Gray90};
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 0.5rem;
`
export const ClientHeaderWrapper = styled.div`
  background: ${COLORS.Background.Neutral};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  gap: 12.25rem;
`
export const ProfileDesignationWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`
export const QuantityWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 3px 0px 0px 0px;
  white-space: nowrap;
`
export const DraftModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  margin-right: 24px;
`
export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 1rem;
  background: ${COLORS.Background.Gray50} !important;
  border-radius: 1px;
  margin: 2px 0px 0px 0px;
`
export const DocViewErrWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 150px;
  color: red;
`
