import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: center;
`
export const File = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 0.5rem;
`

export const UploadWrapper = styled.div`
  /* border: 2px dashed var(--Color-border-primary-default, #60a5fa);
  border-radius: 3px;
  gap: 1rem;
  box-sizing: border-box;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  display: flex;
  background: rgba(96, 165, 250, 0.25);
  &.file-uploaded {
    background-color: ${COLORS.Background.NeutralMedium};
    border: none;
  } */
  display: flex;
  height: 112px;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  &.dashed-border::before {
    content: '';
    position: absolute;
    border: 8px dashed var(--Color-border-primary-default, #60a5fa);
    top: -6px;
    bottom: -6px;
    left: -6px;
    right: -6px;
  }
`
export const InputWrapper = styled.input``
export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`
export const DeleteRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const FileViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const DownloadFileWrapper = styled.div`
  display: flex;
  flex: 0 1 70%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const LabelRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
`
export const ButtonRowWrapper = styled.div`
  display: flex;
  flex: 0 1 30%;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding-right: 1rem;
`
export const SampleFile = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: flex-start;
`
export const InlineErrorWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-end;
  align-items: center;
  color: ${COLORS.Text.Danger};
`
export const IconWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: flex-start;
  align-items: center;
`
export const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
export const MiddleLineWrapper = styled.div`
  flex: 1;
  height: 0.1rem;
  width: 5rem;
  background-color: ${COLORS.Background.Gray130};
`
export const TextLabel = styled.div`
  display: flex;
  color: ${COLORS.Text.Gray50};
`
export const WDLabelErrorWrap = styled.div`
  color: ${COLORS.Text.Danger};
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`
export const TextWrapper = styled.div`
  font-size: 12px;
  color: #0f172a;
  line-height: 14px;
  font-weight: 600;
`
export const ChooseWrapper = styled.div`
  > div {
    button {
      padding: 1rem 1.5rem;
      position: relative;
      &#zIndex {
        z-index: 99999;
      }
    }
  }
  /* > div button:hover {
    background: none !important;
    border: none !important;
  } */
`
export const HintWrapper = styled.div`
  font-size: 14px;
  color: #656565;
  /* &#doc_header {
    white-space: nowrap;
  } */
  &#preview_name {
    width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const PreviewDropWrapper = styled.div`
  > select {
    border-radius: 0 !important;
    min-height: 4rem;
  }
`
