import styled from 'styled-components'

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    &.spinner {
      animation: spin 2s linear infinite;
    }
  }
`
export const StyledSVG = styled.svg`
  position: absolute;
  &.product_info {
    bottom: -20px;
    right: -20px;
  }
  &.primary_app {
    bottom: -40px;
    right: -15px;
  }
  &.product_features {
    bottom: -15px;
    right: -15px;
  }
  &.contact_info {
    bottom: -15px;
    right: -23px;
  }
  &.svg_docs {
    bottom: -15px;
    right: -20px;
  }
  @media screen and (min-width: 1440px) {
    &.product_info {
      bottom: -30px;
      right: -30px;
    }
    &.primary_app {
      bottom: -50px;
      right: -25px;
    }
    &.product_features {
      bottom: -25px;
      right: -25px;
    }
    &.contact_info {
      bottom: -25px;
      right: -30px;
    }
    &.svg_docs {
      bottom: -25px;
      right: -28px;
    }
  }
`
