import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  //gap: 0.5rem;
  //padding: 0.25rem;
  //overflow-y: auto;
`
export const WipHeadingWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  &#wrap_row {
    flex-wrap: wrap;
  }
`

export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 80%;
`
export const ClientWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 45%;
  background-color: ${COLORS.Background.White};
`
export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 45%;
  bottom: 1;
  background-color: ${COLORS.Background.White};
  position: relative;
  &#primary-applicant {
    white-space: nowrap;
  }
`
export const ApplicantInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  flex: 0 1 45%;
`
export const ApplicantInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const AddressSection = styled.div`
  display: flex;
  flex: 0 1 100%;
  gap: 1rem;
`
export const CheckboxInput = styled.input`
  &#decline_state {
    margin-top: 1px;
  }
`
export const CheckMark = styled.span``
export const CheckBoxWrapper = styled.label`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-start;
  align-items: baseline;
  gap: 0.5rem;
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${COLORS.UI.PrimaryText};
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${COLORS.UI.PrimaryText};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  padding: 1rem 0.5rem 0;
  gap: 1rem;
`
export const ApplicantInfoColumnRight = styled.div`
  display: flex;
  flex: 70%;
`
export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`
export const DocumentRowContainer = styled.div`
  display: flex;
  gap: 16px;
  &#secondRow {
    margin-top: 8px;
    flex: 0 1 50%;
    width: 100%;
  }
`
export const DocumentNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const FeatureInfoRow = styled.div`
  display: flex;
  gap: 4px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: 1440px) {
    gap: 12px;
  }
`
export const LegalAddressWrapper = styled.div`
  flex: 0 1 50%;
  align-items: center;
  justify-content: flex-start;
`
export const AddressWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 95%;
  justify-content: flex-start;
  padding: 8px;
  &#option {
    padding: 0;
  }
  &#cssaddress {
    padding: 8px 8px 8px 0;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  flex: 0 1 5%;
  padding-bottom: 3.5rem;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 0.25rem;
  @media screen and (min-width: 1440px) {
    gap: 12px;
  }
`
export const RowWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
  border: 1px solid blue;
  gap: 1rem;
`
export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 0 1 100%;
  width: 100%;
`
export const TextAreaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* background: #eff6ff; */
  &#FisTitle {
    min-height: 6rem;
  }
  &#NoTitle {
    min-height: 2rem;
  }
  //flex-direction: column;
  flex: 0 1 50%;
  border: ${(borderProps: any) =>
    borderProps.readOnly ? 'none' : `1px solid ${COLORS.Border.Neutral}`};
  padding: ${(borderProps: any) => (borderProps.readOnly ? '0px' : '0.15rem')};
  border-radius: 4px;
  .readOnly {
    border: none;
  }
  textarea {
    height: 2.5rem;
    box-sizing: border-box;
    resize: none;
    border-radius: 0.25rem;
    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background: ${COLORS.Background.White};
    }
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.Background.Primary5};
      border-radius: 4px;
    }
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`
export const FeatureInfoRowEmail = styled.div`
  display: flex;
  gap: 4px;
  flex: 0 1 50%;
  flex-direction: column;
  @media screen and (min-width: 1440px) {
    gap: 12px;
  }
`

export const BGcolor = styled.div`
  /* background: #eff6ff; */
`
export const FisReadonlyWrapper = styled.div`
  padding: 0.5rem;
`
export const FisTitleWrapper = styled.div``
export const FirstRowWrapper = styled.div`
  display: flex;
  gap: 1rem;
  &#red {
    flex-direction: row;
  }
`
export const FirstHalfWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`
export const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 19px;
`
export const JointAppWrapper = styled.div`
  display: flex;

  gap: 19px;
`
export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
{
  /* <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '50%',
                          gap: '1rem',
                        }}
                      >
                        <DocumentRowContainer>
                          <RowWrapper>
                            {/* <FeatureInfoRow>
                              <FeatureInfoColumn>
                                <WDLabelClient className="label_responsive">
                                  <Label>{'Legal Address'}</Label>
                                </WDLabelClient>

                                {readOnly ? (
                                  <BGcolor>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {addAccountResponse.partyAddrLegal
                                          ? getLegalAddress()
                                          : legalAddress.label !== 'Select'
                                          ? legalAddress.label
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  </BGcolor>
                                ) : (
                                  getLegalAddress()
                                )}
                              </FeatureInfoColumn>
                              <FeatureInfoColumn>
                                {!readOnly && (
                                  <LegalAddressWrapper>
                                    <CheckBoxWrapper
                                      id="red"
                                      className="container"
                                    >
                                      <CheckBoxNew
                                        // type="checkbox"
                                        checked={sameAsMailing}
                                        onChange={() => {
                                          setSameAsMailing(!sameAsMailing)
                                          setLegalAddress(mailingAddress)
                                        }}
                                      />
                                      <CheckMark className="checkmark"></CheckMark>
                                      <WDLabelSmall>
                                        {'Same as Mailing Address'}
                                      </WDLabelSmall>
                                    </CheckBoxWrapper>
                                  </LegalAddressWrapper>
                                )}
                              </FeatureInfoColumn>
                      //       </FeatureInfoRow> */
}
//       <FeatureInfoRow>
//         <FeatureInfoColumn>
//           <WDLabelClient className="label_responsive">
//             <Label>
//               {'FIS Account Title and Address'}
//             </Label>
//           </WDLabelClient>
//         </FeatureInfoColumn>
//         <FeatureInfoColumn>
//           {readOnly ? (
//             <>
//               {mailingAddress.label !== 'Select' ? (
//                 <BGcolor>
//                   <FisReadonlyWrapper>
//                     <WDLabelClient className="label_responsive">
//                       {client?.clientName
//                         ? client?.clientName
//                         : ''}
//                     </WDLabelClient>
//                     <WDLabelClient>
//                       {/* {mailingAddress.label
//                 .split(',')
//                 .map((item, index) => {
//                   return <div key={index}>{item}</div>
//                 })} */}
//                       {convertAddress(
//                         mailingAddress.label
//                       )}
//                     </WDLabelClient>
//                   </FisReadonlyWrapper>
//                 </BGcolor>
//               ) : (
//                 '--'
//               )}
//             </>
//           ) : (
//             <>
//               {
//                 <BGcolor>
//                   <WDLabelClient className="label_responsive">
//                     {/* <TextAreaWrapper
//                 id={
//                   !mailingDropdown
//                     ? 'FisTitle'
//                     : 'NoTitle'
//                 }
//               > */}
//                     <AddressWrap>
//                       <WDLabelClient className="label_responsive">
//                         {client?.clientName
//                           ? client?.clientName
//                           : ''}
//                       </WDLabelClient>
//                       {mailingAddress.label !==
//                         'Select' && (
//                         <WDLabelClient className="label_responsive">
//                           {convertAddress(
//                             mailingAddress.label
//                           )}
//                         </WDLabelClient>
//                       )}
//                     </AddressWrap>
//                     {/* </TextAreaWrapper> */}
//                   </WDLabelClient>
//                 </BGcolor>
//               }
//             </>
//           )}
//         </FeatureInfoColumn>
//       </FeatureInfoRow>
//     </RowWrapper>
//   </DocumentRowContainer>
// </div> */}
