import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import { XrefRequestModel, XrefResponseModel } from '../../shared/models/IXREF'

export const getXrefDetails = (payload: XrefRequestModel) => {
  // axios call here  and returtn promise
  return axios.get<XrefResponseModel>(
    ENDPOINTS.GET_XREF_DETAILS.replace('{appid}', payload.appid).replace(
      '{lob}',
      payload.lob.toString()
    )
  )
}
