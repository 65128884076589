import { useContext, useMemo } from 'react'
import { useTable } from 'react-table'
import { SvgNoData } from '../../components/svg/SvgNoData'
import {
  NoDataWrapper,
  ReactTable,
  RootContainer,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  ThContentWrapper,
  ThWrapper,
} from '../../components/table/styles'
import { WDLabelBold } from '../../components/ui/WDLabel'
import ClientAndAccountSearchContext from '../../services/clientAndAccountSearch/ClientAndAccountSearchContext'
import {
  AccountDetailsInterface,
  ClientSearchByNameResponseInterface,
} from '../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import { ClientSearchTableProps, ColumnDetails } from './IClientSearch'

export const ClientSearchTable = ({
  rowData,
  closeModalCallbackFn,
  clientDetailsCallBackFn,
}: ClientSearchTableProps) => {
  const ClientSearchTableColumns = [
    {
      Header: 'Client ID',
      accessor: 'clientId',
    },
    {
      Header: 'Full Name',
      accessor: 'clientName',
    },
    {
      Header: 'RepCode',
      accessor: 'repcode',
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (data: any) => {
        const getEmailData = data.cell.row.original.clientEmailList
        if (getEmailData.length > 0)
          return (
            <>
              {getEmailData[0].emailAddress
                ? getEmailData[0].emailAddress
                : '--'}
            </>
          )
        return '--'
      },
    },
    {
      Header: 'Mobile',
      accessor: 'mobile',
      Cell: (data: any) => {
        const getPhoneData = data.cell.row.original.clientContactList
        if (getPhoneData.length > 0)
          return (
            <>
              {getPhoneData[0].partyContactValue
                ? getPhoneData[0].partyContactValue
                : '--'}
            </>
          )
        return '--'
      },
    },
  ]
  const { selectAccount } = useContext(ClientAndAccountSearchContext)
  const data: ClientSearchByNameResponseInterface[] = useMemo(
    () => rowData,
    [rowData]
  )

  const columns = useMemo<ColumnDetails[]>(
    () => ClientSearchTableColumns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ) as []
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })
  const handleAccount = (rowIndex: number) => {
    closeModalCallbackFn()
    clientDetailsCallBackFn(rowData[rowIndex])
    selectAccount({} as AccountDetailsInterface)
  }

  return (
    <RootContainer id="client_search">
      <>
        {data.length > 0 ? (
          <>
            <ReactTable>
              <TableWrapper {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup, index) => (
                    <TableRow
                      {...headerGroup.getHeaderGroupProps()}
                      key={index}
                    >
                      {headerGroup.headers.map((column, colIndex) => (
                        <TableHeaderCell
                          {...column.getHeaderProps([
                            {
                              style: { width: column.width },
                            },
                          ])}
                          key={colIndex}
                        >
                          <ThWrapper>
                            <ThContentWrapper>
                              {column.render('Header')}
                            </ThContentWrapper>
                          </ThWrapper>
                        </TableHeaderCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => {
                    prepareRow(row)
                    return (
                      <TableRow
                        {...row.getRowProps()}
                        key={rowIndex}
                        onClick={() => {
                          handleAccount(rowIndex)
                        }}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <TableDataCell
                              {...cell.getCellProps()}
                              className={cell.column.id}
                              key={index}
                            >
                              {cell.render('Cell')}
                            </TableDataCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </TableWrapper>
            </ReactTable>
            {/* <TotalWrapper>
              <WDLabelSemiBold>
                <Label>
                  {'Total Records'} {rowData.length}
                </Label>
              </WDLabelSemiBold>
            </TotalWrapper> */}
          </>
        ) : (
          <NoDataWrapper>
            <SvgNoData />
            <WDLabelBold>{'No Results Found'}</WDLabelBold>
          </NoDataWrapper>
        )}
      </>
    </RootContainer>
  )
}
