import styled from 'styled-components'
import { COLORS } from '../../../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
`
export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  width: 100%;
`

export const Wrapper1 = styled.div`
  display: flex;
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  padding: 2px;
`
export const FieldContainer = styled.div`
  display: flex;
  flex: 0 1 50%;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
`
export const TabRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 95%;
  ul {
    padding-left: 12px;
  }
  li {
    border-bottom-width: 2px !important;
  }
`
export const STabs = styled.div`
  margin-top: 0px;
  padding: 1px 1px 1px 1px;
  height: 100%;
`

export const StyledTabList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  list-style: none;
`
export const StyledTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  font-family: 'SourceSansPro-Regular', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${COLORS.Text.Neutral};
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.Background.StrongBackground};
  &.is-selected {
    border-bottom: 2px solid ${COLORS.Text.Primary};
  }
`
export const StyledTabPanel = styled.div`
  // width: 60vw;
  //height:90%;
`

export const TextSizes = [
  '0.25rem',
  '0.5rem',
  '0.875rem',
  '1rem',
  '1.125rem',
  '1.25rem',
  '1.5rem',
  '1.625rem',
  '2rem',
  '2.5rem',
  '2.625rem',
  '3rem',
  '3.5rem',
  '4rem',
  '4.25rem',
]
export const SIZES = [
  '0.25rem',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '2.625rem',
  '3rem',
  '3.5rem',
  '4rem',
  '4.5rem',
  '5rem',
  '5.5rem',
  '6rem',
  '6.5rem',
  '7rem',
  '7.5rem',
  '8rem',
  '8.5rem',
  '9rem',
  '9.5rem',
  '10rem',
  '10.5rem',
]

export const TextWeight = ['400', '500', '600', '700']

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${COLORS.UI.NeutralText};
`

export const TrustedContents = styled.div`
  font-style: normal;
  line-height: 1.5rem;
  text-align: justify;
`
export const TabLabel = styled.p`
  margin-left: 12px;
`
export const SearchButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  display: flex;
  align-items: center;
  height: inherit;
`
export const TableDataCell = styled.td`
  padding: 4px 0px 4px 8px;
  width: 34%;
  td:last-child {
    width: auto;
  }
`

export const SearchTerm = styled.div`
  display: flex;
  outline: none;
  color: ${COLORS.Text.Black};
  gap: 0.25rem;
  position: relative;
  input {
    padding-right: 2rem;
    border: 0.0625rem solid ${COLORS.Text.DisabledText};
    border-radius: 4px;
    &:hover {
      border: 0.0625rem solid ${COLORS.Border.PrimaryText};
      color: ${COLORS.Text.Black};
    }
    &:focus {
      border: 0.0625rem solid ${COLORS.Border.PrimaryText};
      color: ${COLORS.Text.Black};
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
`
export const DisclosureButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
`
