import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 30%;
  top: 40%;
  right: 30%;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #fff7ed;
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 40%;
  border: 1px solid #fb923c;
`

export const ModelButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
`
export const ModalContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.5rem;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  width: 80%;
`
export const TextBoxWrapper = styled.div`
  height: 2rem;
  input {
    padding: 0 0.8rem;
    width: 10rem;
    border-radius: 8px;
    height: 2rem;
    border: none;
    outline: none;
  }
  input:hover {
    border: none;
    outline: none;
  }
  input:focus {
    border: none !important;
    outline: none !important;
  }
  input:active {
    border: none;
    outline: none;
  }
`
