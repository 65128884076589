
import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import {
  EnableDisclosureRequest,
  GetDisclosureRequestInterface,
  UpdateDisclosureRequestInterface,
  UpdateDisclosureResponseInterface,
  getDisclosureList,
} from './IDisclosure'

export const getDisclosureAPI = (payload: GetDisclosureRequestInterface) => {
  return axios.post<getDisclosureList[]>(ENDPOINTS.GET_DISCLOSURE_API, payload)
}
export const updateDisclosureAPI = (payload: UpdateDisclosureRequestInterface) => {
  return axios.post(ENDPOINTS.UPDATE_DISCLOSURE_API, payload)
}

export const enableDisclosureAPI = (payload: EnableDisclosureRequest) => {
  return axios.post<UpdateDisclosureResponseInterface>(
    ENDPOINTS.ENABLE_DISCLOSURE,
    payload
  );
};