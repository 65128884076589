import styled from 'styled-components'

const StyledContainer = styled.div`
  display: grid;
  width: 100%;
  background-color: #f0f2f5;
  /* &#inside_page {
    height: 100vh;
  } */
`
export default StyledContainer
