import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const DisclosuretWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  height: 50vh;
  padding: 1rem;
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  //height: 65vh;
  gap: 1rem;
  // overflow-y: scroll;
  // ::-webkit-scrollbar {
  //   background: ${COLORS.Background.NeutralBackgroundMedium};
  //   width: 9px;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   height: 8px;
  //   background: ${COLORS.Background.StrongBackground};
  //   top: 8px;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: rgba(167, 175, 188, 0.3);
  // }
  // ::-webkit-scrollbar-track {
  //   border-radius: 8px;
  // }
`
export const TextContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`
export const ListRow = styled.div`
  display: flex;
  padding-bottom: 1rem;
`
export const ListBullet = styled.div`
  display: flex;
  flex-direction: column;
  width: 3%;
`
export const ListText = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
`
export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  align-self: flex-start;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: ${COLORS.Background.White};
  gap: 0.6rem;
`
export const DiscolsuresContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`
export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const OptionsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1rem;
  height: auto;
`

export const OptionHalf = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const OptionHalfItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding: 0.3rem;
  align-items: center;
  justify-content: space-between;
  width: 62%;
`
export const MasterWrapperModel = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 15%;
  top: 5%;
  right: 5%;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
  background-color: ${COLORS.Background.White};
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 73%;
  word-wrap: break-word;

  border-radius: 4px;
  border: 1px solid ${COLORS.Border.Neutral};
`
export const ModelContent = styled.div`
  overflow-y: scroll;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 75vh;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const MasterWrapper = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  gap: 0.6rem;
`
export const ModelButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const WDLabelLink = styled.div`
  color: ${COLORS.Border.Primary50};
  text-decoration: underline;
`
export const CreateButtonWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  button {
    font-size: 1rem;
    line-height: 2.5rem;
    padding: 0.5rem 1rem;
    height: 2.5rem;
    min-width: 10rem;
  }
`
