import Select, { StylesConfig } from 'react-select'
import { LottieButtonWrapper, Wrapper } from './styles'
import { DefaultProps, SelectDropdownProps } from './ISelectDropDown'
import { useState } from 'react'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../components/svg/SvgChevron'
import { COLORS } from '../../theme/Colors'
import { OptionProps } from './ISelectDropDown'
import Lottie from 'lottie-react'
import Loader from '../../assets/lottiefiles/loader.json'
const SelectDropdown = ({
  onChange,
  options,
  defaultValue,
  value,
  isDisabled,
  isSearchable = false,
  width,
  name,
  placeholder,
  controlShouldRenderValue = true,
  isMulti,
  isInput,
  isLoading,
  onMultipleChange,
  border,
}: SelectDropdownProps) => {
  const [toggleIcon, setToggleIcon] = useState(true)
  const [currentValue, setCurrentValue] = useState('')
  const [currentMultiValue, setCurrentMultiValue] = useState<DefaultProps[]>([])

  // const handleOnChange = (option: OptionProps | unknown) => {
  //   onChange(option as unknown as OptionProps)
  // }
  const handleOnChange = (option: OptionProps | unknown) => {
    setCurrentValue((option as OptionProps).value)
    onChange?.(option as OptionProps)
  }
  // const handleOnChange = ( // crm
  //   newValue: MultiValue<OptionProps> | SingleValue<OptionProps>
  // ) => {
  //   onChange && onChange(newValue as OptionProps)
  // }
  const handleChangeMultiple = (newValue: unknown) => {
    setCurrentMultiValue(newValue as DefaultProps[])
    onMultipleChange?.(newValue as DefaultProps[])
  }
  // const handleChangeMultiple = ( //crm
  //   newValue: MultiValue<OptionProps> | SingleValue<OptionProps>
  // ) => {
  //   onMultipleChange && onMultipleChange(newValue as OptionProps[])
  // }
  const customStyles: StylesConfig = {
    control: (provided: any) => {
      return {
        ...provided,
        minHeight: '32px',
        padding: '8px',
        whiteSpace: 'pre',
        background: '#FFFFF',
        cursor: 'pointer',
        borderColor: border ? COLORS.Text.Danger : COLORS.Border.Neutral,
        borderRadius: '0.5rem',
      }
    },
    valueContainer: (provided: any) => {
      return {
        ...provided,
        padding: 0,
      }
    },
    singleValue: (provided: any) => {
      return {
        ...provided,
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        margin: 0,
      }
    },
    menu: (provided: any) => {
      return {
        ...provided,
        width: '100%',
        boxShadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
        border: `1px solid ${COLORS.UI.NeutralBorderWeak}`,
        zIndex: 99999,
        maxHeight: '200px',
        overflowY: 'auto',
        marginBottom: '8px',
        marginTop: '8px',
        ...(isLoading && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
          height: '3rem',
        }),
      }
    },
    menuList: (provided: any) => {
      return {
        ...provided,
        padding: '4px 0px',
        position: 'relative',
      }
    },
    option: (provided: any) => {
      return {
        ...provided,
        cursor: 'pointer',
        display: 'block',
        fontSize: 'inherit',
        width: '100%',
        padding: '8px 12px',
      }
    },
    input: (provided: any) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
  }

  return (
    <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
      {isMulti ? (
        <Wrapper
          width={width}
          onFocus={() => setToggleIcon(!toggleIcon)}
          onBlur={() => setToggleIcon(true)}
        >
          <Select
            options={options}
            onChange={isMulti ? handleChangeMultiple : handleOnChange}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            isMulti={isMulti}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () =>
                toggleIcon ? (
                  <SvgChevronDownMedium fillColor={COLORS.Icons.Neutral} />
                ) : (
                  <SvgChevronUpMedium fillColor={COLORS.Icons.Neutral} />
                ),
            }}
            name={name}
            value={value}
            classNamePrefix="dropdown"
            styles={customStyles}
            blurInputOnSelect={true}
            placeholder={placeholder}
            controlShouldRenderValue={controlShouldRenderValue}
          />
        </Wrapper>
      ) : (
        <Wrapper
          width={width}
          onFocus={() => setToggleIcon(!toggleIcon)}
          onBlur={() => setToggleIcon(true)}
        >
          <Select
            options={isLoading ? [] : options}
            onChange={isMulti ? handleChangeMultiple : handleOnChange}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () =>
                toggleIcon ? (
                  <SvgChevronDownMedium fillColor={COLORS.Icons.Neutral} />
                ) : (
                  <SvgChevronUpMedium fillColor={COLORS.Icons.Neutral} />
                ),
              MenuList: (props: any) => (
                <div>
                  {isLoading ? (
                    <LottieButtonWrapper>
                      <Lottie animationData={Loader} loop={true} />
                    </LottieButtonWrapper>
                  ) : (
                    props.children
                  )}
                </div>
              ),
            }}
            name={name}
            value={value}
            classNamePrefix="dropdown"
            styles={customStyles}
            blurInputOnSelect={true}
            placeholder={placeholder}
            controlShouldRenderValue={controlShouldRenderValue}
          />
        </Wrapper>
      )}
      {isInput && <input type="number" />}
    </div>
  )
}

export default SelectDropdown
