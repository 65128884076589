import { CheckboxProps } from './ICheckbox'
import { Wrapper, StyledCheckbox, StyledLabel } from './styles'

const Checkbox = ({
  title,
  name,
  checkBoxId,
  value,
  disabled,
  checked,
  defaultChecked,
  indeterminate,
  onChange,
  required,
}: CheckboxProps) => {
  return (
    <Wrapper>
      <StyledCheckbox
        type={'checkbox'}
        id={checkBoxId}
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        indeterminate={indeterminate}
        onChange={onChange}
        name={name}
        required={required}
      />
      {title && <StyledLabel htmlFor={checkBoxId}>{title}</StyledLabel>}
    </Wrapper>
  )
}

export default Checkbox
