import Select, {
  IndicatorsContainerProps,
  MultiValue,
  SingleValue,
} from 'react-select'
import { DefaultProps, SelectDropdownProps } from './ISelectDropdown'
import { Wrapper } from './styles'

const SelectDropdown = ({
  onChange,
  onBlur,
  defaultValue,
  value,
  isMulti,
  borderRadius,
  borderColor,
  options,
  isDisabled,
  isSearchable,
  indicatorSeparator,
  width,
  height,
  name,
  placeholder,
  innerRef,
  padding,
  indicatorColor,
  placeholderColor,
  singleValueColor,
  indicatorContainer,
  className,
}: SelectDropdownProps) => {
  const handleChange = (
    newValue: MultiValue<DefaultProps> | SingleValue<DefaultProps>
  ) => {
    //const inputValue = (newValue as DefaultProps).value.replace(/\W/g, '')
    const inputValue = (newValue as DefaultProps).value;
    onChange && onChange(inputValue)
  }

  const customStyles = {
    control: (provided: any) => {
      return {
        ...provided,
        width: width,
        height: height,
        minHeight: height,
        padding: padding,
        borderRadius: borderRadius,
        borderColor: borderColor,
        display: 'flex',
        alignContent: 'center'

      }
    },
    menu: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      }
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: placeholderColor,
        margin: '0.125rem 0rem',
        fontSize: '14px'
      }
    },
    singleValue: (provided: any) => {
      return {
        ...provided,
        color: singleValueColor,
        fontSize: '14px'
      }
    },
    option: (provided: any) => {
      return {
        ...provided,
        fontSize: '14px',
      }
    },
    indicatorContainer: (provided: IndicatorsContainerProps) => {
      return {
        ...provided,
        color: indicatorContainer,
        fontSize: '14px'
      }
    },
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: indicatorColor, // Custom colour
      padding: '0.25rem',
    }),
    menuPortal: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      }

    }
  }

  return (
    <>
      {!indicatorSeparator ? (
        <Wrapper width={width}>
          <Select
            options={options}
            onChange={handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            components={{
              IndicatorSeparator: () => null,
            }}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            className={className}
          />
        </Wrapper>
      ) : (
        <Wrapper width={width}>
          <Select
            options={options}
            onChange={handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            className={className}
          />
        </Wrapper>
      )}
    </>
  )
}

export default SelectDropdown
