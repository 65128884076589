import { COLORS } from '../../../../../../theme/Colors'
import { WDLabelClient, WDLabel } from '../../../../../../components/ui/WDLabel'
import {
  PersonalInfoWrapper,
  ItemListChildWrapper,
  ItemsListWrapper,
} from './styles'
import { WDButtonTransparent } from '../../../../../../components/ui/WDButton'
import Button from '../../../../../../components/button/Button'
import SvgEye from '../../../../../../components/svg/SvgEye'
import { SvgNoVisible } from '../../../../../../components/svg/SvgNoVisible'
import ApplicantInvestment from './ApplicantInvestment'
import React from 'react'

const RenderApplicantInfo = ({
  wipId,
  partyData,
  visibility,
  setVisibility,
  govtIds,
}: any) => {
  const formatSSN = (value: string) => {
    const first = value.substring(0, 3)
    const second = value.substring(3, 5)
    const third = value.substring(5)
    return first + '-' + second + '-' + third
  }
  const maskSSN = (value: string) => {
    if (value) {
      return value.slice(-4)
    }
    return ''
  }
  const formattingDate = (paramDate: string) => {
    let formattedDate = ''
    if (paramDate) {
      formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
    return formattedDate
  }
  return (
    <div>
      {partyData && (
        <>
          <PersonalInfoWrapper>
            <ItemsListWrapper>
              {wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Social Security Number'}
                  </WDLabel>
                  {govtIds?.type !== 'US Social Security' ? '--' : ''}
                  {visibility && govtIds?.type === 'US Social Security' && (
                    <WDButtonTransparent>
                      <Button
                        type="button"
                        onClick={() => setVisibility(!visibility)}
                      >
                        <SvgEye fillColor={COLORS.Text.NeutralText} />
                        <WDLabelClient className="label_responsive">
                          {formatSSN(govtIds?.number) ?? '--'}
                        </WDLabelClient>
                      </Button>
                    </WDButtonTransparent>
                  )}
                  {!visibility && govtIds?.type === 'US Social Security' && (
                    <WDButtonTransparent>
                      <Button
                        type="button"
                        onClick={() => setVisibility(!visibility)}
                      >
                        <SvgNoVisible fillColor={COLORS.Text.NeutralText} />
                        <WDLabelClient className="label_responsive">
                          &bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&nbsp;
                          {govtIds?.number !== null
                            ? maskSSN(govtIds?.number)
                            : '--'}
                        </WDLabelClient>
                      </Button>
                    </WDButtonTransparent>
                  )}
                </ItemListChildWrapper>
              )}
              <ItemListChildWrapper></ItemListChildWrapper>
            </ItemsListWrapper>
            {govtIds?.type !== 'US Social Security' && (
              <React.Fragment>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'ID Type'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {govtIds?.type ? govtIds?.type : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'ID Number'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {govtIds?.number ? govtIds?.number : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  {!wipId && (
                    <ItemListChildWrapper>
                      <WDLabel className="label_responsive">
                        {'Expiration Date'}
                      </WDLabel>
                      <WDLabelClient className="label_responsive">
                        {govtIds?.expirydate
                          ? formattingDate(govtIds?.expirydate)
                          : '--'}
                      </WDLabelClient>
                    </ItemListChildWrapper>
                  )}
                  {!wipId && (
                    <ItemListChildWrapper>
                      <WDLabel className="label_responsive">
                        {'Issue Date'}
                      </WDLabel>
                      <WDLabelClient className="label_responsive">
                        {govtIds?.issuedate
                          ? formattingDate(govtIds?.issuedate)
                          : '--'}
                      </WDLabelClient>
                    </ItemListChildWrapper>
                  )}
                </ItemsListWrapper>
                {wipId && (
                  <ItemsListWrapper>
                    <ItemListChildWrapper>
                      <WDLabel className="label_responsive">
                        {'Expiration Date'}
                      </WDLabel>
                      <WDLabelClient className="label_responsive">
                        {govtIds?.expirydate
                          ? formattingDate(govtIds?.expirydate)
                          : '--'}
                      </WDLabelClient>
                    </ItemListChildWrapper>
                    <ItemListChildWrapper>
                      <WDLabel className="label_responsive">
                        {'Issue Date'}
                      </WDLabel>
                      <WDLabelClient className="label_responsive">
                        {govtIds?.issuedate
                          ? formattingDate(govtIds?.issuedate)
                          : '--'}
                      </WDLabelClient>
                    </ItemListChildWrapper>
                  </ItemsListWrapper>
                )}
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {govtIds?.type === 'Driver License'
                        ? 'State of Issuance'
                        : 'Country of Issuance'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {govtIds?.countryOfIssuance
                        ? govtIds?.countryOfIssuance
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper></ItemListChildWrapper>
                </ItemsListWrapper>
              </React.Fragment>
            )}
          </PersonalInfoWrapper>
          <PersonalInfoWrapper>
            <ItemsListWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">
                  {'Country of Citizenship'}
                </WDLabel>
                <WDLabelClient className="label_responsive">
                  {partyData?.citizenshipCountry
                    ? partyData?.citizenshipCountry
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              {wipId && <ItemListChildWrapper></ItemListChildWrapper>}
              {!wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Country of Residence'}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.countryOfResidence
                      ? partyData?.countryOfResidence
                      : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
              {!wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {' '}
                      {'Country of Tax Residence'}
                    </span>
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.countryOfTaxResidence
                      ? partyData?.countryOfTaxResidence
                      : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
              {!wipId && <ItemListChildWrapper></ItemListChildWrapper>}
            </ItemsListWrapper>
            {wipId && (
              <ItemsListWrapper>
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Country of Residence'}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.countryOfResidence
                      ? partyData?.countryOfResidence
                      : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
                <ItemListChildWrapper></ItemListChildWrapper>
              </ItemsListWrapper>
            )}
            {wipId && (
              <ItemsListWrapper>
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {' '}
                      {'Country of Tax Residence'}
                    </span>
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.countryOfTaxResidence
                      ? partyData?.countryOfTaxResidence
                      : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
                <ItemListChildWrapper></ItemListChildWrapper>
              </ItemsListWrapper>
            )}
          </PersonalInfoWrapper>
          <PersonalInfoWrapper>
            <ItemsListWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">
                  {'Marital Status'}
                </WDLabel>
                <WDLabelClient className="label_responsive">
                  {partyData?.maritalStatus ? partyData?.maritalStatus : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">
                  {'Deceased Date'}
                </WDLabel>
                <WDLabelClient className="label_responsive">
                  {partyData?.deceasedDate
                    ? partyData?.deceasedDate.substring(8, 10) +
                      '/' +
                      partyData?.deceasedDate.substring(5, 7) +
                      '/' +
                      partyData?.deceasedDate.substring(0, 4)
                    : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              {!wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Number of Dependents'}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.numberOfDependents
                      ? partyData?.numberOfDependents
                      : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
              {!wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">{'Parent'}</WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.parent ? partyData?.parent : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
            </ItemsListWrapper>
            <ItemsListWrapper>
              {wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Number of Dependents'}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.numberOfDependents
                      ? partyData?.numberOfDependents
                      : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
              {wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">{'Parent'}</WDLabel>
                  <WDLabelClient className="label_responsive">
                    {partyData?.parent ? partyData?.parent : '--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
            </ItemsListWrapper>
            {wipId && (
              <>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Employment Status'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.employmentStatus
                        ? partyData?.employmentStatus
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Occupation'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.occupation ? partyData?.occupation : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Type of Business'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.typeOfBusiness
                        ? partyData?.typeOfBusiness
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Name of Business'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.employmentStatus
                        ? partyData?.employmentStatus
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Business Phone Number'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.businessPhone
                        ? partyData?.businessPhone
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Business Address'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.businessAddress
                        ? partyData?.businessAddress
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Type of Business'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.typeOfBusiness
                        ? partyData?.typeOfBusiness
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Name of Business'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.employmentStatus
                        ? partyData?.employmentStatus
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'City'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.city ? partyData?.city : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'State'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.stateName ? partyData?.stateName : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'Zip'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.zipCode ? partyData?.zipCode : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
              </>
            )}
            {!wipId && (
              <>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Employment Status'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.employmentStatus
                        ? partyData?.employmentStatus
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Occupation'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.occupation ? partyData?.occupation : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Type of Business'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.typeOfBusiness
                        ? partyData?.typeOfBusiness
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Name of Business'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.employmentStatus
                        ? partyData?.employmentStatus
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Business Phone Number'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.businessPhone
                        ? partyData?.businessPhone
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">
                      {'Business Address'}
                    </WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.businessAddress
                        ? partyData?.businessAddress
                        : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                </ItemsListWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'City'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.city ? partyData?.city : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'State'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.stateName ? partyData?.stateName : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper>
                    <WDLabel className="label_responsive">{'Zip'}</WDLabel>
                    <WDLabelClient className="label_responsive">
                      {partyData?.zipCode ? partyData?.zipCode : '--'}
                    </WDLabelClient>
                  </ItemListChildWrapper>
                  <ItemListChildWrapper></ItemListChildWrapper>
                </ItemsListWrapper>
              </>
            )}
          </PersonalInfoWrapper>
          <PersonalInfoWrapper>
            <ItemsListWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">{'Client Type'}</WDLabel>
                <WDLabelClient className="label_responsive">
                  {partyData?.typeOfBusiness ? partyData?.typeOfBusiness : '--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              <ItemListChildWrapper>
                <WDLabel className="label_responsive">
                  {'KYC Last Done Date'}
                </WDLabel>
                <WDLabelClient className="label_responsive">
                  {'--'}
                </WDLabelClient>
              </ItemListChildWrapper>
              {!wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Upcoming KYC Date'}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {'--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
              {!wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">{'KYC Date'}</WDLabel>
                  <WDLabelClient className="label_responsive">
                    {'--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
            </ItemsListWrapper>
            <ItemsListWrapper>
              {wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">
                    {'Upcoming KYC Date'}
                  </WDLabel>
                  <WDLabelClient className="label_responsive">
                    {'--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
              {wipId && (
                <ItemListChildWrapper>
                  <WDLabel className="label_responsive">{'KYC Date'}</WDLabel>
                  <WDLabelClient className="label_responsive">
                    {'--'}
                  </WDLabelClient>
                </ItemListChildWrapper>
              )}
            </ItemsListWrapper>
          </PersonalInfoWrapper>
          <div>
            <ApplicantInvestment />
          </div>
        </>
      )}
    </div>
  )
}

export default RenderApplicantInfo
