import { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'
import { BaseFontSize } from '../../../theme/Sizes'
const BodyStyles = css`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'SourceSansPro-Regular', 'Open Sans', sans-serif;
  }
  #root {
    margin: 0 auto;
  }
  @media only screen and (max-width: 575.98px) {
    :root {
      font-size: ${BaseFontSize.xsm};
    }
  }

  @media only screen and (max-width: 767.98px) {
    :root {
      font-size: ${BaseFontSize.sm};
    }
  }

  @media only screen and (max-width: 991.98px) {
    :root {
      font-size: ${BaseFontSize.md};
    }
  }

  @media only screen and (max-width: 1199.98px) {
    :root {
      font-size: ${BaseFontSize.lg};
    }
  }

  @media only screen and (max-width: 1399.98px) {
    :root {
      font-size: ${BaseFontSize.xl};
    }
  }

  @media only screen and (min-width: 1400px) {
    :root {
      font-size: ${BaseFontSize.xxl};
    }
  }
`

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${BodyStyles}
`
