import Lottie from 'lottie-react'
import { useContext, useEffect, useState } from 'react'
import Loader from '../../../assets/lottiefiles/loader.json'
import Button from '../../../components/button/Button'
import Divider from '../../../components/divider/Divider'
import Radio from '../../../components/extra/radio/Radio'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import { SvgEmailType } from '../../../components/svg/SvgHeaders'
import { Toast } from '../../../components/toast/Toast'
import { WDButton, WDButtonOutLine } from '../../../components/ui/WDButton'
import { WDLabel, WDLabelPrimaryText } from '../../../components/ui/WDLabel'
import { ElectronicPreferenceErrorMessage } from '../../../constant/Messages'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { RoleContext } from '../../../services/roleContext/RoleContext'
import ToastContext from '../../../services/toast/ToastContext'
import { toastType } from '../../../shared/config/enums'
import { COLORS } from '../../../theme/Colors'
import { LottieWrapper } from '../../interceptor/styles'
import { OptionProps } from '../../selectDropdown/ISelectDropDown'
import { DividerWrapper } from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import { SpinnerWrapper } from '../accountForms/formIndividual/tabs/ProductFeatures/style'
import { StyledDocumentPanel } from '../accountInformation/styles'
import { ToastWrapper } from '../formWip/styles'
import { WDLabelStyledCardHeader } from './../../../components/ui/WDLabel'
import {
  AppInfoWrapper,
  ContentWrapper,
  FundingButtonWrapper,
  ItemRowWrapper,
  ItemWrapper,
  LottieButtonWrapper,
  PersonalInfoWrapper,
  RadioWrapper,
  SpinnerButtonWrapper,
  TitleGrouping,
  WipHeadingWrapper,
} from './styles'
import { GetWipRequestInterface } from '../formWip/IFormWip'
import { getWipDetails } from '../../../services/formRequest/FormRequestAPI'

interface ElectronicPreferences {
  monthlyStatements: string
  tradeAndOpenOrderConfirmations: string
  shareholderProxyAndRelatedMaterial: string
  shareholderPostSaleProspectus: string
}
const ElectronicPreferences = (props: {
  pdfNodeRef2?: (pdfCanvasElement: HTMLCanvasElement) => HTMLCanvasElement
  isPdf?: boolean
}) => {
  const { accessList } = useContext(RoleContext)
  const {
    wipId,
    readOnly,
    newModelData,
    setNewModelData,
    electronicPrefs,
    setElectronicPrefs,
    autoSaveFunction,
    buttonTextEp,
    setButtonTextEp,
    setDetectChangeEp,
  } = useContext(ApplicationInfoContext)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [loader, setLoader] = useState(false)
  const { setToast } = useContext(ToastContext)

  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const [render, setRender] = useState<boolean>(true)

  const [radioOptions] = useState<OptionProps[]>([
    {
      label: 'Electronic Delivery',
      value: 'Electronic Delivery',
    },
    {
      label: 'Mail Delivery',
      value: 'Mail Delivery',
    },
  ])

  const getElectronicPreferenceData = () => {
    setElectronicPrefs({
      ...electronicPrefs,
      monthlyStatements:
        newModelData?.wipAccountDetailslist[0].statement ||
        newModelData?.wipAccountDetailslist[0].statement !== false
          ? 'Electronic Delivery'
          : 'Mail Delivery',
      tradeAndOpenOrderConfirmations:
        newModelData?.wipAccountDetailslist[0].tradeconfirm ||
        newModelData?.wipAccountDetailslist[0].tradeconfirm !== false
          ? 'Electronic Delivery'
          : 'Mail Delivery',
      shareholderPostSaleProspectus:
        newModelData?.wipAccountDetailslist[0].prospectelecdelivery ||
        newModelData?.wipAccountDetailslist[0].prospectelecdelivery !== false
          ? 'Electronic Delivery'
          : 'Mail Delivery',
      shareholderProxyAndRelatedMaterial:
        newModelData?.wipAccountDetailslist[0].proxyelecdelivery ||
        newModelData?.wipAccountDetailslist[0].proxyelecdelivery !== false
          ? 'Electronic Delivery'
          : 'Mail Delivery',
    })
  }

  useEffect(
    () => {
      if (
        // wipId &&
        // render &&
        newModelData.wipAccountDetailslist &&
        newModelData.wipAccountDetailslist.length > 0
      ) {
        // setRender((isTrigger) => {
        //   if (isTrigger) {
        //     getElectronicPreferenceData()
        //   }
        //   return false
        // })
        setElectronicPrefs({
          ...electronicPrefs,
          monthlyStatements:
            newModelData?.wipAccountDetailslist[0].statement ||
            newModelData?.wipAccountDetailslist[0].statement !== false
              ? 'Electronic Delivery'
              : 'Mail Delivery',
          tradeAndOpenOrderConfirmations:
            newModelData?.wipAccountDetailslist[0].tradeconfirm ||
            newModelData?.wipAccountDetailslist[0].tradeconfirm !== false
              ? 'Electronic Delivery'
              : 'Mail Delivery',
          shareholderPostSaleProspectus:
            newModelData?.wipAccountDetailslist[0].prospectelecdelivery ||
            newModelData?.wipAccountDetailslist[0].prospectelecdelivery !==
              false
              ? 'Electronic Delivery'
              : 'Mail Delivery',
          shareholderProxyAndRelatedMaterial:
            newModelData?.wipAccountDetailslist[0].proxyelecdelivery ||
            newModelData?.wipAccountDetailslist[0].proxyelecdelivery !== false
              ? 'Electronic Delivery'
              : 'Mail Delivery',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newModelData.wipAccountDetailslist]
  )

  const showMessage = (errorMessage: string, status: string) => {
    if (errorMessage != '') {
      setAPIPreviewStatus({
        status: status,
        text: errorMessage,
      })
    }
  }

  const popupConfirmation = () => {
    if (
      newModelData.wipAccountDetailslist &&
      newModelData.wipAccountDetailslist.length > 0
    ) {
      if (buttonTextEp === 'Save') {
        setLoader(true)
        autoSaveFunction().then((res) => {
          if (res && res === 200) {
            getElectronicPreferenceData()
            setButtonTextEp('Update')
            const payload: GetWipRequestInterface = {
              appId: '1',
              wipId: parseInt(wipId),
            }
            getWipDetails(payload)
              .then((getWipRecord) => {
                setNewModelData(getWipRecord.data)
                setToast('success', 'Electronic Preferences saved')

                setLoader(false)
              })
              .catch((error) => {
                console.log('error', error)
              })
          } else {
            showMessage(
              ElectronicPreferenceErrorMessage.failureaddmessage,
              toastType.DANGER
            )
            setLoader(false)
          }
        })
      } else {
        setLoader(true)
        autoSaveFunction().then((data: any) => {
          if (data == 200) {
            setToast('success', 'Electronic Preferences updated')
            setLoader(false)
            const payload: GetWipRequestInterface = {
              appId: '1',
              wipId: parseInt(wipId),
            }
            getWipDetails(payload)
              .then((getWipRecord) => {
                setNewModelData(getWipRecord.data)
              })
              .catch((error) => {
                console.log('error', error)
              })
          } else {
            showMessage(
              ElectronicPreferenceErrorMessage.failureupdatemessage,
              toastType.DANGER
            )
            setLoader(false)
          }
        })
      }
    }
  }
  return (
    // <Container ref={props.pdfNodeRef2}>
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel
        id={props.isPdf ? 'remove' : ''}
        ref={props.pdfNodeRef2}
      >
        <AppInfoWrapper>
          <WipHeadingWrapper
            onClick={() => {
              if (wipId) setToggleHeader(!toggleHeader)
            }}
          >
            <TitleGrouping>
              <SvgEmailType />
              <WDLabelStyledCardHeader className="header_responsive">
                {'Electronic Preferences'}
              </WDLabelStyledCardHeader>
            </TitleGrouping>
            {wipId && !toggleHeader && !props.isPdf && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {wipId && toggleHeader && !props.isPdf && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </WipHeadingWrapper>
          {toggleHeader && (
            <DividerWrapper id="product_feature">
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Background.Neutral}`}
                thickness={'0.1rem'}
              />
            </DividerWrapper>
          )}
          {toggleHeader && (
            <ContentWrapper>
              {loader ? (
                <SpinnerWrapper>
                  <LottieWrapper>
                    <Lottie animationData={Loader} loop={true} />
                  </LottieWrapper>
                </SpinnerWrapper>
              ) : (
                <PersonalInfoWrapper>
                  <ItemRowWrapper>
                    <ItemWrapper>
                      <WDLabel className="label_responsive">
                        {'Monthly Statements'}
                      </WDLabel>
                      {readOnly ? (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep1'}
                                title={radio.label}
                                key={radio.label}
                                name={'Monthly Statements*'}
                                value={electronicPrefs.monthlyStatements}
                                checked={
                                  radio.value ===
                                  electronicPrefs.monthlyStatements
                                }
                                onChange={() => {
                                  electronicPrefs.monthlyStatements =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                disabled={true}
                              />
                            )
                          })}
                        </RadioWrapper>
                      ) : (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep1'}
                                title={radio.label}
                                key={radio.label}
                                name={'Monthly Statements'}
                                value={electronicPrefs.monthlyStatements}
                                checked={
                                  radio.value ===
                                  electronicPrefs.monthlyStatements
                                }
                                onChange={() => {
                                  electronicPrefs.monthlyStatements =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                required
                              />
                            )
                          })}
                        </RadioWrapper>
                      )}
                    </ItemWrapper>
                    <ItemWrapper>
                      <WDLabel className="label_responsive">
                        {'Trade and Open Order Confirmations'}
                      </WDLabel>
                      {readOnly ? (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep2'}
                                title={radio.label}
                                key={radio.label}
                                name={'Trade and Open Order Confirmations'}
                                value={
                                  electronicPrefs.tradeAndOpenOrderConfirmations
                                }
                                checked={
                                  radio.value ===
                                  electronicPrefs.tradeAndOpenOrderConfirmations
                                }
                                onChange={() => {
                                  electronicPrefs.tradeAndOpenOrderConfirmations =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                disabled={true}
                              />
                            )
                          })}
                        </RadioWrapper>
                      ) : (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep2'}
                                title={radio.label}
                                key={radio.label}
                                name={'Trade and Open Order Confirmations'}
                                value={
                                  electronicPrefs.tradeAndOpenOrderConfirmations
                                }
                                checked={
                                  radio.value ===
                                  electronicPrefs.tradeAndOpenOrderConfirmations
                                }
                                onChange={() => {
                                  electronicPrefs.tradeAndOpenOrderConfirmations =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                required
                              />
                            )
                          })}
                        </RadioWrapper>
                      )}
                    </ItemWrapper>
                  </ItemRowWrapper>
                  <ItemRowWrapper>
                    <ItemWrapper>
                      <WDLabel className="label_responsive">
                        {'Shareholder Proxy and Related Material'}
                      </WDLabel>
                      {readOnly ? (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep3'}
                                title={radio.label}
                                key={radio.label}
                                name={'Shareholder Proxy and Related Material'}
                                value={
                                  electronicPrefs.shareholderProxyAndRelatedMaterial
                                }
                                checked={
                                  radio.value ===
                                  electronicPrefs.shareholderProxyAndRelatedMaterial
                                }
                                onChange={() => {
                                  electronicPrefs.shareholderProxyAndRelatedMaterial =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                disabled={true}
                              />
                            )
                          })}
                        </RadioWrapper>
                      ) : (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep3'}
                                title={radio.label}
                                key={radio.label}
                                name={'Shareholder Proxy and Related Material'}
                                value={
                                  electronicPrefs.shareholderProxyAndRelatedMaterial
                                }
                                checked={
                                  radio.value ===
                                  electronicPrefs.shareholderProxyAndRelatedMaterial
                                }
                                onChange={() => {
                                  electronicPrefs.shareholderProxyAndRelatedMaterial =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                required
                              />
                            )
                          })}
                        </RadioWrapper>
                      )}
                    </ItemWrapper>
                    <ItemWrapper>
                      <WDLabel className="label_responsive">
                        {'Shareholder Post-Sale Prospectus'}
                      </WDLabel>
                      {readOnly ? (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep4'}
                                title={radio.label}
                                key={radio.label}
                                name={'Shareholder Post-Sale Prospectus'}
                                value={
                                  electronicPrefs.shareholderPostSaleProspectus
                                }
                                checked={
                                  radio.value ===
                                  electronicPrefs.shareholderPostSaleProspectus
                                }
                                onChange={() => {
                                  electronicPrefs.shareholderPostSaleProspectus =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                disabled={true}
                              />
                            )
                          })}
                        </RadioWrapper>
                      ) : (
                        <RadioWrapper>
                          {radioOptions.map((radio) => {
                            return (
                              <Radio
                                checkBoxId={'ep4'}
                                title={radio.label}
                                key={radio.label}
                                name={'Shareholder Post-Sale Prospectus'}
                                value={
                                  electronicPrefs.shareholderPostSaleProspectus
                                }
                                checked={
                                  radio.value ===
                                  electronicPrefs.shareholderPostSaleProspectus
                                }
                                onChange={() => {
                                  electronicPrefs.shareholderPostSaleProspectus =
                                    radio.value
                                  setElectronicPrefs({ ...electronicPrefs })
                                  setDetectChangeEp(true)
                                }}
                                required
                              />
                            )
                          })}
                        </RadioWrapper>
                      )}
                    </ItemWrapper>
                  </ItemRowWrapper>
                  {apiPreviewStatus.status !== '' && (
                    <ToastWrapper>
                      <Toast
                        text={apiPreviewStatus.text}
                        type={apiPreviewStatus.status}
                        openStatusCallback={(status: boolean) =>
                          setAPIPreviewStatus({
                            status: '',
                            text: '',
                          })
                        }
                      />
                    </ToastWrapper>
                  )}
                </PersonalInfoWrapper>
              )}

              {/* <FundingButtonWrapper>
               
                {accessList.elecPreferences && !readOnly && (
                  <>
                    <WDButtonOutLine>
                      <Button
                        type={'button'}
                        onClick={() => {
                          popupConfirmation()
                        }}
                      >
                        {loader ? (
                          <SpinnerButtonWrapper>
                            <LottieButtonWrapper>
                              <Lottie animationData={Loader} loop={true} />
                            </LottieButtonWrapper>
                          </SpinnerButtonWrapper>
                        ) : (
                          <WDLabelPrimaryText className="label_responsive">
                            {'Save'}
                          </WDLabelPrimaryText>
                        )}
                      </Button>
                    </WDButtonOutLine>
                  </>
                )}
              </FundingButtonWrapper> */}
            </ContentWrapper>
          )}
        </AppInfoWrapper>
      </StyledDocumentPanel>
    </ResponsiveWrapper>
    // </Container>
  )
}

export default ElectronicPreferences
