import React, { useEffect, useState } from 'react'
import {
  Container,
  ContentWrapper,
  HeadingWrapper,
  StyledDocumentPanel,
} from './styles'
import WDSpacer from '../../components/ui/WDSpacer'
import SvgMessageSent from '../../assets/illustrations/SvgMessageSent'
import { WDLabelTextBrand2 } from '../../components/ui/WDLabel'
import { Divider } from '../AOPHomepage/styles'
import { COLORS } from '../../theme/Colors'
import { WDRoundedButton } from '../../components/ui/WDButton'
import Button from '../../components/button/Button'
import { useNavigate } from 'react-router-dom'
import { SpinnerWrapper } from '../forms/accountForms/formIndividual/tabs/ProductFeatures/style'
import { LottieWrapper } from '../interceptor/styles'
import Lottie from 'lottie-react'
import Loader from '../../../src/assets/lottiefiles/loader.json'

const SubmittedForm = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoader(false)
    }, 3000)
    return () => clearTimeout(timeOut)
  }, [])
  return (
    <Container>
      {loader ? (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      ) : (
        <StyledDocumentPanel>
          <HeadingWrapper>
            <WDSpacer bottom={'24px'}>
              <SvgMessageSent />
            </WDSpacer>
            <WDSpacer bottom={'16px'}>
              <WDLabelTextBrand2>
                {
                  'Your details have been submitted for approval with the client'
                }
              </WDLabelTextBrand2>
            </WDSpacer>
            <Divider
              thickness={'1px'}
              bgColor={`${COLORS.Background.Border}`}
              horizontal={true}
            />
            <ContentWrapper>
              <WDLabelTextBrand2>
                Application is submitted to client
              </WDLabelTextBrand2>
              <WDRoundedButton>
                <Button
                  onClick={() => {
                    navigate('/')
                  }}
                  type={'button'}
                >
                  {'Go to Dashboard'}
                </Button>
              </WDRoundedButton>
            </ContentWrapper>
            <Divider
              thickness={'1px'}
              bgColor={`${COLORS.Background.Border}`}
              horizontal={true}
            />
          </HeadingWrapper>
        </StyledDocumentPanel>
      )}
    </Container>
  )
}

export default SubmittedForm
