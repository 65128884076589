import { useContext, useEffect, useState } from 'react'
import XrefContext from '../../../store/XrefContext'
import {
  ConfigInterface,
  DependentInterface,
  DynamicFormResponseInterface,
  FormErrorInterface,
  ObjInterface,
  OptionsInterface,
  ValueJsonInterface,
} from '../../dynamicForm/IDynamicFormControls'
import { Textbox } from '../../../components/extra/textbox/Textbox'
import Checkbox from '../../../components/checkbox/Checkbox'
import Radio from '../../../components/extra/radio/Radio'
import { COLORS } from '../../../theme/Colors'
import {
  ExcludedAsstItemRow,
  ExcludedAsstItems,
  ExcludedAsstWrapper,
  OptionHalf,
  OptionHalfItem,
  OptionsRow,
  TextBoxWrapper,
} from '../accountForms/formIndividual/tabs/AccountFeature/styles'
import { WDButtonTransparent } from '../../../components/ui/WDButton'
import Button from '../../../components/button/Button'
import Image from '../../../components/image/Image'
import { Icons } from '../../../shared/GlobalStyle'
import { getXrefDatabyDomainCode } from '../../../shared/Comman'
import {
  ItemListChildWrapper,
  ItemParentWrapper,
  LabelTextWrapper,
  LabelWrap,
  LabelWrapSpaced,
  LeftColumnWrapper,
  PersonalInfoWrapperAF,
  PersonalInfoWrapperSecOne,
  QuestionGroup,
  RowWrapper,
  SingleCheckBoxWrapper,
} from './styles'
import {
  WDLabelClient,
  WDLabel,
  WDLabelError,
} from '../../../components/ui/WDLabel'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../selectDropdown/SelectDropdown'
import { RadioWrapper } from '../electronicPrefs/styles'
import { OptionProps } from '../../selectDropdown/ISelectDropDown'
import { ItemsListWrapper } from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import ReservedAccountNumberPage from '../getReservedAccountNumber/ReservedAccountNumber'
import CheckBoxNew from '../../../components/checkbox/CheckBoxNew'
import React from 'react'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'

const DynamicFormControl = (props: any) => {
  const {
    config,
    questionValueRes,
    questionValue,
    setQuestionValue,
    setFormError,
    formError,
  } = props

  const xrefStore = useContext(XrefContext)
  const { readOnly, individualPartyData, setDetectChangeProduct } = useContext(
    ApplicationInfoContext
  )
  const [sectionToggle, setSectionToggle] = useState<any>([])
  const [dependentQuestions, setDependentQuestions] =
    useState<DependentInterface>([])
  const [dropdownOptions, setDropdownOptions] = useState<OptionsInterface>({})
  const [checkboxOptions, setCheckboxOptions] = useState<OptionsInterface>({})
  const [radioOptions, setRadioOptions] = useState<OptionsInterface>({})
  const validate = (inputControl: DynamicFormResponseInterface, value: any) => {
    let isTextboxEmpty = !value
    if (inputControl?.subcontroltype?.toUpperCase() === 'CHECKBOX') {
      isTextboxEmpty = !value?.target.checked
    }
    // last checkbox is going to get unchecked
    let isMulticheckAllUnchecked = false
    if (
      questionValue[inputControl.sectionqsnid] &&
      typeof questionValue[inputControl.sectionqsnid] !== 'object'
    ) {
      isMulticheckAllUnchecked =
        questionValue[inputControl.sectionqsnid]?.split(',')?.length == 1 &&
        questionValue[inputControl.sectionqsnid]?.split(',')[0] === value
    }
    if (
      inputControl.isrequired.toUpperCase() === 'Y' &&
      (isTextboxEmpty ||
        isMulticheckAllUnchecked ||
        (inputControl.controltype.toUpperCase() === 'SELECT' && value === '0'))
    ) {
      setFormError((prevState: FormErrorInterface[]) => {
        return {
          ...prevState,
          [inputControl.sectionqsnid]: 'Required',
        }
      })
    } else {
      setFormError((prevState: FormErrorInterface[]) => {
        return {
          ...prevState,
          [inputControl.sectionqsnid]: '',
        }
      })
    }
  }
  const setData = (inputControl: DynamicFormResponseInterface, value: any) => {

    let updateOption = false;
    if (inputControl.controlerseqno === 5 && value?.label == `Margin and Cash Account`) {
      updateOption = true;

      const setOptionId = config[0].objQuestions.find(
        (item: DynamicFormResponseInterface) => item.controlerseqno === 6
      )
      const getNoneVal = dropdownOptions[setOptionId.sectionqsnid].find((obj) => obj.label === 'None')
      if (getNoneVal) {
        setQuestionValue((prevState: DynamicFormResponseInterface) => {
          return {
            ...prevState,
            [setOptionId.sectionqsnid]: {
              label: getNoneVal?.label,
              value: getNoneVal?.value,
              type: setOptionId.controlid,
            },
          }
        })
      }

    }

    setDetectChangeProduct(true)
    if (inputControl.controlerseqno === 9 && config.length > 0) {
      const getQsnId = config[0].objQuestions.findIndex(
        (item: DynamicFormResponseInterface) => item.controlerseqno === 9
      )
      if (getQsnId !== -1) {
        if (value?.label?.toLowerCase() === 'no') {
          setQuestionValue((prevState: DynamicFormResponseInterface) => {
            return {
              ...prevState,
              ['bank_list']: [],
            }
          })
        } else {
          props.fdicSweepBank()
        }
      }
    }

    if (inputControl.controlerseqno === 5 && config.length > 0) {
      const getQsnId = config[0].objQuestions.findIndex(
        (item: { controlerseqno: number }) => item.controlerseqno === 6
      )
      const setOptionId = config[0].objQuestions.find(
        (item: DynamicFormResponseInterface) => item.controlerseqno === 6
      )
      if (getQsnId !== -1 && setOptionId && !updateOption) {
        questionValue[setOptionId.sectionqsnid] = { label: 'Select', value: '' }
        setQuestionValue({ ...questionValue })
        const getDuplicate = Object.assign({}, config[0].objQuestions[getQsnId])
        if (value.label.toLowerCase() === 'cash account') {
          getDuplicate.domaincode = 'OPTTRADING-CASH'
        } else {
          getDuplicate.domaincode = 'OPTTRADING-MARGIN'
        }
        config[0].objQuestions[getQsnId] = getDuplicate
        props.setSectionConfig([...config])
      }
    }
    switch (inputControl.controltype) {
      case 'input': {
        switch (inputControl.subcontroltype) {
          case 'checkbox': {
            setQuestionValue((prevState: DynamicFormResponseInterface) => {
              return {
                ...prevState,
                [inputControl.sectionqsnid]: {
                  label: value?.target.checked.toString(),
                  value: '',
                  type: inputControl.controlid,
                },
              }
            })
            break
          }
          default: {
            setQuestionValue((prevState: DynamicFormResponseInterface) => {
              return {
                ...prevState,
                [inputControl.sectionqsnid]: {
                  label: value,
                  value: '',
                  type: inputControl.controlid,
                },
              }
            })
          }
        }
        break
      }
      case 'select': {
        setQuestionValue((prevState: DynamicFormResponseInterface) => {
          return {
            ...prevState,
            [inputControl.sectionqsnid]: {
              ...value,
              type: inputControl.controlid,
            },
          }
        })
        break
      }
      case 'multicheck': {
        switch (inputControl.subcontroltype) {
          case 'singleCheck': {
            setQuestionValue((prevState: DynamicFormResponseInterface) => ({
              ...prevState,
              [inputControl.sectionqsnid]:
                questionValue[inputControl.sectionqsnid]?.label === value
                  ? ''
                  : { label: value, value: '', type: inputControl.controlid },
            }))
            break
          }
          default: {
            const curVal = questionValue[inputControl.sectionqsnid] || null
            let curChecked = curVal?.split(',') || []
            // If clicked checkbox is already checked, So, remove this value from the checked array
            if (curChecked.includes(value)) {
              curChecked = curChecked.filter((val: string) => val != value)
            }
            // If clicked checkbox is not yet checked, So, push this value into the checked array
            else {
              curChecked.push(value)
            }

            setQuestionValue((prevState: DynamicFormResponseInterface) => ({
              ...prevState,
              [inputControl.sectionqsnid]: curChecked.join(','),
            }))
            break
          }
        }
      }
    }

    validate(inputControl, value)
    // createQuestionValueObj()
  }

  const getDropdownSelectedValue = (
    options: { value: string; label: string }[],
    value: { value: string }
  ) => {
    return options?.find((op: { value: string }) => op.value === value?.value)
  }

  const getMultiDropdownSelectedValue = (
    options: { value: string; label: string }[],
    value: { value: string }
  ) => {
    return options?.filter((op: { value: string }) => op.value === value?.value)
  }

  const handleMultiArrayChange = (
    sectionqsnid: number,
    index: number,
    val: string
  ) => {
    const prevValue = JSON.parse(questionValue[sectionqsnid] || '[]')

    const newQuestionValue = [...prevValue]
    newQuestionValue[index] = val
    setQuestionValue((prevState: DynamicFormResponseInterface) => {
      return {
        ...prevState,
        [sectionqsnid]: JSON.stringify(newQuestionValue),
      }
    })
  }
  const handleMultiArrayAdd = (sectionqsnid: number) => {
    const prevValue = JSON.parse(questionValue[sectionqsnid] || '[]')

    const newQuestionValue = [...prevValue]
    newQuestionValue.push('')
    setQuestionValue((prevState: DynamicFormResponseInterface) => {
      return {
        ...prevState,
        [sectionqsnid]: JSON.stringify(newQuestionValue),
      }
    })
  }
  const handleMultiArrayDelete = (sectionqsnid: number, index: number) => {
    const prevValue = JSON.parse(questionValue[sectionqsnid] || '[]')
    const newQuestionValue = [...prevValue]
    newQuestionValue.splice(index, 1)
    setQuestionValue((prevState: DynamicFormResponseInterface) => {
      return {
        ...prevState,
        [sectionqsnid]: JSON.stringify(newQuestionValue),
      }
    })
  }

  const getValue = (sectionItem: DynamicFormResponseInterface) => {
    if (
      sectionItem.controlid === 'accountName' ||
      sectionItem.controlid === 'shortName'
    ) {
      return (
        (individualPartyData?.firstName ? individualPartyData?.firstName : '') +
        ' ' +
        (individualPartyData?.middleName
          ? individualPartyData?.middleName
          : '') +
        ' ' +
        (individualPartyData?.lastName ? individualPartyData?.lastName : '')
      )
    } else if (
      sectionItem.controlid === 'irsControl' ||
      sectionItem.controlid === 'w9code'
    ) {
      return sectionItem.value
    } else return questionValue[sectionItem.sectionqsnid]?.label ?? ''
  }
  const findQuestionId = (controlId: number) => {
    const getQsnId = config[0].objQuestions.find(
      (item: { controlerseqno: number }) => item.controlerseqno === controlId
    )
    if (getQsnId) return getQsnId.sectionqsnid
    else return 0
  }
  const getControl = (sectionItem: DynamicFormResponseInterface) => {
    switch (sectionItem.controltype.toLocaleLowerCase()) {
      case 'input':
        switch (sectionItem.subcontroltype) {
          case 'number':
            return (
              <>
                <Textbox
                  type="number"
                  name={sectionItem.controlid}
                  required={sectionItem.isrequired === 'Y'}
                  defaultValue={sectionItem.value}
                  borderRadius={'0.5rem'}
                />
              </>
            )
          case 'email':
            return (
              <>
                <Textbox
                  type="email"
                  name={sectionItem.controlid}
                  required={sectionItem.isrequired === 'Y'}
                  defaultValue={sectionItem.value}
                  borderRadius={'0.5rem'}
                />
              </>
            )
          case 'tel':
            return (
              <>
                <Textbox
                  type="tel"
                  name={sectionItem.controlid}
                  required={sectionItem.isrequired === 'Y'}
                  defaultValue={sectionItem.value}
                  borderRadius={'0.5rem'}
                />
              </>
            )
          case 'checkbox':
            return (
              <>
                <Checkbox
                  checkBoxId={sectionItem.controltype}
                  disabled={props.isReview}
                  checked={
                    questionValue[sectionItem.sectionqsnid].label === 'true'
                      ? true
                      : false
                  }
                  onChange={(val: any) => setData(sectionItem, val)}
                  required={sectionItem.isrequired === 'y'}
                />
              </>
            )
          case 'radio':
            return (
              <RadioWrapper>
                {radioOptions[sectionItem.sectionqsnid].length > 0 &&
                  radioOptions[sectionItem.sectionqsnid].map(
                    (option: OptionProps) => {
                      return (
                        <Radio
                          checkBoxId={
                            sectionItem.controlid + '-' + option.value
                          }
                          title={option.label}
                          key={option.value}
                          name={sectionItem.controlid}
                          value={option.value}
                          defaultChecked={
                            option.value === sectionItem.defaultvalue
                          }
                          onChange={() => setData(sectionItem, option.value)}
                          required={sectionItem.isrequired === 'Y'}
                        />
                      )
                    }
                  )}
              </RadioWrapper>
            )
          default:
            if (
              sectionItem.controlid === 'accountName' ||
              sectionItem.controlid === 'shortName' ||
              sectionItem.controlid === 'irsControl' ||
              sectionItem.controlid === 'w9code'
            ) {
              return (
                <WDLabelClient className="label_responsive">
                  <Label>
                    {getValue(sectionItem) ? getValue(sectionItem) : '--'}
                  </Label>
                </WDLabelClient>
              )
            }
            if (sectionItem.sectionqsnid === findQuestionId(23)) {
              return (
                <Textbox
                  type="text"
                  name={sectionItem.controlid}
                  disabled={sectionItem.isDisabled}
                  onChange={(value) => {
                    if (
                      sectionItem.sectionqsnid === findQuestionId(23) &&
                      /^.{0,8}$/.test(value)
                    ) {
                      setData(sectionItem, value)
                    }
                  }}
                  disableBorder={COLORS.Border.Gray50}
                  required={sectionItem.isrequired.toLocaleUpperCase() === 'Y'}
                  value={getValue(sectionItem)}
                  borderRadius={'0.5rem'}
                />
              )
            } else {
              return (
                <Textbox
                  type="text"
                  name={sectionItem.controlid}
                  disabled={sectionItem.isDisabled}
                  onChange={(value) => setData(sectionItem, value)}
                  disableBorder={COLORS.Border.Gray50}
                  required={sectionItem.isrequired.toLocaleUpperCase() === 'Y'}
                  value={getValue(sectionItem)}
                  borderRadius={'0.5rem'}
                />
              )
            }
        }
      case 'select': {
        switch (sectionItem.subcontroltype) {
          case 'multiSelect': {
            // const getValue = getMultiDropdownSelectedValue(
            //   dropdownOptions[sectionItem.sectionqsnid],
            //   questionValue[sectionItem.sectionqsnid]
            // )
            // console.log('getValue', getValue)
            return (
              <SelectDropdown
                onMultipleChange={(value) => {
                  const setBankId = config[0].objQuestions.find(
                    (item: DynamicFormResponseInterface) =>
                      item.controlerseqno === 10
                  )
                  if (setBankId) {
                    setQuestionValue(
                      (prevState: DynamicFormResponseInterface) => {
                        return {
                          ...prevState,
                          [setBankId.sectionqsnid]: value,
                        }
                      }
                    )
                  }
                }}
                isDisabled={props.isReview}
                placeholder={`Select`}
                border={formError[sectionItem.sectionqsnid] === 'Required'}
                value={
                  questionValue[sectionItem.sectionqsnid]
                    ? questionValue[sectionItem.sectionqsnid]
                    : []
                }
                isMulti={true}
                options={dropdownOptions[sectionItem.sectionqsnid]} //{sectionItem.domaincode} // pass domain code in method will return xref data
                name={sectionItem.controlid}
                width={'100%'}
              />
            )
          }
          default: {
            let getValue = getDropdownSelectedValue(
              dropdownOptions[sectionItem.sectionqsnid],
              questionValue[sectionItem.sectionqsnid]
            )
            if (!getValue && config.length > 0) {
              const getQsnIndex = config[0].objQuestions.find(
                (obj: any) => obj.controlerseqno === 6
              )
              if (
                sectionItem.controlerseqno === 6 &&
                getQsnIndex &&
                questionValue[getQsnIndex.sectionqsnid]
              ) {
                getValue = { label: '', value: '' }
              }
            }
            return (
              <>
                <SelectDropdown
                  onChange={(value) => {
                    setData(sectionItem, value)
                  }}
                  isDisabled={props.isReview}
                  placeholder={`Select`}
                  border={formError[sectionItem.sectionqsnid] === 'Required'}
                  // border={flag}
                  // placeholder={
                  //   sectionItem.placeholder
                  //     ? sectionItem.placeholder
                  //     : `Select ${sectionItem.qsntext}`
                  // }
                  options={dropdownOptions[sectionItem.sectionqsnid]} //{sectionItem.domaincode} // pass domain code in method will return xref data
                  name={sectionItem.controlid}
                  value={getValue}
                  width={'100%'}
                />
              </>
            )
          }
        }
      }

      case 'multicheck':
        switch (sectionItem.subcontroltype) {
          case 'singleCheck': {
            return (
              <SingleCheckBoxWrapper>
                {checkboxOptions[sectionItem.sectionqsnid]?.length > 0 &&
                  checkboxOptions[sectionItem.sectionqsnid].map(
                    (option: OptionProps) => {
                      return (
                        <CheckBoxNew
                          checkBoxId={option.label}
                          title={option.label}
                          key={option.value}
                          value={option.value}
                          checked={
                            questionValue[sectionItem.sectionqsnid]?.label ===
                            option.label
                          }
                          onChange={() => setData(sectionItem, option.label)}
                        />
                      )
                    }
                  )}
              </SingleCheckBoxWrapper>
            )
          }
          default:
            return (
              <>
                {checkboxOptions[sectionItem.sectionqsnid].length > 0 &&
                  checkboxOptions[sectionItem.sectionqsnid].map(
                    (option: OptionProps) => {
                      return (
                        <CheckBoxNew
                          checkBoxId={option.label}
                          title={option.label}
                          key={option.value}
                          value={option.value}
                          onChange={() => setData(sectionItem, option.value)}
                        />
                      )
                    }
                  )}
              </>
            )
        }

      case 'multiarray':
        return (
          <>
            <OptionsRow>
              <OptionHalf>
                <OptionHalfItem>
                  <TextBoxWrapper>
                    <Textbox
                      onChange={(e) => {
                        handleMultiArrayChange(sectionItem.sectionqsnid, 0, e)
                      }}
                      value={
                        JSON.parse(
                          questionValue[sectionItem.sectionqsnid] || '[]'
                        )?.[0] || ''
                      }
                      placeholder={`Enter ${sectionItem.qsntext}`}
                    />
                  </TextBoxWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => {
                        handleMultiArrayAdd(sectionItem.sectionqsnid)
                      }}
                    >
                      <Image image={Icons.AddIcon} alt="Add" />
                    </Button>
                  </WDButtonTransparent>
                </OptionHalfItem>
              </OptionHalf>
            </OptionsRow>

            {questionValue[sectionItem.sectionqsnid] && (
              <OptionsRow>
                <ExcludedAsstWrapper>
                  {JSON.parse(questionValue[sectionItem.sectionqsnid]).map(
                    (val: string, index: number) => {
                      if (index == 0) {
                        return null
                      } else {
                        return (
                          <ExcludedAsstItems key={index}>
                            <ExcludedAsstItemRow>
                              <Textbox
                                disabled={props.isReview}
                                name={val}
                                onChange={(e) => {
                                  handleMultiArrayChange(
                                    sectionItem.sectionqsnid,
                                    index,
                                    e
                                  )
                                }}
                                value={
                                  JSON.parse(
                                    questionValue[sectionItem.sectionqsnid]
                                  )[index]
                                }
                                placeholder={`Enter ${sectionItem.qsntext}`}
                              />

                              <WDButtonTransparent>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    handleMultiArrayDelete(
                                      sectionItem.sectionqsnid,
                                      index
                                    )
                                  }}
                                >
                                  <Image image={Icons.Cancel} alt="Cancel" />
                                </Button>
                              </WDButtonTransparent>
                            </ExcludedAsstItemRow>
                          </ExcludedAsstItems>
                        )
                      }
                    }
                  )}
                </ExcludedAsstWrapper>
              </OptionsRow>
            )}
          </>
        )
    }
  }

  const getSectionToggleCreate = (result: ConfigInterface[]) => {
    const obj: ObjInterface = {}
    result.forEach((item) => {
      obj[item.sectionid] = true
    })
    setSectionToggle(obj)
  }

  const getDependentQuestions = (config: ConfigInterface[]) => {
    const dependents: DependentInterface = {}
    config.forEach((section) => {
      section.objQuestions.forEach((question) => {
        if (question.drivingqsnid) {
          dependents[question.drivingqsnid] =
            dependents[question.drivingqsnid] || []
          dependents[question.drivingqsnid].push(question)
        }
      })
    })
    setDependentQuestions(dependents)
  }

  const setInitialQuestionsValue = (
    questionValuesRes: DynamicFormResponseInterface[]
  ) => {
    const qnsValues: ValueJsonInterface = {}
    questionValuesRes.forEach((question) => {
      qnsValues[question.sectionqsnid] = question.value
    })

    setQuestionValue(qnsValues)
  }

  const bindAllXrefToState = (config: ConfigInterface[]) => {
    const dropdownOps: OptionsInterface = {}
    const checkboxOps: OptionsInterface = {}
    const radioOps: OptionsInterface = {}
    config.forEach((section: ConfigInterface) => {
      section.objQuestions.forEach((question) => {
        switch (question.controltype) {
          case 'select':
            {
              let getDomCode = question.domaincode
              if (question.controlerseqno === 6) {
                const getSelectedVal = questionValue
                const getQsnIndex = section.objQuestions.find(
                  (obj) => obj.controlerseqno === 5
                )
                if (
                  getQsnIndex &&
                  getSelectedVal[getQsnIndex.sectionqsnid] &&
                  getSelectedVal[getQsnIndex.sectionqsnid].label &&
                  getSelectedVal[getQsnIndex.sectionqsnid].value == '1'
                ) {
                  getDomCode = 'OPTTRADING-CASH'
                } else if (
                  getQsnIndex &&
                  getSelectedVal[getQsnIndex.sectionqsnid] &&
                  getSelectedVal[getQsnIndex.sectionqsnid].label &&
                  getSelectedVal[getQsnIndex.sectionqsnid].value == '2'
                ) {
                  getDomCode = 'OPTTRADING-MARGIN'
                }
              }
              const dropdownXrefData = getXrefDatabyDomainCode(
                xrefStore.xrefDetails,
                getDomCode
              )

              const dropdownOptions: OptionProps[] = []
              dropdownXrefData.map((feature) => {
                dropdownOptions.push({
                  label: feature.value,
                  value: feature.code,
                })
              })
              // const sortedOptions: OptionProps[] = [...dropdownOptions].sort(
              //   (a, b) => Number(a.value) - Number(b.value)
              // )
              dropdownOps[question.sectionqsnid] = dropdownOptions
            }
            break
          case 'multicheck':
            {
              const checkboxXrefData = getXrefDatabyDomainCode(
                xrefStore.xrefDetails,
                question.domaincode
              )

              const checkboxOptions: OptionProps[] = []
              checkboxXrefData.map((feature) => {
                checkboxOptions.push({
                  label: feature.value,
                  value: feature.code,
                })
              })
              checkboxOps[question.sectionqsnid] = checkboxOptions
            }
            break
          case 'input':
            {
              const radioXrefData = getXrefDatabyDomainCode(
                xrefStore.xrefDetails,
                question.domaincode
              )

              const radioOptions: OptionProps[] = []
              radioXrefData.map((feature) => {
                radioOptions.push({
                  label: feature.value,
                  value: feature.code,
                })
              })
              radioOps[question.sectionqsnid] = radioOptions
            }
            break
        }
      })
    })

    setDropdownOptions(dropdownOps)
    setCheckboxOptions(checkboxOps)
    setRadioOptions(radioOps)
  }

  useEffect(() => {
    getSectionToggleCreate(config)
    getDependentQuestions(config)
    config.forEach((section: DynamicFormResponseInterface) => {
      setFormError((prevState: FormErrorInterface[]) => {
        return {
          ...prevState,
          [section.sectionqsnid]: '',
        }
      })
    })
  }, [config, setFormError])

  useEffect(
    () => {
      if (questionValueRes !== null) {
        setInitialQuestionsValue(questionValueRes)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionValueRes]
  )

  useEffect(
    () => {
      bindAllXrefToState(config)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, xrefStore, questionValue]
  )

  const getReadOnlyValue = (sectionItem: DynamicFormResponseInterface) => {
    return getValue(sectionItem) !== '' &&
      getValue(sectionItem) !== null &&
      getValue(sectionItem) !== undefined
      ? getValue(sectionItem)
      : '--'
  }

  return (
    <RowWrapper>
      {config.map((section: ConfigInterface, index: number) => {
        return (
          <React.Fragment key={index}>
            <PersonalInfoWrapperSecOne id="left_pane">
              <ItemsListWrapper>
                <ItemListChildWrapper>
                  {sectionToggle[section.sectionid] && (
                    <QuestionGroup>
                      {section?.objQuestions?.map(
                        (
                          item: DynamicFormResponseInterface,
                          lIndex: number
                        ) => {
                          if (item.isEnabled) {
                            if (
                              (!item.drivingqsnid && lIndex < 2) ||
                              lIndex === 3 ||
                              item.controlerseqno === 19
                            ) {
                              return (
                                <LeftColumnWrapper
                                  key={item.sectionqsnid}
                                  id="qns"
                                >
                                  <LabelTextWrapper>
                                    <WDLabel className="label_responsive">
                                      <Label>{item.qsntext}</Label>
                                    </WDLabel>
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        {item.controltype === 'select' &&
                                          getReadOnlyValue(item)
                                          ? getReadOnlyValue(item) ?? '--'
                                          : getReadOnlyValue(item)}
                                      </WDLabelClient>
                                    ) : (
                                      getControl(item)
                                    )}
                                  </LabelTextWrapper>
                                </LeftColumnWrapper>
                              )
                            }
                          }
                        }
                      )}
                    </QuestionGroup>
                  )}
                </ItemListChildWrapper>
              </ItemsListWrapper>
            </PersonalInfoWrapperSecOne>
            <PersonalInfoWrapperAF>
              <ItemsListWrapper>
                <ItemListChildWrapper>
                  {sectionToggle[section.sectionid] && (
                    <>
                      {section?.objQuestions?.map(
                        (
                          item: DynamicFormResponseInterface,
                          lIndex: number
                        ) => {
                          if (item.isEnabled) {
                            if (!item.drivingqsnid && lIndex == 2) {
                              return (
                                <QuestionGroup key={item.sectionqsnid} id="qns">
                                  <LeftColumnWrapper>
                                    <LabelTextWrapper>
                                      <LabelWrap>
                                        <WDLabel className="label_responsive">
                                          <Label>{item.qsntext}</Label>
                                        </WDLabel>
                                        {!readOnly &&
                                          formError[item.sectionqsnid] ===
                                          'Required' && (
                                            <WDLabelError>
                                              {'Required'}
                                            </WDLabelError>
                                          )}
                                      </LabelWrap>
                                      {readOnly ? (
                                        <WDLabelClient className="label_responsive">
                                          {item.controltype === 'select' &&
                                            getReadOnlyValue(item)
                                            ? getReadOnlyValue(item) ?? '--'
                                            : getReadOnlyValue(item)}
                                        </WDLabelClient>
                                      ) : (
                                        getControl(item)
                                      )}
                                    </LabelTextWrapper>
                                  </LeftColumnWrapper>
                                </QuestionGroup>
                              )
                            }
                          }
                        }
                      )}
                    </>
                  )}
                </ItemListChildWrapper>
              </ItemsListWrapper>
              <ItemParentWrapper>
                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    {sectionToggle[section.sectionid] && (
                      <QuestionGroup>
                        {section?.objQuestions?.map(
                          (
                            item: DynamicFormResponseInterface,
                            lIndex: number
                          ) => {
                            if (item.isEnabled) {
                              if (
                                (!item.drivingqsnid &&
                                  item.controlerseqno === 5) ||
                                item.controlerseqno === 20 ||
                                item.controlerseqno === 7
                              ) {
                                return (
                                  <QuestionGroup
                                    key={item.sectionqsnid}
                                    id="qns"
                                  >
                                    <LeftColumnWrapper>
                                      <LabelTextWrapper>
                                        <LabelWrapSpaced>
                                          <WDLabel className="label_responsive">
                                            <Label>{item.qsntext}</Label>
                                          </WDLabel>
                                          {!readOnly &&
                                            formError[item.sectionqsnid] ===
                                            'Required' && (
                                              <WDLabelError>
                                                {'Required'}
                                              </WDLabelError>
                                            )}
                                        </LabelWrapSpaced>
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            {item.controltype === 'select' &&
                                              getReadOnlyValue(item)
                                              ? getReadOnlyValue(item) ?? '--'
                                              : getReadOnlyValue(item)}
                                          </WDLabelClient>
                                        ) : (
                                          getControl(item)
                                        )}
                                      </LabelTextWrapper>
                                    </LeftColumnWrapper>
                                  </QuestionGroup>
                                )
                              }
                            }
                          }
                        )}
                      </QuestionGroup>
                    )}
                    {sectionToggle[section.sectionid] && (
                      <>
                        {section?.objQuestions?.map(
                          (
                            item: DynamicFormResponseInterface,
                            lIndex: number
                          ) => {
                            if (item.isEnabled) {
                              if (item.controlerseqno === 9) {
                                return (
                                  <QuestionGroup
                                    key={item.sectionqsnid}
                                    id="qns"
                                  >
                                    {' '}
                                    <LeftColumnWrapper>
                                      <LabelTextWrapper
                                        id={
                                          item.isrequired !== 'y'
                                            ? 'optional'
                                            : ''
                                        }
                                      >
                                        <LabelWrapSpaced>
                                          <WDLabel className="label_responsive">
                                            <Label>{item.qsntext}</Label>
                                          </WDLabel>
                                          {!readOnly &&
                                            formError[item.sectionqsnid] ===
                                            'Required' && (
                                              <WDLabelError>
                                                {'Required'}
                                              </WDLabelError>
                                            )}
                                        </LabelWrapSpaced>
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            {item.controltype === 'select' &&
                                              getReadOnlyValue(item)
                                              ? getReadOnlyValue(item) ?? '--'
                                              : getReadOnlyValue(item)}
                                          </WDLabelClient>
                                        ) : (
                                          getControl(item)
                                        )}
                                      </LabelTextWrapper>
                                    </LeftColumnWrapper>
                                  </QuestionGroup>
                                )
                              }
                            }
                          }
                        )}
                      </>
                    )}
                    <ReservedAccountNumberPage
                      resNo={props.resNo}
                      setResNo={props.setResNo}
                      setQuestionValue={setQuestionValue}
                      config={config}
                      formError={formError}
                      setFormError={setFormError}
                    />
                  </ItemListChildWrapper>
                </ItemsListWrapper>

                <ItemsListWrapper>
                  <ItemListChildWrapper>
                    {sectionToggle[section.sectionid] && (
                      <>
                        {section?.objQuestions?.map(
                          (
                            item: DynamicFormResponseInterface,
                            lIndex: number
                          ) => {
                            if (item.isEnabled) {
                              if (
                                (!item.drivingqsnid &&
                                  item.controlerseqno === 6) ||
                                item.controlerseqno === 8 ||
                                item.controlerseqno === 10
                              ) {
                                if (
                                  item.controlerseqno === 10 &&
                                  questionValue[findQuestionId(9)] &&
                                  questionValue[findQuestionId(9)].label !==
                                  'Yes'
                                )
                                  return

                                return (
                                  <QuestionGroup
                                    key={item.sectionqsnid}
                                    id="qns"
                                  >
                                    {' '}
                                    <LeftColumnWrapper>
                                      <LabelTextWrapper
                                        id={
                                          item.isrequired !== 'y'
                                            ? 'optional'
                                            : ''
                                        }
                                      >
                                        <LabelWrapSpaced>
                                          <WDLabel className="label_responsive">
                                            <Label>{item.qsntext}</Label>
                                          </WDLabel>
                                          {!readOnly &&
                                            formError[item.sectionqsnid] ===
                                            'Required' && (
                                              <WDLabelError>
                                                {'Required'}
                                              </WDLabelError>
                                            )}
                                        </LabelWrapSpaced>
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            {item.controltype === 'select' &&
                                              getReadOnlyValue(item)
                                              ? getReadOnlyValue(item) ?? '--'
                                              : getReadOnlyValue(item)}
                                          </WDLabelClient>
                                        ) : (
                                          getControl(item)
                                        )}
                                      </LabelTextWrapper>
                                    </LeftColumnWrapper>
                                  </QuestionGroup>
                                )
                              }
                            }
                          }
                        )}
                      </>
                    )}
                    {sectionToggle[section.sectionid] &&
                      questionValue[findQuestionId(20)] &&
                      questionValue[findQuestionId(20)].label === 'Yes' && (
                        <>
                          {section?.objQuestions?.map(
                            (
                              item: DynamicFormResponseInterface,
                              lIndex: number
                            ) => {
                              if (item.isEnabled) {
                                if (
                                  questionValue[findQuestionId(20)] &&
                                  questionValue[findQuestionId(20)].label ===
                                  'Yes' &&
                                  item.controlerseqno === 23
                                ) {
                                  return (
                                    <QuestionGroup
                                      key={item.sectionqsnid}
                                      id="qns"
                                    >
                                      {' '}
                                      <LeftColumnWrapper>
                                        <LabelTextWrapper
                                          id={
                                            item.isrequired !== 'y'
                                              ? 'optional'
                                              : ''
                                          }
                                        >
                                          <LabelWrapSpaced>
                                            <WDLabel className="label_responsive">
                                              <Label>{item.qsntext}</Label>
                                            </WDLabel>
                                            {!readOnly &&
                                              formError[item.sectionqsnid] ===
                                              'Required' && (
                                                <WDLabelError>
                                                  {'Required'}
                                                </WDLabelError>
                                              )}
                                          </LabelWrapSpaced>
                                          {readOnly ? (
                                            <WDLabelClient className="label_responsive">
                                              {item.controltype === 'select' &&
                                                getReadOnlyValue(item)
                                                ? getReadOnlyValue(item) ?? '--'
                                                : getReadOnlyValue(item)}
                                            </WDLabelClient>
                                          ) : (
                                            getControl(item)
                                          )}
                                        </LabelTextWrapper>
                                      </LeftColumnWrapper>
                                    </QuestionGroup>
                                  )
                                }
                              }
                            }
                          )}
                        </>
                      )}
                    {sectionToggle[section.sectionid] && (
                      <>
                        {section?.objQuestions?.map(
                          (
                            item: DynamicFormResponseInterface,
                            lIndex: number
                          ) => {
                            if (item.isEnabled) {
                              if (
                                item.controlerseqno === 21 ||
                                item.controlerseqno === 24
                              ) {
                                return (
                                  <QuestionGroup
                                    key={item.sectionqsnid}
                                    id="qns"
                                  >
                                    {' '}
                                    <LeftColumnWrapper>
                                      <LabelTextWrapper
                                        id={
                                          item.isrequired !== 'y'
                                            ? 'optional'
                                            : ''
                                        }
                                      >
                                        <LabelWrapSpaced>
                                          <WDLabel className="label_responsive">
                                            <Label>{item.qsntext}</Label>
                                          </WDLabel>
                                          {!readOnly &&
                                            formError[item.sectionqsnid] ===
                                            'Required' && (
                                              <WDLabelError>
                                                {'Required'}
                                              </WDLabelError>
                                            )}
                                        </LabelWrapSpaced>
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            {item.controltype === 'select' &&
                                              getReadOnlyValue(item)
                                              ? getReadOnlyValue(item) ?? '--'
                                              : getReadOnlyValue(item)}
                                          </WDLabelClient>
                                        ) : (
                                          getControl(item)
                                        )}
                                      </LabelTextWrapper>
                                    </LeftColumnWrapper>
                                  </QuestionGroup>
                                )
                              }
                            }
                          }
                        )}
                      </>
                    )}
                  </ItemListChildWrapper>
                </ItemsListWrapper>
              </ItemParentWrapper>
            </PersonalInfoWrapperAF>
          </React.Fragment>
        )
      })}
    </RowWrapper>
  )
}

export default DynamicFormControl
