import {
  AccountSearchByNumberPayloadInterface,
  ClientAddressResponseInterface,
  ClientByIdPayloadInterface,
  ClientIdPayloadInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  EntityDataRequestInterface,
  EntityDataResponseInterface,
  IndividualClientResponseInterface,
  UpdateAppStatusRequestInterface,
  WIPAcctIdPayloadInterface,
  UpdateTrustedContactInterface,
  GetTrustedContactReqInterface,
  GetTrustedContactResponseInterface,
  UpdateCommentSection,
  AddDisclosureDashboard,
  UpdateAppStatusResponseInterface,
  IGetIndustryPayload,
  IGetDepoBankPayload,
} from './IClientAndAccountSearchContext'
import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import { AdvisorDataResponseInterface } from '../../composites/forms/formWip/IFormWip'
import { BeneficiaryPayloadInterface, deleteBeneficiaryPayloadInterface } from '../Retirement/IRetirementContext'

const host = ''
export const clientSearchByNameAPI = (
  payload: ClientSearchByNamePayloadInterface
) => {
  return axios.post<ClientSearchByNameResponseInterface[]>(
    host + ENDPOINTS.GET_CLIENT_LIST_BY_NAME,
    payload
  )
}

export const clientSearchByIdAPI = (payload: ClientByIdPayloadInterface) => {
  return axios.post<ClientSearchByNameResponseInterface>(
    host + ENDPOINTS.GET_CLIENT_BY_ID,
    payload
  )
}

export const accountSearchByNumberAPI = (
  payload: AccountSearchByNumberPayloadInterface
) => {
  return axios.post<[ClientSearchByNameResponseInterface]>(
    host + ENDPOINTS.GET_ACCOUNTS_BY_NUMBER,
    payload
  )
}

export const getClientDetailsDataAPI = (
  payload: ClientByIdPayloadInterface
) => {
  return axios.post<IndividualClientResponseInterface>(
    host + ENDPOINTS.GET_CLIENT_DETAILS_DATA,
    payload
  )
}
export const getTrustedContactDetails = (
  payload: GetTrustedContactReqInterface
) => {
  return axios.get<GetTrustedContactResponseInterface>(
    host +
      ENDPOINTS.GET_TRUSTED_CONTACT.replace('{wipID}', payload.wipid.toString())
  )
}

export const updateTrustedContactDetails = (
  payload: UpdateTrustedContactInterface
) => {
  return axios.post<UpdateTrustedContactInterface>(
    host + ENDPOINTS.UPDATE_TRUSTED_CONTACT,
    payload
  )
}

export const updateBeneficiaryDetails = (
  payload: BeneficiaryPayloadInterface
) => {
  return axios.post<BeneficiaryPayloadInterface>(
    host + ENDPOINTS.UPDATE_TRUSTED_CONTACT,
    payload
  )
}


export const deleteBeneficiaryDetails = (
  payload: deleteBeneficiaryPayloadInterface
) => {
  return axios.delete<BeneficiaryPayloadInterface>(
    host + ENDPOINTS.DELETE_BENEFICIARY_CONTACT,
    { data: payload }
  )
}

export const getClientAddressAPI = (payload: ClientIdPayloadInterface) => {
  return axios.post<ClientAddressResponseInterface[]>(
    host + ENDPOINTS.GET_CLIENT_ADDRESS,
    payload
  )
}

export const updateAppStatusAPI = (
  payload: UpdateAppStatusRequestInterface
) => {
  return axios.post<UpdateAppStatusResponseInterface>(
    host + ENDPOINTS.UPDATE_APP_STATUS,
    payload
  )
}
export const addDisclosureDashboardAPI = (payload: AddDisclosureDashboard) => {
  return axios.post(host + ENDPOINTS.ADD_DISCLOSURE_DASHBOARD, payload)
}
export const updateCommentAPI = (payload: UpdateCommentSection) => {
  return axios.post(host + ENDPOINTS.UPDATE_COMMENT_SECTION, payload)
}

export const getClientInvestmentAPI = (payload: ClientByIdPayloadInterface) => {
  return axios.post(host + ENDPOINTS.GET_PARTY_INVESTMENT, payload)
}

export const getContactInfoData = (payload: WIPAcctIdPayloadInterface) => {
  return axios.post(host + ENDPOINTS.GET_CONTACT_INFO, payload)
}

export const updateContactInfoData = (payload: any) => {
  return axios.post(host + ENDPOINTS.UPDATE_CONTACT_INFO, payload)
}

export const addContactInfoData = (payload: any) => {
  return axios.post(host + ENDPOINTS.ADD_CONTACT_INFO, payload)
}

export const getAccountInfoData = (wipid: number) => {
  return axios.get(host + ENDPOINTS.GET_ACCOUNT_FEATURE + wipid)
}

export const updateAccountInfoData = (payload: any) => {
  return axios.post(host + ENDPOINTS.UPDATE_NEW_ACCOUNT_FEATURE, payload)
}

export const addAccountInfoData = (payload: any) => {
  return axios.post(host + ENDPOINTS.ADD_ACCOUNT_FEATURE, payload)
}

export const getAdvisorMasterData = () => {
  return axios.get<AdvisorDataResponseInterface>(
    host + ENDPOINTS.GET_ADVISOR_LIST
  )
}

export const getEntityDataAPI = (payload: EntityDataRequestInterface) => {
  return axios.post<EntityDataResponseInterface>(
    host + ENDPOINTS.GET_ENTITY_DETAILS,
    payload
  )
}

export const getIndustryAffiliationAPI = (payload: IGetIndustryPayload) => {
  return axios.post(ENDPOINTS.GET_INDUSTRY, payload)
}
export const getDepositBankList = (payload: IGetDepoBankPayload) => {
  return axios.post(ENDPOINTS.DEPO_BANKLIST, payload)
}
