export const COLORS = {
  Text: {
    NeutralText: '#0F172A',
    NeutralTextWeak: '#475569',
    NeutralTextDisabled: ' #A7AFBC',
    PrimaryText: '#2563EB',
    White: '#FFFFFF',
    Black: '#000000',
    Brand2: '#1E3A8A',
    Brand3: '#00245D',
    Primary50: '#1F5EB7',
    Gray20: '#334155',
    Gray40: '#A7AFBC',
    Gray: '#475569',
    Gray50: ' #7B8698',
    Success: '#2e844a',
    Success2: '#22C373',
    Negative2: '#C72F24',
    Gray100: '#1E293B',
    Warning: '#FB923C',
    Danger: ' #BA0517',
    DisabledText: '#A7AFBC',
    Tertiary: '#ffffff',
    PrimaryTextStrong: '#1E3A8A',
    Neutral: '#0F172A',
    Neutral40: '#A7AFBC',
    Neutral70: '#334155',
    Neutral80: '#1E293B',
    NeutralInverted: '#FFFFFF',
    NeutralWeak: '#475569',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    PrimaryDark: '#00245D',
    Error: '#C72F24',
    Pending:'#C2410C',
    TextWeak: '#475569', //Name has to be provided by UX team
    GrayScaleBlack: '#181E27',
    TextHeader: '#5D5D5D',
    DocumentPending:'rgb(236, 215, 255)',
    Violet:'rgb(136, 21, 236)'
  },

  Background: {
    Primary: '#eff6ff',
    PrimaryActive: '#1E40AF',
    Primary5: '#bfdbfe',
    Neutral: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    White: '#FFFFFF',
    PrimaryBgStrongActive: '#1E40AF',
    Primary10: '#A4C5E8',
    PrimaryText: '#2563EB',
    Primary20: '#6699D2',
    Primary50: '#1F5EB7',
    Primary70: '#0847BE',
    Primary80: '#002698',
    Primary90: '#000833',
    OffWhite1: '#f0f2f5',
    OffWhite: '#e5e5e5',
    Brand3: '#00245D',
    Gray30: '#D0D7E2',
    Gray50: '#cbd5e1',
    Gray80: '#F8FAFC',
    Gray90: '#A7AFBC4D',
    Gray110: '#E2E8F0',
    Gray120: '#e3ebff',
    Gray130: '#e0e0de',
    OffWhite4: '#EBF7E6',
    OffWhite5: '#FEF1EE',
    OffWhite6: '#FFF7ED',
    OffWhite7: '#FEE7CB',
    Success3: '#22683E',
    Salmon: '#fa8072',
    OffGrey: '#F5F5F5',
    Danger: ' #BA0517',
    Transparent: 'transparent',
    Warning: '#FB923C',
    Success2: '#22C373',
    OffWhite8: '#DBEAFE',
    DisabledText: '#A7AFBC',
    OverlayBackground: 'rgba(71, 85, 105, 0.25)',
    PrimaryBorderStrong: '#60A5FA',
    NeutralBorder: '#bfdbfe',
    NeutralBackgroundMedium: '#F1F5F9',
    BackgroundActive: '#BFDBFE',
    NeutralStrong: '#E2E8F0',
    Black: '#000000',
    StrongBackground: '#E2E8F0',
    Border: '#CBD5E1',
    BorderHover: '#64748B',
    Pending:"#FFF7ED",
    Rejected:'#FE7765',
    PendingWithClient:'#CDEFC4',
  },
  UI: {
    Primary: '#192638',
    Text: '#0F172A',
    TextWeak: '#475569', //Name has to be provided by the UX team
    Tertiary: '#ffffff',
    Brand3: '#00245D',
    PrimaryText: '#2563EB',
    PrimaryTextStrong: '#1E3A8A',
    Primary10: '#A4C5E8',
    Primary20: '#6699D2',
    Primary50: '#1F5EB7',
    Primary60: '#3B82F6',
    Primary70: '#0847BE',
    Primary80: '#1E40AF',
    Primary90: '#000833',
    Primary100: '#CFF2FE',
    Primary800: '#05388E',
    White: '#FFFFFF',
    OffWhite: '#e5e5e5',
    MilkyWhite: '#FCFDFF',
    Gray05: '#FAFBFC',
    Gray10: '#F0F2F5',
    Gray20: '#334155',
    Gray30: '#D0D7E2',
    Gray40: '#A7AFBC',
    Gray: '#475569',
    Gray50: ' #7B8698',
    Gray60: '#596579',
    Gray80: '#192638',
    Gray120: '#808080',
    Gray130: '#666666',
    Gray140: '#6a6a6a',
    Elevation01: '#00245D',
    Success2: '#22C373',
    Negative2: '#C72F24',
    BlueishBlack: '#181E27',
    Black: '#000000',
    Brand1: '#00A9CE',
    Gray70: '#374253',
    Blue9: '#01155F',
    GrayscaleBlack: '#181E27',
    Brand2: '#326295',
    Gray90: '#0F1825',
    Gray100: '#1E293B',
    Blue1: '#00245D', //name to be provided by UX team
    LightWhite: '#EEF3F5', //,/name to be provided by UX team
    NeutralText: '#0F172A',
    NeutralDisabled: '#A7AFBC',
    Neutral50: '#64748B',
    FaintGreen: '#9dbfaf',
    Warning: '#FB923C',
    Danger: ' #BA0517',
    Quaternary: '#475569', //icon color
    BackgroundStrong: '#2563EB', //Name has to be provided by UX team
    DangerBackground: '#EA001E',
    Error: '#C72F24',
    Success: '#2E844A',
    NeutralBorderWeak: '#e2e8f0',
  },
  Border: {
    Neutral: '#CBD5E1',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryText: '#2563EB',
    Primary5: '#bfdbfe',
    Primary50: '#1F5EB7',
    Primary60: '#3B82F6',
    Primary70: '#0847BE',
    White: '#FFFFFF',
    Gray50: '#cbd5e1',
    Danger: '#C72F24',
    DangerStrong: '#EA001E',
    Accent: '#1F5EB7',
    Success2: '#22C373',
    SuccessStrong: '#41B658',
    Salmon1: '#FE7765',
    Orange: '#FB923C',
    Gray110: '#E2E8F0',
    Transparent: 'transparent',
    NeutralWeak: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    DropdownBorderBlue: '#2648ff',
  },
  BoxShadow: {
    Shadow1: 'rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 36, 93, 0.3)',
    Shadow3: 'rgba(0, 0, 0, 0.075)',
    Shadow4: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    BackgroundShadow: 'rgba(71, 85, 105, 0.25)',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#475569',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
  },
  Buttons: {
    GrayButton: '#CCCCCC',
    DangerButton: '#C54E4E',
  },
}
