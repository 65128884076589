import { useContext, useEffect, useState } from 'react'
import { ItemWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import BestInterest from './BestInterest/BestInterest'
import ContingentBeneficiary from './ContingentBeneficiary/ContingentBeneficiary'
import PersonalInformation from './PersonalInformation/PersonalInfo'
import PrimaryBeneficiary from './PrimaryBeneficiary/PrimaryBeneficiary'
import SourceOfContribution from './SourceOfContribution/SourceOfContribution'

interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
}
const Retirement = ({ stage, setStage }: FormWipProps) => {
  const [tabValue, setTabValue] = useState([] as any[])
  const { newModelData, wipId } = useContext(ApplicationInfoContext)

  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0].accountinfo?.length) {
      setTabValue(newModelData?.wipAccountDetailslist?.[0].accountinfo)
    }
  }, [newModelData?.wipAccountDetailslist?.[0]])
  return (
    <ItemWrapper
      sm={6}
      md={4}
      lg={3}
      id={'full_col'}
      // className={!wipId ? 'new_wip_padding' : 'enable_scroll'}
    >
      <div id="left_grp_next">
        {tabValue &&
          tabValue.map((item, index) => {
            if (wipId) {
              switch (item) {
                case 'Personal Information':
                  return <PersonalInformation key={index} />
                case 'Source of Initial Contribution':
                  return <SourceOfContribution key={index} />
                case 'Best of Interest':
                  return <BestInterest key={index} />
                case 'Primary Beneficiary':
                  return (
                    <PrimaryBeneficiary
                      key={'primary'}
                      type={'primary'}
                      header="Primary Beneficiary"
                    />
                  )
                case 'Contingent Beneficiary':
                  return (
                    <PrimaryBeneficiary
                      key={'contingent'}
                      type={'contingent'}
                      header="Contingent Beneficiary"
                    />
                  )
                default:
                  return <></>
              }
            }
          })}
      </div>
    </ItemWrapper>
  )
}

export default Retirement
