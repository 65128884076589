import styled, { css } from 'styled-components'
import { DividerProps } from './IDivider'

const toggleHeight = (height: DividerProps) => {
  if (height) {
    return height
  }
  return '100vh'
}
export const DividerLine = styled.div<DividerProps>`
  ${({ horizontal, thickness, bgColor, height }: DividerProps) =>
    horizontal
      ? css`
          width: 100%;
          height: ${thickness};
          background-color: ${bgColor};
          margin: ${height} 0;
        `
      : css`
          width: ${thickness};
          height: ${toggleHeight};
          background-color: ${bgColor};
          margin: 0rem;
          padding: 0rem;
        `}
  &#borderradius {
    border-radius: 0 0 0.25rem 0.25rem;
  }
`
