import { MainContainer, LoaderWrapper, TemplateContainer } from './styles'
import jsPDF from 'jspdf'
import { toCanvas } from 'html-to-image'
import {
  useEffect,
  useRef,
  MutableRefObject,
  useState,
  useContext,
} from 'react'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { LottieWrapper } from '../../interceptor/styles'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import '../../../pages/PdfTemplate/App.css'
import ToastContext from '../../../services/toast/ToastContext'
import { DisclosureContext } from '../../../services/disclosureContext/DisclosureContext'
interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
  downloadPdf?: boolean
  downloadCallback: (val: boolean) => void
  updateStatusCallback: () => void
  callUpdate: boolean
  contentData: any
}
const ReviewTemplate = ({
  downloadPdf,
  downloadCallback,
  updateStatusCallback,
  callUpdate,
  contentData,
}: FormWipProps) => {
  const pdfNodeRef = useRef([]) as MutableRefObject<HTMLCanvasElement[]>
  const [triggerDownload, setTriggerDownload] = useState(true)
  const [tableLoader, setTableLoader] = useState(false)
  const { wipId, wipFormData, setReadOnly, loader, getWipResponse ,newModelData } =
    useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const{templateData} = useContext(DisclosureContext)

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }
  useEffect(
    () => {
      setReadOnly(true)
      return () => {
        setReadOnly(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const pdfPullTimeStamp =
    new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()

  const signatureName =
  newModelData &&
    (newModelData?.wipAccountDetailslist?.[0]?.parties?.find(party=>party.relationshipTypeId === 1)?.partyname ??
''
    )

  const generateRequestPDF = async () => {
    setTableLoader(true)
    timeout(4000)
    try {
      const pdf = new jsPDF('portrait', 'pt', 'a4')
      for (
        let pdfNodeIndex = 0;
        pdfNodeIndex < pdfNodeRef.current.length;
        pdfNodeIndex++
      ) {
        const nodeRef = pdfNodeRef.current[pdfNodeIndex]
        const dataUrl = await toCanvas(nodeRef, {
          cacheBust: true,
        })
        if (pdfNodeIndex === 0) {
          await new Promise((resolve) => setTimeout(resolve, 1200))
        }
        if (dataUrl) {
          const imgProperties = pdf.getImageProperties(dataUrl)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width
          if (pdfNodeIndex !== 0) {
            pdf.addPage()
          }
          pdf.addImage(dataUrl, 'PNG', 0, 20, pdfWidth, pdfHeight)
          pdf.setFontSize(8)
          pdf.text(
            `${wipFormData.t_WIP.applicationnickname}`,
            10,
            pdf.internal.pageSize.height - 10
          )
          pdf.text(
            `${signatureName}\n\n${pdfPullTimeStamp}`,
            pdf.internal.pageSize.width - 120,
            pdf.internal.pageSize.height - 25
          )
        }
      }
      pdf.save(`${wipId}_${wipFormData.t_WIP.applicationnickname}.pdf`)
      downloadCallback(false)
      setTableLoader(false)
      if (callUpdate) updateStatusCallback()
    } catch (error) {
      downloadCallback(false)
      setToast('error', 'Not able to Download PDF, please Try later')
    }
  }

  useEffect(
    () => {
      if (
        downloadPdf &&
        pdfNodeRef.current.length > 0 &&
        triggerDownload &&
        !loader
      ) {
        setTriggerDownload((currentVal) => {
          if (currentVal) generateRequestPDF()
          return false
        })
      } else {
        downloadCallback(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadPdf]
  )
  const items = Array.from({ length: templateData.length }, (_, index) => index + 1)
  return (
    <TemplateContainer>
      {tableLoader && (
        <LoaderWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </LoaderWrapper>
      )}
      {downloadPdf && (
        // <>
        //   <MainContainer
        //     ref={(pdfCanvasElement: HTMLCanvasElement) =>
        //       (pdfNodeRef.current[0] = pdfCanvasElement)
        //     }
        //   >
        //     <div
        //       className="injectPdf"
        //       dangerouslySetInnerHTML={{ __html: contentData[0] }}
        //     ></div>
        //   </MainContainer>
        //   <MainContainer
        //     ref={(pdfCanvasElement: HTMLCanvasElement) =>
        //       (pdfNodeRef.current[1] = pdfCanvasElement)
        //     }
        //   >
        //     <div
        //       className="injectPdf"
        //       dangerouslySetInnerHTML={{ __html: contentData[1] }}
        //     ></div>
        //   </MainContainer>
        //   <MainContainer
        //     ref={(pdfCanvasElement: HTMLCanvasElement) =>
        //       (pdfNodeRef.current[2] = pdfCanvasElement)
        //     }
        //   >
        //     <div
        //       className="injectPdf"
        //       dangerouslySetInnerHTML={{ __html: contentData[2] }}
        //     ></div>
        //   </MainContainer>
        //   <MainContainer
        //     ref={(pdfCanvasElement: HTMLCanvasElement) =>
        //       (pdfNodeRef.current[3] = pdfCanvasElement)
        //     }
        //   >
        //     <div
        //       className="injectPdf"
        //       dangerouslySetInnerHTML={{ __html: contentData[3] }}
        //     ></div>
        //   </MainContainer>
        //   <MainContainer
        //     ref={(pdfCanvasElement: HTMLCanvasElement) =>
        //       (pdfNodeRef.current[4] = pdfCanvasElement)
        //     }
        //   >
        //     <div
        //       className="injectPdf"
        //       dangerouslySetInnerHTML={{ __html: contentData[4] }}
        //     ></div>
        //   </MainContainer>
        // </>
        <>
          {items.map((item, index) => {
            return (
              <MainContainer
                ref={(pdfCanvasElement: HTMLCanvasElement) =>
                  (pdfNodeRef.current[index] = pdfCanvasElement)
                }
                key={index}
              >
                <div
                  className="injectPdf"
                  dangerouslySetInnerHTML={{ __html: contentData[index] }}
                ></div>
              </MainContainer>
            )
          })}
        </>
      )}
    </TemplateContainer>
  )
}

export default ReviewTemplate
