import { createContext } from 'react'
import { GetContactResponseInterface } from '../../composites/forms/contactInfo/IContactInfo'
import { ContextProps } from '../../composites/forms/formWip/IFormWip'
import {
  clientSearchByIdAPI,
  clientSearchByNameAPI,
  getClientAddressAPI,
  getClientDetailsDataAPI,
  getContactInfoData,
  getEntityDataAPI,
} from './ClientAndAccountSearchAPI'
import {
  AccountDetailsInterface,
  ClientAddressResponseInterface,
  ClientByIdPayloadInterface,
  ClientIdPayloadInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  EntityDataRequestInterface,
  EntityDataResponseInterface,
  IndividualClientDetails,
  IndividualClientResponseInterface,
  PartyData,
  WIPAcctIdPayloadInterface,
} from './IClientAndAccountSearchContext'

interface SelectClientFn {
  (client: ClientSearchByNameResponseInterface | null): void
}

interface SelectAccountFn {
  (account: AccountDetailsInterface | null): void
}

interface ClientSearchFn {
  (payload: ClientSearchByNamePayloadInterface): Promise<void>
}

interface ClientSearchByIdFn {
  (payload: ClientByIdPayloadInterface): Promise<void>
}

interface GetIndividualPartyDataFn {
  (payload: ClientByIdPayloadInterface): Promise<void>
}

interface GetClientAddressDataFn {
  (payload: ClientIdPayloadInterface): Promise<
    ClientAddressResponseInterface[] | undefined
  >
}

interface GetContactInfoFn {
  (payload: WIPAcctIdPayloadInterface): Promise<
    GetContactResponseInterface | undefined
  >
}

interface GetEntityInfoFn {
  (payload: EntityDataRequestInterface): Promise<
    EntityDataResponseInterface | undefined
  >
}

export interface SearchContextInterface {
  clients: ClientSearchByNameResponseInterface[] | null
  accounts: AccountDetailsInterface[] | null
  client: ClientSearchByNameResponseInterface | null
  selectedAccount: AccountDetailsInterface | null
  setClients: (payload: ClientSearchByNameResponseInterface[]) => void
  setAccounts: (Details: AccountDetailsInterface[]) => void
  setApiError: (Details: Error) => void
  selectClient: SelectClientFn
  selectAccount: SelectAccountFn
  searchClients: ClientSearchFn
  searchClientById: ClientSearchByIdFn
  clientAccountApiError: object | string | null
  individualPartyData: IndividualClientResponseInterface
  setIndividualPartyData: (payload: IndividualClientResponseInterface) => void
  clientAddress: ClientAddressResponseInterface[] | null
  setClientAddress: (payload: ClientAddressResponseInterface[]) => void
  getIndividualPartyData: GetIndividualPartyDataFn
  getClientAddress: GetClientAddressDataFn
  contactInfoData: any
  setContactInfoData: (payload: any) => void
  getContactInfo: GetContactInfoFn
  entityData: EntityDataResponseInterface | null
  setEntityData: (payload: EntityDataResponseInterface) => void
  getEntityData: GetEntityInfoFn
}

const ClientStore: SearchContextInterface = {
  clients: null,
  accounts: null,
  client: null,
  selectedAccount: null,
  clientAccountApiError: null,
  individualPartyData: PartyData,
  setIndividualPartyData: (Details: IndividualClientResponseInterface) => {
    ClientStore.individualPartyData = Details
  },
  clientAddress: null,
  setClientAddress: (Details: ClientAddressResponseInterface[]) => {
    ClientStore.clientAddress = Details
  },
  contactInfoData: {} as GetContactResponseInterface,
  entityData: null,
  setEntityData: (Details: any) => {
    ClientStore.entityData = Details
  },
  setContactInfoData: (Details: GetContactResponseInterface) => {
    ClientStore.contactInfoData = Details
  },

  setClients: (Details: ClientSearchByNameResponseInterface[]) => {
    ClientStore.clients = Details
  },
  setAccounts: (Details) => {
    ClientStore.accounts = Details
  },
  selectClient: (Details) => {
    ClientStore.client = Details
  },
  selectAccount: (Details) => {
    ClientStore.selectedAccount = Details
  },
  setApiError: (Details) => {
    ClientStore.clientAccountApiError = Details
  },
  searchClients: async (payload: ClientSearchByNamePayloadInterface) => {
    try {
      const response = await clientSearchByNameAPI(payload)
      ClientStore.setClients(response.data)
    } catch (error) {
      if (error instanceof Error) {
        ClientStore.setApiError(error)
      }
    }
  },

  searchClientById: async (payload: ClientByIdPayloadInterface) => {
    try {
      const response = await clientSearchByIdAPI(payload)
      ClientStore.setClients([])
      ClientStore.selectClient(response.data)
    } catch (error) {
      if (error instanceof Error) {
        ClientStore.setApiError(error)
      }
    }
  },

  getIndividualPartyData: async (payload: ClientByIdPayloadInterface) => {
    try {
      const response = await getClientDetailsDataAPI(payload)
      ClientStore.setIndividualPartyData(response.data)
    } catch (error) {
      if (error instanceof Error) {
        ClientStore.setApiError(error)
        ClientStore.setIndividualPartyData(IndividualClientDetails)
      }
    }
  },

  getClientAddress: async (payload: ClientIdPayloadInterface) => {
    try {
      const response = await getClientAddressAPI(payload)
      ClientStore.setClientAddress(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        ClientStore.setApiError(error)
      }
    }
  },

  getContactInfo: async (payload: WIPAcctIdPayloadInterface) => {
    try {
      const response = await getContactInfoData(payload)
      ClientStore.setContactInfoData(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        ClientStore.setApiError(error)
      }
    }
  },

  getEntityData: async (payload: EntityDataRequestInterface) => {
    try {
      const response = await getEntityDataAPI(payload)
      ClientStore.setEntityData(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        ClientStore.setApiError(error)
      }
    }
  },
}
const ClientAndAccountSearchContext = createContext(ClientStore)

export const ClientAndAccountSearchContextProvider = (props: ContextProps) => {
  return (
    <ClientAndAccountSearchContext.Provider value={ClientStore}>
      {props.children}
    </ClientAndAccountSearchContext.Provider>
  )
}

export default ClientAndAccountSearchContext
