import styled from 'styled-components'

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  #react-doc-viewer #header-bar {
    display: none;
  }
`

export const Wrapper = styled.div`
width: 100%;
height:500px;
`