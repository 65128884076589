import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const WipHeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  z-index: 99;
  position: relative;
`
export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 80%;
`
export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  //gap: 0.5rem;
  //padding: 0.25rem;
  //overflow-y: auto;
`
export const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
gap:1rem`

export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0px;
`
export const TopDividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0;
`
export const PersonalInfoWrapper = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem 0;
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 0 1 49%;
`
export const ItemRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const RadioWrapper = styled.div`
  display: flex;
  gap: 24px;
`
export const FundingButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem;
  gap: 0.5rem;
  position: relative;
  z-index: 99;
  button {
    z-index: 99999;
    position: relative;
    padding: 0.5rem 1.5rem;
  }
`
export const SpinnerButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LottieButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
`
