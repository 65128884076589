import { ISvg } from './ISvg'

export const SvgCloseSmall = ({ fillColor = '#475569' }: ISvg) => {
    return (
        <svg
            width="14"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            viewBox="0 0 14 16">
            <path
                fillRule="evenodd"
                d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
                fill={fillColor}
            />
        </svg>
    )
}
