import ReviewInformation from '../reviewSection/ReviewInformation'
function ClientReviewInformation({
  saveRecall,
  resetSave,
  setIsUploadSend,
}: any) {
  return (
    <ReviewInformation
      isClientReview={true}
      saveRecall={saveRecall}
      resetSave={(val: boolean) => resetSave(val)}
      setIsUploadSend={(val: boolean) => {
        setIsUploadSend(val)
      }}
    />
  )
}

export default ClientReviewInformation
