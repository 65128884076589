import {
  ToastIcon,
  ToastText,
  Main,
  ToastWrapper,
  IconWrapper,
  Master,
  DividerWrapper,
} from './styles'
import Image from '../image/Image'
import { useEffect, useState } from 'react'
import { Icons } from '../../shared/GlobalStyle'
import { WDButtonTransparent } from '../ui/WDButton'
import Button from '../button/Button'
import { ToastProps } from './IToast'
import { COLORS } from '../../theme/Colors'
import { SvgCloseSmall } from '../svg/SvgCloseSmall'
import Divider from '../divider/Divider'

export const Toast = ({ text, type, openStatusCallback }: ToastProps) => {
  const handleAttributes = (type: string | undefined) => {
    switch (type) {
      case 'success':
        return {
          title: 'Success!',
          icon: Icons.Success,
          image: Icons.Cancel,
          colors: '#2E844A',
        }
      case 'error':
        return {
          title: 'Error!',
          icon: Icons.ToastError,
          image: Icons.Cancel,
          colors: '#e74c3c',
        }
      case 'warning':
        return {
          title: 'Warning!',
          icon: Icons.ToastWarning,
          image: Icons.Cancel,
          colors: '#f1c40f',
        }
      case 'info':
        return {
          title: 'Info!',
          icon: Icons.ToastInfo,
          image: Icons.Cancel,
          colors: '#3498db',
        }
      default:
        return {
          title: 'Default!',
          icon: Icons.Default,
          image: Icons.Cancel,
          colors: `${COLORS.Border.PrimaryBorderStrong}`,
        }
    }
  }

  const [isOpen, setIsOpen] = useState('')
  const [isBorderOpen, setIsBorderOpen] = useState(true)

  useEffect(
    () => {
      if (type) {
        setIsOpen('open')
        setIsBorderOpen(true)
        setTimeout(() => {
          setIsOpen('')
          openStatusCallback(false)
          setIsBorderOpen(false)
        }, 2900)
      }
    },
    [openStatusCallback, type]
  )

  const handleToggle = () => {
    setIsOpen('')
    openStatusCallback(false)
    setIsBorderOpen(false)
  }

  return (
    <Master>
      {isOpen && (
        <Main type={type}>
          <IconWrapper id="flexend">
            <WDButtonTransparent>
              <Button type={''} onClick={() => handleToggle()}>
                <SvgCloseSmall fillColor={'#475569'} />
              </Button>
            </WDButtonTransparent>
          </IconWrapper>
          <ToastWrapper>
            <IconWrapper id="gap">
              <ToastIcon>
                {handleAttributes(type).icon && (
                  <Image
                    image={handleAttributes(type).icon}
                    alt={'Toast Icon'}
                  />
                )}
              </ToastIcon>
              <ToastText text={text}>{text}</ToastText>
            </IconWrapper>
          </ToastWrapper>
        </Main>
      )}
      <DividerWrapper id={'border'} animateWidth={isBorderOpen}>
        <Divider
          horizontal={true}
          bgColor={handleAttributes(type).colors}
          thickness={'100%'}
          id={'borderradius'}
        />
      </DividerWrapper>
    </Master>
  )
}
