import React, { useContext, useState } from 'react'
import Button from '../../components/button/Button'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import SvgClose from '../../components/svg/SvgClose'
import { SvgSpinner } from '../../components/svg/SvgSpinner'
import SvgUpload from '../../components/svg/SvgUpload'
import { SvgYellowWarning } from '../../components/svg/SvgYellowWarning'
import {
  WDButton,
  WDButtonGrayTransparent,
  WDButtonNeutral,
  WDButtonPrimaryOutlined,
} from '../../components/ui/WDButton'
import { WDLabelBold } from '../../components/ui/WDLabel'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'
import { RoleContext } from '../../services/roleContext/RoleContext'
import ToastContext from '../../services/toast/ToastContext'
import { COLORS } from '../../theme/Colors'
import { PreviewFileProps } from './IPreviewFile'
import {
  ButtonWrappers,
  ContentWrapperPop,
  FileNameWrapper,
  MainWrapper,
  MasterWrapperModel,
  ModalContentWrapper,
  ModalWrappers,
  OverlayWrappers,
  UploadBtnWrapper,
  Wrapper,
} from './styles'

const PreviewFile = ({
  file,
  fileIndex,
  onDelete,
  setSelectedFiles,
  onDocumentIdChange,
  selectedDocumentIds,
  setSelectedDocumentIds,
  blankError,
  handleSubmit,
  spinner,
  applicantType,
  setApplicantType,
  uploadedFiles,
  documentDropdown,
  selectedApplicant,
  setSelectedApplicant,
  applicantOptions,
  disableCondition,
}: PreviewFileProps) => {
  const [showPopup, setShowPopup] = useState(false)
  const [localSelectedApplicant, setLocalSelectedApplicant] =
    useState<string>('1')
  const { getWipResponse, setUploadError, newModelData } = useContext(
    ApplicationInfoContext
  )
  const [fileToDelte, setFileToDelte] = useState<File | null>()
  const { accessList } = useContext(RoleContext)
  const { setToast } = useContext(ToastContext)
  const handleDeleteClick = (delFile: File) => {
    setUploadError('')
    setFileToDelte(delFile)
    setShowPopup(true)
  }
  const deleteDoc = () => {
    blankError()

    const index = selectedDocumentIds.findIndex(
      (docDetails) => docDetails.name == fileToDelte?.name
    )
    const indexApplicant = applicantType?.findIndex(
      (docDetails) => docDetails.name == fileToDelte?.name
    )
    if (indexApplicant && indexApplicant >= 0) {
      applicantType?.splice(index, 1)
      applicantType && setApplicantType?.([...applicantType])
    }
    if (index >= 0) {
      const updatedIds = [...selectedDocumentIds]
      updatedIds.splice(index, 1)
      setSelectedDocumentIds(updatedIds)
    }

    onDelete(fileToDelte)
    setSelectedFiles((prev) => prev.filter((file) => file !== fileToDelte))
  }

  const handleDropDownIdChange = (name: string, docId: string) => {
    blankError()
    const updatedDocumentIds = [...selectedDocumentIds]
    const index = selectedDocumentIds.findIndex(
      (docDetails) => docDetails.name == name
    )
    updatedDocumentIds[index].docid = docId
    setSelectedDocumentIds(updatedDocumentIds)
    onDocumentIdChange(updatedDocumentIds)
    // if (getWipResponse?.wipaccount[0]?.accttitle !== 'Joint') {
    //   updatedDocumentIds[index].iscoapplicant = false
    // }
  }

  const handleApplicantDetails = (name: string, value: string) => {
    blankError()
    const updatedDocumentIds = [...selectedDocumentIds]
    setLocalSelectedApplicant(value)
    setSelectedApplicant(value)
    const index = selectedDocumentIds.findIndex(
      (docDetails) => docDetails.name == name
    )
    updatedDocumentIds[index].iscoapplicant = value == '2' ? true : false
    setSelectedDocumentIds(updatedDocumentIds)
    onDocumentIdChange(updatedDocumentIds)
  }
  const validateDisable = (name: string) => {
    let flag = false
    // if (
    //   uploadedFiles.find((file) => file.iscoapplicant == false) &&
    //   uploadedFiles.find((file) => file.iscoapplicant == true)
    // ) {
    //   flag = getWipResponse?.wipDTO?.wipstatus === '8' ? false : true
    // } else if (uploadedFiles.find((file) => file.iscoapplicant == false)) {
    //   const index = selectedDocumentIds.findIndex(
    //     (docDetails) => docDetails.name == name
    //   )

    //   if (
    //     getWipResponse?.wipaccount[0]?.accttitle === 'Joint' &&
    //     !selectedDocumentIds[index].iscoapplicant
    //   ) {
    //     flag = true
    //   } else {
    //     flag = getWipResponse?.wipDTO?.wipstatus === '8' ? false : true
    //   }
    // } else if (uploadedFiles.find((file) => file.iscoapplicant == true)) {
    //   const index = selectedDocumentIds.findIndex(
    //     (docDetails) => docDetails.name == name
    //   )
    //   if (
    //     getWipResponse?.wipaccount[0]?.accttitle === 'Joint' &&
    //     selectedDocumentIds[index].iscoapplicant
    //   ) {
    //     flag = true
    //   } else {
    //     flag = getWipResponse?.wipDTO?.wipstatus === '8' ? false : true
    //   }
    // }

    const isFileTypeExist = uploadedFiles.find(
      (file) =>
        file.docid ===
          (selectedDocumentIds?.[0]?.docid &&
            parseInt(selectedDocumentIds[0]?.docid)) &&
        file.relationshipid === Number(selectedApplicant)
    )
    if (isFileTypeExist) {
      flag = true
      setUploadError('Document already uploaded')
    } else {
      setUploadError('')
    }
    return flag
  }
  
  return (
    <Wrapper>
      {showPopup && (
        <MasterWrapperModel>
          <OverlayWrappers>
            <ModalWrappers>
              <ModalContentWrapper>
                <SvgYellowWarning fillColor={'#F97316'} />
                <ContentWrapperPop>
                  <WDLabelBold>{`The selected document will be deleted, click Delete to proceed.`}</WDLabelBold>
                  <ButtonWrappers>
                    <WDButton>
                      <Button
                        type={'button'}
                        onClick={() => {
                          setShowPopup(false)
                          deleteDoc()
                        }}
                      >
                        Delete
                      </Button>
                    </WDButton>
                    <WDButtonNeutral>
                      <Button
                        type={'button'}
                        onClick={() => {
                          setShowPopup(false)
                          setFileToDelte(null)
                        }}
                      >
                        {'Cancel'}
                      </Button>
                    </WDButtonNeutral>
                  </ButtonWrappers>
                </ContentWrapperPop>
              </ModalContentWrapper>
            </ModalWrappers>
          </OverlayWrappers>
        </MasterWrapperModel>
      )}

      <MainWrapper>
        <FileNameWrapper>
          {/* <DocNameWrapper>
                        <DocWrapper id="upload">
                          <HintWrapper id="preview_name"> */}
          {file.name}
          {/* </HintWrapper> */}
        </FileNameWrapper>
        {newModelData?.wipAccountDetailslist?.[0]?.parties?.length > 1 && (
          // <PreviewDropWrapper>
          <SelectDropdown
            width="9rem"
            placeholder={'Applicant Type'}
            options={applicantOptions}
            onChange={(e: string) => {
              handleApplicantDetails(file.name, e)
            }}
            value={{
              label:
                applicantOptions.find(
                  (obj) => obj.value === localSelectedApplicant
                )?.label ?? '',
              value:
                applicantOptions.find(
                  (obj) => obj.value === localSelectedApplicant
                )?.value ?? '',
            }}
            borderRadius={'8px'}
            borderColor={COLORS.Border.Neutral}
            indicatorColor={COLORS.Icons.Neutral}
            placeholderColor={COLORS.Text.Neutral}
            height={'2rem'}
          />
          // </PreviewDropWrapper>
        )}
        {/* <PreviewDropWrapper> */}
        <SelectDropdown
          width="12rem"
          placeholder={'Select Document Type'}
          options={documentDropdown}
          onChange={(e: string) => {
            if (e) {
              handleDropDownIdChange(file.name, e)
            }
          }}
          value={
            documentDropdown.length > 0
              ? documentDropdown.filter(
                  (dropdownval: { value: string }) =>
                    dropdownval.value ==
                    selectedDocumentIds.filter(
                      (fileids) => fileids.name == file.name
                    )[0].docid
                )[0] ?? { value: '', label: '' }
              : { value: '', label: '' }
          }
          borderRadius={'8px'}
          borderColor={COLORS.Border.Neutral}
          indicatorColor={COLORS.Icons.Neutral}
          placeholderColor={COLORS.Text.Neutral}
          height={'2rem'}
        />
        {accessList.uploadDocuments ? (
          !spinner ? (
            <WDButtonPrimaryOutlined>
              <Button
                type="button"
                onClick={() => {
                  if (getWipResponse?.wipaccount?.[0]?.accttitle == 'Joint') {
                    const index = selectedDocumentIds.findIndex(
                      (docDetails) => docDetails.name == file.name
                    )
                    if (
                      selectedDocumentIds[index].iscoapplicant !== undefined
                    ) {
                      handleSubmit(index)
                    } else {
                      setToast('error', 'Please select applicant type!')
                    }
                  } else {
                    handleSubmit(fileIndex)
                  }
                }}
                disabled={validateDisable(file.name) || disableCondition}
                suffixedIcon={<SvgUpload fillColor={COLORS.Text.Primary} />}
              >
                {'Upload'}
              </Button>
            </WDButtonPrimaryOutlined>
          ) : (
            <>
              <UploadBtnWrapper>
                <SvgSpinner />
              </UploadBtnWrapper>
            </>
          )
        ) : null}
        <WDButtonGrayTransparent>
          <Button type={'button'} onClick={() => handleDeleteClick(file)}>
            <SvgClose fillColor={COLORS.UI.Gray140} />
          </Button>
        </WDButtonGrayTransparent>
      </MainWrapper>
    </Wrapper>
  )
}

export default PreviewFile
