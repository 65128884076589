import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
export const DisclosureHtmlWrapper = styled.div`
  padding: 12px;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => COLORS.UI.NeutralBorderWeak};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => COLORS.Background.DisabledText};
    border-radius: 0.25rem;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  padding: 4px 0px;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`
export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`
export const Wrapper = styled.div`
  display: flex;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  height: 87vh;
  padding: 1.5rem;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`
export const StyledDocumentPanel = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.Background.White};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border: 1px solid ${COLORS.Background.White};
  width: 95%;
  padding: 0.5rem;
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  } */
  /* @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const TableHistoryWrapper = styled.div`
  padding: 8px;
  table tbody tr td {
    width: 25%;
    :nth-child(2) {
      width: 20%;
    }
  }
  &.table_loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const WipHeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`
export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 80%;
`
export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 0.5rem;
  //padding: 0.25rem;
  //overflow-y: auto;
`

export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0px;
`
export const TopDividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0 16px 0;
`
export const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
`
export const ItemWrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`
export const RadioWrapper = styled.div`
  display: flex;
  gap: 24px;
`
export const FundingButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
`
export const FormControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
`
export const ApprovalButtons = styled.div`
  display: flex;
  gap: 1rem;
`
