import { StyledDiv } from './styles'

export const SvgSpinner = () => {
  return (
    <StyledDiv>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="spinner"
      >
        <g id=".main / MSpinner">
          <path
            id="Ellipse 3"
            d="M8.00016 1.33332C11.6821 1.33332 14.6668 4.31809 14.6668 7.99999C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 7.99999C1.3335 4.31809 4.31826 1.33332 8.00016 1.33332ZM8.00016 14.6C11.6452 14.6 14.6002 11.6451 14.6002 7.99999C14.6002 4.35491 11.6452 1.39999 8.00016 1.39999C4.35508 1.39999 1.40016 4.35491 1.40016 7.99999C1.40016 11.6451 4.35508 14.6 8.00016 14.6Z"
            stroke="#CBD5E1"
            strokeWidth="2"
            strokeLinejoin="round"
          />

          <path
            id="Ellipse 2"
            d="M8.00016 1.33332C9.22964 1.33332 10.4352 1.67332 11.4835 2.31572C12.5318 2.95812 13.382 3.87791 13.9402 4.97339C14.4984 6.06886 14.7427 7.29736 14.6463 8.52305C14.5498 9.74874 14.1163 10.9239 13.3936 11.9186L13.3397 11.8794C14.0551 10.8946 14.4843 9.73125 14.5798 8.51782C14.6753 7.30438 14.4334 6.08817 13.8808 5.00365C13.3282 3.91913 12.4865 3.00854 11.4487 2.37256C10.4108 1.73659 9.21735 1.39999 8.00016 1.39999L8.00016 1.33332Z"
            stroke="#2563EB"
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </StyledDiv>
  )
}
