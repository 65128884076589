import React, { useContext, useState } from 'react'
import Button from '../../components/button/Button'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import SvgClose from '../../components/svg/SvgClose'
import { SvgSpinner } from '../../components/svg/SvgSpinner'
import SvgUpload from '../../components/svg/SvgUpload'
import {
  WDButtonGrayTransparent,
  WDButtonPrimaryOutlined,
} from '../../components/ui/WDButton'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'
import ToastContext from '../../services/toast/ToastContext'
import { COLORS } from '../../theme/Colors'
import { PartyDetails } from '../forms/formWip/IFormWip'
import { PreviewDisclosureProps } from './IPreviewFile'
import {
  FileNameWrapper,
  MainWrapper,
  SelectButtonWrapper,
  UploadBtnWrapper,
  Wrapper,
} from './styles'

const PreviewDisclosure = ({
  disclosureIndex,
  disclosure,
  disclosures,
  documentDropdown,
  spinner,
  blankError,
  acknowledgeDisclosure,
  selectedDisclosureIds,
  setSelectedDisclosureIds,
  onDisclosureIdChange,
  setDisclosures,
  selectedDocumentIds,
  setSelectedDocumentIds,

  disableCondition,
}: PreviewDisclosureProps) => {
  const [showPopup, setShowPopup] = useState(false)

  const [applicantType, setApplicantType] = useState<string>('')
  const { setToast } = useContext(ToastContext)
  const [selectedId, setSelectedId] = useState<string>('')
  const { newModelData, selectedClient, setUploadError } = useContext(
    ApplicationInfoContext
  )

  const handleApplicantDetails = (name: string, value: string) => {
    blankError()
    const updatedDiscIds = [...selectedDisclosureIds]
    const index = selectedDisclosureIds.findIndex(
      (discDetails) => discDetails.name == name
    )

    updatedDiscIds[index].partyid =
      value == '2'
        ? newModelData.wipAccountDetailslist[0].parties.filter(
            (party: PartyDetails) => party.relationshipTypeId == 2
          )[0].partyId
        : selectedClient?.clientId
    setSelectedDisclosureIds(updatedDiscIds)
    onDisclosureIdChange(updatedDiscIds)
    setApplicantType(value)
  }

  const handleDropDownIdChange = (name: string, docId: string) => {
    blankError()
    const updatedDiscIds = [...selectedDisclosureIds]
    const updatedDocumentIds = [...selectedDocumentIds]
    const discIndex = selectedDisclosureIds.findIndex(
      (discDetails) => discDetails.name == name
    )
    const docIndex = selectedDocumentIds.findIndex(
      (docDetails) => docDetails.name == name
    )
    updatedDocumentIds[docIndex].docid = docId
    updatedDocumentIds[docIndex].iscoapplicant =
      applicantType == '2' ? true : false

    updatedDiscIds[discIndex].discId = docId
    updatedDiscIds[discIndex].docid = docId
    updatedDiscIds[discIndex].partyid =
      applicantType == '2'
        ? newModelData.wipAccountDetailslist[0].parties.filter(
            (party: PartyDetails) => party.relationshipTypeId == 2
          )[0].partyId
        : selectedClient?.clientId
    updatedDocumentIds[docIndex].partyid =
      applicantType == '2'
        ? newModelData.wipAccountDetailslist[0].parties.filter(
            (party: PartyDetails) => party.relationshipTypeId == 2
          )[0].partyId
        : selectedClient?.clientId
    setSelectedDisclosureIds(updatedDiscIds)
    setSelectedDocumentIds(updatedDocumentIds)
    onDisclosureIdChange(updatedDiscIds)
  }

  const removeFile = (disclosure: File) => {
    blankError()
    const newFiles = disclosures.filter((file) => file !== disclosure)
    const index = selectedDisclosureIds.findIndex(
      (docDetails) => docDetails.name == disclosure?.name
    )
    if (index >= 0) {
      const updatedIds = [...selectedDisclosureIds]
      updatedIds.splice(index, 1)
      setSelectedDisclosureIds(updatedIds)
    }
    setDisclosures(newFiles)
    setShowPopup(true)
  }

  // const validateDisable = () => {
  //   let flag = false
  //   const isFileTypeExist = uploadedFiles.find(
  //     (file) =>
  //       file.docid ===
  //         (selectedDocumentIds?.[0]?.docid &&
  //           parseInt(selectedDocumentIds[0]?.docid)) &&
  //       file.relationshipid === Number(selectedApplicant)
  //   )
  //   if (isFileTypeExist) {
  //     flag = true
  //     setUploadError('Disclosure already uploaded')
  //   } else {
  //     setUploadError('')
  //   }
  //   return flag
  // }

  return (
    <Wrapper>
      {/* {showPopup && (
        <MasterWrapperModel>
          <OverlayWrappers>
            <ModalWrappers>
              <ModalContentWrapper>
                <SvgYellowWarning fillColor={'#F97316'} />
                <ContentWrapperPop>
                  <WDLabelBold>{`The selected document will be removed, click Delete to proceed.`}</WDLabelBold>
                  <ButtonWrappers>
                    <WDButton>
                      <Button
                        type={'button'}
                        onClick={() => {
                          setShowPopup(false)
                        }}
                      >
                        {'Remove'}
                      </Button>
                    </WDButton>
                    <WDButtonDisabled>
                      <Button
                        type={'button'}
                        onClick={() => {
                          setShowPopup(false)
                          setFileToDelte(null)
                        }}
                      >
                        {'Cancel'}
                      </Button>
                    </WDButtonDisabled>
                  </ButtonWrappers>
                </ContentWrapperPop>
              </ModalContentWrapper>
            </ModalWrappers>
          </OverlayWrappers>
        </MasterWrapperModel>
      )}
      {disclosures.length > 0 && (
        <>
          {disclosures &&
            disclosures.map((disclosure, index) => {
              return ( */}
      <React.Fragment>
        <MainWrapper>
          <FileNameWrapper title={disclosure.name}>
            {disclosure.name}
          </FileNameWrapper>

          <SelectButtonWrapper>
            <SelectDropdown
              placeholder={'Select Document Type'}
              options={documentDropdown}
              borderRadius={'8px'}
              borderColor={COLORS.Border.Neutral}
              indicatorColor={COLORS.Icons.Neutral}
              placeholderColor={COLORS.Text.Neutral}
              height={'2rem'}
              onChange={(e: string) => {
                if (e) {
                  handleDropDownIdChange(disclosure.name, e)
                }
              }}
              value={
                documentDropdown.length > 0
                  ? documentDropdown.filter(
                      (dropdownval: { value: string }) =>
                        dropdownval.value ==
                        selectedDocumentIds.filter(
                          (fileids) => fileids.name == disclosure.name
                        )[0].docid
                    )[0] ?? { value: '', label: '' }
                  : { value: '', label: '' }
              }
              width="12rem"
            />
            {!spinner ? (
              <WDButtonPrimaryOutlined>
                <Button
                  type="button"
                  onClick={() => {
                    const docIndex = selectedDocumentIds.findIndex(
                      (docDetails) => docDetails.name == disclosure.name
                    )
                    setSelectedId(disclosureIndex.toString())
                    if (
                      newModelData.wipAccountDetailslist[0].accttitle == 'Joint'
                    ) {
                      if (
                        selectedDocumentIds[docIndex].iscoapplicant !==
                        undefined
                      ) {
                        acknowledgeDisclosure(disclosure)
                      } else {
                        setToast('error', 'Please select applicant type!')
                      }
                    } else {
                      acknowledgeDisclosure(disclosure)
                    }
                  }}
                  disabled={disableCondition()}
                  suffixedIcon={<SvgUpload fillColor={COLORS.Text.Primary} />}
                >
                  {'Upload'}
                </Button>
              </WDButtonPrimaryOutlined>
            ) : (
              spinner &&
              disclosureIndex.toString() == selectedId && (
                <UploadBtnWrapper>
                  <SvgSpinner />
                </UploadBtnWrapper>
              )
            )}

            <WDButtonGrayTransparent>
              <Button type={'button'} onClick={() => removeFile(disclosure)}>
                <SvgClose fillColor={COLORS.UI.Gray140} />
              </Button>
            </WDButtonGrayTransparent>
          </SelectButtonWrapper>
        </MainWrapper>
      </React.Fragment>
      {/* )
            })}
        </>
      )}*/}
    </Wrapper>
  )
}

export default PreviewDisclosure
