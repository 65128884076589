import IOBrowser from '@interopio/browser'
import IODesktop from '@interopio/desktop'
import {
  IOConnectInitSettings,
  IOConnectProvider,
} from '@interopio/react-hooks'
import IOWorkspaces from '@interopio/workspaces-api'
//import Loader from '../components/loader/Loader'

interface Props {
  children: React.ReactNode
}

export const InteropContextProvider = ({ children }: Props) => {
  const settings: IOConnectInitSettings = {
    desktop: {
      factory: IODesktop,
      config: {
        channels: true,
        appManager: 'full',
        layouts: 'full',
        libraries: [IOWorkspaces],
      },
    },
    browser: {
      factory: IOBrowser,
      config: {
        libraries: [IOWorkspaces],
      },
    },
  }

  return (
    <>
      <IOConnectProvider fallback={<h1>Loading...</h1>} settings={settings}>
        {children}
      </IOConnectProvider>
    </>
  )
}
export default InteropContextProvider
