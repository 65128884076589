export const DomnCode = {
  //Account Feature
  TOGGLE: 'TOGGLE',
  CASHMARGIN: 'CASHMARGIN',
  FDICBANKLIST: 'FDICBK',
  MANAGEDASSETPROGTYPE: 'MAAPTY',
  OPTIONLEVEL: 'OPTLVL',
  //roles
  ROLETYPE: 'RoleTYP',
  TRANSFERTYPE: 'TRNTYP',
  RECURRING: 'RECURR',
  //inv prof investment
  ANNUALINCOME: 'ANNINC',
  LIQNETWORTH: 'LIQNET',
  INVESTMENTKNOWLEDGE: 'INVKNO',
  INVESTMENTTIME: 'INVTIM',
  TOTALNETWORTH: 'TOTNET',
  INVESTMENTOBJECTIVE: 'INVOBJ',
  INVESTMENTEXPERIENCE: 'INVEXP',
  SOURCEOFFUND: 'SRCFUN',
  INVESTMENTEXPERIENCETIME: 'INEXTM',
  DIVIDENDPAYMENT: 'DIVPAY',
  OPTIONQUESTIONS: 'OPTRQU',
  OPTIONKNOWLEDGE: 'OPTKNW',
  OPTIONEXPERIENCE: 'OPTEXP',
  OPTIONINVESTMENTCODE: 'OPTINV',
  LIQUIDITYNEEDS: 'LIQNDS',
  RISKTOLERANCE: 'RISTOL',
  SOURCECONTRIBUTIONS: 'SOUCON',
  ADDNPART:'ADDNPART'


}
