import styled from 'styled-components'
import { css } from 'styled-components'
import { COLORS } from '../../../../../../theme/Colors'
import { IReview } from '../../styles'

export const FeatureInfoRow = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.5rem;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  width: 25%;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
`
export const FeatureInfoColumn1 = styled.div`
  display: flex;
  width: 24.5%;
  padding: 0.5rem;
  gap: 0.5rem;
`
export const CenterWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  padding: 0.5rem;
`
export const AccountWrapper = styled.div`
  ${(props: IReview) =>
    props.isReview
      ? css``
      : css`
          //border-radius: 8px;
          //box-shadow: 2px 2px 6px 2px ${COLORS.BoxShadow.Shadow2};
        `}
  display: flex;
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const AccountWrapperTemp = styled.div`
  ${(props: IReview) =>
    props.isReview
      ? css``
      : css`
          //border-radius: 8px;
          //box-shadow: 2px 2px 6px 2px ${COLORS.BoxShadow.Shadow2};
        `}
  display: flex;
  //background-color: ${COLORS.Background.White};
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const SaveButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  //box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  align-self: flex-end;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: ${COLORS.Background.White};
  gap: 0.6rem;
  flex: 0 1 100%;
`
export const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
`
export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  gap: 0.6rem;
`
export const AccFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`
// export const SectionWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 0.5rem 0.5rem;
//   /* margin: 0 0.5rem; */
//   /* border-radius: 0px; */
//   /* border: 2px soild; */
//   /* box-shadow: 2px 2px 6px 2px ${COLORS.BoxShadow.Shadow2};
//   background-color: ${COLORS.Background.White}; */
//   border-bottom: 1px solid #ccc;
//   width: 100%;
//   max-width: 85rem;
//   gap: 0.5rem;
//   /* margin-top: 0.9rem; */
// `
export const SectionWrapper = styled.div`
  ${(props: IReview) =>
    props.isReview
      ? css``
      : css`
          //border-radius: 8px;
          //box-shadow: 2px 2px 6px 2px ${COLORS.BoxShadow.Shadow2};
        `}
  display: flex;
  flex-direction: column;
  &.isdownload && {
    background-color: ${COLORS.Background.White};
  }
  width: 100%;
  max-width: 85rem;
  gap: 0.5rem;
`
export const CollapseWrapper = styled.div`
  ${(props: IReview) =>
    props.isReview
      ? css`
          background-color: #cbd5e1;
          padding: 5px 5px;
        `
      : css``}
  display: flex;
  /* border-radius: 0.5rem; */
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  gap: 0.5rem;
  cursor: pointer;
`
export const CollapseHeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  width: 100%;
`
export const CollapseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  justify-content: flex-end;
  width: 100%;
  padding-right: 1rem;
`

export const CreditPlusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
`
export const CreditPlusItemContainer = styled.div`
  flex: 1 0 calc(16.67%- 10px);
  min-width: calc(16.67%- 10px);
  min-width: 12% !important;
  margin-left: 1rem;
`

export const AccordionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  gap: 0.5rem;
`
export const AccordionColContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
`

export const OptionsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1rem;
  height: auto;
`

export const OptionHalf = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const OptionHalfItem = styled.div`
  display: flex;
  flex: 1 1 50%;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding: 0.3rem;
  align-items: center;
  justify-content: flex-start;
  width: 92%;
`

export const MangeAssetValueItemWrapper = styled.div`
  display: flex;
  flex: 1 1 50%;
  flex-wrap: nowrap;
  gap: 0.5rem;
  padding: 0.3rem;
  align-items: center;
  justify-content: space-between;
  width: 92%;
`
export const TextBoxWrapper = styled.div`
  width: calc(50%-10px);
`
export const OptionDividerWrapper = styled.div`
  max-height: 43vh;
  margin-right: 1rem;
  overflow: hidden;
`
export const ExcludedAsstWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const ExcludedAsstItems = styled.div`
  flex-basis: 25%;
`
export const ExcludedAsstItemRow = styled.div`
  display: flex;
  flex-direction: row;
  // background-color:pink;
  gap: 0.3rem;
  padding: 0.2rem;
  align-items: center;
`

{
  /* <headerSection>
<leftSection></leftSection>
<rightSection></rightSection>
</headerSection> */
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 60%;
`
export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  flex-flow: row-reverse;
  width: 40%;
`
