import Lottie from 'lottie-react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Loader from '../../../assets/lottiefiles/loader.json'
import Divider from '../../../components/divider/Divider'
import Label from '../../../components/label/Label'
import {
  DefaultProps,
  OptionProps,
} from '../../../components/selectDropdown/ISelectDropdown'
import { SvgAccountType } from '../../../components/svg/SvgAccountType'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import { Textbox } from '../../../components/textbox/Textbox'
import {
  WDLabel,
  WDLabelError,
  WDLabelStyledCardHeader,
} from '../../../components/ui/WDLabel'
import { WDTextboxBorderRadius } from '../../../components/ui/WDTextbox'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { getAdvisorMasterData } from '../../../services/clientAndAccountSearch/ClientAndAccountSearchAPI'
import { getAccountTypes } from '../../../services/formRequest/FormRequestAPI'
import { GetAdvisorDataRepCodeRequest } from '../../../services/LobOfficeCode/ILobOfficeContext'
import { LobInfoContext } from '../../../services/LobOfficeCode/LobOfficeContext'
import { COLORS } from '../../../theme/Colors'
import { ClientSearch } from '../../clientSearch/ClientSearch'
import { LottieWrapper } from '../../interceptor/styles'
import SelectDropdown from '../../selectDropdown/SelectDropdown'
import {
  AppInfoWrapper,
  DividerWrapper,
} from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import { SpinnerWrapper } from '../accountForms/formIndividual/tabs/ProductFeatures/style'
import { StyledDocumentPanel } from '../accountInformation/styles'
import {
  FormContainer,
  GroupListItems,
  ItemListChildWrapper,
  ItemsListWrapper,
  ItemsWrapper,
  ProductWrapper,
  RowWrapper,
  TitleGrouping,
  WipHeadingWrapper,
} from './styles'
import { IGetAccTypeRequest, PartyModel } from './IFormWip'
import ToastContext from '../../../services/toast/ToastContext'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'

interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
  isPDF?: boolean
}
export interface FormWipError {
  WipAccounttype: string
  WipAccountitle: string
  repcode: string
  ApplicationNickname: string
}
const FormWip = ({ isPDF }: FormWipProps) => {
  const {
    wipId,
    wipFormData,
    setWipFormData,
    newModelData,
    setSelectedAccount,
    setSelectedSubAccount,
    setAccountTypes,
    accountTypes,
    showError,
    setShowError,
    productInfoFieldCheck,
    setProductInfoFieldCheck,
    GetStateList,
    stateList,
    relationList,
    setRelationList,
    accountType,
    setAccountType,
    selectedAccount,
  } = useContext(ApplicationInfoContext)
  const {
    advisorDataByRepCode,
    setAdvisorDataByRepCode,
    GetAdvisorDataByRepcode,
    GetLobOfcCode,
    lobOfcCode,
    setLobOfcCode,
    lookupDetails,
  } = useContext(LobInfoContext)
  const { setToast } = useContext(ToastContext)
  const [loader, setLoader] = useState(false)
  const [repCodeError, setRepCodeError] = useState(false)
  const [advisor] = useState(sessionStorage.getItem('AvatarName') || '')
  const form = useRef<HTMLFormElement>(null)
  const [openSearchClientPopup, setOpenSearchClientPopup] =
    useState<boolean>(false)
  const [dropdownLoader, setDropdownLoader] = useState<boolean>(false)
  const [selectedDropdownIds, setSelectedDropdownIds] = useState<string[]>([
    '1',
  ])
  const [selectedSubAccountIds, setSelectedSubAccountIds] = useState<string[]>([
    '',
  ])

  const [subAccountType, setSubAccountType] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [ownerType, setOwnerType] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [stateName, setStateName] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })

  const [dropdownItems, setDropdownItems] = useState<DefaultProps[]>([])
  const [subAccountTypes, setSubAccountTypes] = useState<DefaultProps[]>([])
  const [repCodeList, setRepCodeList] = useState<DefaultProps[]>([])
  const [toggleHeader, setToggleHeader] = useState(true)
  const [accountHolders, setAccountHolders] = useState<OptionProps[]>([])
  useEffect(
    () => {
      // setLoader(true)
      let getIgnore = false

      async function getMasterAdvisorList() {
        getAdvisorMasterData()
          .then((res) => {
            if (res.data) {
              const getRepCodesList = res.data.rrCodes
              const tempArrRep: DefaultProps[] = []
              getRepCodesList.forEach((item: string) => {
                tempArrRep.push({
                  value: item + '-' + res.data.adUserName,
                  label: item + '-' + res.data.adUserName,
                })
              })
              setRepCodeList(tempArrRep)
              // setRepCodeList([])
              const WIPObject = wipFormData
              WIPObject.t_WIP.advisor = advisor
              WIPObject.t_WIP.faid = res.data.adUserName
              setWipFormData(WIPObject)

              setLoader(false)
            }
          })
          .catch(() => {
            setLoader(false)
          })
      }
      if (!getIgnore && !wipId) {
        getMasterAdvisorList()
      }
      return () => {
        getIgnore = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const getRelationshipList = (option: OptionProps) => {
    const list = accountTypes?.accountTypeDetails?.filter(
      (obj) =>
        obj?.acctsubtypeid.toString() === option?.value &&
        obj.relationshiptypeid <= 2
    )
    const tempTParty: PartyModel[] = []
    if (list.length === 0) {
      tempTParty.push({
        partyid: 0,
        firstname: '',
        lastname: '',
        relationshipTypeId: 1,
        issubmitted: true,
        partydetails: '',
        benedesignation: '',
        benepercentage: 0,
        relationshipToPrimaryOwner: '',
      })
    }
    list.forEach((item) => {
      tempTParty.push({
        partyid: 0,
        firstname: '',
        lastname: '',
        relationshipTypeId: item.relationshiptypeid,
        issubmitted: true,
        partydetails: '',
        benedesignation: '',
        benepercentage: 0,
        relationshipToPrimaryOwner: '',
      })
    })
    if (tempTParty.length > 0) {
      wipFormData.t_WIP_Account[0].t_Party_Role = tempTParty
      setWipFormData(wipFormData)
    }
  }
  useEffect(() => {
    if (accountTypes?.accountTypeDetails.length > 0) {
      const temp: DefaultProps[] = []
      const uniqueType = accountTypes?.accountTypeDetails.filter(
        (item: { accttypeid: number }, index: any, self: any[]) =>
          index === self.findIndex((t) => t.accttypeid === item.accttypeid)
      )
      uniqueType.forEach((item: any) => {
        temp.push({
          label: item.accttype.toString(),
          value: item.accttypeid.toString(),
        })
      })
      setDropdownItems(temp)
      const subAccountTypes: DefaultProps[] = []
      const uniqueSubType = accountTypes?.accountTypeDetails.filter(
        (item: { acctsubtypeid: number }, index: any, self: any[]) =>
          index ===
          self.findIndex((t) => t.acctsubtypeid === item.acctsubtypeid)
      )

      uniqueSubType.forEach((item: any) => {
        if (item.accttype === selectedDropdownIds[0]) {
          subAccountTypes.push({
            label: item.acctsubtype,
            value: item.acctsubtypeid.toString(),
          })
        }
      })

      setSubAccountTypes(subAccountTypes)
      if (accountType.label === 'Joint') {
        setSubAccountType(subAccountTypes[0])
        setProductInfoFieldCheck({
          ...productInfoFieldCheck,
          subAccountType: false,
        })
        getRelationshipList(subAccountTypes[0])

        const WIPObject = wipFormData
        const tPartyRole = wipFormData?.t_WIP_Account?.[0]?.t_Party_Role ?? []
        WIPObject.t_WIP_Account = [
          {
            accounttypeid: Number(accountType.value),
            accttitle: accountType.label,
            subaccounttypeid: Number(subAccountTypes?.[0]?.value),
            t_Party_Role: tPartyRole,
          },
        ]
        setWipFormData(WIPObject)
      }
    }
    //  else {
    //   getProductDropdown()
    // }
  }, [accountTypes, selectedDropdownIds])
  const onDropdownSelect = useCallback(
    (index: number, option: OptionProps) => {
      const updatedSelectedDropdown = [...selectedDropdownIds]
      updatedSelectedDropdown[index] = option.label
      setSelectedDropdownIds(updatedSelectedDropdown)
      setSelectedAccount({
        label: option.label,
        accountTypeId: option.value,
        value: option.value,
      })
    },
    [selectedDropdownIds, setSelectedAccount]
  )

  const getAccountHolders = useCallback(
    (subProductType: string[]) => {
      const dropdownOptions: any[] = []
      accountTypes?.lookupDetails.map((obj) => {
        dropdownOptions.push({
          label: obj.name,
          value: obj.id,
        })
        setAccountHolders(dropdownOptions)
      })
    },
    [accountTypes]
  )

  const onSubAccountSelect = useCallback(
    (index: number, option: OptionProps) => {
      const updatedSelectedDropdown = [...selectedSubAccountIds]
      updatedSelectedDropdown[index] = option.label
      setSelectedSubAccountIds(updatedSelectedDropdown)
      setSelectedSubAccount({
        label: option.label,
        accountTypeId: option.value,
        value: option.value,
      })
      if (selectedDropdownIds && selectedDropdownIds[0] === 'Retirement') {
        getAccountHolders(updatedSelectedDropdown)
      }
    },
    [
      getAccountHolders,
      selectedDropdownIds,
      selectedSubAccountIds,
      setSelectedSubAccount,
    ]
  )

  const toggleClientSearchModal = (open: boolean) => {
    setOpenSearchClientPopup(open)
  }
  const handleAppNicknameChange = (value: string) => {
    if (!value) {
      setProductInfoFieldCheck({ ...productInfoFieldCheck, nickname: false })
      setShowError((prevState: any) => {
        return {
          ...prevState,
          nickname: true,
        }
      })
    } else {
      const updatedWipFormData = { ...wipFormData }
      if (updatedWipFormData && updatedWipFormData.t_WIP) {
        updatedWipFormData.t_WIP.applicationnickname = value
        setWipFormData(updatedWipFormData)
        setProductInfoFieldCheck({ ...productInfoFieldCheck, nickname: false })
        setShowError({ ...showError, nickname: false })
      }
    }
  }

  const getRepCodeInfo = async (value: string) => {
    const payload: GetAdvisorDataRepCodeRequest = {
      RepCode: value.split('-')[0],
    }
    try {
      setDropdownLoader(true)
      const advisorDataResponse = await GetAdvisorDataByRepcode(payload)
      const ofcCode = {
        officeCode: advisorDataResponse.officeCode,
        RepCode: value.split('-')[0],
      }
      const lobOfcCodeResponse = await GetLobOfcCode(ofcCode)
      if (!lobOfcCodeResponse || lobOfcCodeResponse.length === 0) {
        getRelationshipList({ label: '', value: '' })
        setRepCodeError(true)
        setDropdownLoader(false)
      } else {
        setLobOfcCode(lobOfcCodeResponse)
        setRepCodeError(false)
      }
      if (lobOfcCodeResponse.length > 0) {
        const payload: IGetAccTypeRequest = {
          lob: lobOfcCodeResponse[0].lobname ?? '',
        }
        const accountTypesResponse = await getAccountTypes(payload)      
        setAccountTypes(accountTypesResponse.data)
        setDropdownLoader(false)
      }
    } catch (error) {
      console.error(error)
      getRelationshipList({ label: '', value: '' })
      setDropdownLoader(false)
    }
  }

  useEffect(() => {
    if (wipId) {
      setSelectedDropdownIds([
        newModelData &&
          newModelData.wipAccountDetailslist &&
          newModelData.wipAccountDetailslist[0].accttypetext,
      ])
    }
  }, [wipId, newModelData])

  useEffect(() => {
    setShowError({
      repCode: false,
      productType: false,
      subAccountType: false,
      ownerType: false,
      nickname: false,
      client: false,
    })
  }, [setShowError])
  useEffect(() => {
    if (wipFormData.t_WIP.repcode === '') {
      setSubAccountTypes([])
      setDropdownItems([])
    }
  }, [wipFormData && wipFormData.t_WIP && wipFormData.t_WIP.repcode])

  useEffect(() => {
    if (dropdownItems.length === 0 || wipFormData.t_WIP.repcode.length < 3) {
      setAccountType({ label: 'Select', value: '--' })
      setSubAccountType({ label: 'Select', value: '--' })
    }
  }, [dropdownItems, wipFormData])
  return (
    // <Container>
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel id={isPDF ? 'remove' : ''}>
        <AppInfoWrapper>
          <WipHeadingWrapper
            onClick={() => {
              if (wipId) setToggleHeader(!toggleHeader)
            }}
          >
            <TitleGrouping>
              <SvgAccountType fillColor={COLORS.Icons.PrimaryActive} />
              <WDLabelStyledCardHeader className="header_responsive">
                {'Product Information'}
              </WDLabelStyledCardHeader>
            </TitleGrouping>
            {wipId && !toggleHeader && !isPDF && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {wipId && toggleHeader && !isPDF && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </WipHeadingWrapper>
          {toggleHeader && (
            <DividerWrapper>
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Background.Neutral}`}
                thickness={'0.1rem'}
              />
            </DividerWrapper>
          )}
          {toggleHeader && (
            <FormContainer ref={form}>
              {
                loader ? (
                  <SpinnerWrapper>
                    <LottieWrapper>
                      <Lottie animationData={Loader} loop={true} />
                    </LottieWrapper>
                  </SpinnerWrapper>
                ) : !wipId ? (
                  <GroupListItems>
                    <ProductWrapper id={!wipId ? 'productinfo-gap' : ''}>
                      <RowWrapper>
                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              {'Representative Code*'}
                            </WDLabel>
                            {showError.repCode && (
                              <WDLabelError>{'Required'}</WDLabelError>
                            )}
                          </ItemListChildWrapper>
                          <ItemListChildWrapper>
                            {wipId ? (
                              <WDLabel className="label_responsive">
                                <Label>
                                  {newModelData && newModelData.wipDetails
                                    ? newModelData.wipDetails.repcode
                                    : '--'}
                                </Label>
                              </WDLabel>
                            ) : repCodeList.length ? (
                              <SelectDropdown
                                width={'100%'}
                                border={showError.repCode}
                                name="account-dropdown"
                                options={repCodeList}
                                placeholder="Select Rep Code"
                                onChange={(option) => {
                                  if (option?.label)
                                    getRepCodeInfo(option?.label)
                                  if (wipFormData && wipFormData.t_WIP) {
                                    const WIPObject = wipFormData
                                    WIPObject.t_WIP.repcode = option
                                      ? option?.value
                                      : ''
                                    setWipFormData({ ...WIPObject })
                                    if (WIPObject.t_WIP.repcode === '') {
                                      setShowError({
                                        ...showError,
                                        repCode: true,
                                      })
                                    } else {
                                      setProductInfoFieldCheck({
                                        ...productInfoFieldCheck,
                                        repCode: false,
                                      })
                                      setShowError({
                                        ...showError,
                                        repCode: false,
                                      })
                                    }
                                  }
                                }}
                                defaultValue={{
                                  value: wipFormData.t_WIP?.repcode,
                                  label: wipFormData.t_WIP?.repcode,
                                }}
                              />
                            ) : (
                              <WDTextboxBorderRadius className="inputBox_responsive">
                                <Textbox
                                  name="account-dropdown"
                                  onChange={(value) => {
                                    if (!value)
                                      getRelationshipList({
                                        label: '',
                                        value: '',
                                      })
                                    wipFormData.t_WIP.repcode =
                                      value?.toUpperCase()
                                    setWipFormData({ ...wipFormData })
                                    if (value.length > 3) {
                                      getRepCodeInfo(value?.toUpperCase())
                                    }
                                    if (wipFormData.t_WIP.repcode === '') {
                                      setShowError({
                                        ...showError,
                                        repcode: true,
                                      })
                                    } else {
                                      setShowError({
                                        ...showError,
                                        repcode: false,
                                      })
                                      setProductInfoFieldCheck({
                                        ...productInfoFieldCheck,
                                        repCode: false,
                                      })
                                    }
                                  }}
                                  value={wipFormData.t_WIP?.repcode}
                                />
                              </WDTextboxBorderRadius>
                            )}
                          </ItemListChildWrapper>
                          {repCodeError && (
                            <ItemListChildWrapper>
                              <WDLabelError>
                                <Label>
                                  {
                                    'This RepCode does not have any Line of Business associated with it.'
                                  }
                                </Label>
                              </WDLabelError>
                            </ItemListChildWrapper>
                          )}
                        </ItemsListWrapper>
                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              {'Product Type*'}
                            </WDLabel>
                            {showError.productType && (
                              <WDLabelError>{'Required'}</WDLabelError>
                            )}
                          </ItemListChildWrapper>
                          <ItemListChildWrapper>
                            {!wipId ? (
                              selectedDropdownIds.map((_, index: number) => {
                                return (
                                  <SelectDropdown
                                    key={index}
                                    border={showError.productType}
                                    width={'100%'}
                                    name="account-dropdown"
                                    options={dropdownItems}
                                    placeholder="Select"
                                    onChange={(option) => {
                                      if (option) {
                                        getRelationshipList({
                                          label: '',
                                          value: '',
                                        })
                                        onDropdownSelect(index, option)
                                        onSubAccountSelect(0, {
                                          label: 'Select',
                                          value: '--',
                                        })
                                        setAccountType(option)
                                        setSubAccountType({
                                          label: 'Select',
                                          value: '--',
                                        })
                                        setOwnerType({
                                          label: 'Select',
                                          value: '--',
                                        })
                                        const WIPObject = wipFormData
                                        const tPartyRole =
                                          wipFormData?.t_WIP_Account?.[0]
                                            ?.t_Party_Role ?? []
                                        WIPObject.t_WIP_Account = [
                                          {
                                            accounttypeid: Number(option.value),
                                            accttitle: option.label,
                                            subaccounttypeid: 0,
                                            t_Party_Role: tPartyRole,
                                          },
                                        ]
                                        setWipFormData(WIPObject)
                                        setProductInfoFieldCheck({
                                          ...productInfoFieldCheck,
                                          productType: false,
                                        })
                                        setShowError({
                                          ...showError,
                                          productType: false,
                                        })
                                      }
                                    }}
                                    value={accountType}
                                    isLoading={dropdownLoader}
                                  />
                                )
                              })
                            ) : (
                              <WDLabel className="label_responsive">
                                {newModelData &&
                                newModelData.wipAccountDetailslist
                                  ? newModelData.wipAccountDetailslist[0]
                                      .accttypetext
                                  : '--'}
                              </WDLabel>
                            )}
                          </ItemListChildWrapper>
                        </ItemsListWrapper>
                      </RowWrapper>
                      <RowWrapper>
                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              {'Product Sub Type*'}
                            </WDLabel>
                            {showError.subAccountType && (
                              <WDLabelError>{'Required'}</WDLabelError>
                            )}
                          </ItemListChildWrapper>

                          <ItemListChildWrapper>
                            {!wipId ? (
                              selectedSubAccountIds.map((_, index: number) => {
                                return (
                                  <SelectDropdown
                                    key={index}
                                    width={'100%'}
                                    name="account-dropdown"
                                    border={showError.subAccountType}
                                    options={subAccountTypes}
                                    placeholder="Select Sub Account Type"
                                    onChange={(option) => {
                                      if (option) {
                                        getRelationshipList(option)
                                        onSubAccountSelect(index, option)
                                        setSubAccountType(option)
                                        setOwnerType({
                                          label: 'Select',
                                          value: '--',
                                        })
                                        const WIP = wipFormData.t_WIP
                                        const WIPObject =
                                          wipFormData.t_WIP_Account
                                        if (WIPObject.length > 0) {
                                          WIPObject[0].subaccounttypeid =
                                            parseInt(option.value)
                                          WIP.iraaccountownertypeid = 0
                                        }
                                        wipFormData.t_WIP_Account = WIPObject
                                        setWipFormData(wipFormData)
                                        setProductInfoFieldCheck({
                                          ...productInfoFieldCheck,
                                          subAccountType: false,
                                        })

                                        setShowError({
                                          ...showError,
                                          subAccountType: false,
                                        })
                                        if (option.label === 'Custodian') {
                                          GetStateList()
                                        }
                                      }
                                    }}
                                    value={subAccountType}
                                    isLoading={dropdownLoader}
                                  />
                                )
                              })
                            ) : (
                              <WDLabel className="label_responsive">
                                {newModelData &&
                                newModelData.wipAccountDetailslist
                                  ? newModelData.wipAccountDetailslist[0]
                                      .subaccttypetext
                                  : '--'}
                              </WDLabel>
                            )}
                          </ItemListChildWrapper>
                        </ItemsListWrapper>
                        {accountType.label == 'Retirement' &&
                          (subAccountType.label == 'IRA Coverdell ESA' ||
                            subAccountType.label == 'Wedbush IRA Cont' ||
                            subAccountType.label == 'Wedbush IRA Roth' ||
                            subAccountType.label == 'IRA Inherited Trade' ||
                            subAccountType.label == 'IRA Inherited Roth') && (
                            <ItemsListWrapper>
                              <ItemListChildWrapper>
                                <WDLabel className="label_responsive">
                                  {'Account is being opened for?*'}
                                </WDLabel>
                                {showError.ownerType && (
                                  <WDLabelError>{'Required'}</WDLabelError>
                                )}
                              </ItemListChildWrapper>

                              <ItemListChildWrapper>
                                {!wipId ? (
                                  <SelectDropdown
                                    width={'100%'}
                                    name="account-dropdown"
                                    border={showError.ownerType}
                                    options={accountHolders}
                                    placeholder="Select Account Owner"
                                    onChange={(option) => {
                                      if (option) {
                                        setOwnerType(option)
                                        const WIPObject = wipFormData.t_WIP
                                        if (WIPObject) {
                                          WIPObject.iraaccountownertypeid =
                                            option.value
                                        }
                                        wipFormData.t_WIP = WIPObject
                                      }
                                      if (
                                        option?.label === 'Adult' &&
                                        wipFormData.t_WIP_Account?.[0] &&
                                        wipFormData?.t_WIP_Account?.[0]
                                          ?.t_Party_Role &&
                                        wipFormData?.t_WIP_Account?.[0]
                                          ?.t_Party_Role.length > 1
                                      ) {
                                        wipFormData.t_WIP_Account?.[0].t_Party_Role.splice(
                                          1,
                                          1
                                        )
                                      } else getRelationshipList(subAccountType)
                                      setWipFormData(wipFormData)
                                      setProductInfoFieldCheck({
                                        ...productInfoFieldCheck,
                                        ownerType: false,
                                      })
                                      setShowError({
                                        ...showError,
                                        ownerType: false,
                                      })
                                    }}
                                    value={ownerType}
                                    isDisabled={
                                      subAccountType.label ==
                                        'IRA Coverdell ESA' &&
                                      ownerType.value === 'Minor'
                                        ? true
                                        : false
                                    }
                                  />
                                ) : (
                                  <WDLabel className="label_responsive">
                                    {newModelData &&
                                    newModelData.wipAccountDetailslist &&
                                    newModelData.wipAccountDetailslist[0]
                                      .accttypetext &&
                                    newModelData.wipAccountDetailslist.length >
                                      0
                                      ? newModelData.wipAccountDetailslist[0]
                                          .accttypetext
                                      : '--'}
                                  </WDLabel>
                                )}
                              </ItemListChildWrapper>
                            </ItemsListWrapper>
                          )}

                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              {'Application Nickname*'}
                            </WDLabel>
                            {showError.nickname && (
                              <WDLabelError>{'Required'}</WDLabelError>
                            )}
                          </ItemListChildWrapper>
                          <ItemListChildWrapper>
                            {!wipId ? (
                              <WDTextboxBorderRadius className="inputBox_responsive">
                                <Textbox
                                  border={
                                    showError.nickname ? COLORS.Text.Danger : ''
                                  }
                                  defaultValue={
                                    wipFormData.t_WIP?.applicationnickname || ''
                                  }
                                  onChange={(value: string) =>
                                    handleAppNicknameChange(value)
                                  }
                                />
                              </WDTextboxBorderRadius>
                            ) : (
                              <WDLabel className="label_responsive">
                                {newModelData && newModelData.wipDetails
                                  ? newModelData.wipDetails.applicationnickname
                                  : '--'}
                              </WDLabel>
                            )}
                          </ItemListChildWrapper>
                        </ItemsListWrapper>
                      </RowWrapper>
                      <RowWrapper>
                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              {'Advisor Name'}
                            </WDLabel>
                          </ItemListChildWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>
                                {wipId
                                  ? newModelData.wipDetails?.advisor
                                  : wipFormData &&
                                    wipFormData.t_WIP &&
                                    wipFormData.t_WIP.repcode
                                  ? advisor
                                  : repCodeList.length > 0
                                  ? advisor
                                  : '--'}
                              </Label>
                            </WDLabel>
                          </ItemListChildWrapper>
                        </ItemsListWrapper>
                      </RowWrapper>
                    </ProductWrapper>
                  </GroupListItems>
                ) : (
                  <GroupListItems>
                    <ProductWrapper id={!wipId ? 'productinfo-gap' : ''}>
                      <ItemsWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            {'Representative Code'}
                          </WDLabel>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          {wipId ? (
                            <WDLabel className="label_responsive">
                              <Label>
                                {newModelData && newModelData.wipDetails
                                  ? newModelData.wipDetails.repcode
                                  : '--'}
                              </Label>
                            </WDLabel>
                          ) : repCodeList.length ? (
                            <SelectDropdown
                              width={'100%'}
                              name="account-dropdown"
                              options={repCodeList}
                              placeholder="Select Rep Code"
                              onChange={(option) => {
                                if (wipFormData && wipFormData.t_WIP) {
                                  const WIPObject = wipFormData
                                  WIPObject.t_WIP.repcode = option
                                    ? option?.value
                                    : ''
                                  setWipFormData({ ...WIPObject })
                                }
                              }}
                              defaultValue={{
                                value: wipFormData.t_WIP?.repcode,
                                label: wipFormData.t_WIP?.repcode,
                              }}
                            />
                          ) : (
                            <WDTextboxBorderRadius className="inputBox_responsive">
                              <Textbox
                                name="account-dropdown"
                                onChange={(value) => {
                                  wipFormData.t_WIP.repcode = value
                                  setWipFormData({ ...wipFormData })
                                }}
                                value={wipFormData.t_WIP?.repcode}
                              />
                            </WDTextboxBorderRadius>
                          )}
                        </ItemListChildWrapper>
                      </ItemsWrapper>
                      <ItemsWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            {'Product Type'}
                          </WDLabel>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          {!wipId ? (
                            selectedDropdownIds.map((_, index: number) => {
                              return (
                                <SelectDropdown
                                  key={index}
                                  width={'100%'}
                                  name="account-dropdown"
                                  options={dropdownItems}
                                  placeholder="Select Account Type"
                                  onChange={(option) => {
                                    if (option) {
                                      onDropdownSelect(index, option)
                                      setAccountType(option)
                                      const WIPObject = wipFormData
                                      WIPObject.t_WIP_Account = [
                                        {
                                          accounttypeid: Number(option.value),
                                          accttitle: option.label,
                                          subaccounttypeid: 0,
                                        },
                                      ]
                                      setWipFormData(WIPObject)
                                    }
                                  }}
                                  value={accountType}
                                />
                              )
                            })
                          ) : (
                            <WDLabel className="label_responsive">
                              {newModelData &&
                              newModelData.wipAccountDetailslist
                                ? newModelData.wipAccountDetailslist[0]
                                    .accttypetext
                                : '--'}
                            </WDLabel>
                          )}
                        </ItemListChildWrapper>
                      </ItemsWrapper>
                      <ItemsWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            {'Product Sub Type'}
                          </WDLabel>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          {!wipId ? (
                            selectedSubAccountIds.map((_, index: number) => {
                              return (
                                <SelectDropdown
                                  key={index}
                                  width={'100%'}
                                  name="account-dropdown"
                                  options={subAccountTypes}
                                  placeholder="Select Sub Account Type"
                                  onChange={(option) => {
                                    if (option) {
                                      onSubAccountSelect(index, option)
                                      setSubAccountType(option)
                                      setOwnerType({
                                        label: 'Select',
                                        value: '--',
                                      })
                                      const WIPObject = wipFormData
                                      WIPObject.t_WIP_Account = [
                                        {
                                          accounttypeid: Number(
                                            accountType.value
                                          ),
                                          accttitle: accountType.label,
                                          subaccounttypeid: parseInt(
                                            option.value
                                          ),
                                        },
                                      ]
                                      setWipFormData(WIPObject)
                                    }
                                  }}
                                  value={subAccountType}
                                />
                              )
                            })
                          ) : (
                            <WDLabel className="label_responsive">
                              {newModelData &&
                              newModelData.wipAccountDetailslist
                                ? newModelData.wipAccountDetailslist[0]
                                    .subaccttypetext
                                : '--'}
                            </WDLabel>
                          )}
                        </ItemListChildWrapper>
                      </ItemsWrapper>
                      {accountType.label == 'Retirement' &&
                        (subAccountType.label == 'IRA Coverdell ESA' ||
                          subAccountType.label == 'Wedbush IRA Cont' ||
                          subAccountType.label == 'Wedbush IRA Roth' ||
                          subAccountType.label == 'IRA Inherited Trade' ||
                          subAccountType.label == 'IRA Inherited Roth') && (
                          <ItemsListWrapper>
                            <ItemListChildWrapper>
                              <WDLabel className="label_responsive">
                                {'Is the account owner a Minor or Entity?'}
                              </WDLabel>
                              {showError.ownerType && (
                                <WDLabelError>{'Required'}</WDLabelError>
                              )}
                            </ItemListChildWrapper>

                            <ItemListChildWrapper>
                              {!wipId ? (
                                <SelectDropdown
                                  width={'100%'}
                                  name="account-dropdown"
                                  border={showError.ownerType}
                                  options={accountHolders}
                                  placeholder="Select account owner"
                                  onChange={(option) => {
                                    if (option) {
                                      setOwnerType(option)
                                      const WIPObject = wipFormData.t_WIP
                                      if (WIPObject) {
                                        WIPObject.iraaccountownertypeid =
                                          option.value
                                      }
                                      wipFormData.t_WIP = WIPObject
                                    }
                                    setWipFormData(wipFormData)
                                    setProductInfoFieldCheck({
                                      ...productInfoFieldCheck,
                                      ownerType: false,
                                    })
                                    setShowError({
                                      ...showError,
                                      ownerType: false,
                                    })
                                  }}
                                  value={ownerType}
                                  isDisabled={
                                    subAccountType.label ==
                                      'IRA Coverdell ESA' &&
                                    ownerType.value === 'Minor'
                                      ? true
                                      : false
                                  }
                                />
                              ) : (
                                <WDLabel className="label_responsive">
                                  {newModelData &&
                                  newModelData.wipAccountDetailslist &&
                                  newModelData.wipAccountDetailslist[0]
                                    .accttypetext &&
                                  newModelData.wipAccountDetailslist.length > 0
                                    ? newModelData.wipAccountDetailslist[0]
                                        .accttypetext
                                    : '--'}
                                </WDLabel>
                              )}
                            </ItemListChildWrapper>
                          </ItemsListWrapper>
                        )}
                      {accountType.label == 'Individual' &&
                        subAccountType.label == 'Custodian' && (
                          <ItemsListWrapper>
                            <ItemListChildWrapper>
                              <WDLabel className="label_responsive">
                                {'State Gift/transfer was made?'}
                              </WDLabel>
                              {showError.ownerType && (
                                <WDLabelError>{'Required'}</WDLabelError>
                              )}
                            </ItemListChildWrapper>

                            <ItemListChildWrapper>
                              {!wipId ? (
                                <SelectDropdown
                                  width={'100%'}
                                  name="account-dropdown"
                                  border={showError.ownerType}
                                  options={accountHolders}
                                  placeholder="Select account owner"
                                  onChange={(option) => {
                                    if (option) {
                                      setOwnerType(option)
                                      const WIPObject = wipFormData.t_WIP
                                      if (WIPObject) {
                                        WIPObject.iraaccountownertypeid =
                                          option.value
                                      }
                                      wipFormData.t_WIP = WIPObject
                                    }
                                    setWipFormData(wipFormData)
                                    setProductInfoFieldCheck({
                                      ...productInfoFieldCheck,
                                      ownerType: false,
                                    })
                                    setShowError({
                                      ...showError,
                                      ownerType: false,
                                    })
                                  }}
                                  value={ownerType}
                                  isDisabled={
                                    subAccountType.label ==
                                      'IRA Coverdell ESA' &&
                                    ownerType.value === 'Minor'
                                      ? true
                                      : false
                                  }
                                />
                              ) : (
                                <WDLabel className="label_responsive">
                                  {newModelData &&
                                  newModelData.wipAccountDetailslist &&
                                  newModelData.wipAccountDetailslist[0]
                                    .accttypetext &&
                                  newModelData.wipAccountDetailslist.length > 0
                                    ? newModelData.wipAccountDetailslist[0]
                                        .accttypetext
                                    : '--'}
                                </WDLabel>
                              )}
                            </ItemListChildWrapper>
                          </ItemsListWrapper>
                        )}
                      {wipId &&
                        newModelData?.wipAccountDetailslist?.[0]?.accttitle ==
                          'Retirement' &&
                        (newModelData?.wipAccountDetailslist?.[0]
                          ?.subaccttypetext == 'IRA Coverdell ESA' ||
                          newModelData?.wipAccountDetailslist?.[0]
                            ?.subaccttypetext == 'Wedbush IRA Cont' ||
                          newModelData?.wipAccountDetailslist?.[0]
                            ?.subaccttypetext == 'Wedbush IRA Roth' ||
                          newModelData?.wipAccountDetailslist?.[0]
                            ?.subaccttypetext == 'IRA Inherited Trade' ||
                          newModelData?.wipAccountDetailslist?.[0]
                            ?.subaccttypetext == 'IRA Inherited Roth') && (
                          <ItemsWrapper>
                            <ItemListChildWrapper>
                              <WDLabel className="label_responsive">
                                {'Account is being opened for?*'}
                              </WDLabel>
                              {showError.ownerType && (
                                <WDLabelError>{'Required'}</WDLabelError>
                              )}
                            </ItemListChildWrapper>

                            <ItemListChildWrapper>
                              <WDLabel className="label_responsive">
                                {lookupDetails.find(
                                  (obj: any) =>
                                    obj.id ===
                                    newModelData?.wipDetails
                                      ?.iraaccountownertypeid
                                )?.name ?? '--'}
                              </WDLabel>
                            </ItemListChildWrapper>
                          </ItemsWrapper>
                        )}

                      <ItemsWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            {'Application Nickname'}
                          </WDLabel>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          {!wipId ? (
                            <WDTextboxBorderRadius className="inputBox_responsive">
                              <Textbox
                                defaultValue={
                                  wipFormData.t_WIP?.applicationnickname || ''
                                }
                                onChange={(value: string) =>
                                  handleAppNicknameChange(value)
                                }
                              />
                            </WDTextboxBorderRadius>
                          ) : (
                            <WDLabel className="label_responsive">
                              {newModelData && newModelData.wipDetails
                                ? newModelData.wipDetails.applicationnickname
                                : '--'}
                            </WDLabel>
                          )}
                        </ItemListChildWrapper>
                      </ItemsWrapper>
                      <ItemsWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            {'Advisor Name'}
                          </WDLabel>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            <Label>
                              {wipId
                                ? newModelData.wipDetails?.advisor
                                : wipFormData &&
                                  wipFormData.t_WIP &&
                                  wipFormData.t_WIP.repcode
                                ? advisor
                                : repCodeList.length > 0
                                ? advisor
                                : '--'}
                            </Label>
                          </WDLabel>
                        </ItemListChildWrapper>
                      </ItemsWrapper>
                    </ProductWrapper>
                  </GroupListItems>
                )
                // })
              }
              {openSearchClientPopup && (
                <ClientSearch closeModalCallbackFn={toggleClientSearchModal} />
              )}
              {/* <SvgBgWrapper>
                <SvgPrimaryApplicant />
              </SvgBgWrapper> */}
            </FormContainer>
          )}
        </AppInfoWrapper>
      </StyledDocumentPanel>
    </ResponsiveWrapper>
    // </Container>
  )
}
export default FormWip
