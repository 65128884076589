import Lottie from 'lottie-react'
import { RefObject, useContext, useEffect, useRef, useState } from 'react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Button from '../../../../components/button/Button'
import Divider from '../../../../components/divider/Divider'
import { SvgBin } from '../../../../components/svg/SvgBin'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../components/svg/SvgChevron'
import { SvgProfile } from '../../../../components/svg/SvgProfile'
import SvgSearch from '../../../../components/svg/SvgSearch'
import RetirementTable from '../../../../components/table/RetirementTable'
import { Textbox } from '../../../../components/textbox/Textbox'
import { Toast } from '../../../../components/toast/Toast'
import { WDButtonOutLine } from '../../../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../components/ui/WDLabel'
import { WDTextboxBorderRadius } from '../../../../components/ui/WDTextbox'
import { ResponsiveWrapper } from '../../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../../services/applicationContext/ApplicationPageContext'
import { getClientDetailsDataAPI } from '../../../../services/clientAndAccountSearch/ClientAndAccountSearchAPI'
import RetirementDetailsContext from '../../../../services/Retirement/RetirementContext'
import { formatDate, formatSSN } from '../../../../shared/utils/GlobalFunctions'
import { COLORS } from '../../../../theme/Colors'
import { ClientSearch } from '../../../clientSearch/ClientSearch'
import {
  AccFeatureWrapper,
  AccountWrapper,
} from '../../accountForms/formIndividual/tabs/AccountFeature/styles'
import { DividerWrapper } from '../../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import { StyledDocumentPanel } from '../../accountInformation/styles'
import {
  FundingButtonWrapper,
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../electronicPrefs/styles'
import {
  TitleGrouping,
  ToastWrapper,
  WipHeadingWrapper,
} from '../../formWip/styles'
import { SectionWrapper, TableWrapper } from '../styles'
import { BeneficiaryProps } from './IPrimaryBeneficiary'
import {
  ClientSearchCard,
  ClientSearchWrapperForBeneficiary,
  DangerText,
  FeatureInfoColumn,
  FormContainer,
  ImageWrapper,
  RowWrapper,
  SocItemWrapper,
  StyledButtonDisabled,
} from './style'

const PrimaryBeneficiary = ({ type, header }: BeneficiaryProps) => {
  const {
    readOnly,
    updatePrimaryBeneficiary,
    newModelData,
    deleteBeneficiary,
  } = useContext(ApplicationInfoContext)
  const {
    selectedClientPrimary,
    selectedClientContingent,
    selectedDataTotal,
    selectedDataContingentTotal,
    setSelectedDataTotal,
    setSelectedContingentDataTotal,
    selectedPrimaryBeneficiary,
    setSelectedPrimaryBeneficiary,
    selectedContingentBeneficiary,
    setSelectedContingentBeneficiary,
    selectedBeneOwner,
    setSelectedBeneOwner,
    selectedClientBeneOwner,
    beneOwnerTotal,
    setBeneOwnerTotal,
    selectedClientAddiParticipant,
    selectedAddiParticipant,
    setSelectedAddiParticipant,
    addiParticipantTotal,
    setAddiParticipantTotal,
    selectedTrustClient,
    setSelectedTrustClient,
    trustTotal,
    setTrustTotal,
    selectedTrustItems,
    setSelectedTrustItems,
  } = useContext(RetirementDetailsContext)
  const [loader, setLoader] = useState(false)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [searchData, setSearchData] = useState<string>('')
  const wrapperRef = useRef(null)
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Social Security Number',
      accessor: 'socialSecurityNumber',
    },
    {
      Header: 'Date of Birth',
      accessor: 'dateOfBirth',
    },
    {
      Header: 'Relationship',
      accessor: 'relationship',
    },
    {
      Header: '% of Benefits',
      accessor: 'percentageOfBenefits',
    },
    {
      Header: ' ',
      accessor: 'empty',
    },
  ]

  const handleNewValue = () => {
    if (type === 'primary') {
      setSelectedPrimaryBeneficiary &&
        setSelectedPrimaryBeneficiary([
          ...selectedPrimaryBeneficiary,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ])
    } else if (type === 'contingent') {
      setSelectedContingentBeneficiary &&
        setSelectedContingentBeneficiary([
          ...selectedContingentBeneficiary,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ])
    } else if (type === 'beneOwner') {
      setSelectedBeneOwner &&
        setSelectedBeneOwner([
          ...selectedBeneOwner,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ])
    } else if (type === 'addlPpt') {
      setSelectedAddiParticipant &&
        setSelectedAddiParticipant([
          ...selectedAddiParticipant,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ])
    } else if (type === 'trust') {
      setSelectedTrustClient((prevTrustClient) => ({
        ...prevTrustClient,
        trust: [
          ...prevTrustClient.trust,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ],
      }))
    } else if (type === 'trustBene') {
      setSelectedTrustClient((prevTrustClient) => ({
        ...prevTrustClient,
        trustBene: [
          ...prevTrustClient.trust,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ],
      }))
    } else if (type === 'trustEe') {
      setSelectedTrustClient((prevTrustClient) => ({
        ...prevTrustClient,
        trustEe: [
          ...prevTrustClient.trust,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ],
      }))
    } else if (type === 'successorTrustee') {
      setSelectedTrustClient((prevTrustClient) => ({
        ...prevTrustClient,
        successorTrustee: [
          ...prevTrustClient.trust,
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ],
      }))
    }
  }

  useEffect(
    () => {
      if (type === 'primary') {
        if (selectedClientPrimary?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({ partyId: selectedClientPrimary?.clientId })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                selectedPrimaryBeneficiary[
                  selectedPrimaryBeneficiary.length - 1
                ] = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? getApplicant?.partyDemographic?.dateOfBirth
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedPrimaryBeneficiary &&
                  setSelectedPrimaryBeneficiary([...selectedPrimaryBeneficiary])
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'contingent') {
        if (selectedClientContingent?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedClientContingent?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                selectedContingentBeneficiary[
                  selectedContingentBeneficiary.length - 1
                ] = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? getApplicant?.partyDemographic?.dateOfBirth
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedContingentBeneficiary &&
                  setSelectedContingentBeneficiary([
                    ...selectedContingentBeneficiary,
                  ])
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'beneOwner') {
        if (selectedClientBeneOwner?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedClientBeneOwner?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data

              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                selectedBeneOwner[selectedBeneOwner.length - 1] = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? formatDate(getApplicant?.partyDemographic?.dateOfBirth)
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedBeneOwner &&
                  setSelectedBeneOwner([...selectedBeneOwner])
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'addlPpt') {
        if (selectedClientAddiParticipant?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedClientAddiParticipant?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                selectedAddiParticipant[selectedAddiParticipant.length - 1] = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? formatDate(getApplicant?.partyDemographic?.dateOfBirth)
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedAddiParticipant &&
                  setSelectedAddiParticipant([...selectedAddiParticipant])
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'trust') {
        if (selectedTrustItems?.trust?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedTrustItems?.trust?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                // selectedTrustClient.trust[selectedTrustClient.trust.length - 1]
                const newTrustClient = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? formatDate(getApplicant?.partyDemographic?.dateOfBirth)
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }

                setSelectedTrustClient((prevTrustItems) => {
                  const updatedTrust = [...prevTrustItems.trust]
                  updatedTrust[updatedTrust.length - 1] = newTrustClient
                  return { ...prevTrustItems, trust: updatedTrust }
                })
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'trustEe') {
        if (selectedTrustItems?.trustEe?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedTrustItems?.trustEe?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                // selectedTrustClient.trust[selectedTrustClient.trust.length - 1]
                const newTrustClient = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? formatDate(getApplicant?.partyDemographic?.dateOfBirth)
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedTrustClient((prevTrustItems) => {
                  const updatedTrust = [...prevTrustItems.trustEe]
                  updatedTrust[updatedTrust.length - 1] = newTrustClient
                  return { ...prevTrustItems, trustEe: updatedTrust }
                })
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'trustBene') {
        if (selectedTrustItems?.trustBene?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedTrustItems?.trustBene?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                // selectedTrustClient.trust[selectedTrustClient.trust.length - 1]
                const newTrustClient = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? formatDate(getApplicant?.partyDemographic?.dateOfBirth)
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedTrustClient((prevTrustItems) => {
                  const updatedTrust = [...prevTrustItems.trustBene]
                  updatedTrust[updatedTrust.length - 1] = newTrustClient
                  return { ...prevTrustItems, trustBene: updatedTrust }
                })
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      } else if (type === 'successorTrustee') {
        if (selectedTrustItems?.successorTrustee?.clientId) {
          setLoader(true)
          getClientDetailsDataAPI({
            partyId: selectedTrustItems?.successorTrustee?.clientId,
          })
            .then((res) => {
              setLoader(false)
              const getApplicant = res.data
              if (getApplicant) {
                const ssn = getApplicant?.clientIds?.find(
                  (id: any) => id.idType === 'US Social Security'
                )?.idNumber
                // selectedTrustClient.trust[selectedTrustClient.trust.length - 1]
                const newTrustClient = {
                  partyId: getApplicant?.partyId,
                  name: getApplicant?.fullName?.trim()?.length
                    ? getApplicant?.fullName
                    : getApplicant?.firstName,
                  socialSecurityNumber: ssn ? formatSSN(ssn) : '',
                  dateOfBirth: getApplicant?.partyDemographic?.dateOfBirth
                    ? formatDate(getApplicant?.partyDemographic?.dateOfBirth)
                    : '',
                  relationship: '',
                  percentageOfBenefits: 0,
                }
                setSelectedTrustClient((prevTrustItems) => {
                  const updatedTrust = [...prevTrustItems.successorTrustee]
                  updatedTrust[updatedTrust.length - 1] = newTrustClient
                  return { ...prevTrustItems, successorTrustee: updatedTrust }
                })
              }
            })
            .catch(() => {
              setAPIPreviewStatus({
                status: 'error',
                text: 'Error while fetching data',
              })
            })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedClientPrimary,
      selectedClientContingent,
      selectedClientAddiParticipant,
      selectedClientBeneOwner,
      selectedTrustItems.trust,
      selectedTrustItems.successorTrustee,
      selectedTrustItems.trustBene,
      selectedTrustItems.trustEe,
    ]
  )
  const [openSearchClientPopup, setOpenSearchClientPopup] =
    useState<boolean>(false)
  const toggleClientSearchModal = (open: boolean) => {
    setOpenSearchClientPopup(open)
    if (!open) setSearchData('')
  }
  const handleClientKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && searchData) {
      if (searchData.length > 1) {
        toggleClientSearchModal(true)
      }
    }
  }
  const handleSearchClient = () => {
    if (searchData) {
      if (searchData.length > 1) {
        toggleClientSearchModal(true)
      }
    }
  }
  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (!ref?.current?.contains(event.target as Node)) {
            setOpenSearchClientPopup(false)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)
  const calculateSum = () => {
    let sum = 0

    if (type === 'primary') {
      selectedPrimaryBeneficiary.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'contingent') {
      selectedContingentBeneficiary.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'beneOwner') {
      selectedBeneOwner.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'addlPpt') {
      selectedAddiParticipant.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'trust') {
      selectedTrustClient.trust.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'trustEe') {
      selectedTrustClient.trustEe.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'trustBene') {
      selectedTrustClient.trustBene.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    } else if (type === 'successorTrustee') {
      selectedTrustClient.successorTrustee.forEach((element: any) => {
        sum += element.percentageOfBenefits
          ? parseInt(element.percentageOfBenefits)
          : 0
      })
    }

    return parseInt(sum.toString())
  }

  useEffect(() => {
    if (newModelData) {
      if (
        newModelData?.wipAccountDetailslist &&
        newModelData?.wipAccountDetailslist[0]?.parties
      ) {
        const relTypeId =
          type === 'primary'
            ? 4
            : type === 'contingent'
              ? 5
              : type === 'trust'
                ? 11
                : type === 'trustEe'
                  ? 9
                  : type === 'trustBene'
                    ? 13
                    : type === 'successorTrustee'
                      ? 10
                      : type === 'addlPpt'
                        ? 6 || 8 || 12
                        : 7

        const beneficiary = newModelData?.wipAccountDetailslist[0]?.parties
          ?.filter((party: any) => {
            return party.relationshipTypeId === relTypeId
          })
          ?.map((p: any) => {
            const partyJson = JSON.parse(p?.partydetails)

            return {
              partyId: partyJson?.IndividualDetails?.partyId,
              name: partyJson?.IndividualDetails?.fullName,
              socialSecurityNumber:
                partyJson?.IndividualDetails?.socialSecurityNumber === ''
                  ? formatSSN(partyJson?.IndividualDetails?.taxIDNumber)
                  : formatSSN(
                    partyJson?.IndividualDetails?.socialSecurityNumber
                  ),
              dateOfBirth:
                formatDate(partyJson?.IndividualDetails?.dateOfBirth) ?? '',
              relationship: p?.relationshipToPrimaryOwner,
              percentageOfBenefits: p.benepercentage,
            }
          })

        const Beneficiaries = beneficiary && beneficiary.map((p: any) => p)

        const emptyBeneficiary = [
          {
            partyId: 0,
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: '',
            relationship: '',
            percentageOfBenefits: 0,
          },
        ]

        if (type === 'primary') {
          setSelectedPrimaryBeneficiary?.(
            Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary
          )
        } else if (type === 'contingent') {
          setSelectedContingentBeneficiary?.(
            Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary
          )
        } else if (type === 'beneOwner') {
          setSelectedBeneOwner?.(
            Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary
          )
        } else if (type === 'addlPpt') {
          setSelectedAddiParticipant?.(
            Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary
          )
        } else if (type === 'trust') {
          setSelectedTrustItems((prevTrustItems: any) => ({
            ...prevTrustItems,
            trust: Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary,
          }))
        } else if (type === 'trustEe') {
          setSelectedTrustItems((prevTrustItems: any) => ({
            ...prevTrustItems,
            trustEe:
              Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary,
          }))
        } else if (type === 'trustBene') {
          setSelectedTrustItems((prevTrustItems: any) => ({
            ...prevTrustItems,
            trustBene:
              Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary,
          }))
        } else if (type === 'successorTrustee') {
          setSelectedTrustItems((prevTrustItems: any) => ({
            ...prevTrustItems,
            successorTrustee:
              Beneficiaries.length > 0 ? Beneficiaries : emptyBeneficiary,
          }))
        }
      }
    }
  }, [newModelData])

  useEffect(() => {
    const calculateSumValue = calculateSum()

    type === 'primary' &&
      setSelectedDataTotal &&
      setSelectedDataTotal(calculateSumValue)
  }, [selectedPrimaryBeneficiary])

  useEffect(() => {
    const calculateSumValue = calculateSum()

    type === 'contingent' &&
      setSelectedContingentDataTotal &&
      setSelectedContingentDataTotal(calculateSumValue)
  }, [selectedContingentBeneficiary])

  useEffect(() => {
    const calculateSumValue = calculateSum()

    type === 'beneOwner' &&
      setBeneOwnerTotal &&
      setBeneOwnerTotal(calculateSumValue)
  }, [selectedBeneOwner])

  useEffect(() => {
    const calculateSumValue = calculateSum()

    type === 'addlPpt' &&
      setAddiParticipantTotal &&
      setAddiParticipantTotal(calculateSumValue)
  }, [selectedAddiParticipant])
  useEffect(() => {
    const calculateSumValue = calculateSum()

    if (type === 'trust') {
      setTrustTotal &&
        setTrustTotal((prevTrustTotal) => ({
          ...prevTrustTotal,
          trust: calculateSumValue,
        }))
    }

    if (type === 'trustEe') {
      setTrustTotal &&
        setTrustTotal((prevTrustTotal) => ({
          ...prevTrustTotal,
          trustEe: calculateSumValue,
        }))
    }

    if (type === 'trustBene') {
      setTrustTotal &&
        setTrustTotal((prevTrustTotal) => ({
          ...prevTrustTotal,
          trustBene: calculateSumValue,
        }))
    }

    if (type === 'successorTrustee') {
      setTrustTotal &&
        setTrustTotal((prevTrustTotal) => ({
          ...prevTrustTotal,
          successorTrustee: calculateSumValue,
        }))
    }
  }, [selectedTrustItems.trust,
  selectedTrustItems.successorTrustee,
  selectedTrustItems.trustBene,
  selectedTrustItems.trustEe])

  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel>
        <WipHeadingWrapper>
          <TitleGrouping
            id="red"
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            <SvgProfile />
            <WDLabelStyledCardHeader className="header_responsive">
              {header}
            </WDLabelStyledCardHeader>
          </TitleGrouping>

          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            {!toggleHeader && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            <AccFeatureWrapper>
              <AccountWrapper>
                <FormContainer id="FormContainer">
                  <RowWrapper>
                    {!readOnly && (
                      <SectionWrapper id={'client'}>
                        {openSearchClientPopup && (
                          <ClientSearchCard ref={wrapperRef}>
                            <ClientSearch
                              searchValue={searchData}
                              closeModalCallbackFn={toggleClientSearchModal}
                              isPrimaryBeneficiary={type === 'primary'}
                              isContingentBeneficiary={type === 'contingent'}
                              isBeneOwner={type === 'beneOwner'}
                              isAddiParticipant={type === 'addlPpt'}
                              isTrust={type === 'trust'}
                              isTrustEe={type === 'trustEe'}
                              isTrustBene={type === 'trustBene'}
                              isSuccessorTrust={type === 'successorTrust'}
                            />
                          </ClientSearchCard>
                        )}
                        <SocItemWrapper>
                          <FeatureInfoColumn>
                            <ClientSearchWrapperForBeneficiary>
                              <WDTextboxBorderRadius className="label_responsive">
                                <Textbox
                                  placeholder="Enter name"
                                  onChange={(value) => {
                                    setSearchData(value)
                                  }}
                                  value={searchData}
                                  onKeyDown={handleClientKeyPress}
                                />
                              </WDTextboxBorderRadius>
                              <StyledButtonDisabled>
                                <Button
                                  type={'button'}
                                  prefixedIcon={
                                    <SvgSearch
                                      fillColor={COLORS.Icons.Neutral}
                                    />
                                  }
                                  onClick={handleSearchClient}
                                />
                              </StyledButtonDisabled>
                            </ClientSearchWrapperForBeneficiary>
                          </FeatureInfoColumn>
                        </SocItemWrapper>
                      </SectionWrapper>
                    )}
                    <TableWrapper>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          zIndex: '99999 !important',
                        }}
                      >
                        {loader ? (
                          <SpinnerButtonWrapper>
                            <LottieButtonWrapper>
                              <Lottie animationData={Loader} loop={true} />
                            </LottieButtonWrapper>
                          </SpinnerButtonWrapper>
                        ) : (
                          <RetirementTable
                            readOnly={readOnly}
                            key={type}
                            tableColumns={tableColumns}
                            tableData={
                              type === 'primary'
                                ? selectedPrimaryBeneficiary
                                : type === 'contingent'
                                  ? selectedContingentBeneficiary
                                  : type === 'addlPpt'
                                    ? selectedAddiParticipant
                                    : type === 'trust'
                                      ? selectedTrustClient.trust
                                      : type === 'trustEee'
                                        ? selectedTrustClient.trustEe
                                        : type === 'trustBene'
                                          ? selectedTrustClient.trustBene
                                          : type === 'successorTrustee'
                                            ? selectedTrustClient.successorTrustee
                                            : selectedBeneOwner
                            }
                            onClick={() => ''}
                            handleNewValue={handleNewValue}
                            setSelectedData={(data) => {
                              if (type === 'primary') {
                                setSelectedPrimaryBeneficiary &&
                                  setSelectedPrimaryBeneficiary(data)
                              } else if (type === 'contingent') {
                                setSelectedContingentBeneficiary &&
                                  setSelectedContingentBeneficiary(data)
                              } else if (type === 'beneOwner') {
                                setSelectedBeneOwner &&
                                  setSelectedBeneOwner(data)
                              } else if (type === 'addlPpt') {
                                setSelectedAddiParticipant &&
                                  setSelectedAddiParticipant(data)
                              } else if (type === 'trust') {
                                setSelectedTrustItems &&
                                  setSelectedTrustItems(
                                    (prevTrustItems: any) => {
                                      return {
                                        ...prevTrustItems,
                                        trust: data,
                                      }
                                    }
                                  )
                              } else if (type === 'trustEe') {
                                setSelectedTrustItems &&
                                  setSelectedTrustItems(
                                    (prevTrustItems: any) => {
                                      return {
                                        ...prevTrustItems,
                                        trustEe: data,
                                      }
                                    }
                                  )
                              } else if (type === 'trustBene') {
                                setSelectedTrustItems &&
                                  setSelectedTrustItems(
                                    (prevTrustItems: any) => {
                                      return {
                                        ...prevTrustItems,
                                        trustBene: data,
                                      }
                                    }
                                  )
                              } else if (type === 'successorTrustee') {
                                setSelectedTrustItems &&
                                  setSelectedTrustItems(
                                    (prevTrustItems: any) => {
                                      return {
                                        ...prevTrustItems,
                                        successorTrustee: data,
                                      }
                                    }
                                  )
                              }
                            }}
                            type={type}
                            deleteSelectedRow={(row, participantId) => {
                              const accId =
                                newModelData?.wipAccountDetailslist[0]
                                  ?.wipacctid

                              deleteBeneficiary(
                                type === 'primary'
                                  ? 4
                                  : type === 'contingent'
                                    ? 5
                                    : type === 'addlPpt'
                                      ? participantId
                                      : type === 'trust'
                                        ? 11
                                        : type === 'trustEe'
                                          ? 9
                                          : type === 'trustBene'
                                            ? 13
                                            : type === 'successorTrust'
                                              ? 10
                                              : 7,
                                row?.original?.partyId,
                                accId,
                                type === 'primary'
                                  ? 4
                                  : type === 'contingent'
                                    ? 5
                                    : type === 'addlPpt'
                                      ? participantId
                                      : type === 'trust'
                                        ? 11
                                        : type === 'trustEe'
                                          ? 9
                                          : type === 'trustBene'
                                            ? 13
                                            : type === 'successorTrust'
                                              ? 10
                                              : 7
                              )
                                .then((res) => {
                                  if (res === 200) {
                                    setAPIPreviewStatus({
                                      status: 'success',
                                      text: `${header} deleted successfully`,
                                    })
                                    // const CardStatusValue = cardsStatus.filter(
                                    //   (card) => card !== 'trusted'
                                    // )
                                    // setCardsStatus(CardStatusValue)
                                    // updateCardsSaved(CardStatusValue)
                                  }
                                })
                                .catch(() => {
                                  setAPIPreviewStatus({
                                    status: 'error',
                                    text: 'Something went wrong, please try again',
                                  })
                                })
                              // popupConfirmation()
                            }}
                          />
                        )}
                        <div
                          style={{
                            backgroundColor: '#c8e1ff',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '0.5rem 0.2rem',
                            gap: '2rem',
                            alignItems: 'center',
                          }}
                        >
                          <WDLabel className="label_responsive">
                            {'Total :'}
                          </WDLabel>
                          <SocItemWrapper>
                            <WDTextboxBorderRadius className="inputBox_responsive">
                              {type === 'primary' && (
                                <Textbox
                                  disabled={readOnly}
                                  readOnly={true}
                                  value={
                                    selectedDataTotal
                                      ? selectedDataTotal?.toString()
                                      : '0'
                                  }
                                />
                              )}
                              {type === 'contingent' && (
                                <Textbox
                                  disabled={readOnly}
                                  readOnly={true}
                                  value={
                                    selectedDataContingentTotal
                                      ? selectedDataContingentTotal?.toString()
                                      : '0'
                                  }
                                />
                              )}
                              {type === 'beneOwner' && (
                                <Textbox
                                  disabled={readOnly}
                                  readOnly={true}
                                  value={
                                    beneOwnerTotal
                                      ? beneOwnerTotal?.toString()
                                      : '0'
                                  }
                                />
                              )}
                              {type === 'addlPpt' && (
                                <Textbox
                                  readOnly={true}
                                  value={
                                    addiParticipantTotal
                                      ? addiParticipantTotal?.toString()
                                      : '0'
                                  }
                                />
                              )}
                              {type === 'trust' && (
                                <Textbox
                                  readOnly={true}
                                  value={
                                    trustTotal?.trust
                                      ? trustTotal?.trust?.toString()
                                      : '0'
                                  }
                                />
                              )}

                              {type === 'trustEe' && (
                                <Textbox
                                  readOnly={true}
                                  value={
                                    trustTotal?.trustEe
                                      ? trustTotal?.trustEe?.toString()
                                      : '0'
                                  }
                                />
                              )}

                              {type === 'trustBene' && (
                                <Textbox
                                  readOnly={true}
                                  value={
                                    trustTotal?.trustBene
                                      ? trustTotal?.trustBene?.toString()
                                      : '0'
                                  }
                                />
                              )}

                              {type === 'successorTrustee' && (
                                <Textbox
                                  readOnly={true}
                                  value={
                                    trustTotal?.successorTrustee
                                      ? trustTotal?.successorTrustee?.toString()
                                      : '0'
                                  }
                                />
                              )}
                            </WDTextboxBorderRadius>
                            {!readOnly && <SvgBin fillColor="#c8e1ff" />}
                          </SocItemWrapper>
                        </div>

                        {(type === 'primary' &&
                          selectedDataTotal &&
                          selectedDataTotal > 100) ||
                          (type === 'contingent' &&
                            selectedDataContingentTotal &&
                            selectedDataContingentTotal > 100) ||
                          (type === 'beneOwner' &&
                            beneOwnerTotal &&
                            beneOwnerTotal > 100) ||
                          (type === 'addlPpt' &&
                            addiParticipantTotal &&
                            addiParticipantTotal > 100) ||
                          (type === 'trust' &&
                            trustTotal?.trust &&
                            trustTotal.trust > 100) ||
                          (type === 'trustEe' &&
                            trustTotal?.trustEe &&
                            trustTotal.trustEe > 100) ||
                          (type === 'trustBene' &&
                            trustTotal?.trustBene &&
                            trustTotal.trustBene > 100) ||
                          (type === 'successorTrustee' &&
                            trustTotal?.successorTrustee &&
                            trustTotal.successorTrustee > 100) ? (
                          <DangerText>
                            *The total percentage of benefits should not exceed
                            100%
                          </DangerText>
                        ) : (
                          ''
                        )}
                      </div>
                    </TableWrapper>
                  </RowWrapper>
                  {apiPreviewStatus.status !== '' && (
                    <ToastWrapper>
                      <Toast
                        text={apiPreviewStatus.text}
                        type={apiPreviewStatus.status}
                        openStatusCallback={(status: boolean) =>
                          setAPIPreviewStatus({
                            status: '',
                            text: '',
                          })
                        }
                      />
                    </ToastWrapper>
                  )}
                </FormContainer>
              </AccountWrapper>
            </AccFeatureWrapper>

            <FundingButtonWrapper>
              {!readOnly && (
                <WDButtonOutLine>
                  <Button
                    type={'button'}
                    onClick={() => {
                      updatePrimaryBeneficiary(
                        type === 'primary'
                          ? 4
                          : type === 'contingent'
                            ? 5
                            : type === 'addlPpt'
                              ? -1
                              : type === 'trust'
                                ? 11
                                : type === 'trustEe'
                                  ? 9
                                  : type === 'trustBene'
                                    ? 13
                                    : type === 'successorTrust'
                                      ? 10
                                      : 7,

                        type === 'primary'
                          ? selectedPrimaryBeneficiary
                          : type === 'contingent'
                            ? selectedContingentBeneficiary
                            : type === 'beneOwner'
                              ? selectedBeneOwner
                              : type === 'trust'
                                ? selectedTrustItems.trust
                                : type === 'trustEe'
                                  ? selectedTrustItems.trustEe
                                  : type === 'trustBene'
                                    ? selectedTrustItems.trustBene
                                    : type === 'successorTrustee'
                                      ? selectedTrustItems.successorTrustee
                                      : selectedAddiParticipant
                      )
                        .then((res) => {
                          if (res === 200) {
                            setAPIPreviewStatus({
                              status: 'success',
                              text: `${header} updated successfully`,
                            })
                            // const CardStatusValue = cardsStatus.filter(
                            //   (card) => card !== 'trusted'
                            // )
                            // setCardsStatus(CardStatusValue)
                            // updateCardsSaved(CardStatusValue)
                          }
                        })
                        .catch(() => {
                          setAPIPreviewStatus({
                            status: 'error',
                            text: 'Something went wrong, please try again',
                          })
                        })
                    }}
                  >
                    {/* {loader ? (
                      <SpinnerButtonWrapper>
                        <LottieButtonWrapper>
                          <Lottie animationData={Loader} loop={true} />
                        </LottieButtonWrapper>
                      </SpinnerButtonWrapper>
                    ) : ( */}
                    <WDLabelPrimaryText className="label_responsive">
                      {'Save'}
                    </WDLabelPrimaryText>
                    {/* )} */}
                  </Button>
                </WDButtonOutLine>
              )}
            </FundingButtonWrapper>
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default PrimaryBeneficiary
