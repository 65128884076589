import { AxiosResponse } from 'axios'
import Lottie from 'lottie-react'
import { RefObject, useContext, useEffect, useRef, useState } from 'react'
import Loader from '../../../assets/lottiefiles/loader.json'
import Button from '../../../components/button/Button'
import CheckBoxNew from '../../../components/checkbox/CheckBoxNew'
import Divider from '../../../components/divider/Divider'
import Label from '../../../components/label/Label'
import { SvgAdd } from '../../../components/svg/SvgAddIcon'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import SvgSearch from '../../../components/svg/SvgSearch'
import { SvgShield1 } from '../../../components/svg/SvgShield'
import { Textbox } from '../../../components/textbox/Textbox'
import { Toast } from '../../../components/toast/Toast'
import {
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelClient,
  WDLabelError,
  WDLabelPrimaryTextSmall,
  WDLabelSmall,
  WDLabelStyledCardHeader,
} from '../../../components/ui/WDLabel'
import { WDTextboxBorderRadius } from '../../../components/ui/WDTextbox'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import {
  getClientDetailsDataAPI,
  getTrustedContactDetails,
} from '../../../services/clientAndAccountSearch/ClientAndAccountSearchAPI'
import { GetTrustedContactResponseInterface } from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import { COLORS } from '../../../theme/Colors'
import { ClientSearch } from '../../clientSearch/ClientSearch'
import { LottieWrapper } from '../../interceptor/styles'
import { DividerWrapper } from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import { SpinnerWrapper } from '../accountForms/formIndividual/tabs/ProductFeatures/style'
import { StyledDocumentPanel } from '../accountInformation/styles'
import { LabelWrapper } from '../contactInfo/styles'
import { ToastWrapper } from '../formWip/styles'
import {
  AppInfoWrapper,
  CheckBoxWrapper,
  ChevronWrapper,
  ClientSearchCard,
  CodeRow,
  ContentWrapper,
  CountryRow,
  EditWrapper,
  FormFieldWrapper,
  IDInfoHalfRow,
  IDInfoRow,
  PersonalInfoWrapper,
  SearchWrapper,
  TitleGrouping,
  WipHeadingWrapper,
} from './styles'

const TrustedContact = (props: {
  pdfNodeRef2?: (pdfCanvasElement: HTMLCanvasElement) => HTMLCanvasElement
  isPdf?: boolean
}) => {
  const {
    wipId,
    readOnly,
    trustedContact,
    getWipResponse,
    setTrustedContact,
    selectedCheck,
    setSelectedCheck,
    isDashboardNavigate,
    relation,
    setRelation,
    trustedParty,
    setTrustedParty,
    setDetectChangeTc,
    trustedError,
    setTrustedError,
  } = useContext(ApplicationInfoContext)
  const wrapperRef = useRef(null)
  const [openSearchClientPopup, setOpenSearchClientPopup] =
    useState<boolean>(false)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [loaderIcon, setLoaderIcon] = useState(false)

  const [selectedAddr, setSelectedAddr] = useState({
    value: 'Domestic',
    label: 'Domestic',
  })

  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })

  const toggleClientSearchModal = (open: boolean) => {
    setOpenSearchClientPopup(open)
  }

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (!ref?.current?.contains(event.target as Node)) {
            setOpenSearchClientPopup(false)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)

  const getSelectedClient = (currentClient: { clientId: number }) => {
    setTrustedContact(currentClient.clientId)
    setRelation(relation)
  }

  useEffect(() => {
    if (relation.trim() == '') {
      setTrustedError({
        ...trustedError,
        relation: true,
      })
    } else {
      setTrustedError({
        ...trustedError,
        relation: false,
      })
    }
  }, [relation])

  useEffect(
    () => {
      if (trustedContact) {
        setLoaderIcon(true)
        getClientDetailsDataAPI({ partyId: trustedContact })
          .then((res) => {
            setLoaderIcon(false)
            setTrustedParty({ ...res.data })
            // progressVerify[0].validateCards[5] = {
            //   trustedContact: true,
            // }
            // setProgressVerify([...progressVerify])
          })
          .catch(() => setLoaderIcon(false))
      } else if (
        isDashboardNavigate &&
        getWipResponse.wipaccount &&
        getWipResponse.wipaccount.length > 0
      ) {
        setLoaderIcon(true)
        getTrustedContactDetails({
          wipid: parseInt(wipId),
        })
          .then((res: AxiosResponse<GetTrustedContactResponseInterface>) => {
            const getTrustData = JSON.parse(res.data.trustedcontactvalue)
            if (res.data.istrustedcontact) {
              setSelectedCheck(false)
            } else {
              setSelectedCheck(true)
            }
            if (!getTrustData || Object.keys(getTrustData).length === 0) {
              setTrustedParty({ ...JSON.parse(res.data.individualdata) })
            } else {
              setTrustedParty({ ...JSON.parse(res.data.trustedcontactvalue) })
            }
            setRelation(res.data.relationShip)
            setLoaderIcon(false)
          })
          .catch(() => {
            setLoaderIcon(false)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trustedContact, getWipResponse]
  )

  useEffect(() => {
    if (trustedParty) {
      if (trustedParty?.addressType?.value === 'Foreign') {
        setSelectedAddr({ value: 'Foreign', label: 'Foreign' })
      }
    }
  }, [trustedParty])

  return (
    // <Container ref={props.pdfNodeRef2}>
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel
        id={props.isPdf ? 'remove' : ''}
        ref={props.pdfNodeRef2}
      >
        <AppInfoWrapper>
          <WipHeadingWrapper
            onClick={() => {
              if (wipId) setToggleHeader(!toggleHeader)
            }}
          >
            <TitleGrouping
              onClick={() => {
                if (wipId) setToggleHeader(!toggleHeader)
              }}
            >
              <SvgShield1 />
              <WDLabelStyledCardHeader className="header_responsive">
                {'Trusted Contact Information'}
              </WDLabelStyledCardHeader>
            </TitleGrouping>
            {wipId && toggleHeader && !readOnly && (
              <EditWrapper>
                <SearchWrapper>
                  <WDButtonTransparent>
                    <Button
                      type={'button'}
                      disabled={selectedCheck}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        setOpenSearchClientPopup(true)
                        e.stopPropagation()
                        // setToggleHeader(!toggleHeader)
                      }}
                      prefixedIcon={
                        !selectedCheck ? (
                          <SvgSearch fillColor={COLORS.UI.Primary60} />
                        ) : (
                          <SvgAdd fillColor={COLORS.UI.Primary60} />
                        )
                      }
                    >
                      <WDLabelPrimaryTextSmall>
                        {!selectedCheck
                          ? 'Search Trusted Contact'
                          : 'Add Trusted Contact'}
                      </WDLabelPrimaryTextSmall>
                    </Button>
                  </WDButtonTransparent>
                </SearchWrapper>
              </EditWrapper>
            )}

            {wipId && !toggleHeader && !props.isPdf && (
              <ChevronWrapper
                onClick={() => {
                  if (wipId) setToggleHeader(!toggleHeader)
                }}
              >
                <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
              </ChevronWrapper>
            )}

            {wipId && toggleHeader && !props.isPdf && (
              <ChevronWrapper
                onClick={() => {
                  if (wipId) setToggleHeader(!toggleHeader)
                }}
              >
                <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
              </ChevronWrapper>
            )}
          </WipHeadingWrapper>
          {toggleHeader && (
            <DividerWrapper>
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Background.Neutral}`}
                thickness={'0.1rem'}
              />
            </DividerWrapper>
          )}
          {toggleHeader && (
            <ContentWrapper>
              {loaderIcon ? (
                <SpinnerWrapper>
                  <LottieWrapper>
                    <Lottie animationData={Loader} loop={true} />
                  </LottieWrapper>
                </SpinnerWrapper>
              ) : (
                <PersonalInfoWrapper>
                  {openSearchClientPopup && (
                    <ClientSearchCard ref={wrapperRef}>
                      <ClientSearch
                        trustedContact
                        closeModalCallbackFn={toggleClientSearchModal}
                        clientDetailsCallBackFn={(details) =>
                          getSelectedClient(details)
                        }
                      />
                    </ClientSearchCard>
                  )}
                  <FormFieldWrapper>
                    {readOnly ? (
                      <>
                        {selectedCheck && (
                          <CheckBoxWrapper className="container">
                            <WDLabelSmall className="label_responsive">
                              {
                                'Client has declined to provide details at this time'
                              }
                            </WDLabelSmall>
                          </CheckBoxWrapper>
                        )}
                      </>
                    ) : (
                      <CheckBoxWrapper className="container">
                        <CheckBoxNew
                          checked={selectedCheck}
                          onChange={() => {
                            setSelectedCheck(!selectedCheck)
                            setDetectChangeTc(true)
                            setTrustedParty({})
                            setRelation('')
                          }}
                          disabled={readOnly}
                        />
                        {/* <CheckMark className="checkmark"></CheckMark> */}
                        <WDLabelSmall className="label_responsive">
                          {
                            'Client has declined to provide details at this time'
                          }
                        </WDLabelSmall>
                      </CheckBoxWrapper>
                    )}
                    {!selectedCheck ? (
                      <>
                        <CountryRow>
                          <IDInfoRow>
                            <WDLabel className="label_responsive">
                              <Label>{'First Name'}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {trustedParty?.firstName
                                  ? trustedParty?.firstName
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </IDInfoRow>
                          <IDInfoRow>
                            <WDLabel className="label_responsive">
                              <Label>{'Middle Name'}</Label>
                            </WDLabel>

                            <WDLabelClient className="label_responsive">
                              <Label>
                                {trustedParty?.middleName
                                  ? trustedParty?.middleName
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </IDInfoRow>
                          <IDInfoRow>
                            <WDLabel className="label_responsive">
                              <Label>{'Last Name'}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {trustedParty.lastName
                                  ? trustedParty.lastName
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </IDInfoRow>
                        </CountryRow>

                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
                        <CountryRow>
                          <IDInfoRow>
                            <WDLabel className="label_responsive">
                              <Label>{'Address'}</Label>
                            </WDLabel>
                            <WDLabel className="label_responsive">
                              <Label>
                                {trustedParty?.addressType?.value
                                  ? trustedParty?.addressType?.value
                                  : '--'}
                              </Label>
                            </WDLabel>
                          </IDInfoRow>

                          {/* </div> */}
                        </CountryRow>
                        <CountryRow>
                          {selectedAddr.value === 'Domestic' && (
                            <IDInfoRow>
                              <WDLabel className="label_responsive">
                                <Label>{'Domestic Address'}</Label>
                              </WDLabel>
                              <WDLabelClient className="label_responsive">
                                <Label>
                                  {trustedParty.domesticAddress
                                    ? trustedParty.domesticAddress
                                    : '--'}
                                </Label>
                              </WDLabelClient>
                            </IDInfoRow>
                          )}
                          {selectedAddr.value === 'Foreign' && (
                            <IDInfoRow>
                              <WDLabel className="label_responsive">
                                <Label>{'Foreign Address'}</Label>
                              </WDLabel>

                              <WDLabelClient className="label_responsive">
                                <Label>
                                  {trustedParty?.foreignAddress
                                    ? trustedParty?.foreignAddress
                                    : '--'}
                                </Label>
                              </WDLabelClient>
                            </IDInfoRow>
                          )}
                        </CountryRow>
                        {selectedAddr.value === 'Domestic' && (
                          <>
                            <CountryRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'City'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic?.city
                                      ? trustedParty?.partyDemographic?.city
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'State'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic?.stateName
                                      ? trustedParty?.partyDemographic
                                          ?.stateName
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Zip'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic?.zipCode
                                      ? trustedParty?.partyDemographic?.zipCode
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                            </CountryRow>
                            <CountryRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Home Phone'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic?.homePhone
                                      ? trustedParty?.partyDemographic
                                          ?.homePhone
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Work Phone'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic
                                      ?.businessPhone
                                      ? trustedParty?.partyDemographic
                                          ?.businessPhone
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Mobile Phone'}</Label>
                                </WDLabel>

                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic
                                      ?.employerPhone
                                      ? trustedParty?.partyDemographic
                                          ?.employerPhone
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                            </CountryRow>
                            <CountryRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Email Address'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic
                                      ?.emailAddress
                                      ? trustedParty?.partyDemographic
                                          ?.emailAddress
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow id="relation">
                                <LabelWrapper>
                                  <WDLabel className="label_responsive">
                                    <Label>
                                      {'Relationship to Primary Applicant*'}
                                    </Label>
                                  </WDLabel>
                                  {trustedError.relation && !readOnly && (
                                    <WDLabelError>
                                      <Label>{'Required'}</Label>
                                    </WDLabelError>
                                  )}
                                </LabelWrapper>

                                <>
                                  {!readOnly ? (
                                    <WDTextboxBorderRadius className="inputBox_responsive">
                                      <Textbox
                                        padding={'0.25rem'}
                                        border={
                                          trustedError.relation
                                            ? COLORS.Text.Danger
                                            : ''
                                        }
                                        onChange={(value) => {
                                          setRelation(value)
                                          setDetectChangeTc(true)
                                        }}
                                        value={relation}
                                      />
                                    </WDTextboxBorderRadius>
                                  ) : (
                                    <WDLabelClient className="label_responsive">
                                      <Label>{relation ?? '--'}</Label>
                                    </WDLabelClient>
                                  )}
                                </>
                              </IDInfoRow>
                              {/* <IDInfoRow></IDInfoRow> */}
                            </CountryRow>
                          </>
                        )}

                        {selectedAddr.value === 'Foreign' && (
                          <>
                            <CountryRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'City'}</Label>
                                </WDLabel>

                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic?.city
                                      ? trustedParty?.partyDemographic?.city
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Country'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  {trustedParty.partyDemographic.countryName
                                    ? trustedParty.partyDemographic.countryName
                                    : '--'}
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Foreign Zip'}</Label>
                                </WDLabel>

                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic?.zipCode
                                      ? trustedParty?.partyDemographic?.zipCode
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                            </CountryRow>
                            <CountryRow>
                              <IDInfoRow>
                                <CodeRow>
                                  <IDInfoHalfRow>
                                    <WDLabel className="label_responsive">
                                      <Label>{'Home Phone'}</Label>
                                    </WDLabel>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {trustedParty?.partyDemographic
                                          ?.homePhone
                                          ? trustedParty?.partyDemographic
                                              ?.homePhone
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  </IDInfoHalfRow>
                                </CodeRow>
                              </IDInfoRow>
                              <IDInfoRow>
                                <CodeRow>
                                  <IDInfoHalfRow>
                                    <WDLabel className="label_responsive">
                                      <Label>{'Work Phone'}</Label>
                                    </WDLabel>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {trustedParty?.partyDemographic
                                          ?.businessPhone
                                          ? trustedParty?.partyDemographic
                                              ?.businessPhone
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  </IDInfoHalfRow>
                                </CodeRow>
                              </IDInfoRow>
                              <IDInfoRow>
                                <CodeRow>
                                  <IDInfoHalfRow>
                                    <WDLabel className="label_responsive">
                                      <Label>{'Mobile Phone'}</Label>
                                    </WDLabel>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {trustedParty.partyDemographic
                                          .employerPhone
                                          ? trustedParty.partyDemographic
                                              .employerPhone
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  </IDInfoHalfRow>
                                </CodeRow>
                              </IDInfoRow>
                            </CountryRow>
                            <CountryRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>{'Email Address'}</Label>
                                </WDLabel>
                                <WDLabelClient className="label_responsive">
                                  <Label>
                                    {trustedParty?.partyDemographic
                                      ?.emailAddress
                                      ? trustedParty?.partyDemographic
                                          ?.emailAddress
                                      : '--'}
                                  </Label>
                                </WDLabelClient>
                              </IDInfoRow>
                              <IDInfoRow>
                                <WDLabel className="label_responsive">
                                  <Label>
                                    {'Relationship to Primary Applicant'}
                                  </Label>
                                </WDLabel>
                                {getWipResponse?.wipDTO && (
                                  <>
                                    {!readOnly ? (
                                      <WDTextboxBorderRadius>
                                        <Textbox
                                          padding={'0.25rem'}
                                          onChange={(value) => {
                                            setRelation(value)
                                            setDetectChangeTc(true)
                                          }}
                                          value={relation}
                                        />
                                      </WDTextboxBorderRadius>
                                    ) : (
                                      <WDLabelClient className="label_responsive">
                                        <Label>{relation ?? '--'}</Label>
                                      </WDLabelClient>
                                    )}
                                  </>
                                )}
                              </IDInfoRow>
                            </CountryRow>
                          </>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </FormFieldWrapper>
                  {apiPreviewStatus.status !== '' && (
                    <ToastWrapper>
                      <Toast
                        text={apiPreviewStatus.text}
                        type={apiPreviewStatus.status}
                        openStatusCallback={() =>
                          setAPIPreviewStatus({
                            status: '',
                            text: '',
                          })
                        }
                      />
                    </ToastWrapper>
                  )}
                </PersonalInfoWrapper>
              )}

              {/* <ButtonWrapper>
             
                {accessList.trustedContact && !readOnly && (
                  <WDButtonOutLine>
                    <Button
                      type={'button'}
                      onClick={() => {
                        if (!selectedCheck && trustedError.relation) {
                          return true
                        }
                        setLoaderIcon(true)
                        updateTrustedContact()
                          .then((res) => {
                            if (res === 200) {
                              setAPIPreviewStatus({
                                status: 'success',
                                text: 'Trusted Contact updated successfully',
                              })
                              const CardStatusValue = cardsStatus.filter(
                                (card) => card !== 'trusted'
                              )
                              setCardsStatus(CardStatusValue)
                              updateCardsSaved(CardStatusValue)
                              setLoaderIcon(false)
                            }
                          })
                          .catch(() => {
                            setLoaderIcon(false)
                            setAPIPreviewStatus({
                              status: 'error',
                              text: 'Something went wrong, please try again',
                            })
                          })
                      }}
                   
                    >
                      {loaderIcon ? (
                        <SpinnerButtonWrapper>
                          <LottieButtonWrapper>
                            <Lottie animationData={Loader} loop={true} />
                          </LottieButtonWrapper>
                        </SpinnerButtonWrapper>
                      ) : (
                        <WDLabelPrimaryText className="label_responsive">
                          {'Save'}
                        </WDLabelPrimaryText>
                      )}
                    </Button>
                  </WDButtonOutLine>
                )}
              </ButtonWrapper> */}
            </ContentWrapper>
          )}
        </AppInfoWrapper>
      </StyledDocumentPanel>
    </ResponsiveWrapper>
    // </Container>
  )
}

export default TrustedContact
