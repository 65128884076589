import Select, {
  IndicatorsContainerProps,
  MultiValue,
  SingleValue,
} from 'react-select'
import { DefaultProps, SelectDropdownProps } from './ISelectDropdown'
import { Wrapper } from './styles'
import { SvgChevronDownMedium, SvgChevronUpMedium } from '../../svg/SvgChevron'
import { useState } from 'react'
import { COLORS } from '../../../theme/Colors'

const SelectDropdown = ({
  onChange,
  onBlur,
  defaultValue,
  value,
  isMulti,
  borderRadius,
  borderColor,
  options,
  isDisabled,
  isSearchable,
  indicatorSeparator,
  width,
  height,
  name,
  placeholder,
  innerRef,
  padding,
  indicatorColor,
  placeholderColor,
  singleValueColor,
  indicatorContainer,
  className,
  backgroundColor,
  border,
}: SelectDropdownProps) => {
  const handleChange = (
    newValue: MultiValue<DefaultProps> | SingleValue<DefaultProps>
  ) => {
    const inputValue = (newValue as DefaultProps).value.replace(/\W/g, '')
    onChange && onChange(inputValue)
  }

  const customStyles = {
    control: (provided: any) => {
      return {
        ...provided,
        width: width,
        height: height,
        minHeight: height,
        padding: padding,
        borderRadius: '0.5rem',
        borderColor: border ? COLORS.Text.Danger : borderColor,
        backgroundColor: backgroundColor,
        fontSize: '14px',
      }
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: placeholderColor,
        backgroundColor: backgroundColor,
        margin: '0.125rem 0rem',
        fontSize: '14px',
      }
    },
    singleValue: (provided: any) => {
      return {
        ...provided,
        color: singleValueColor,
      }
    },
    indicatorContainer: (provided: IndicatorsContainerProps) => {
      return {
        ...provided,
        color: indicatorContainer,
      }
    },
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: indicatorColor, // Custom colour
      padding: '0.25rem',
    }),
  }
  const [toggleIcon, setToggleIcon] = useState(true)
  return (
    <>
      {!indicatorSeparator ? (
        <Wrapper
          width={width}
          onFocus={() => setToggleIcon(!toggleIcon)}
          onBlur={() => setToggleIcon(true)}
        >
          <Select
            options={options}
            onChange={handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () =>
                toggleIcon ? (
                  <SvgChevronDownMedium fillColor={COLORS.Icons.Neutral} />
                ) : (
                  <SvgChevronUpMedium fillColor={COLORS.Icons.Neutral} />
                ),
            }}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            className={className}
            classNamePrefix="dropdown"
          />
        </Wrapper>
      ) : (
        <Wrapper width={width}>
          <Select
            options={options}
            onChange={handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            className={className}
          />
        </Wrapper>
      )}
    </>
  )
}

export default SelectDropdown
