// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
// import "@cyntler/react-doc-viewer/dist/index.css";
import { MainWrapper } from './styles'
import {
  Worker,
  Viewer,
  DocumentLoadEvent,
  SpecialZoomLevel,
} from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Wrapper } from './styles'
import { useEffect, useState } from 'react'

interface PreviewPayload {
  blob: Blob
  fileName: string
  fileType: string
  fileURL: string
}

export const PreviewDocumentPage = ({
  blob,
  fileName,
  fileType,
  fileURL,
}: PreviewPayload) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  })

  const handleDocumentLoad = (e: DocumentLoadEvent) => {
    e.file.name = fileName ? fileName : e.file.name
  }

  const [documentUrl, setDocumentUrl] = useState<string>('')

  useEffect(() => {
    const setImage = async () => {
      if (blob) {
        const reader = new FileReader()
        await reader.readAsDataURL(blob)
        reader.onload = async () => {
          await setDocumentUrl(reader.result as string)
        }
        reader.onerror = () => {
          throw new Error('Fail to load the file')
        }
      }
    }
    setImage()
  }, [])

  return (
    <MainWrapper>
      {fileType !== 'application/pdf' ? (
        <Wrapper>
          <DocViewer
            prefetchMethod="GET"
            className="viewer"
            documents={[
              {
                uri: documentUrl || fileURL,
                fileType: fileType,
                fileName,
              },
            ]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: false,
                disableFileName: false,
                retainURLParams: false,
              },
              csvDelimiter: ',', // "," as default,
              pdfZoom: {
                defaultZoom: 1.1, // 1 as default,
                zoomJump: 0.2, // 0.1 as default,
              },
              pdfVerticalScrollByDefault: true, // false as default
            }}
          />
        </Wrapper>
      ) : (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
          <Wrapper>
            <Viewer
              fileUrl={fileURL}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={SpecialZoomLevel.ActualSize}
              theme="dark"
              onDocumentLoad={handleDocumentLoad}
            />
          </Wrapper>
        </Worker>
      )}
    </MainWrapper>
  )
}

export default PreviewDocumentPage
