export const NoToUpdate = {
  NotDirty: 'There are no changes to be saved',
  UnsavedChangesPopUp:
    'There are some unsaved changes. Do you wish to continue?',
}

export const GeneralValidation =
  'The required information is missing, Please Review the form before save.'

export const ErrorMessage = {
  //wip details fields
  WipPartyName: 'Party Name is Required.',
  WipRepCode: 'RepCode is Required.',
  WipAccounttype: 'Please select an account type.',
  WipAccountitle: 'Account Title should be limited to 200 characters.',
  WipAccountRepeat: 'Account cannot be Duplicated.',
  NoWip: 'No wip found',
  WipApplicationNickName: 'Application nickname is required.',
  WipApplicationCharLimit: 'Only 100 Characters allowed.',

  //response
  RecordNotUpdateMsg: 'Failed to update the record.',
  RecordNotCreatedMsg: 'Failed to create the record',
}
export const SearchWipMessage = {
  IsNumerix: 'Wip Id must be a number',
}
export const SearchResNoMessage = {
  IsMaxNine: 'Invalid account number',
}
export const AccountFeatureErrorMessage = {
  DYNAMIC_ALL_REQUIRED_FIELD: 'Please enter the required field.',
  INVALIDPERCENTAGE: 'Percentage should be between 0 and 100',
  //Credit Plus
  ISCREDPLUSACC: 'Credit plus is required',
  CHECKWRITINGACC: 'Please select check writing account dropdown',
  WDBDEBITCARD: 'Please select Wedbush debit card dropdown',
  //FDIC Sweep
  ISFDICSWEEP: 'FDIC Sweep is required',
  //ishousehold
  ISHOUSEHOLD: 'Household is required',
  HOUSEACCNUM: 'Account Number is required & Must be 8 characters',
  //Managed Asset Account
  ISMANAGEDASSETACC: 'Managed Asset Account is required',
  ELIGIBLEASSETVAL: 'Eligible Asset Value is required',
  CHARGEACC: 'Charge Account is required & Must be 8 characters',
  PROGTYPE: 'Program Type is required',
  EXCLUDEASSET: 'Please fill all textboxes or delete the unused ones',
  TOTALFEE: 'Manager Fee% + Advisory Fee% must be equal 100%',
  //Option Account
  ISOPTIONACC: 'Option Account is required',
  OPTIONLEVEL: 'Option Level is required',
  OPTIONSINVESTMENTEXPERIENCEREQ:
    'All fields in Options Investment Experience are required',
  OPTIONSINVESTMENTKNOWLEDGEREQ:
    'All fields in Options Investment Knowledge are required',
  //Margin Account
  ISMARGINACC: 'Margin Account is required',
  RESNOREQUIRED: 'Reserved Account no is required',
}
export const TrustedContactErrorMessage = {
  //trusted contact fields
  RelationshipType: 'Relation type required.',
  TrustedDecline: 'Trusted contact decline,',
}
export const SuccessMessage = {
  AddCoountSuceessMsg: 'New Payee account created Successfully. New token is: ',
  AddACHTransferSuccessMsg: 'Your transfer request is submitted.',

  //api response don't change this msg
  RecordUpdateMsg: 'Record successfully updated',
  RecordCreatedMsg: 'Record created successfully',
  DisclosureAddMessage: 'Disclosure acknowledged',
}

export const FundingErrorMessage = {
  //wip details fields
  transferamount: 'Transfer Amount is required.',
  depositaccountnumber: 'Deposit Account Number is required.',
  banknumber: 'Bank Number is required.',
  transfertype: 'Please select a transfer type',
  recurring: 'Please select a recurring frequency',
  recurringyn: 'Please Select a recurring',
  RoutingDigitValidate: 'Only numbers are allowed.',
  RoutingDepositLengthValidate:
    'Deposit Account Number should be a maximum of 20 digits.',
  RoutingBankLengthValidate:
    'Bank Number should should be a maximum of 20 digits.',
  isTwoNumafterDecimal:
    'Transfer Amount value should have a maximum of 2 digits after decimal point.',
  successaddmessage: 'Record successfully added.',
  failureaddmessage: 'Failed to add the record.',
  successupdatemessage: 'Record successfully updated.',
  failureupdatemessage: 'Failed to update the record.',
  RemoveRecurring: 'Recurring is not required.',
}

export const InvProfileMsg = {
  Income: 'Emphasis on investments that generate income.',
  Growth:
    'Emphasis on investments more likely to appreciate in principal rather than generate income.',
  Speculation:
    'Emphasis on potential for significant appreciation; willing to accept a high risk for loss of principal.',
  Trading:
    'Seeks to take advantage of short term trading opportunities. High turnover, high risk.',
  AnnualIncomeReq: 'Annual Income is required',
  LiquidNetWorthReq: 'Liquid Net Worth is required',
  TotalNetWorthReq: 'Total Net Worth is required',
  TaxBracketReq: 'Tax Bracket is required',
  PercentageValidation: 'Percentage cannot be greater than 100',
  AllInvObjectiveReq: 'All fields in Investment Objective section are required',
  RiskTolarenceReq: 'Risk tolerance is required',
  IlliquidityNeedsReq: 'Please select illiquid investments dropdown',
  CoveredWritingReq: 'Covered Writing is required',
  PurchasingCallsandPutsReq: 'Purchasing Calls and Puts is required',
  SpreadsPutsandCallsReq: 'Spreads (Puts and Calls) is required',
  UncoveredPutWritingReq: 'Uncovered Put Writing is required',
  UncoveredCallWritingReq: 'Uncovered Call Writing is required',
  UncoveredIndexReq: 'Uncovered Index is required',
  OptionsInvestmentExperienceReq:
    'All fields in Options Investment Experience are required',
  OptionsInvestmentKnowledgeReq:
    'All fields in Options Investment Knowledge are required',
  InvestmentExpreienceReq: 'All fields in Investment Experience are required',
  SourceOfFundReq: 'Please select atleast one Source Of Fund',
  InvestmentKnowledgeReq: 'Investment Knowledge is required',
  InvestmentAllocationReq: 'Investment Allocation is required',
  InvestmentTimeHorizonReq: 'Investment Time Horizon is required',
  DividendsandInterestPaymentsReq:
    'Dividends and Interest Payments is required field',
  Rule14bReq: 'Please select value for disclosure rule 14b-(c)',
  LiquidityNeedsReq: 'Please fill the Liquidity Needs Section',
  ProfileUpdatedSuccess: 'Profile Updated Successfully',
  ProfilesavedSuccess: 'Profile Saved Successfully',
  ProfileUpdatedFail: 'Something went wrong while updating.',
  ProfilesavedFail: 'Something went wrong while saving.',
  PriorityShouldNotRepeat: `Priority cannot be same for multiple fields in the Investment objective.`,
}

export const SearchwipidErrorMessage = {
  isNumeric: 'Wipid should  be numeric.',
}

export const FormRolesMessage = {
  RoleAdded: 'Role added Successfully',
  RoleUpdated: 'Role updated Successfully',
  RoleDeleted: 'Role deleted Successfully',
  RoleAddFail: 'Something went wrong while adding the role',
  RoleUpdateFail: 'Something went wrong while updating the role',
  RoleDeleteFail: 'Something went wrong while deleting the role',
  RoleIsRequired: 'Please select a role before submitting',
  ClientNameIsRequired: `Client name cannot be empty`,
  DeleteRoleConfirm: `Are you sure you want to DELETE this role?`,
}

export const FormValidtionMessage = {
  SoftValidationMsg:
    'There are required fields missing, Do you wish to continue?',
}
export const RegularExpression = {
  isTwodigitNumafterDecimal: /^(?!$)\d{0,10}(?:\.\d{1,2})?$/,
  isnumeric: /^[0-9]*$/,
}
export const PartyInfo = {
  PartyNotFound: `No record found for this party`,
}

export const ElectronicPreferenceErrorMessage = {
  //wip details fields
  successaddmessage: 'Record successfully added.',
  failureaddmessage: 'Failed to add the record.',
  successupdatemessage: 'Record successfully updated.',
  failureupdatemessage: 'Failed to update the record.',
}
