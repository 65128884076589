import React, { useContext, useEffect } from 'react'
import Label from '../../components/label/Label'
import { WDLabelContentHeader } from '../../components/ui/WDLabel'
import {
  ContentHeaderWrapper,
  Header,
  StyledHead,
} from './styles'
import { ResponsiveWrapper } from '../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'

const ContentHeader = ({ source }: any) => {
  const { wipId, setWipId, newModelData } = useContext(ApplicationInfoContext)

  useEffect(() => {
    if (!wipId) {
      setWipId(wipId)
    }
  }, [setWipId, wipId])

  return wipId && source !== 'new' ? (
    <Header>
      <StyledHead>
        <Label>{`WIP ID: ${wipId}`}</Label>
        <Label>{'|'}</Label>
        <Label>
          {newModelData?.wipDetails?.applicationnickname
            ? `${newModelData?.wipDetails?.applicationnickname}'s Account`
            : ''}
        </Label>
      </StyledHead>
    </Header>
  ) : (
    <ResponsiveWrapper id="common">
      {source == 'new' && !wipId && (
        <ContentHeaderWrapper>
          <Header>
            <WDLabelContentHeader className="header_responsive">
              <Label>{'New Account Application'}</Label>
            </WDLabelContentHeader>
          </Header>
        </ContentHeaderWrapper>
      )}
    </ResponsiveWrapper>
  )
}

export default ContentHeader
