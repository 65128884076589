import { useContext, useState } from 'react'
import Button from '../../../../../components/button/Button'
import SvgClose from '../../../../../components/svg/SvgClose'
import {
  WDButton,
  WDButtonGrayTransparent,
  WDButtonOutLine,
} from '../../../../../components/ui/WDButton'
import {
  WDLabelBold,
  WDLabelPrimaryText,
} from '../../../../../components/ui/WDLabel'
import { AccountInfoBtnWrapper } from '../../../../../pages/accountApplicationPage/styles'
import { COLORS } from '../../../../../theme/Colors'
import {
  HeaderWrapper,
  ModalWrapper,
  OverlayWrapper,
} from './styles'
import { TitleGrouping } from '../../styles'
import Divider from '../../../../../components/divider/Divider'
import { DividerWrapper } from '../../../formWip/styles'
import { Textarea } from '../../../../../components/extra/textArea/Textarea'
import ToastContext from '../../../../../services/toast/ToastContext'

export interface PopUpProps {
  setRejectAccount: (value: boolean) => void
  
}
const RejectAccount = ({ setRejectAccount }: PopUpProps) => {
  const [rejMessage, setRejMessage] = useState('')
  const [error, setError] = useState<boolean>(false)
  const { setToast } = useContext(ToastContext)

  const handleMessage = (e: string) => {
    setRejMessage(e)
    setError(false)
  }
  const handleSubmit = () => {
    if (rejMessage.trim() === '') {
      setError(true)
    } else {
      setRejectAccount(false)
      setToast('success', "Account Rejected Successfully")
    }
  }

  return (
    <>
      <OverlayWrapper>
        <ModalWrapper>
          <HeaderWrapper>
            <TitleGrouping>
              <WDLabelBold>{'Reject Account'}</WDLabelBold>
            </TitleGrouping>
            <WDButtonGrayTransparent>
              <Button
                type={'button'}
                onClick={() => {
                  setRejectAccount(false)
                  setError(false)
                }}
              >
                <SvgClose fillColor={COLORS.Icons.Neutral} />
              </Button>
            </WDButtonGrayTransparent>
          </HeaderWrapper>
          <DividerWrapper>
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Gray50}`}
              thickness={'0.0625rem'}
            />
          </DividerWrapper>
          <Textarea
            onChange={handleMessage}
            value={rejMessage}
            placeholder={'Please enter here'}
            required
            className={error ? 'error' : ''}
          ></Textarea>
          <AccountInfoBtnWrapper>
            <WDButton>
              <WDButtonOutLine>
                <Button
                  type={'button'}
                  onClick={() => {
                    setRejectAccount(false)
                    setError(false)
                  }}
                >
                  <WDLabelPrimaryText>{'Cancel'}</WDLabelPrimaryText>
                </Button>
              </WDButtonOutLine>
            </WDButton>
            <WDButton>
              <Button type="button" onClick={handleSubmit}>
                {'Submit'}
              </Button>
            </WDButton>
          </AccountInfoBtnWrapper>
        </ModalWrapper>
      </OverlayWrapper>
    </>
  )
}
export default RejectAccount