import { useRef } from 'react'
import { CheckboxProps } from './ICheckbox'
import { CheckBoxWrapper, CheckboxInput, StyledLabel } from './styles'

const CheckBoxNew = ({
  title,
  checkBoxId,
  disabled,
  checked,
  indeterminate,
  value,
  onChange,
  required,
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  return (
    <div>
      <CheckBoxWrapper className="custom-checkbox">
        <CheckboxInput
          type={'checkbox'}
          ref={checkboxRef}
          value={value}
          id={checkBoxId}
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          required={required}
        />
        <span className="checkmark"></span>
        {title && <StyledLabel  className="label_responsive" htmlFor={checkBoxId}>{title}</StyledLabel>}
      </CheckBoxWrapper>
    </div>
  )
}

export default CheckBoxNew
