export const getXrefDatabyDomainCode = (response: any, code: string) => {
  const res = response?.data
  const xrefdData = []
  for (const prop in res) {
    if (res[prop].domncode == code) {
      xrefdData.push(res[prop])
    }
  }
  return xrefdData
}
