import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  height: fit-content;
  //overflow-y: auto;
  padding: 1rem;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const SectionHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`
export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 0px;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`
export const FieldWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
export const LabelWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
`
export const ValueWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 50%;
  align-items: flex-start;
  justify-content: flex-start;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  flex: 0 1 10%;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: ${COLORS.UI.Black};
  top: 5rem;
  right: 2rem;
  z-index: 1000;
`
export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 1 90%;
  padding: 0.5rem 0.25rem 0;
`
export const WipHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  //gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`
export const HeadingTitleWrapper = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const ClientMainWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
export const ClientNameWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  #client_name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #475569;
  }
  #client_value {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #0f172a;
  }
`
export const SearchButtonWrapper = styled.div`
  button {
    top: 1px;
    position: relative;
    color: #2563eb;
  }
`
export const RepCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  gap: 8px;
  .labelWrapper {
    width: 40%;
  }
  .inputWrapper {
    input {
      width: 225px;
    }
    min-width: 225px;
  }
`
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  gap: 0.5rem;
  padding: 0 1.5rem;
  @media screen and (min-width: 1440px) {
    gap: 30px;
  }
`
export const ItemsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100%;
  gap: 4px;
  @media screen and (min-width: 1440px) {
    gap: 12px;
  }
`
export const ItemsWrapper = styled.div`
  display: flex;
  flex: 100%;
  gap: 4px;
`
export const ItemListChildWrapper = styled.div`
  display: flex;
  flex: 50%;
  align-items: center;
  justify-content: space-between;
`
export const GroupListItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &#productinfo-gap {
    gap: 0.5rem;
    @media screen and (min-width: 1440px) {
      gap: 30px;
    }
  }
`
export const TitleGrouping = styled.div`
  display: flex;
  align-items: center;
  flex: 80%;
  gap: 0.5rem;
`
export const LogoWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-end;
`
export const Header = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-between;
  align-items: center;
`
export const StyledHead = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  flex: 0 1 100%;
`
export const NoteWrapper1 = styled.div`
  font-size: 10px;
  font-weight: 300;
  line-height: 10px;
  text-align: left;
`
