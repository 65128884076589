import styled from 'styled-components'
import { ProgressStateStyleProps } from './IProgressBar'
import { COLORS } from '../../theme/Colors'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const StageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`
export const StageNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  /* border: ${(ProgressStateStyleProps: ProgressStateStyleProps) =>
    ProgressStateStyleProps.isActive
      ? `2px solid ${COLORS.UI.Brand2}`
      : `2px solid ${COLORS.Background.Gray50}`};
  background: ${(ProgressStateStyleProps: ProgressStateStyleProps) =>
    ProgressStateStyleProps.isActive
      ? `${COLORS.UI.Brand2}`
      : `${COLORS.Background.Gray50}`}; */
  color: ${(ProgressStateStyleProps: ProgressStateStyleProps) =>
    ProgressStateStyleProps.isActive
      ? '#ffffff'
      : `${COLORS.Background.Gray50}`};
  @media screen and (min-width: 1440px) {
    height: 35px;
    width: 35px;
  }
  @media screen and ((min-width: 1280px) and (max-width:1440px)) {
    height: 30px;
    width: 30px;
  }
`
export const StageNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  &.disable-btn {
    cursor: not-allowed;
    pointer-events: none;
  }
`
export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 1 18%;
  width: 100%;
`
export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`
export const Progressbar = styled.ul`
  counter-reset: step;
  display: flex;
  gap: 3.5rem;
  flex: 0 1 100%;
  width: 100%;
  padding: 0 1rem;
  justify-content: center;
  &.li {
    list-style: none;
    display: inline-block;
    width: 30.33%;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .li::before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
  }
  .li::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
  }
`
export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  /* @media screen and (min-width: 1440px) {
    width: 150px;
  }
  @media (min-width: 1280px) and (min-width: 1440px) {
    width: 120px;
  }
  @media (min-width: 1024px) and (min-width: 1280px) {
    width: 120px;
  } */
`
