import { Dispatch, ReactNode, createContext, useMemo, useState } from 'react'
import {
  GetApprovalRequest,
  GetApprovalResponse,
  GetAuditTrialsResponse,
  GetCardsResponseInterface,
  GetCommentResponse,
  UpdateApprovalResponse,
  UpdateApprovalsRequest,
  UpdateCardsRequestInterface,
  UpdateCommentsRequest,
  UpdateCommentsResponse,
} from './IApproval'
import {
  getApprovalsAPI,
  getAuditTrialsAPI,
  getCardsAPI,
  getCommentsAPI,
  updateApprovalAPI,
  updateCardsAPI,
  updateCommentsAPI,
} from './ApprovalAPI'

interface GetApprovalFn {
  (payload: GetApprovalRequest): any
}
interface GetCommentsFn {
  (payload: GetApprovalRequest): any
}
interface GetAuditTrialsFn {
  (payload: GetApprovalRequest): any
}
interface UpdateCommentsFn {
  (payload: UpdateCommentsRequest): any
}
interface UpdateApprovalsFn {
  (payload: UpdateApprovalsRequest): Promise<UpdateApprovalsRequest>
}
interface GetCardsStatusFn {
  (payload: GetApprovalRequest): Promise<GetCardsResponseInterface>
}
interface UpdateCardsStatusFn {
  (payload: UpdateCardsRequestInterface): Promise<
    GetCardsResponseInterface | undefined
  >
}
interface ContextProps {
  children?: ReactNode
}
export const ApprovalContext = createContext<ApprovalInterface>(
  {} as ApprovalInterface
)
export interface ApprovalInterface {
  approvals: GetApprovalResponse[]
  setApprovals: Dispatch<React.SetStateAction<GetApprovalResponse[]>>
  auditTrials: GetAuditTrialsResponse[]
  setAuditTrials: Dispatch<React.SetStateAction<GetAuditTrialsResponse[]>>
  comments: GetCommentResponse[]
  setComments: Dispatch<React.SetStateAction<GetCommentResponse[]>>
  updateComments: UpdateCommentsResponse[]
  setUpdateComments: Dispatch<React.SetStateAction<UpdateCommentsResponse[]>>
  updateApproval: UpdateApprovalResponse[]
  setUpdateApproval: Dispatch<React.SetStateAction<UpdateApprovalResponse[]>>
  getApproval: GetApprovalFn
  getAuditTrials: GetAuditTrialsFn
  getComments: GetCommentsFn
  updateComment: UpdateCommentsFn
  updateApprovals: UpdateApprovalsFn
  tableLoader: boolean
  setTableLoader: Dispatch<React.SetStateAction<boolean>>
  isRecalled: boolean
  setIsRecalled: Dispatch<React.SetStateAction<boolean>>
  showComment: boolean
  setShowComment: Dispatch<React.SetStateAction<boolean>>
  commentButton: string
  setCommentButton: Dispatch<React.SetStateAction<string>>
  cardsStatus: string[]
  setCardsStatus: Dispatch<React.SetStateAction<string[]>>
  getCardsStatus: GetCardsStatusFn
  updateCardsStatus: UpdateCardsStatusFn
}
export const ApprovalContextProvider = ({ children }: ContextProps) => {
  const [apiError, setApiError] = useState<object | null | string>(null)
  const [tableLoader, setTableLoader] = useState(false)
  const [isRecalled, setIsRecalled] = useState(false)
  const [cardsStatus, setCardsStatus] = useState<string[]>([])
  const [showComment, setShowComment] = useState(false)
  const [commentButton, setCommentButton] = useState('')
  const [approvals, setApprovals] = useState<GetApprovalResponse[]>(
    [] as GetApprovalResponse[]
  )
  const [auditTrials, setAuditTrials] = useState<GetAuditTrialsResponse[]>(
    [] as GetAuditTrialsResponse[]
  )
  const [comments, setComments] = useState<GetCommentResponse[]>(
    [] as GetCommentResponse[]
  )
  const [updateApproval, setUpdateApproval] = useState<
    UpdateApprovalResponse[]
  >([] as UpdateApprovalResponse[])
  const [updateComments, setUpdateComments] = useState<
    UpdateCommentsResponse[]
  >([] as UpdateCommentsResponse[])

  const getApproval = async function (payload: GetApprovalRequest) {
    try {
      setTableLoader(true)
      const response = await getApprovalsAPI(payload)
      setApprovals(response.data)
      setTableLoader(false)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }

  const getAuditTrials = async function (payload: GetApprovalRequest) {
    try {
      setTableLoader(true)
      const response = await getAuditTrialsAPI(payload)
      setAuditTrials(response.data)
      setTableLoader(false)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }
  const getComments = async function (payload: GetApprovalRequest) {
    try {
      setTableLoader(true)
      const response = await getCommentsAPI(payload)
      setComments(response.data)
      setTableLoader(false)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }
  const updateApprovals = async function (payload: UpdateApprovalsRequest) {
    try {
      const response = await updateApprovalAPI(payload)
      setUpdateApproval(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }
  const updateComment = async function (payload: UpdateCommentsRequest) {
    try {
      const response = await updateCommentsAPI(payload)
      setUpdateComments(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }

  const getCardsStatus = async function (payload: GetApprovalRequest) {
    try {
      const response = await getCardsAPI(payload)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }

  const updateCardsStatus = async function (
    payload: UpdateCardsRequestInterface
  ) {
    try {
      const response = await updateCardsAPI(payload)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }

  const values = useMemo(
    () => ({
      apiError,
      approvals,
      getApproval,
      auditTrials,
      getAuditTrials,
      getComments,
      comments,
      updateComments,
      setUpdateComments,
      updateApprovals,
      updateComment,
      updateApproval,
      setUpdateApproval,
      setApprovals,
      setAuditTrials,
      setComments,
      tableLoader,
      setTableLoader,
      showComment,
      setShowComment,
      commentButton,
      setCommentButton,
      isRecalled,
      setIsRecalled,
      cardsStatus,
      setCardsStatus,
      getCardsStatus,
      updateCardsStatus,
    }),
    [
      apiError,
      approvals,
      auditTrials,
      comments,
      updateComments,
      updateApproval,
      tableLoader,
      showComment,
      commentButton,
      isRecalled,
      cardsStatus,
    ]
  )
  return (
    <ApprovalContext.Provider value={values}>
      {children}
    </ApprovalContext.Provider>
  )
}
