import { ISvg } from './ISvg'

const SvgClose = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93996 5.00077L8.80662 2.14077C8.93216 2.01524 9.00269 1.84498 9.00269 1.66744C9.00269 1.48991 8.93216 1.31964 8.80662 1.19411C8.68109 1.06857 8.51083 0.998047 8.33329 0.998047C8.15576 0.998047 7.9855 1.06857 7.85996 1.19411L4.99996 4.06077L2.13996 1.19411C2.01442 1.06857 1.84416 0.998047 1.66663 0.998047C1.48909 0.998047 1.31883 1.06857 1.19329 1.19411C1.06776 1.31964 0.997231 1.48991 0.997231 1.66744C0.997231 1.84498 1.06776 2.01524 1.19329 2.14077L4.05996 5.00077L1.19329 7.86077C1.13081 7.92275 1.08121 7.99648 1.04737 8.07772C1.01352 8.15896 0.996094 8.2461 0.996094 8.33411C0.996094 8.42211 1.01352 8.50925 1.04737 8.59049C1.08121 8.67173 1.13081 8.74546 1.19329 8.80744C1.25527 8.86993 1.329 8.91952 1.41024 8.95337C1.49148 8.98721 1.57862 9.00464 1.66663 9.00464C1.75463 9.00464 1.84177 8.98721 1.92301 8.95337C2.00425 8.91952 2.07798 8.86993 2.13996 8.80744L4.99996 5.94077L7.85996 8.80744C7.92193 8.86993 7.99567 8.91952 8.07691 8.95337C8.15815 8.98721 8.24528 9.00464 8.33329 9.00464C8.4213 9.00464 8.50844 8.98721 8.58968 8.95337C8.67092 8.91952 8.74465 8.86993 8.80662 8.80744C8.86911 8.74546 8.91871 8.67173 8.95255 8.59049C8.9864 8.50925 9.00382 8.42211 9.00382 8.33411C9.00382 8.2461 8.9864 8.15896 8.95255 8.07772C8.91871 7.99648 8.86911 7.92275 8.80662 7.86077L5.93996 5.00077Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SvgClose
