import { useContext, useEffect } from 'react'
import Table from '../../../../../components/table/Table'
import { TableHistoryWrapper } from '../../styles'
import { LottieWrapper } from '../../../../interceptor/styles'
import Lottie from 'lottie-react'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { ApprovalContext } from '../../../../../services/approvalScreenContext/ApprovalContext'
import { Data } from '../../../../../components/table/ITable'
import { ApplicationInfoContext } from '../../../../../services/applicationContext/ApplicationPageContext'

const AuditTrails = () => {
  const { auditTrials, tableLoader, getAuditTrials } =
    useContext(ApprovalContext)
  const { wipId } = useContext(ApplicationInfoContext)
  useEffect(() => {
    if (!wipId) return
    getAuditTrials({ wipid: Number(wipId) })
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wipId])

  const tableColumns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'User',
      accessor: 'user',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
  ]

  // const tableData = [
  //   {
  //     role: 'Front Officer',
  //     user: 'Add',
  //     action: 'submitted',
  //     date: '08/02/2022 04:30am',
  //   },
  //   {
  //     role: 'Front Officer',
  //     user: 'Add',
  //     action: 'submitted',
  //     date: '08/02/2022 04:30am',
  //   },
  //   {
  //     role: 'Front Officer',
  //     user: 'Add',
  //     action: 'submitted on 12/2/2023',
  //     date: '08/02/2022 04:30am',
  //   },
  // ]

  return (
    <TableHistoryWrapper className={tableLoader ? 'table_loader' : ''}>
      {!tableLoader ? (
        <Table
          tableColumns={tableColumns}
          tableData={auditTrials as unknown as Data[]}
          onClick={() => ''}
        />
      ) : (
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
      )}
    </TableHistoryWrapper>
  )
}
export default AuditTrails
