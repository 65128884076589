import Lottie from 'lottie-react'
import { useCallback, useContext, useEffect, useState } from 'react'
import Loader from '../../../assets/lottiefiles/loader.json'
import Button from '../../../components/button/Button'
import CheckBoxNew from '../../../components/checkbox/CheckBoxNew'
import Divider from '../../../components/divider/Divider'
import Label from '../../../components/label/Label'
import { OptionProps } from '../../../components/selectDropdown/ISelectDropdown'
import { SvgCall1 } from '../../../components/svg/SvgCall'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import SvgInfoCircle from '../../../components/svg/SvgInfoCircle'
import { SvgPencil } from '../../../components/svg/SvgPencil'
import {
  WDButtonOutLine,
  WDButtonPrimaryOutlined,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import {
  WDLabelClient,
  WDLabelDetailsGap,
  WDLabelError,
  WDLabelPrimaryText,
  WDLabelSmall,
} from '../../../components/ui/WDLabel'
import { FundingErrorMessage } from '../../../constant/Messages'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import ClientAndAccountSearchContext from '../../../services/clientAndAccountSearch/ClientAndAccountSearchContext'
import {
  ClientAddressResponseInterface,
  PartyContactResponseInterface,
  PartyEmailResponseInterface,
} from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import {
  GetClientJSON,
  getWipDetails,
} from '../../../services/formRequest/FormRequestAPI'
import ToastContext from '../../../services/toast/ToastContext'
import { COLORS } from '../../../theme/Colors'
import { LottieWrapper } from '../../interceptor/styles'
import SelectDropdown from '../../selectDropdown/SelectDropdown'
import { NoteWrapper } from '../accountForms/formIndividual/tabs/AccountFeature/styles'
import { DividerWrapper } from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import { FundingButtonWrapper } from '../accountForms/formIndividual/tabs/FundingInfo/styles'
import { SpinnerWrapper } from '../accountForms/formIndividual/tabs/ProductFeatures/style'
import { StyledDocumentPanel } from '../accountInformation/styles'
import {
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../electronicPrefs/styles'
import { WDLabelStyledCardHeader } from './../../../components/ui/WDLabel'
import { GetContactInfoRequestInterface } from './IContactInfo'
import {
  AddressWrap,
  AddressWrapper,
  AppInfoWrapper,
  BGcolor,
  CheckBoxWrapper,
  DocumentRowContainer,
  EditWrapper,
  FeatureInfoColumn,
  FeatureInfoRow,
  FirstHalfWrapper,
  FirstRowWrapper,
  FormContainer,
  IconWrapper,
  InfoItemWrapper,
  LabelWrapper,
  LegalAddressWrapper,
  Row,
  TextAreaWrapper,
  TitleGrouping,
  WipHeadingWrapper,
} from './styles'
import { RoleContext } from '../../../services/roleContext/RoleContext'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { AppId } from '../../../../src/constant/AppId'
import XrefContext from '../../../store/XrefContext'
import { getXrefDatabyDomainCode } from '../../../../src/shared/Comman'
import { GetWipRequestInterface } from '../formWip/IFormWip'

const ContactInformation = (props: {
  pdfNodeRef3?: (pdfCanvasElement: HTMLCanvasElement) => HTMLCanvasElement
  isPdf?: boolean
}) => {
  const {
    wipId,
    selectedClient,
    newModelData,
    setNewModelData,
    contactCardLoader,
    buttonTextContact,
    mailingAddress,
    setMailingAddress,
    legalAddress,
    setLegalAddress,
    mobilePhone,
    setMobilePhone,
    otherPhone,
    setOtherPhone,
    businessPhone,
    setBusinessPhone,
    homePhone,
    setHomePhone,
    fax,
    setFax,
    emailAddress,
    setEmailAddress,
    addAccountResponse,
    setAddAccountResponse,
    autoSaveFunction,
    setDetectChangeContact,
    contactError,
    setContactError,
    setContactCardLoader,
    isDashboardNavigate,
    setXrefDetails,
  } = useContext(ApplicationInfoContext)
  const { accessList } = useContext(RoleContext)
  const { setToast } = useContext(ToastContext)
  const { searchClientById, client, setContactInfoData, getContactInfo } =
    useContext(ClientAndAccountSearchContext)
  const [secondary, setSecondary] = useState(false)
  const [sameAsMailing, setSameAsMailing] = useState(false)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [dropdownSelected, setDropdownSelected] = useState(false)
  const [newPartyContact, setNewPartyContact] = useState<
    PartyContactResponseInterface[]
  >([])

  const [newPartyEmail, setNewPartyEmail] = useState<
    PartyEmailResponseInterface[]
  >([])
  const [newClientAddressList, setNewClientAddressList] = useState<
    ClientAddressResponseInterface[]
  >([])
  const [mailingDropdown, setMailingDropdown] = useState(false)
  const [mailingAddressOptions, setMailingAddressOptions] = useState<
    OptionProps[]
  >([])
  const [legalAddressOptions, setLegalAddressOptions] = useState<OptionProps[]>(
    []
  )
  const xrefStore = useContext(XrefContext)
  const { readOnly } = useContext(ApplicationInfoContext)
  const [emailOptions, setEmailOptions] = useState<OptionProps[]>([])
  const [contactOptions, setContactOptions] = useState<OptionProps[]>([])
  const [phoneTypes, setPhoneTypes] = useState<OptionProps[]>([])
  const [legalPartyArr, setLegalPartyArr] = useState<
    ClientAddressResponseInterface[] | undefined
  >([])
  const [mailingPartyArr, setMailingPartyArr] = useState<
    ClientAddressResponseInterface[] | undefined
  >([])

  const getEmailList = useCallback(
    () => {
      const optionEmail: OptionProps[] = []
      newPartyEmail?.forEach((email: PartyEmailResponseInterface) => {
        optionEmail?.push({
          label: email.emailAddress,
          value: email.emailId.toString(),
        })
      })
      setEmailOptions(optionEmail)

      // if (addAccountResponse.partyEmail)
      //   setEmailAddress(
      //     optionEmail.find(
      //       (contact) =>
      //         contact.value === addAccountResponse.partyEmail.toString()
      //     ) as OptionProps
      //   )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAccountResponse, newPartyEmail]
  )
  const formatPhoneNumber = (contact: string) => {
    const cleaned = ('' + contact).replace(/\D/g, '')
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ')-', match[3], '-', match[4]].join('')
    }
    return contact
  }
  const getContactList = useCallback(
    () => {
      const optionArray: OptionProps[] = []
      newPartyContact?.forEach((contact: PartyContactResponseInterface) => {
        optionArray?.push({
          label: contact.phone.length
            ? formatPhoneNumber(contact.phone)
            : 'Select',
          value: contact.contactId?.toString(),
        })
      })

      setContactOptions(optionArray)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAccountResponse, newPartyContact]
  )

  const goToClientMaintenance = () => {
    if (selectedClient.clientId) {
      window.open(
        `https://clientmaintenance-dev.azurewebsites.net?clientID=${selectedClient.clientId}`
      )
      //window.open(`http://localhost:3000?clientID=${2}`)
    }
  }

  useEffect(() => {
    if (
      wipId &&
      // isDashboardNavigate &&
      newModelData?.wipAccountDetailslist &&
      newModelData?.wipAccountDetailslist.length > 0
    ) {
      const getDomCode = 'OTHPHN'
      const dropdownXrefData = getXrefDatabyDomainCode(
        xrefStore.xrefDetails,
        getDomCode
      )
      const dropdownOptions: any[] = []
      dropdownXrefData.map((obj) => {
        dropdownOptions.push({
          label: obj.value,
          value: obj.code,
        })
        setPhoneTypes(dropdownOptions)
      })
      const getApplicant =
        newModelData?.wipAccountDetailslist[0]?.contactdetailslist
      const getContactDetails =
        newModelData?.wipAccountDetailslist?.[0]?.contactdetails
      if (getApplicant) {
        setContactCardLoader(true)
        const parseData = JSON?.parse(getApplicant)
        const getParsedData = JSON?.parse(getContactDetails)
        if (parseData && getParsedData) {
          setNewPartyContact(parseData.PartyContact)
          setNewPartyEmail(parseData.PartyEmail)
          setNewClientAddressList(parseData.GetClientAddress)
          setMobilePhone(
            contactOptions?.find(
              (item) => item.value === getParsedData?.mobilephoneid?.toString()
            ) ?? { label: '', value: '' }
          )
          setOtherPhone(
            dropdownOptions?.find(
              (item) => Number(item.value) === getParsedData?.otherphonetypeid
            ) ?? { label: '', value: '' }
          )
          setBusinessPhone(
            contactOptions?.find(
              (item) => item.value === getParsedData?.otherphoneid?.toString()
            ) ?? { label: '', value: '' }
          )
          setHomePhone(
            contactOptions?.find(
              (item) => item.value === getParsedData?.otherphoneid?.toString()
            ) ?? { label: '', value: '' }
          )
          setFax(
            contactOptions?.find(
              (item) => item.value === getParsedData?.otherphoneid?.toString()
            ) ?? { label: '', value: '' }
          )
          setEmailAddress(
            emailOptions?.find(
              (item) => item.value === getParsedData?.emailid?.toString()
            ) ?? { label: '', value: '' }
          )
          if (
            getParsedData?.mailingaddrid &&
            getParsedData?.mailingaddrid === getParsedData?.legaladdrid
          ) {
            setSameAsMailing(true)
          }
          setContactCardLoader(false)
        } else if (parseData) {
          setContactCardLoader(false)
          setNewPartyContact(parseData.PartyContact)
          setNewPartyEmail(parseData.PartyEmail)
          setNewClientAddressList(parseData.GetClientAddress)
          setMobilePhone(
            parseData?.PartyContact?.length > 0
              ? parseData?.PartyContact?.find(
                  (item: any) => item.phoneType === 'MobilePhone'
                )
                ? {
                    label: formatPhoneNumber(
                      parseData?.PartyContact?.find(
                        (item: any) => item.phoneType === 'MobilePhone'
                      )?.phone?.toString()
                    ),
                    value: parseData?.PartyContact?.find(
                      (item: any) => item.phoneType === 'MobilePhone'
                    )?.contactId?.toString(),
                  }
                : { label: 'Select', value: '' }
              : { label: 'Select', value: '' }
          )
          if (
            getParsedData?.mailingaddrid &&
            getParsedData?.mailingaddrid === getParsedData?.legaladdrid
          ) {
            setSameAsMailing(true)
          }
          setContactCardLoader(false)
        }
      }
    }
  }, [
    wipId,
    newModelData?.wipAccountDetailslist,
    xrefStore?.xrefDetails,
    contactOptions,
  ])

  const compareAddresses = (address1: any, address2: any) => {
    return (
      address1?.partyAddressMailing1 === address2?.partyAddressLegal1 &&
      address1?.partyAddressMailing2 === address2?.partyAddressLegal2 &&
      address1?.partyAddressMailingCity === address2?.partyAddressLegalCity &&
      address1?.partyAddressMailingState === address2?.partyAddressLegalState &&
      address1?.partyAddressMailingZip === address2?.partyAddressLegalZip &&
      address1?.partyAddressMailingCountry ===
        address2?.partyAddressLegalCountry
    )
  }
  useEffect(() => {
    if (sameAsMailing && legalAddress.selectedValueFormate.length > 0) {
      setMailingAddress({
        label: legalAddress?.label,
        value: legalAddress?.value,
        selectedValue: legalAddress?.selectedValue,
        selectedValueFormate: [
          {
            partyAddressMailing1:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegal1,
            partyAddressMailing2:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegal2,
            partyAddressMailingCity:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegalCity,
            partyAddressMailingState:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegalState,
            partyAddressMailingStateCode:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegalStateCode,
            partyAddressMailingZip:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegalZip,
            partyAddressMailingCountry:
              legalAddress?.selectedValueFormate[0]?.partyAddressLegalCountry,
            partyAddressMailingCountryCode:
              legalAddress?.selectedValueFormate[0]
                ?.partyAddressLegalCountryCode,
          },
        ],
      })
      setMailingDropdown(false)
      setDetectChangeContact(true)
    }
  }, [sameAsMailing, legalAddress])
  // const getContactInfoApi = () => {
  //   setContactCardLoader(true)
  //   getContactInfo(payload).then((res) => {
  //     // const res = newModelData.
  //     if (res) {
  //       const getLegal = JSON.parse(res?.partyaddr1)
  //       const getMailing = JSON.parse(res?.partyaddr2)
  //       const responseData = res
  //       setContactInfoData(responseData)
  //       setAddAccountResponse(responseData)
  //       //legal address
  //       if (getLegal?.length > 0) {
  //         setLegalAddress({
  //           label:
  //             getLegal.length > 0 ? (
  //               <AddressWrap id="option">
  //                 <WDLabelClient>
  //                   {getLegal[0]?.partyAddressLegal1 + ' '}
  //                 </WDLabelClient>
  //                 <WDLabelClient>
  //                   {getLegal[0]?.partyAddressLegal2 + ' '}
  //                 </WDLabelClient>
  //                 <WDLabelClient>
  //                   {getLegal[0]?.partyAddressLegalCity +
  //                     ' ' +
  //                     getLegal[0]?.partyAddressLegalState +
  //                     ' ' +
  //                     getLegal[0]?.partyAddressLegalZip +
  //                     ' '}
  //                 </WDLabelClient>
  //                 <WDLabelClient>
  //                   {getLegal[0]?.partyAddressLegalCountry}
  //                 </WDLabelClient>
  //               </AddressWrap>
  //             ) : (
  //               '--'
  //             ),
  //           value:
  //             getLegal.length > 0
  //               ? getLegal[0]?.partyAddressLegal1 ??
  //               '' + getLegal[0]?.partyAddressLegal2 ??
  //               '' + getLegal[0]?.partyAddressLegalCity ??
  //               '' + getLegal[0]?.partyAddressLegalState ??
  //               '' + getLegal[0]?.partyAddressLegalZip ??
  //               '' + getLegal[0]?.partyAddressLegalCountry ??
  //               ''
  //               : '--',
  //           selectedValue:
  //             getLegal.length > 0
  //               ? getLegal[0]?.partyAddressLegal1 ??
  //               '' + getLegal[0]?.partyAddressLegal2 ??
  //               '' + getLegal[0]?.partyAddressLegalCity ??
  //               '' + getLegal[0]?.partyAddressLegalState ??
  //               '' + getLegal[0]?.partyAddressLegalZip ??
  //               '' + getLegal[0]?.partyAddressLegalCountry ??
  //               ''
  //               : 'Select',
  //           selectedValueFormate:
  //             getLegal.length > 0
  //               ? [
  //                 {
  //                   partyAddressLegal1:
  //                     getLegal && getLegal[0]?.partyAddressLegal1
  //                       ? getLegal[0]?.partyAddressLegal1
  //                       : '',
  //                   partyAddressLegal2:
  //                     getLegal && getLegal[0]?.partyAddressLegal2
  //                       ? getLegal[0]?.partyAddressLegal2
  //                       : '',
  //                   partyAddressLegalCity:
  //                     getLegal && getLegal[0]?.partyAddressLegalCity
  //                       ? getLegal[0]?.partyAddressLegalCity
  //                       : '',
  //                   partyAddressLegalState:
  //                     getLegal && getLegal[0]?.partyAddressLegalState
  //                       ? getLegal[0]?.partyAddressLegalState
  //                       : '',
  //                   partyAddressLegalZip:
  //                     getLegal && getLegal[0]?.partyAddressLegalZip
  //                       ? getLegal[0]?.partyAddressLegalZip
  //                       : '',
  //                   partyAddressLegalCountry:
  //                     getLegal && getLegal[0]?.partyAddressLegalCountry
  //                       ? getLegal[0]?.partyAddressLegalCountry
  //                       : '',
  //                 },
  //               ]
  //               : '',
  //         })
  //       } else {
  //         setLegalAddress({
  //           label: '--',
  //           value: '--',
  //           selectedValue: 'Select',
  //           selectedValueFormate: '',
  //         })
  //       }

  //       //mail address
  //       if (getMailing && getMailing.length > 0) {
  //         setMailingAddress({
  //           label: getMailing[0]?.partyAddressMailing1 ? (
  //             <AddressWrap id="option">
  //               <WDLabelClient>
  //                 {getMailing[0]?.partyAddressMailing1 + ' '}
  //               </WDLabelClient>
  //               <WDLabelClient>
  //                 {getMailing[0]?.partyAddressMailing2 + ' '}
  //               </WDLabelClient>
  //               <WDLabelClient>
  //                 {getMailing[0]?.partyAddressMailingCity +
  //                   ' ' +
  //                   getMailing[0]?.partyAddressMailingState +
  //                   ' ' +
  //                   getMailing[0]?.partyAddressMailingZip +
  //                   ' '}
  //               </WDLabelClient>
  //               <WDLabelClient>
  //                 {getMailing[0]?.partyAddressMailingCountry}
  //               </WDLabelClient>
  //             </AddressWrap>
  //           ) : (
  //             '--'
  //           ),
  //           value: getMailing[0]?.partyAddressMailing1
  //             ? getMailing[0]?.partyAddressMailing1 +
  //             getMailing[0]?.partyAddressMailing2 +
  //             getMailing[0]?.partyAddressMailingCity +
  //             getMailing[0]?.partyAddressMailingState +
  //             getMailing[0]?.partyAddressMailingZip +
  //             getMailing[0]?.partyAddressMailingCountry
  //             : '--',
  //           selectedValue: getMailing[0]?.partyAddressMailing1
  //             ? getMailing[0]?.partyAddressMailing1 +
  //             getMailing[0]?.partyAddressMailing2 +
  //             getMailing[0]?.partyAddressMailingCity +
  //             getMailing[0]?.partyAddressMailingState +
  //             getMailing[0]?.partyAddressMailingZip +
  //             getMailing[0]?.partyAddressMailingCountry
  //             : 'Select',
  //           selectedValueFormate: getMailing[0]?.partyAddressMailing1
  //             ? [
  //               {
  //                 partyAddressMailing1: getMailing[0]?.partyAddressMailing1,
  //                 partyAddressMailing2: getMailing[0]?.partyAddressMailing2,
  //                 partyAddressMailingCity:
  //                   getMailing[0]?.partyAddressMailingCity,
  //                 partyAddressMailingState:
  //                   getMailing[0]?.partyAddressMailingState,
  //                 partyAddressMailingZip:
  //                   getMailing[0]?.partyAddressMailingZip,
  //                 partyAddressMailingCountry:
  //                   getMailing[0]?.partyAddressMailingCountry,
  //               },
  //             ]
  //             : '',
  //         })
  //       } else {
  //         setMailingAddress({
  //           label: '--',
  //           value: '--',
  //           selectedValue: 'Select',
  //           selectedValueFormate: '',
  //         })
  //       }

  //       //mobile phone
  //       setMobilePhone({
  //         label: responseData.partyPhone
  //           ? formatPhoneNumber(responseData.partyPhone)
  //           : 'Select',
  //         // value: responseData.partyPhone?.toString(),
  //         value: responseData.partyPhoneId?.toString()
  //       })

  //       //email
  //       setEmailAddress({
  //         label: responseData.partyEmail ?? 'Select',
  //         value: responseData.partyEmail?.toString(),
  //       })

  //       //other phone
  //       if (responseData?.otherPhone) {
  //         setOtherPhone({
  //           label:
  //             phoneTypes.find(
  //               (item: any) =>
  //                 responseData?.otherPhone?.split('-')[1] ===
  //                 (item.label || item.value)
  //             )?.label ?? responseData?.otherPhone?.split('-')[1],

  //           value:
  //             phoneTypes.find(
  //               (item: any) =>
  //                 responseData?.otherPhone?.split('-')[1] ===
  //                 (item.label || item.value)
  //             )?.value ?? responseData?.otherPhone?.split('-')[1],
  //         })
  //       } else {
  //         setOtherPhone({
  //           label: 'Select',
  //           value: '--',
  //         })
  //       }
  //       const phoneType = phoneTypes.find(
  //         (contact) =>
  //           contact.label == responseData?.otherPhone?.split('-')[1] ||
  //           contact.value == responseData?.otherPhone?.split('-')[1]
  //       )
  //       const getOtherVal = {
  //         label: responseData?.otherPhone?.split('-')[0],
  //         value: responseData?.otherPhone?.split('-')[0],
  //       }
  //       if (
  //         phoneType?.label == 'Business' ||
  //         phoneType?.label == 'BusinessPhone'
  //       ) {
  //         if (!getOtherVal) return
  //         setBusinessPhone(getOtherVal)
  //       } else if (
  //         phoneType?.label === 'Home' ||
  //         phoneType?.label === 'HomePhone'
  //       ) {
  //         if (!getOtherVal) return
  //         setHomePhone(getOtherVal)
  //       } else if (phoneType?.label === 'Fax') {
  //         if (!getOtherVal) return
  //         setFax(getOtherVal)
  //       }
  //       if (getMailing && getLegal) {
  //         if (compareAddresses(getMailing[0], getLegal[0])) {
  //           setSameAsMailing(true)
  //         } else {
  //           setSameAsMailing(false)
  //         }
  //       }
  //       setContactCardLoader(false)
  //     }
  //   })
  // }

  useEffect(() => {
    const getContactDetails =
      newModelData?.wipAccountDetailslist?.[0]?.contactdetails
    const getParsedData = getContactDetails
      ? JSON?.parse(getContactDetails)
      : null
    const selectedLegalId = getParsedData?.legaladdrid ?? 0
    let currentSelected = legalAddressOptions.filter(
      (obj) => obj.value?.toString() === selectedLegalId?.toString()
    )
    if (currentSelected.length === 0 && legalAddressOptions.length > 0) {
      currentSelected = legalAddressOptions
    }
    if (legalAddressOptions[0]?.selectedValue) {
      setLegalAddress({
        label: (
          <AddressWrap id="option">
            <WDLabelClient className="label_responsive">
              {currentSelected[0]?.selectedValueFormate[0]?.partyAddressLegal1 +
                ' '}
            </WDLabelClient>
            <WDLabelClient className="label_responsive">
              {currentSelected[0]?.selectedValueFormate[0].partyAddressLegal2 +
                ' '}
            </WDLabelClient>
            <WDLabelClient className="label_responsive">
              {currentSelected[0]?.selectedValueFormate[0]
                .partyAddressLegalCity +
                ' ' +
                currentSelected[0]?.selectedValueFormate[0]
                  .partyAddressLegalState +
                ' ' +
                currentSelected[0]?.selectedValueFormate[0]
                  .partyAddressLegalZip +
                ' '}
            </WDLabelClient>
            <WDLabelClient className="label_responsive">
              {
                currentSelected[0]?.selectedValueFormate[0]
                  .partyAddressLegalCountry
              }
            </WDLabelClient>
          </AddressWrap>
        ),
        value: currentSelected[0]?.value,
        selectedValue: currentSelected[0]?.selectedValue,
        selectedValueFormate: [
          {
            partyAddressLegal1:
              currentSelected[0]?.selectedValueFormate[0]?.partyAddressLegal1,
            partyAddressLegal2:
              currentSelected[0]?.selectedValueFormate[0]?.partyAddressLegal2,
            partyAddressLegalCity:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressLegalCity,
            partyAddressLegalState:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressLegalState,
            partyAddressLegalStateCode:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressLegalStateCode,
            partyAddressLegalZip:
              currentSelected[0]?.selectedValueFormate[0]?.partyAddressLegalZip,
            partyAddressLegalCountry:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressLegalCountry,
            partyAddressLegalCountryCode:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressLegalCountryCode,
          },
        ],
      })
    }
  }, [legalAddressOptions])

  useEffect(() => {
    const getContactDetails =
      newModelData?.wipAccountDetailslist?.[0]?.contactdetails
    const getParsedData = getContactDetails
      ? JSON?.parse(getContactDetails)
      : null
    const selectedMailId = getParsedData?.mailingaddrid ?? 0
    let currentSelected = mailingAddressOptions.filter(
      (obj) => obj.value?.toString() === selectedMailId?.toString()
    )
    if (currentSelected.length === 0 && mailingAddressOptions.length > 0) {
      currentSelected = mailingAddressOptions
    }
    if (mailingAddressOptions[0]?.selectedValue) {
      setMailingAddress({
        label: (
          <AddressWrap id="option">
            <WDLabelClient>
              {currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailing1 + ' '}
            </WDLabelClient>
            <WDLabelClient>
              {currentSelected[0]?.selectedValueFormate[0]
                .partyAddressMailing2 + ' '}
            </WDLabelClient>
            <WDLabelClient>
              {currentSelected[0]?.selectedValueFormate[0]
                .partyAddressMailingCity +
                ' ' +
                currentSelected[0]?.selectedValueFormate[0]
                  .partyAddressMailingState +
                ' ' +
                currentSelected[0]?.selectedValueFormate[0]
                  .partyAddressMailingZip +
                ' '}
            </WDLabelClient>
            <WDLabelClient>
              {
                currentSelected[0]?.selectedValueFormate[0]
                  .partyAddressMailingCountry
              }
            </WDLabelClient>
          </AddressWrap>
        ),
        value: currentSelected[0]?.value,
        selectedValue: currentSelected[0]?.selectedValue,
        selectedValueFormate: [
          {
            partyAddressMailing1:
              currentSelected[0]?.selectedValueFormate[0]?.partyAddressMailing1,
            partyAddressMailing2:
              currentSelected[0]?.selectedValueFormate[0]?.partyAddressMailing2,
            partyAddressMailingCity:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailingCity,
            partyAddressMailingState:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailingState,
            partyAddressMailingStateCode:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailingStateCode,
            partyAddressMailingZip:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailingZip,
            partyAddressMailingCountry:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailingCountry,
            partyAddressMailingCountryCode:
              currentSelected[0]?.selectedValueFormate[0]
                ?.partyAddressMailingCountryCode,
          },
        ],
      })
    }
  }, [mailingAddressOptions])

  const popupConfirmation = () => {
    let isValid = true
    let localError = contactError
    if (
      legalAddress.selectedValue === 'Select' ||
      legalAddress.value === '' ||
      legalAddress.label === 'Select'
    ) {
      localError = { ...localError, legalAddr: true }
      isValid = false
    }
    if (
      mailingAddress.selectedValue === 'Select' ||
      mailingAddress.value === '' ||
      mailingAddress.label === 'Select'
    ) {
      localError = { ...localError, mailingAddr: true }
      isValid = false
    }
    if (emailAddress?.label === 'Select') {
      localError = { ...localError, email: true }
      isValid = false
    }
    if (mobilePhone?.label === 'Select') {
      localError = { ...localError, mobile: true }
      isValid = false
    }

    setContactError(localError)
    setTimeout(() => {
      setContactError({
        legalAddr: false,
        mailingAddr: false,
        mobile: false,
        email: false,
      })
    }, 5000)
    setContactCardLoader(true)
    autoSaveFunction().then((res) => {
      if (res == 200) {
        const payload: GetWipRequestInterface = {
          appId: '1',
          wipId: parseInt(wipId),
        }
        getWipDetails(payload)
          .then((getWipRecord) => {
            setNewModelData(getWipRecord.data)
            setToast('success', 'Contact details updated')
            setContactCardLoader(false)
          })
          .catch((error) => {
            console.log('error', error)
          })
      } else setToast('error', FundingErrorMessage.failureupdatemessage)
    })
  }
  const getAddressOptions = useCallback(() => {
    const legalAddrArray: OptionProps[] = []
    const mailingAddrArray: OptionProps[] = []
    setLegalPartyArr(
      newClientAddressList?.filter(
        (addr: any) =>
          addr.addressType === 'Legal Address' ||
          addr.addressType === 'LegalAddress'
      )
    )
    newClientAddressList
      ?.filter(
        (addr: any) =>
          addr.addressType === 'Legal Address' ||
          addr.addressType === 'LegalAddress'
      )
      .forEach((address: any) => {
        legalAddrArray?.push({
          value: address.addressId.toString(),
          label: (
            <AddressWrap id="option">
              <WDLabelClient>{address?.streetAddress1 + ' '}</WDLabelClient>
              <WDLabelClient>{address?.streetAddress2 + ' '}</WDLabelClient>
              <WDLabelClient>
                {address?.city +
                  ' ' +
                  address?.stateCode +
                  ' ' +
                  address?.zipCode +
                  ' '}
              </WDLabelClient>
              <WDLabelClient>{address?.country}</WDLabelClient>
            </AddressWrap>
          ),
          selectedValue:
            address.streetAddress1 +
            ' ' +
            address.streetAddress2 +
            ' ' +
            address.city +
            ' ' +
            address.state +
            ' ' +
            address.zipCode +
            ' ' +
            address.country,
          selectedValueFormate: [
            {
              partyAddressLegal1: address.streetAddress1 ?? '',
              partyAddressLegal2: address.streetAddress2 ?? '',
              partyAddressLegalCity: address.city ?? '',
              partyAddressLegalState: address.state ?? '',
              partyAddressLegalStateCode: address.stateCode ?? '',
              partyAddressLegalZip: address.zipCode ?? '',
              partyAddressLegalCountry: address.country ?? '',
              partyAddressLegalCountryCode: address.countryAlpha2Code ?? '',
            },
          ],
        })
      })
    setLegalAddressOptions(legalAddrArray)

    setMailingPartyArr(
      newClientAddressList?.filter(
        (addr: any) => addr.addressType === 'MailingAddress'
      )
    )
    newClientAddressList
      ?.filter((addr: any) => addr.addressType === 'MailingAddress')
      .forEach((address: any) => {
        mailingAddrArray?.push({
          label: (
            <AddressWrap id="option">
              <WDLabelClient>{address?.streetAddress1 + ' '}</WDLabelClient>
              <WDLabelClient>{address?.streetAddress2 + ' '}</WDLabelClient>
              <WDLabelClient>
                {address?.city +
                  ' ' +
                  address?.stateCode +
                  ' ' +
                  address?.zipCode +
                  ' '}
              </WDLabelClient>
              <WDLabelClient>{address?.country}</WDLabelClient>
            </AddressWrap>
          ),
          value: address.addressId.toString(),
          selectedValue:
            address.streetAddress1 +
            ' ' +
            address.streetAddress2 +
            ' ' +
            address.state +
            ' ' +
            address.zipCode +
            ' ' +
            address.country,
          selectedValueFormate: [
            {
              partyAddressMailing1: address.streetAddress1,
              partyAddressMailing2: address.streetAddress2,
              partyAddressMailingCity: address.city,
              partyAddressMailingState: address.state,
              partyAddressMailingStateCode: address.stateCode,
              partyAddressMailingZip: address.zipCode,
              partyAddressMailingCountry: address.country,
              partyAddressMailingCountryCode: address.countryAlpha2Code,
            },
          ],
        })
      })
    setMailingAddressOptions(mailingAddrArray)
  }, [newClientAddressList])

  const onChangeLegalAddress = (option: any) => {
    setLegalAddress(option as OptionProps)
    setDropdownSelected(false)
    setDetectChangeContact(true)
  }
  const getLegalAddress = () => {
    if (!dropdownSelected) {
      return (
        <TextAreaWrapper readOnly={readOnly}>
          {legalAddress.label !== '--' ? (
            legalAddress?.label
          ) : (
            <AddressWrap>
              <WDLabelClient>{readOnly ? '--' : 'Select'}</WDLabelClient>
            </AddressWrap>
          )}
          {!readOnly && (
            <IconWrapper>
              <WDButtonTransparent>
                <Button
                  type="button"
                  onClick={() => {
                    setDropdownSelected(true)
                  }}
                >
                  <SvgPencil fillColor={COLORS.Icons.Neutral} />
                </Button>
              </WDButtonTransparent>
            </IconWrapper>
          )}
        </TextAreaWrapper>
      )
    } else {
      return (
        <SelectDropdown
          placeholder="Select legal address"
          width={'100%'}
          border={contactError.legalAddr}
          options={legalAddressOptions}
          name={'legalAddress'}
          onChange={(option) => {
            onChangeLegalAddress(option)
          }}
          value={{
            value: legalAddress?.selectedValue || '',
            label: legalAddress?.selectedValue || '',
          }}
        />
      )
    }
  }

  const getMailingAddress = () => {
    if (!mailingDropdown) {
      return (
        <TextAreaWrapper readOnly={readOnly}>
          {mailingAddress.value !== '--' ? (
            mailingAddress?.label
          ) : (
            <AddressWrap>
              <WDLabelClient>{readOnly ? '--' : 'Select'}</WDLabelClient>
            </AddressWrap>
          )}
          {!readOnly && (
            <IconWrapper>
              <WDButtonTransparent>
                <Button
                  type="button"
                  onClick={() => {
                    setMailingDropdown(true)
                  }}
                  disabled={sameAsMailing}
                >
                  <SvgPencil fillColor={COLORS.Icons.Neutral} />
                </Button>
              </WDButtonTransparent>
            </IconWrapper>
          )}
        </TextAreaWrapper>
      )
    } else {
      return (
        <SelectDropdown
          placeholder="Select mailing address"
          width={'100%'}
          options={mailingAddressOptions}
          border={contactError.mailingAddr}
          name={'mailingAddress'}
          onChange={(option) => {
            setMailingAddress(option as OptionProps)
            setMailingDropdown(false)
            setDetectChangeContact(true)
          }}
          value={
            sameAsMailing
              ? {
                  value: legalAddress?.selectedValue || '',
                  label: legalAddress?.selectedValue || '',
                }
              : {
                  value: mailingAddress?.selectedValue || '',
                  label: mailingAddress?.selectedValue || '',
                }
          }
          isDisabled={sameAsMailing}
        />
      )
    }
  }

  useEffect(() => {
    if (selectedClient?.clientId) {
      searchClientById({ partyId: selectedClient?.clientId })
    }
  }, [searchClientById, selectedClient?.clientId])

  useEffect(() => {
    if (newClientAddressList?.length) {
      getAddressOptions()
    }
    if (newPartyEmail?.length) {
      getEmailList()
    }
    if (newPartyContact?.length) {
      getContactList()
    }
  }, [
    newClientAddressList?.length,
    // getAddressOptions,
    // getContactList,
    // getEmailList,
    newPartyContact?.length,
    newPartyEmail?.length,
    addAccountResponse,
  ])
  const convertAddress = () => {
    return (
      <AddressWrap id="cssaddress">
        <WDLabelClient className="label_responsive">
          {client?.clientName ? client?.clientName : ''}
        </WDLabelClient>
        {mailingAddress.label !== 'Select' ? mailingAddress.label : ''}
      </AddressWrap>
    )
  }

  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel
        id={props.isPdf ? 'remove' : ''}
        ref={props.pdfNodeRef3}
      >
        <AppInfoWrapper>
          <WipHeadingWrapper
            onClick={() => {
              if (wipId) setToggleHeader(!toggleHeader)
            }}
          >
            <TitleGrouping>
              <SvgCall1 />
              <WDLabelStyledCardHeader className="header_responsive">
                {'Contact Information'}
              </WDLabelStyledCardHeader>
            </TitleGrouping>

            {selectedClient.clientId && toggleHeader && !readOnly && (
              <EditWrapper>
                <WDButtonPrimaryOutlined>
                  <Button type="button" onClick={() => goToClientMaintenance()}>
                    {'Client Maintenance'}
                  </Button>
                </WDButtonPrimaryOutlined>
              </EditWrapper>
            )}
            {wipId && !toggleHeader && !props.isPdf && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {wipId && toggleHeader && !props.isPdf && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </WipHeadingWrapper>
          {toggleHeader && (
            <DividerWrapper id="product_feature">
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Background.Neutral}`}
                thickness={'0.1rem'}
              />
            </DividerWrapper>
          )}
          {toggleHeader && (
            <AddressWrapper>
              <FormContainer>
                {contactCardLoader ? (
                  <SpinnerWrapper>
                    <LottieWrapper>
                      <Lottie animationData={Loader} loop={true} />
                    </LottieWrapper>
                  </SpinnerWrapper>
                ) : (
                  <>
                    <>
                      <DocumentRowContainer>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                          }}
                        >
                          <FirstRowWrapper>
                            <FeatureInfoRow>
                              <FeatureInfoColumn>
                                <LabelWrapper>
                                  <WDLabelClient className="label_responsive">
                                    <Label>{'Legal Address*'}</Label>
                                  </WDLabelClient>
                                  {contactError?.legalAddr && (
                                    <WDLabelError className="label_responsive">
                                      <Label>{'Required'}</Label>
                                    </WDLabelError>
                                  )}
                                </LabelWrapper>
                                {readOnly ? (
                                  <BGcolor>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {legalAddress.value
                                          ? getLegalAddress()
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  </BGcolor>
                                ) : (
                                  getLegalAddress()
                                )}
                              </FeatureInfoColumn>
                            </FeatureInfoRow>
                            <FeatureInfoRow>
                              <FeatureInfoColumn>
                                <LabelWrapper>
                                  <WDLabelClient className="label_responsive">
                                    <Label>
                                      {'Statement Mailing Address*'}
                                    </Label>
                                  </WDLabelClient>
                                  {contactError?.mailingAddr && (
                                    <WDLabelError className="label_responsive">
                                      <Label>{'Required'}</Label>
                                    </WDLabelError>
                                  )}
                                </LabelWrapper>
                              </FeatureInfoColumn>
                              <FeatureInfoColumn>
                                {readOnly ? (
                                  <BGcolor>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {mailingAddress.value
                                          ? getMailingAddress()
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  </BGcolor>
                                ) : (
                                  getMailingAddress()
                                )}
                              </FeatureInfoColumn>
                              <FeatureInfoColumn>
                                {!readOnly && (
                                  <LegalAddressWrapper>
                                    <CheckBoxWrapper
                                      id="red"
                                      className="container"
                                    >
                                      <CheckBoxNew
                                        // type="checkbox"
                                        checked={sameAsMailing}
                                        onChange={(event) => {
                                          setSameAsMailing(!sameAsMailing)
                                          setDetectChangeContact(true)
                                          const currentAddress = event.target
                                            .checked
                                            ? legalPartyArr?.find(
                                                (addr) =>
                                                  addr.addressId ===
                                                  Number(
                                                    legalAddressOptions[0].value
                                                  )
                                              )
                                            : mailingPartyArr?.find(
                                                (addr) =>
                                                  addr.addressId ===
                                                  Number(
                                                    mailingAddressOptions[0]
                                                      .value
                                                  )
                                              )
                                          if (currentAddress) {
                                            setContactError({
                                              ...contactError,
                                              mailingAddr: false,
                                            })
                                          }
                                        }}
                                      />
                                      <WDLabelSmall className="label_responsive">
                                        {'Same as Legal Address'}
                                      </WDLabelSmall>
                                    </CheckBoxWrapper>
                                  </LegalAddressWrapper>
                                )}
                              </FeatureInfoColumn>
                            </FeatureInfoRow>
                          </FirstRowWrapper>
                          <FirstRowWrapper id="red">
                            <InfoItemWrapper>
                              <FeatureInfoRow>
                                <FeatureInfoColumn>
                                  <LabelWrapper>
                                    <WDLabelClient className="label_responsive">
                                      <Label>{'Mobile Phone*'}</Label>
                                    </WDLabelClient>
                                    {contactError?.mobile && (
                                      <WDLabelError className="label_responsive">
                                        <Label>{'Required'}</Label>
                                      </WDLabelError>
                                    )}
                                  </LabelWrapper>
                                </FeatureInfoColumn>
                                <NoteWrapper>
                                  <SvgInfoCircle fillColor={COLORS.Text.Gray} />
                                  <WDLabelClient className="label_responsive">
                                    <Label>
                                      {
                                        'This phone number will be used to generate OTP'
                                      }
                                    </Label>
                                  </WDLabelClient>
                                </NoteWrapper>
                                <FeatureInfoColumn>
                                  {readOnly ? (
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {mobilePhone.label !== ''
                                          ? mobilePhone.label
                                          : '--'}
                                      </Label>
                                    </WDLabelClient>
                                  ) : (
                                    <SelectDropdown
                                      placeholder="Select mobile phone"
                                      width={'100%'}
                                      border={contactError?.mobile}
                                      options={contactOptions}
                                      name={'mobilePhone'}
                                      onChange={(option) => {
                                        setMobilePhone(option as OptionProps)
                                        setDetectChangeContact(true)
                                        setContactError({
                                          ...contactError,
                                          mobile: false,
                                        })
                                      }}
                                      value={mobilePhone}
                                    />
                                  )}
                                </FeatureInfoColumn>
                              </FeatureInfoRow>
                              <FirstHalfWrapper>
                                <FeatureInfoRow>
                                  <FeatureInfoColumn>
                                    <Row>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Other Phone'}</Label>
                                      </WDLabelClient>
                                      <WDLabelDetailsGap className="label_responsive">
                                        {'(Optional)'}
                                      </WDLabelDetailsGap>
                                    </Row>
                                  </FeatureInfoColumn>
                                  <FeatureInfoColumn>
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {otherPhone.value !== '' &&
                                          otherPhone.label !== 'Select'
                                            ? otherPhone.label
                                            : '--'}
                                        </Label>
                                      </WDLabelClient>
                                    ) : (
                                      <SelectDropdown
                                        placeholder="Select"
                                        width={'100%'}
                                        options={phoneTypes}
                                        name={'businessPhone'}
                                        onChange={(option) => {
                                          setOtherPhone(option as OptionProps)
                                          setDetectChangeContact(true)
                                        }}
                                        value={otherPhone}
                                      />
                                    )}
                                  </FeatureInfoColumn>
                                </FeatureInfoRow>
                                {(otherPhone.label === 'Business' ||
                                  otherPhone.label === 'BusinessPhone') && (
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Business Phone'}</Label>
                                      </WDLabelClient>
                                    </FeatureInfoColumn>
                                    <FeatureInfoColumn>
                                      {readOnly ? (
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {businessPhone.label !== ''
                                              ? businessPhone.label
                                              : '--'}
                                          </Label>
                                        </WDLabelClient>
                                      ) : (
                                        <SelectDropdown
                                          placeholder="Select"
                                          width={'100%'}
                                          options={contactOptions}
                                          name={'businessPhone'}
                                          onChange={(option) => {
                                            setBusinessPhone(
                                              option as OptionProps
                                            )
                                            setDetectChangeContact(true)
                                          }}
                                          value={businessPhone}
                                        />
                                      )}
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                )}
                                {(otherPhone.label === 'Home' ||
                                  otherPhone.label === 'HomePhone') && (
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Home Phone'}</Label>
                                      </WDLabelClient>
                                    </FeatureInfoColumn>
                                    <FeatureInfoColumn>
                                      {readOnly ? (
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {homePhone.label !== ''
                                              ? homePhone.label
                                              : '--'}
                                          </Label>
                                        </WDLabelClient>
                                      ) : (
                                        <SelectDropdown
                                          placeholder="Select home phone"
                                          width={'100%'}
                                          options={contactOptions}
                                          name={'homePhone'}
                                          onChange={(option) => {
                                            setHomePhone(option as OptionProps)
                                            setDetectChangeContact(true)
                                          }}
                                          value={homePhone}
                                        />
                                      )}
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                )}
                                {otherPhone.label === 'Fax' && (
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Fax'}</Label>
                                      </WDLabelClient>
                                    </FeatureInfoColumn>
                                    <FeatureInfoColumn>
                                      {readOnly ? (
                                        <WDLabelClient>
                                          <Label>
                                            {fax.label !== ''
                                              ? fax.label
                                              : '--'}
                                          </Label>
                                        </WDLabelClient>
                                      ) : (
                                        <SelectDropdown
                                          placeholder="Select fax"
                                          width={'100%'}
                                          options={contactOptions}
                                          name={'fax'}
                                          onChange={(option) => {
                                            setFax(option as OptionProps)
                                            setDetectChangeContact(true)
                                          }}
                                          value={fax}
                                        />
                                      )}
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                )}
                              </FirstHalfWrapper>
                              <FirstHalfWrapper>
                                <FeatureInfoRow>
                                  <FeatureInfoColumn>
                                    <LabelWrapper>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Email Address*'}</Label>
                                      </WDLabelClient>
                                      {contactError?.email && (
                                        <WDLabelError className="label_responsive">
                                          <Label>{'Required'}</Label>
                                        </WDLabelError>
                                      )}
                                    </LabelWrapper>
                                  </FeatureInfoColumn>
                                  <FeatureInfoColumn>
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {emailAddress?.value !== '' &&
                                          emailAddress?.label !== 'Select'
                                            ? emailAddress?.label
                                            : '--'}
                                        </Label>
                                      </WDLabelClient>
                                    ) : (
                                      <SelectDropdown
                                        placeholder="Select email address for eDelivery"
                                        width={'100%'}
                                        border={contactError?.email}
                                        options={emailOptions}
                                        name={'email'}
                                        onChange={(option) => {
                                          setEmailAddress(option as OptionProps)
                                          setDetectChangeContact(true)
                                          setContactError({
                                            ...contactError,
                                            email: false,
                                          })
                                        }}
                                        value={emailAddress}
                                      />
                                    )}
                                  </FeatureInfoColumn>
                                </FeatureInfoRow>
                              </FirstHalfWrapper>
                            </InfoItemWrapper>
                            <InfoItemWrapper>
                              <FeatureInfoRow>
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    <Label>
                                      {'FIS Account Title and Address'}
                                    </Label>
                                  </WDLabelClient>
                                </FeatureInfoColumn>
                                <FeatureInfoColumn>
                                  {convertAddress()}
                                </FeatureInfoColumn>
                              </FeatureInfoRow>
                            </InfoItemWrapper>
                          </FirstRowWrapper>
                        </div>
                      </DocumentRowContainer>
                    </>
                    {secondary &&
                      newModelData &&
                      newModelData.wipAccountDetailslist &&
                      newModelData.wipAccountDetailslist[0].accttitle ===
                        'Joint' && (
                        <DocumentRowContainer>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '1rem',
                            }}
                          >
                            <FirstRowWrapper>
                              <FeatureInfoRow>
                                <FeatureInfoColumn>
                                  <LabelWrapper>
                                    <WDLabelClient className="label_responsive">
                                      <Label>{'Legal Address*'}</Label>
                                    </WDLabelClient>
                                    {contactError?.legalAddr && (
                                      <WDLabelError className="label_responsive">
                                        <Label>{'Required'}</Label>
                                      </WDLabelError>
                                    )}
                                  </LabelWrapper>
                                  {readOnly ? (
                                    <BGcolor>
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {addAccountResponse.partyAddrLegal
                                            ? getLegalAddress()
                                            : legalAddress.label !== 'Select'
                                            ? legalAddress.label
                                            : '--'}
                                        </Label>
                                      </WDLabelClient>
                                    </BGcolor>
                                  ) : (
                                    getLegalAddress()
                                  )}
                                </FeatureInfoColumn>
                              </FeatureInfoRow>
                              <FeatureInfoRow>
                                <FeatureInfoColumn>
                                  <LabelWrapper>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {'Statement Mailing Address*'}
                                      </Label>
                                    </WDLabelClient>
                                    {contactError?.mailingAddr && (
                                      <WDLabelError className="label_responsive">
                                        <Label>{'Required'}</Label>
                                      </WDLabelError>
                                    )}
                                  </LabelWrapper>
                                </FeatureInfoColumn>
                                <FeatureInfoColumn>
                                  {readOnly ? (
                                    <BGcolor>
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {addAccountResponse.partyAddrMailing
                                            ? getMailingAddress()
                                            : mailingAddress.label !== 'Select'
                                            ? mailingAddress.label
                                            : '--'}
                                        </Label>
                                      </WDLabelClient>
                                    </BGcolor>
                                  ) : (
                                    getMailingAddress()
                                  )}
                                </FeatureInfoColumn>
                                <FeatureInfoColumn>
                                  {!readOnly && (
                                    <LegalAddressWrapper>
                                      <CheckBoxWrapper
                                        id="red"
                                        className="container"
                                      >
                                        <CheckBoxNew
                                          // type="checkbox"
                                          checked={sameAsMailing}
                                          onChange={(event) => {
                                            setSameAsMailing(!sameAsMailing)
                                            setDetectChangeContact(true)
                                            const currentAddress = event.target
                                              .checked
                                              ? legalPartyArr?.find(
                                                  (addr) =>
                                                    addr.addressId ===
                                                    Number(
                                                      legalAddressOptions[0]
                                                        .value
                                                    )
                                                )
                                              : mailingPartyArr?.find(
                                                  (addr) =>
                                                    addr.addressId ===
                                                    Number(
                                                      mailingAddressOptions[0]
                                                        .value
                                                    )
                                                )
                                            if (currentAddress) {
                                              setContactError({
                                                ...contactError,
                                                mailingAddr: false,
                                              })
                                            }
                                          }}
                                        />
                                        <WDLabelSmall className="label_responsive">
                                          {'Same as Legal Address'}
                                        </WDLabelSmall>
                                      </CheckBoxWrapper>
                                    </LegalAddressWrapper>
                                  )}
                                </FeatureInfoColumn>
                              </FeatureInfoRow>
                            </FirstRowWrapper>
                            <FirstRowWrapper id="red">
                              <InfoItemWrapper>
                                <FeatureInfoRow>
                                  <FeatureInfoColumn>
                                    <LabelWrapper>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{'Mobile Phone*'}</Label>
                                      </WDLabelClient>
                                      {contactError?.mobile && (
                                        <WDLabelError className="label_responsive">
                                          <Label>{'Required'}</Label>
                                        </WDLabelError>
                                      )}
                                    </LabelWrapper>
                                  </FeatureInfoColumn>
                                  <NoteWrapper>
                                    <SvgInfoCircle
                                      fillColor={COLORS.Text.Gray}
                                    />
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {
                                          'This phone number will be used to generate OTP'
                                        }
                                      </Label>
                                    </WDLabelClient>
                                  </NoteWrapper>
                                  <FeatureInfoColumn>
                                    {readOnly ? (
                                      <WDLabelClient className="label_responsive">
                                        <Label>
                                          {mobilePhone.label !== 'Select'
                                            ? mobilePhone.label
                                            : '--'}
                                        </Label>
                                      </WDLabelClient>
                                    ) : (
                                      <SelectDropdown
                                        placeholder="Select mobile phone"
                                        width={'100%'}
                                        border={contactError?.mobile}
                                        options={contactOptions}
                                        name={'mobilePhone'}
                                        onChange={(option) => {
                                          setMobilePhone(option as OptionProps)
                                          setDetectChangeContact(true)
                                          setContactError({
                                            ...contactError,
                                            mobile: false,
                                          })
                                        }}
                                        value={mobilePhone}
                                      />
                                    )}
                                  </FeatureInfoColumn>
                                </FeatureInfoRow>
                                <FirstHalfWrapper>
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <Row>
                                        <WDLabelClient className="label_responsive">
                                          <Label>{'Other Phone'}</Label>
                                        </WDLabelClient>
                                        <WDLabelDetailsGap className="label_responsive">
                                          {'(Optional)'}
                                        </WDLabelDetailsGap>
                                      </Row>
                                    </FeatureInfoColumn>
                                    <FeatureInfoColumn>
                                      {readOnly ? (
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {otherPhone.label !== 'Select'
                                              ? otherPhone.label
                                              : '--'}
                                          </Label>
                                        </WDLabelClient>
                                      ) : (
                                        <SelectDropdown
                                          placeholder="Select"
                                          width={'100%'}
                                          options={phoneTypes}
                                          name={'businessPhone'}
                                          onChange={(option) => {
                                            setOtherPhone(option as OptionProps)
                                            setDetectChangeContact(true)
                                          }}
                                          value={otherPhone}
                                        />
                                      )}
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                  {(otherPhone.label === 'Business' ||
                                    otherPhone.label === 'BusinessPhone') && (
                                    <FeatureInfoRow>
                                      <FeatureInfoColumn>
                                        <WDLabelClient className="label_responsive">
                                          <Label>{'Business Phone'}</Label>
                                        </WDLabelClient>
                                      </FeatureInfoColumn>
                                      <FeatureInfoColumn>
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            <Label>{businessPhone.label}</Label>
                                          </WDLabelClient>
                                        ) : (
                                          <SelectDropdown
                                            placeholder="Select"
                                            width={'100%'}
                                            options={contactOptions}
                                            name={'businessPhone'}
                                            onChange={(option) => {
                                              setBusinessPhone(
                                                option as OptionProps
                                              )
                                              setDetectChangeContact(true)
                                            }}
                                            value={businessPhone}
                                          />
                                        )}
                                      </FeatureInfoColumn>
                                    </FeatureInfoRow>
                                  )}
                                  {(otherPhone.label === 'Home' ||
                                    otherPhone.label === 'HomePhone') && (
                                    <FeatureInfoRow>
                                      <FeatureInfoColumn>
                                        <WDLabelClient className="label_responsive">
                                          <Label>{'Home Phone'}</Label>
                                        </WDLabelClient>
                                      </FeatureInfoColumn>
                                      <FeatureInfoColumn>
                                        {readOnly ? (
                                          <WDLabelClient className="label_responsive">
                                            <Label>{homePhone.label}</Label>
                                          </WDLabelClient>
                                        ) : (
                                          <SelectDropdown
                                            placeholder="Select home phone"
                                            width={'100%'}
                                            options={contactOptions}
                                            name={'homePhone'}
                                            onChange={(option) => {
                                              setHomePhone(
                                                option as OptionProps
                                              )
                                              setDetectChangeContact(true)
                                            }}
                                            value={homePhone}
                                          />
                                        )}
                                      </FeatureInfoColumn>
                                    </FeatureInfoRow>
                                  )}
                                  {otherPhone.label === 'Fax' && (
                                    <FeatureInfoRow>
                                      <FeatureInfoColumn>
                                        <WDLabelClient className="label_responsive">
                                          <Label>{'Fax'}</Label>
                                        </WDLabelClient>
                                      </FeatureInfoColumn>
                                      <FeatureInfoColumn>
                                        {readOnly ? (
                                          <WDLabelClient>
                                            <Label>{fax.label}</Label>
                                          </WDLabelClient>
                                        ) : (
                                          <SelectDropdown
                                            placeholder="Select fax"
                                            width={'100%'}
                                            options={contactOptions}
                                            name={'fax'}
                                            onChange={(option) => {
                                              setFax(option as OptionProps)
                                              setDetectChangeContact(true)
                                            }}
                                            value={fax}
                                          />
                                        )}
                                      </FeatureInfoColumn>
                                    </FeatureInfoRow>
                                  )}
                                </FirstHalfWrapper>
                                <FirstHalfWrapper>
                                  <FeatureInfoRow>
                                    <FeatureInfoColumn>
                                      <LabelWrapper>
                                        <WDLabelClient className="label_responsive">
                                          <Label>{'Email Address*'}</Label>
                                        </WDLabelClient>
                                        {contactError?.email && (
                                          <WDLabelError className="label_responsive">
                                            <Label>{'Required'}</Label>
                                          </WDLabelError>
                                        )}
                                      </LabelWrapper>
                                    </FeatureInfoColumn>
                                    <FeatureInfoColumn>
                                      {readOnly ? (
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {emailAddress.label !== 'Select'
                                              ? emailAddress.label
                                              : '--'}
                                          </Label>
                                        </WDLabelClient>
                                      ) : (
                                        <SelectDropdown
                                          placeholder="Select email address for eDelivery"
                                          width={'100%'}
                                          border={contactError?.email}
                                          options={emailOptions}
                                          name={'email'}
                                          onChange={(option) => {
                                            setEmailAddress(
                                              option as OptionProps
                                            )
                                            setDetectChangeContact(true)
                                            setContactError({
                                              ...contactError,
                                              email: false,
                                            })
                                          }}
                                          value={emailAddress}
                                        />
                                      )}
                                    </FeatureInfoColumn>
                                  </FeatureInfoRow>
                                </FirstHalfWrapper>
                              </InfoItemWrapper>
                              <InfoItemWrapper>
                                <FeatureInfoRow>
                                  <FeatureInfoColumn>
                                    <WDLabelClient className="label_responsive">
                                      <Label>
                                        {'FIS Account Title and Address'}
                                      </Label>
                                    </WDLabelClient>
                                  </FeatureInfoColumn>
                                  <FeatureInfoColumn>
                                    {convertAddress()}
                                  </FeatureInfoColumn>
                                </FeatureInfoRow>
                              </InfoItemWrapper>
                            </FirstRowWrapper>
                          </div>
                        </DocumentRowContainer>
                      )}
                  </>
                )}
              </FormContainer>

              {/* <FundingButtonWrapper>
                
                {accessList.contactInfo && !readOnly && (
                  <WDButtonOutLine>
                    <Button
                      type={'button'}
                      onClick={() => {
                        popupConfirmation()
                      }}
                    >
                      {contactCardLoader ? (
                        <SpinnerButtonWrapper>
                          <LottieButtonWrapper>
                            <Lottie animationData={Loader} loop={true} />
                          </LottieButtonWrapper>
                        </SpinnerButtonWrapper>
                      ) : buttonTextContact === 'update' ? (
                        <WDLabelPrimaryText className="label_responsive">
                          {'Save'}
                        </WDLabelPrimaryText>
                      ) : (
                        <WDLabelPrimaryText className="label_responsive">
                          {'Save'}
                        </WDLabelPrimaryText>
                      )}
                    </Button>
                  </WDButtonOutLine>
                )}
              </FundingButtonWrapper> */}
            </AddressWrapper>
          )}
        </AppInfoWrapper>
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default ContactInformation
