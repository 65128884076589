import { StyledDiv } from './styles'

export const SvgError = () => {
  return (
    <StyledDiv>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="matrix(-1 0 0 1 24 0)"
          fill="#EA001E"
        />
        <path
          d="M12.9439 12.0027L15.8105 9.14273C15.9361 9.01719 16.0066 8.84693 16.0066 8.66939C16.0066 8.49186 15.9361 8.3216 15.8105 8.19606C15.685 8.07053 15.5147 8 15.3372 8C15.1597 8 14.9894 8.07053 14.8639 8.19606L12.0039 11.0627L9.14387 8.19606C9.01833 8.07053 8.84807 8 8.67053 8C8.493 8 8.32273 8.07053 8.1972 8.19606C8.07166 8.3216 8.00114 8.49186 8.00114 8.66939C8.00114 8.84693 8.07166 9.01719 8.1972 9.14273L11.0639 12.0027L8.1972 14.8627C8.13471 14.9247 8.08512 14.9984 8.05127 15.0797C8.01743 15.1609 8 15.2481 8 15.3361C8 15.4241 8.01743 15.5112 8.05127 15.5924C8.08512 15.6737 8.13471 15.7474 8.1972 15.8094C8.25917 15.8719 8.33291 15.9215 8.41415 15.9553C8.49539 15.9892 8.58252 16.0066 8.67053 16.0066C8.75854 16.0066 8.84568 15.9892 8.92692 15.9553C9.00816 15.9215 9.08189 15.8719 9.14387 15.8094L12.0039 12.9427L14.8639 15.8094C14.9258 15.8719 14.9996 15.9215 15.0808 15.9553C15.1621 15.9892 15.2492 16.0066 15.3372 16.0066C15.4252 16.0066 15.5123 15.9892 15.5936 15.9553C15.6748 15.9215 15.7486 15.8719 15.8105 15.8094C15.873 15.7474 15.9226 15.6737 15.9565 15.5924C15.9903 15.5112 16.0077 15.4241 16.0077 15.3361C16.0077 15.2481 15.9903 15.1609 15.9565 15.0797C15.9226 14.9984 15.873 14.9247 15.8105 14.8627L12.9439 12.0027Z"
          fill="white"
        />
      </svg>
    </StyledDiv>
  )
}
