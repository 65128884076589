import { useContext, useEffect, useRef, useState } from 'react'
import Divider from '../../../components/divider/Divider'
import { SvgAccountType } from '../../../components/svg/SvgAccountType'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import { WDLabelStyledCardHeader } from '../../../components/ui/WDLabel'
import {
  ApprovalWrapper,
  ItemWrapper,
} from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { COLORS } from '../../../theme/Colors'
import { StyledDocumentPanel } from '../accountInformation/styles'
import ReviewInformation from '../reviewSection/ReviewInformation'
import {
  AppInfoWrapper,
  Container,
  ContentWrapper,
  DividerWrapper,
  PersonalInfoWrapper,
  TitleGrouping,
  WipHeadingWrapper,
} from './styles'
import FormDisclosure from './tab/FormDisclosure'
import {
  GetWipRequestInterface,
  CreateWipRequestInterface,
} from '../formWip/IFormWip'
import { getWipDetails } from '../../../services/formRequest/FormRequestAPI'
import { ClientSearchByNameResponseInterface } from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'

interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
}
const ApprovalsPage = ({ stage, setStage }: FormWipProps) => {
  const [toggleHeader, setToggleHeader] = useState(true)
  const {
    wipId,
    newModelData,
    setNewModelData,
    setWipFormData,
    setSelectedClient,
  } = useContext(ApplicationInfoContext)
  const scrollReference = useRef<any>(null)

  // const handleInfiniteScroll = async () => {
  //   console.log('scrollTop ', scrollReference?.current?.scrollTop)
  // }

  // useEffect(() => {
  //   console.log(' useEffect', scrollReference)
  //   scrollReference?.current?.addEventListener('scroll', handleInfiniteScroll)

  //   return () => {
  //     scrollReference?.current?.removeEventListener('scroll', () => {
  //       // scrollReference?.current?.scrollTo(0, 0)
  //       console.log('cleared')
  //     })
  //   }
  // }, [])

  useEffect(() => {
    if (Object.keys(newModelData).length === 0 && wipId) {
      const payload: GetWipRequestInterface = {
        appId: '1',
        wipId: parseInt(wipId),
      }
      getWipDetails(payload)
        .then((getWipRecord) => {
          setNewModelData((prevState: any) => {
            return {
              ...prevState,
              repCode: getWipRecord.data.wipDTO?.repcode,
              applicationNickname:
                getWipRecord.data.wipDTO?.applicationnickname,
              partyId: getWipRecord.data.wipDTO?.partyid,
              advisor: getWipRecord.data.wipDTO?.advisor,
            }
          })
          setNewModelData(getWipRecord.data)
          setSelectedClient((prev: ClientSearchByNameResponseInterface) => {
            return {
              ...prev,
              clientId: getWipRecord.data.wipDTO?.partyid,
            }
          })
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [newModelData, setSelectedClient, setWipFormData, setNewModelData, wipId])
  return (
    <ApprovalWrapper>
      <ItemWrapper sm={6} md={4} lg={3} id="full_col" ref={scrollReference}>
        <Container>
          <StyledDocumentPanel>
            <AppInfoWrapper>
              <WipHeadingWrapper
                onClick={() => {
                  if (wipId) setToggleHeader(!toggleHeader)
                }}
              >
                <TitleGrouping>
                  <SvgAccountType fillColor={COLORS.Icons.PrimaryActive} />
                  <WDLabelStyledCardHeader className="header_responsive">
                    {'Approvals'}
                  </WDLabelStyledCardHeader>
                </TitleGrouping>
                {wipId && !toggleHeader && (
                  <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
                )}
                {wipId && toggleHeader && (
                  <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
                )}
              </WipHeadingWrapper>
              {toggleHeader && (
                <DividerWrapper>
                  <Divider
                    horizontal={true}
                    bgColor={`${COLORS.Background.Neutral}`}
                    thickness={'0.1rem'}
                  />
                </DividerWrapper>
              )}
              {toggleHeader && (
                <ContentWrapper>
                  <PersonalInfoWrapper>
                    <FormDisclosure />
                  </PersonalInfoWrapper>
                </ContentWrapper>
              )}
            </AppInfoWrapper>
          </StyledDocumentPanel>
        </Container>
      </ItemWrapper>
      <ReviewInformation />
    </ApprovalWrapper>
  )
}

export default ApprovalsPage
