import Lottie from 'lottie-react'
import { useCallback, useContext, useEffect, useState } from 'react'
import Loader from '../../../../../../assets/lottiefiles/loader.json'
import Button from '../../../../../../components/button/Button'
import CheckBoxNew from '../../../../../../components/checkbox/CheckBoxNew'
import Divider from '../../../../../../components/divider/Divider'
import Label from '../../../../../../components/label/Label'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../../../components/svg/SvgChevron'
import { SvgProductType } from '../../../../../../components/svg/SvgHeaders'
import DepositBankListTable from '../../../../../../components/table/DepositBankListTable'
import { Data } from '../../../../../../components/table/ITable'
import { Toast } from '../../../../../../components/toast/Toast'
import { WDButtonOutLine } from '../../../../../../components/ui/WDButton'
import {
  StyledLabelError,
  WDLabelBold,
  WDLabelClient,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../../../components/ui/WDLabel'
import { AppId } from '../../../../../../constant/AppId'
import { ResponsiveWrapper } from '../../../../../../pages/accountApplicationPage/styles'
import AccountFeatureContext, {
  IAccountFeatureStore,
} from '../../../../../../services/AccountFeature/AccountFeatureContext'
import { ApplicationInfoContext } from '../../../../../../services/applicationContext/ApplicationPageContext'
import { getWipDetails } from '../../../../../../services/formRequest/FormRequestAPI'
import { LobInfoContext } from '../../../../../../services/LobOfficeCode/LobOfficeContext'
import { RoleContext } from '../../../../../../services/roleContext/RoleContext'
import ToastContext from '../../../../../../services/toast/ToastContext'
import { getXrefDatabyDomainCode } from '../../../../../../shared/Comman'
import XrefContext from '../../../../../../store/XrefContext'
import { COLORS } from '../../../../../../theme/Colors'
import {
  DynamicFormResponseInterface,
  FormErrorInterface,
  ValueJsonInterface,
} from '../../../../../dynamicForm/IDynamicFormControls'
import { LottieWrapper } from '../../../../../interceptor/styles'
import DynamicFormControl from '../../../../accountFeatures/AccountFeatures'
import { StyledDocumentPanel } from '../../../../accountInformation/styles'
import {
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../../../electronicPrefs/styles'
import { FormContainer } from '../../../../formDisclosure/style'
import {
  TitleGrouping,
  ToastWrapper,
  WipHeadingWrapper,
} from '../../../../formWip/styles'
import { AccFeatureWrapper, AccountWrapper } from '../AccountFeature/styles'
import { DividerWrapper } from '../ApplicantInfo/styles'
import { FundingButtonWrapper } from '../FundingInfo/styles'
import { ObjInterface, Question, Section } from './IProductFeatures'
import { DisabledWrapper, SpinnerWrapper, TableWrapper } from './style'
interface AccountIProps {
  isAccountDetailsOpen?: boolean
  isReview?: boolean
}
export interface OnSaveInterface {
  sectionqsnid?: string
  value?: string
  resAcctNo?: string
  AcctTypeId?: number
}
const ProductFeatures = (props: AccountIProps) => {
  const { setToast } = useContext(ToastContext)
  const { lobOfcCode } = useContext(LobInfoContext)
  const { accessList } = useContext(RoleContext)
  const {
    wipId,
    autoSaveFunction,
    questionValue,
    setQuestionValue,
    readOnly,
    resNo,
    setResNo,
    loaderProductIcon,
    setXrefDetails,
    accountNumber,
    setReserveError,
    formErrorProdFeature,
    setFormErrorProdFeature,
    sectionConfig,
    setSectionConfig,
    newModelData,
    setNewModelData,
    isDashboardNavigate,
    saveFlag,
    setSaveFlag,
    setLoaderProductIcon,
  } = useContext(ApplicationInfoContext)
  const xrefStore = useContext(XrefContext)
  // const [showPopup, setShowPopup] = useState(false)
  const { getAccountConfig } = useContext<IAccountFeatureStore>(
    AccountFeatureContext
  )

  // const [sectionConfig, setSectionConfig] = useState<Section[]>([])
  const [qnsValueResponse, setQnsValueResponse] = useState<
    ValueJsonInterface[]
  >([])
  const [tableLoader, setTableLoader] = useState(false)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [accountConfig, setAccountConfig] = useState<[]>([])
  const [, setSectionToggle] = useState<ObjInterface>([])
  const [bankList, setBankList] = useState<any[]>([])
  const [selectedBank, setSelectedBank] = useState<any>([])
  const [isBankError, setIsBankError] = useState(false)
  const [featureStatus, setFeatureStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const [splitData, setSplitData] = useState<{
    data1: string[]
    data2: string[]
  }>({
    data1: [],
    data2: [],
  })
  const [initialGetBank, setInitialGetBank] = useState<any>([])
  const getSectionToggleCreate = (result: DynamicFormResponseInterface[]) => {
    const obj: ObjInterface = {}
    result &&
      result.forEach((item) => {
        obj[item.sectionid] = true
      })
    setSectionToggle(obj)
  }

  const isValid = () => {
    let isValid = true
    // Object.keys(formError).forEach((key) => {
    //   if (formError[key]) {
    //     isValid = false
    //   }
    // })
    if (!accountNumber) {
      setReserveError(true)
      isValid = false
    }
    return isValid
  }

  // Transform the JSON array to the desired structure
  const transformJSON = (json: Section[]): Section[] => {
    json.map((section, secIndex) => {
      section.objQuestions.map((question, questionIndex) => {
        if (
          question.controltype.toLocaleLowerCase() === 'subheader' &&
          question.grpqsnid !== null
        ) {
          const filterAndDelete: number[] = JSON.parse(question.grpqsnid)
          const subHeaderGroup: Question[] = []

          filterAndDelete.map((id) => {
            const subHeaderQuesInd = json[secIndex]['objQuestions'].findIndex(
              ({ sectionqsnid }) => sectionqsnid === id
            )
            if (subHeaderQuesInd) {
              subHeaderGroup.push(
                json[secIndex]['objQuestions'][subHeaderQuesInd]
              )
            }

            json[secIndex]['objQuestions'].splice(subHeaderQuesInd, 1)
          })

          json[secIndex]['objQuestions'].splice(
            questionIndex + 1,
            0,
            ...subHeaderGroup
          )
        }
      })
    })
    return json
  }

  const setInitialFormError = (json: Section[], valueJSON: any) => {
    let errorFlag = false
    json.forEach((section) => {
      section.objQuestions.forEach((question) => {
        if (question.isrequired === 'y') {
          errorFlag = !valueJSON[question.sectionqsnid] ? true : false
          setFormErrorProdFeature((prevState: FormErrorInterface) => {
            return {
              ...prevState,
              // [question.sectionqsnid]: valueJSON
              //   ? getValidationIfRequired(question.sectionqsnid, valueJSON)
              //   : 'Required',
              [question.sectionqsnid]: !valueJSON[question.sectionqsnid]
                ? 'Required'
                : '',
            }
          })
        }
      })
    })
    return errorFlag
  }
  const getAccountInfo = useCallback(
    async (newModelData: any) => {
      const payload = {
        acctypeid: newModelData.wipAccountDetailslist[0].accounttypeid, //individual type,
        acctsubtypeid: newModelData.wipAccountDetailslist[0].subaccounttypeid,
        lob: 'WEALTH',
        tabid: 1,
      }
      // setTableLoader(true)
      getAccountConfig(payload).then((accountConfig) => {
        setAccountConfig(accountConfig.data)
        // const appid = AppId.appid
        if (
          newModelData &&
          newModelData.wipDetails &&
          newModelData.wipDetails.wipid
        ) {
          const payload = {
            appid: AppId.appid,
            lob: newModelData?.wipDetails?.lobid,
          }
          xrefStore
            .getXrefDetails(payload)
            .then((response) => {
              setXrefDetails(response)
              if (accountConfig.data.length > 0) {
                accountConfig.data[0].objQuestions.sort(function (
                  a: DynamicFormResponseInterface,
                  b: DynamicFormResponseInterface
                ) {
                  return a.controlerseqno - b.controlerseqno
                })
              }
              getSectionToggleCreate(accountConfig.data)
              const transQues = transformJSON(accountConfig.data)
              setSectionConfig(transQues)
              setTableLoader(false)
              if (
                newModelData &&
                newModelData?.wipAccountDetailslist &&
                newModelData?.wipAccountDetailslist.length > 0
              ) {
                const valueJsonRes = JSON.parse(
                  newModelData?.wipAccountDetailslist[0]?.accountfeatures
                )
                // construct local state
                const tempArr: ValueJsonInterface[] = []
                if (valueJsonRes?.accountType) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'accountType'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.accountType,
                      value: valueJsonRes?.accountTypeId,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.nonObjBenefOwner) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'nonObjBenefOwner'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.nonObjBenefOwner,
                      value: '',
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.optionsAccount) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'optionsAccount'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.optionsAccount,
                      value: valueJsonRes?.optionsAccountId,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.creditPlusAccount) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'creditPlusAccount'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.creditPlusAccount,
                      value: valueJsonRes?.creditPlusAccountId,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.requestCheckTitle) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'requestCheckTitle'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.requestCheckTitle,
                      value: '',
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.houseHold) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'houseHold'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.houseHold,
                      value: valueJsonRes?.houseHoldId,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.primaryAccNum) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'primaryAccNum'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.primaryAccNum,
                      value: '',
                      type: getObject?.controlid,
                    },
                  })
                }

                if (valueJsonRes?.jointType) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'jointType'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.jointType,
                      value: valueJsonRes?.jointTypeid,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.stategift) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'stategift'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.stategift,
                      value: valueJsonRes?.stategiftid,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.fdicSweep) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'fdicSweep'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes?.fdicSweep,
                      value: valueJsonRes?.fdicSweepid,
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.['Dividend&Interest']) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'Dividend&Interest'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes['Dividend&Interest'],
                      value: valueJsonRes['Dividend&InterestId'],
                      type: getObject?.controlid,
                    },
                  })
                }
                if (valueJsonRes?.reserveAcctNo) {
                  const getObject = transQues?.[0]?.objQuestions?.find(
                    (obj) => obj.controlid === 'reserveAcctNo'
                  )
                  tempArr.push({
                    domncode: getObject?.domaincode ?? '',
                    qsntext: getObject?.qsntext ?? '',
                    sectionqsnid: getObject?.sectionqsnid ?? '',
                    value: {
                      label: valueJsonRes.reserveAcctNo,
                      value: '',
                      type: getObject?.controlid,
                    },
                  })
                  setResNo(valueJsonRes.reserveAcctNo)
                } else setResNo('')
                if (valueJsonRes?.banklist) {
                  setInitialGetBank(valueJsonRes?.banklist)
                } else setInitialGetBank([])
                setQnsValueResponse(tempArr)
                //update bank
                // const getSavedBank = valueJsonRes?.find(
                //   (obj: any) => obj.sectionqsnid === 'bank_list'
                // )
                // if (getSavedBank && getSavedBank.value) {
                //   setSelectedBank(getSavedBank.value)
                // }
                // const acctypeVal = valueJsonRes?.find(
                //   (item: { controlid: string }) =>
                //     item.controlid == 'accountType'
                // )
                // if (acctypeVal?.value) {
                //   const getQsnId = transQues[0].objQuestions.findIndex(
                //     (item: { controlid: string }) =>
                //       item.controlid === 'optionsAccount'
                //   )
                //   if (getQsnId !== -1) {
                //     const getDuplicate = Object.assign(
                //       {},
                //       transQues[0].objQuestions[getQsnId]
                //     )
                //     if (acctypeVal.value.value == '1') {
                //       getDuplicate.domaincode = 'OPTTRADING-CASH'
                //     } else {
                //       getDuplicate.domaincode = 'OPTTRADING-MARGIN'
                //     }
                //     transQues[0].objQuestions[getQsnId] = getDuplicate
                //     setSectionConfig([...transQues])
                //   }
                // } else setSectionConfig(transQues)
                //set account
                // if (valueJsonRes?.length > 0) {
                //   const getResAccount = valueJsonRes?.filter(
                //     (obj: any) => !obj.sectionqsnid
                //   )

                //   if (getResAccount.length > 0 && getResAccount[0]?.resAcctNo) {
                //     setResNo(getResAccount[0].resAcctNo)
                //   }
                // }
              }
            })
            .catch(() => {
              setTableLoader(false)
            })
        }
      })
    },
    [
      newModelData,
      getAccountConfig,
      xrefStore,
      setXrefDetails,
      setSectionConfig,
      setResNo,
    ]
  )
  useEffect(() => {
    if (saveFlag && newModelData?.wipAccountDetailslist?.[0]?.wipacctid) {
      getAccountInfo(newModelData)
      setSaveFlag(false)
    }
  }, [newModelData, saveFlag])
  useEffect(() => {
    if (wipId && newModelData?.wipAccountDetailslist?.[0]?.wipacctid) {
      if (!isDashboardNavigate) {
        getWipDetails({
          appId: '1',
          wipId: newModelData?.wipDetails?.wipid,
        })
          .then((getWipRecord) => {
            setNewModelData(getWipRecord.data)
            getAccountInfo(getWipRecord.data)
          })
          .catch((err) => {
            console.log('error', err)
          })
      } else {
        getAccountInfo(newModelData)
      }
    }
  }, [newModelData?.wipDetails?.wipid, wipId])

  const submitOnConfirmation = () => {
    const getValidate = setInitialFormError(sectionConfig, questionValue)
    // setTimeout(() => {
    setFormErrorProdFeature({})
    // }, 5000)
    if (
      // isValid() &&
      // Object.keys(resNo).length > 0 &&
      // !reserveError &&
      getValidate
    ) {
      autoSaveFunction().then((resolve: any) => {
        if (resolve == 200) {
          getWipDetails({
            appId: '1',
            wipId: parseInt(wipId),
          })
            .then((getWipRecord) => {
              setNewModelData(getWipRecord.data)
              getAccountInfo(getWipRecord.data)
              setToast('success', 'Product Features saved')
            })
            .catch((err) => {
              console.log('error', err)
            })
        }
      })
    }
  }

  const checkDepositBank = () => {
    if (sectionConfig.length === 0) return false
    const getQsnId = sectionConfig[0].objQuestions.find(
      (item: any) => item.controlerseqno === 9
    )
    if (getQsnId) {
      const getSecId: any = getQsnId.sectionqsnid
      const selectedValue: any =
        questionValue[getSecId as keyof DynamicFormResponseInterface]
      if (selectedValue && selectedValue.label === 'Yes') {
        return true
      }
    }
    return false
  }
  const bindAllXrefToState = (config: Section[]) => {
    if (!xrefStore.xrefDetails) return
    const getDomCode = 'DEPOBANK'
    const dropdownXrefData = getXrefDatabyDomainCode(
      xrefStore.xrefDetails,
      getDomCode
    )
    const dropdownOptions: any[] = []
    dropdownXrefData.map((feature) => {
      dropdownOptions.push({
        label: feature.value,
        value: feature.location,
        ischecked: false,
        code: feature.code,
      })
    })
    setBankList(dropdownOptions)
    if (initialGetBank.length > 0) {
      const tempBankArr: any = []
      initialGetBank?.forEach((item: string) => {
        const findFList = dropdownOptions.find((sItem) => sItem.label === item)
        if (findFList) {
          tempBankArr.push({ ...findFList, ischecked: true })
        }
      })
      setSelectedBank(tempBankArr)
      setQuestionValue((prevState: DynamicFormResponseInterface) => {
        return {
          ...prevState,
          ['bank_list']: tempBankArr,
        }
      })
    } else {
      setSelectedBank(dropdownOptions)
      setQuestionValue((prevState: DynamicFormResponseInterface) => {
        return {
          ...prevState,
          ['bank_list']: dropdownOptions,
        }
      })
    }
  }

  const tableColumns = [
    {
      Header: '',
      accessor: 'Check',

      Cell: (row: any) => {
        return (
          <DisabledWrapper
            readOnly={readOnly}
            checked={
              selectedBank.find(
                (item: any) => item.code === row.row.original.code
              )?.ischecked
            }
          >
            <CheckBoxNew
              title={row.cell.value}
              value={row.cell.value}
              checked={
                selectedBank.find(
                  (item: any) => item.code === row.row.original.code
                )?.ischecked
              }
              disabled={readOnly}
              onChange={() => {
                const getValue = row.row.original.code
                const existArr = selectedBank.find(
                  (item: any) => item.code === getValue
                )
                if (existArr) {
                  existArr.ischecked = !existArr.ischecked
                } else {
                  const updatedRow = { ...row.row.original, ischecked: true }
                  selectedBank.push(updatedRow)
                }
                const unCheckedBankDetails1 = selectedBank.filter(
                  (item: any) => !item.ischecked
                )
                if (
                  bankList.length > 0 &&
                  bankList.length === selectedBank.length &&
                  selectedBank.filter((obj: any) => !obj.ischecked)?.length ===
                    0
                ) {
                  setIsBankError(true)
                } else setIsBankError(false)
                setSelectedBank([...selectedBank])
                setQuestionValue((prevState: DynamicFormResponseInterface) => {
                  return {
                    ...prevState,
                    ['bank_list']: selectedBank,
                  }
                })
              }}
            />
          </DisabledWrapper>
        )
      },
    },
    {
      Header: 'Program Bank',
      accessor: 'label',
    },
    { Header: 'Location', accessor: 'value' },
  ]

  const splitTable = () => {
    if (bankList.length > 5) {
      const half = Math.ceil(bankList.length / 2)
      const data1 = bankList.slice(0, half)
      const data2 = bankList.slice(half)
      setSplitData({ data1: data1, data2: data2 })
    }
  }
  useEffect(() => {
    if (bankList && bankList.length > 5) {
      splitTable()
    }
  }, [bankList])

  useEffect(
    () => {
      bindAllXrefToState(sectionConfig)
      // DepoBankList()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionConfig]
  )
  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel>
        <WipHeadingWrapper onClick={() => setToggleHeader(!toggleHeader)}>
          <TitleGrouping>
            <SvgProductType />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Product Features'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>

          {!toggleHeader && (
            <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
          )}
          {toggleHeader && (
            <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
          )}
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            <AccFeatureWrapper>
              <AccountWrapper props={props}>
                <FormContainer>
                  {!tableLoader && !loaderProductIcon ? (
                    <>
                      <DynamicFormControl
                        questionValue={questionValue}
                        setQuestionValue={setQuestionValue}
                        config={sectionConfig}
                        setSectionConfig={setSectionConfig}
                        questionValueRes={qnsValueResponse}
                        formError={formErrorProdFeature}
                        setFormError={setFormErrorProdFeature}
                        isReview={props.isReview}
                        resNo={resNo}
                        setResNo={setResNo}
                        fdicSweepBank={() => {
                          setQuestionValue(
                            (prevState: DynamicFormResponseInterface) => {
                              return {
                                ...prevState,
                                ['bank_list']: selectedBank,
                              }
                            }
                          )
                        }}
                      />
                      {checkDepositBank() && (
                        <>
                          <WDLabelBold className="label_responsive">
                            <Label>{'Deposit Bank List'}</Label>
                          </WDLabelBold>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {`Upon enrolling in the insured Deposit Program (the "Program"), all available cash balances will be transferred into deposit accounts at the Program banks and insured up to $2,500,000 for individual accounts and $5,000,000 for joint accounts. Cash deposits are subjected to FDIC insurable limits. Please read the enclosed Terms and Conditions carefully as they describe the program in further detail`}
                            </Label>
                          </WDLabelClient>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {`Please review the list of participating banks below and exclude, via check mark, any financial
institution meeting one of the following conditions.`}
                            </Label>
                          </WDLabelClient>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {`    
 
1 - You already have a cash deposit of $250,0000 or $500,000 if a joint account , at that bank.`}
                            </Label>
                          </WDLabelClient>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {`
OR
`}
                            </Label>
                          </WDLabelClient>{' '}
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {`
2 - Your aggregate deposit(s) with that bank, along with your cash deposit(s) would exceed $250,000 or $500,000 if a joint account.`}
                            </Label>
                          </WDLabelClient>
                          <TableWrapper>
                            <DepositBankListTable
                              tableColumns={tableColumns}
                              tableData={splitData.data1 as unknown as Data[]}
                              onClick={() => ''}
                            />
                            {bankList.length > 5 && (
                              <DepositBankListTable
                                tableColumns={tableColumns}
                                tableData={splitData.data2 as unknown as Data[]}
                                onClick={() => ''}
                              />
                            )}
                          </TableWrapper>
                          {isBankError ? (
                            <StyledLabelError className="label_responsive">
                              {'*Please uncheck at least one bank'}
                            </StyledLabelError>
                          ) : null}
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {`Wedbush Insured Deposit Program satisfies the FDIC’s requirements for agency pass‐through deposit insurance coverage. Program banks in the network are FDIC‐ insured banks and savings associations as those terms are defined in the Federal Deposit Insurance Act. The FDIC insurance limit is $250,000, or $500,000 if a joint account, in each eligible account type per depositor per bank. Please carefully review the disclosure document for detailed information regarding FDIC insurance limits. The Wedbush Insured Deposit program currently offers $2.5 million of FDIC insurance per eligible account. While you may exclude any number of banks you choose, the maximum level of FDIC insurance may decrease from $2.5 million to a lower coverage level as a result. Please contact your Financial Advisor with any questions. `}
                            </Label>
                          </WDLabelClient>
                        </>
                      )}
                    </>
                  ) : (
                    <SpinnerWrapper>
                      <LottieWrapper>
                        <Lottie animationData={Loader} loop={true} />
                      </LottieWrapper>
                    </SpinnerWrapper>
                  )}
                </FormContainer>
              </AccountWrapper>
              {featureStatus.status !== '' && (
                <ToastWrapper>
                  <Toast
                    text={featureStatus.text}
                    type={featureStatus.status}
                    openStatusCallback={() =>
                      setFeatureStatus({
                        status: '',
                        text: '',
                      })
                    }
                  />
                </ToastWrapper>
              )}

              {/* <FundingButtonWrapper>
                {accessList.productFeatures && !readOnly && (
                  <WDButtonOutLine>
                    <Button
                      type={'button'}
                      disabled={isBankError}
                      onClick={() => {
                        submitOnConfirmation()
                        const getValidate = setInitialFormError(
                          sectionConfig,
                          questionValue
                        )
                        if (!getValidate) {
                          setLoaderProductIcon(true)
                          autoSaveFunction().then((resolve) => {
                            if (resolve == 200) {
                              getWipDetails({
                                appId: '1',
                                wipId: parseInt(wipId),
                              }).then((getWipRecord) => {
                                setNewModelData(getWipRecord.data)
                                getAccountInfo(getWipRecord.data)
                              })
                              setToast('success', 'Product Features saved')
                              setLoaderProductIcon(false)
                            }
                          })
                        }
                      }}
                    >
                      {loaderProductIcon ? (
                        <SpinnerButtonWrapper>
                          <LottieButtonWrapper>
                            <Lottie animationData={Loader} loop={true} />
                          </LottieButtonWrapper>
                        </SpinnerButtonWrapper>
                      ) : (
                        <WDLabelPrimaryText className="label_responsive">
                          {'Save'}
                        </WDLabelPrimaryText>
                      )}
                    </Button>
                  </WDButtonOutLine>
                )}
              </FundingButtonWrapper> */}
            </AccFeatureWrapper>
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default ProductFeatures
