import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { SelectDropdownProps } from './ISelectDropDown'

export const Wrapper = styled.div<SelectDropdownProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: max-content;
  min-width: ${(props: SelectDropdownProps) => props.width};
  width: ${(props: SelectDropdownProps) => props.width};
  @media screen and (min-width: 1440px) {
    .dropdown__control {
      height: 3rem !important;
      border-radius: 0.5rem !important;
      font-size: 16px !important;
      .dropdown__value-container {
        .dropdown__single-value{
          font-size: 16px !important;
        }
      }
    }
  }

  .dropdown__control {
    :hover {
      //background-color: ${COLORS.Background.Primary10};
      //border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
      /* svg path {
        fill: ${COLORS.Background.PrimaryActive};
      } */
    }
    :focus-within {
      /* background-color: ${COLORS.Background.NeutralBorder};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
      svg path {
        fill: ${COLORS.Text.PrimaryTextStrong};
      } */
    }
    :disabled {
      background-color: ${COLORS.Background.StrongBackground};
      border: 1px solid ${COLORS.Text.NeutralTextDisabled};
      svg path {
        fill: ${COLORS.Text.NeutralTextDisabled};
      }
    }
    /* &.dropdown__control--is-focused {
      border: 2px solid ${COLORS.Border.DropdownBorderBlue};
      outline: none;
      transition: none;
      min-height: 32px;
      box-shadow: none;

      div {
        //color: ${COLORS.Text.PrimaryTextStrong};
      }
    } */
    &.dropdown__control--is-disabled {
      border: 1px solid ${COLORS.Text.NeutralTextDisabled};
      div {
        color: ${COLORS.Text.NeutralTextDisabled};
      }
      svg path {
        fill: ${COLORS.Text.NeutralTextDisabled};
      }
    }
  }
  .dropdown__value-container {
  }
  .dropdown__single-value {
    :hover {
      color: ${COLORS.Background.PrimaryActive};
    }
    :focus-within {
      //color: ${COLORS.Text.PrimaryTextStrong};
    }
    :active {
      color: ${COLORS.Text.PrimaryTextStrong};
    }
    :disabled {
      color: ${COLORS.Text.NeutralTextDisabled};
    }
  }
  .dropdown__indicators {
  }
  .dropdown__menu {
    z-index: 9999999 !important;
    ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 5px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.Background.Primary10};
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  }
  .dropdown__menu-list {
    max-height: 200px;
    ::-webkit-scrollbar {
      background: ${COLORS.Background.NeutralBackgroundMedium};
      width: 5px;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      height: 100px;
      background: ${COLORS.UI.Brand1};
      top: 8px;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(167, 175, 188, 0.3);
    }
    ::-webkit-scrollbar-track {
      background: ${COLORS.Background.White};
    }
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.Background.Primary10};
      border-radius: 4px;
    }
  }

  .dropdown__option {
    :last-child {
      border: none;
    }
    :hover {
      background: '#DEEBFF';
    }
    &.dropdown__option--is-selected {
      background-color: ${COLORS.UI.PrimaryText};
      color: ${COLORS.UI.White};
    }
  }
  .dropdown__option--is-focused {
    background: '#DEEBFF';
  }
`
export const LottieButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 2rem;
  padding: 0.5rem;
`