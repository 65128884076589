import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 0.2rem;
`

export const CurrentWrapper = styled.div`
  padding: 0.25rem 0.5rem;
`

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0.5rem 0rem 1.5rem 0rem;
  justify-content: space-between;
`

export const FilterWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`

export const FilterWrapperWireInfo = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`
export const LeftWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const CellWrap = styled.p`
  word-wrap: break-word;
`
export const ButtonWrapper = styled.p`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`
export const BtnTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.Transparent};
  border-radius: 4px;
  cursor: pointer;
  button {
    border: ${COLORS.Border.Transparent};
    background: none;
    color: ${COLORS.Text.Primary};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
    height: 1rem;
  }
`

export const DetailsContainer = styled.div`
  //   background-color: ${() => COLORS.Background.White};
  width: 100%;
  max-height: 70vh;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => COLORS.Background.White};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => COLORS.Background.Primary10};
    border-radius: 4px;
  }
`

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const DashboardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const DashboardContentWrapperOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  background-color: ${COLORS.Background.White};
  flex: 0 1 100%;
`
export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
export const TextBoxButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
  margin-right: 1rem;
  gap: 0.25rem;
  padding-top: 1.5rem;
  max-width: 6rem;
`

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 20%;
`

export const DateWireWarpper = styled.div`
  display: flex;
  margin-left: 14px;
  flex-direction: row;
  flex: 0 1 20%;
`
export const MasterWrapperThree = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`
export const FirstTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
`
export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.7rem;
`
export const WrapperTwo = styled.div`
  display: flex;
  padding-top: 1rem;
  gap: 1rem;
  padding-left: 1rem;
  z-index: 9;
`
export const WrapperOne = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
export const WrapperDivider = styled.div`
  padding: 0.5rem 0.25rem;
`
export const WrapperDividerTwo = styled.div`
  padding: 0.5rem 0.05rem;
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SIZES[3]};
`
// -------------------------

// import styled from "styled-components";
export const TableWrapper = styled.div`
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  table {
    table-layout: flex;
    border-collapse: collapse;
    min-width: 100%;
    flex-direction: column;
    border: none;
    text-align: left;

    thead {
      font-family: 'SFMono-Regular';
      font-style: normal;

      tr {
        display: table-row;
        align-items: center;
        box-shadow: inset 0px -1px 0px ${() => COLORS.UI.Gray30};

        th {
          display: table-cell;
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
          box-shadow: inset 0 -1px 0 ${() => COLORS.UI.Gray30};
          padding: 0.25rem 0 0.25rem 0.5rem;
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }

    tbody {
      font-family: 'SFMono-Regular';
      font-style: normal;
      tr:nth-child(2n) {
        background-color: rgba(223, 237, 255, 0.24) !important;
      }

      tr {
        display: table-row;
        font-family: 'SFMono-Regular';

        &.description {
          border-bottom: 0.25rem solid ${() => COLORS.Border.NeutralMedium};
        }

        &.selected {
          background-color: ${() => COLORS.Background.Gray90};
          border-bottom: 1px solid ${() => COLORS.Border.Primary5};
          td {
            color: ${() => COLORS.UI.PrimaryText};
            font-weight: 600;
          }
        }
        td {
          display: table-cell;
          flex-wrap: wrap;
          // word-break: break-word;
          width: fit-content;
          align-items: center;
          padding: 0.5rem;
          gap: 0.5rem;
          box-shadow: inset 0 -1px 0 ${() => COLORS.UI.Gray30};
          font-size: 0.875rem;
          line-height: 1.25rem;
          &.Attribute {
            font-family: 'SFMono-Regular';
          }
          &#R {
            color: ${() => COLORS.UI.Negative2};
          }
          &#O {
            color: ${() => COLORS.UI.Success2};
          }
          &#C {
            color: ${() => COLORS.UI.PrimaryText};
            &.Status {
              color: ${() => COLORS.UI.NeutralText};
              font-weight: 700;
              font-size: 1rem;
              line-height: 1.25rem;
            }
            &#R {
              color: ${() => COLORS.UI.Negative2};
            }
            &#O {
              color: ${() => COLORS.UI.Success2};
            }
            &#C {
              color: ${() => COLORS.UI.PrimaryText};
            }
            &#Description {
              width: 3.125rem;
            }
          }
        }
      }

      tfoot {
        font-family: 'SFMono-Regular';
        font-style: normal;

        tr {
          display: table-row;
          align-items: center;

          td {
            display: table-cell;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`
export const NoDataWrap = styled.div`
  height: 50vh;
  width: 89vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TableHead = styled.thead`
  background-color: ${() => COLORS.Background.White};
  position: sticky;
  top: 5%;
  z-index: 1;
`

export const TableHeader = styled.th`
  padding: 16px;
  height: 4rem;
  font-weight: bold;
  background: rgba(37, 99, 235, 0.16);
  border-right: 1px solid rgb(204, 204, 204);
`
export const TableBody = styled.tbody``

export const TableRow = styled.tr``

export const TableCell = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
`

export const ShowButton = styled.button`
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
`

export const CompleteButton = styled.button`
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
`

export const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;

    pre {
      overflow-x: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
`

export const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`
export const MasterWrapperModel = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 20%;
  top: 10%;
  right: 20%;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
  background-color: ${COLORS.Background.White};
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 60%;
  word-wrap: break-word;

  border-radius: 4px;
  border: 1px solid ${COLORS.Border.Neutral};
`
export const ModelContent = styled.div`
  overflow-y: scroll;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 75vh;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
