import styled, { css } from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { ButtonProps } from './IButton'

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder
      ? ButtonProps.bgColor
      : ButtonProps.borderLessBgColor};

  color: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder ? ButtonProps.color : COLORS.Text.Primary50};
  border-width: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder ? `1px` : `0`};
  border-style: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder ? `solid` : `0`};
  border-color: ${(ButtonProps: ButtonProps) =>
    ButtonProps.borderColor ? ButtonProps.borderColor : COLORS.Text.Primary50};
  border-radius: ${(ButtonProps: ButtonProps) =>
    ButtonProps.borderRadius ? ButtonProps.borderRadius : `8px`};
  cursor: pointer;
  outline: none;
  padding: ${(ButtonProps: ButtonProps) => ButtonProps.padding};
  &:disabled {
    cursor: no-drop;
  }
  ${(ButtonProps: ButtonProps) =>
    ButtonProps.hover &&
    css`
      &:hover .hover-button {
        background-color: ${COLORS.Text.Gray};
      }
    `};
  height: inherit;
`
