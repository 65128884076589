import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { REMSizes, SIZES, TextSizes, TextWeight } from '../../theme/Sizes'
import { LabelProps } from './ITheming'

export const StyledLabel = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[5]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelBlack = styled.div`
  font-size: ${SIZES[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[5]};
  font-style: normal;
  color: ${COLORS.Text.Black};
`
export const StyledLabelBlackSemiBold = styled.div`
  font-size: ${SIZES[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[5]};
  font-style: normal;
  color: ${COLORS.Text.Black};
`
export const StyledLabelDetails = styled.div`
  font-weight: ${TextWeight[2]};
  font-size: ${SIZES[3]};
  line-height: ${TextSizes[3]};
  color: ${COLORS.UI.Gray};
  font-style: normal;
`
export const StyledLabelAttachment = styled.div`
  font-size: ${SIZES[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
  background: ${COLORS.Background.OffWhite8};
  padding: ${SIZES[1]} ${SIZES[0]};
  border-radius: ${SIZES[0]};
`
export const StyledLabelSmall = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`

export const StyledLabelSmallWithEllipse = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[1]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
  white-space: nowrap;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledLabelSmallOne = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelNeutral = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[7]};
  font-style: normal;
  color: ${COLORS.Text.NeutralTextWeak};
`
export const StyledLabelNeutralWeakSmall = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralTextWeak};
`
export const StyledLabelNeutralWeak = styled.div`
  font-size: ${SIZES[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralTextWeak};
`
export const StyledFormHeading = styled.div`
  font-size: ${TextSizes[2]};
  line-height: ${TextSizes[4]};
  font-style: normal;
  font-family: SourceSansPro-SemiBold;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelClient = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[1]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelCardHeader = styled.div`
  font-size: ${REMSizes[4]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.TextHeader};
`
export const StyledLabelSearchHeader = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelDashboardCard = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelAreaName = styled.div`
  font-size: ${REMSizes[5]};
  font-weight: ${TextWeight[3]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelHeader = styled.div`
  font-size: ${TextSizes[10]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[11]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelSemiBold = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.Gray};
`
export const StyledLabelLogo = styled.div`
  font-size: ${TextSizes[7]};
  font-weight: 'bold';
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.Brand3};
`
export const StyledLabelBold = styled.div`
  font-size: ${SIZES[3]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledLabelPDFHeader = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.Black};
`
export const StyledLabelApprovalHeader = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.Black};
`
export const StyledLabelHeaderNeutralText = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`

export const StyledLabelGray = styled.div`
  font-size: ${SIZES[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.Gray};
`

export const StyledCardHeader = styled.div`
  font-size: ${REMSizes[5]};
  font-weight: ${TextWeight[3]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.Brand2};
`
export const StyledLabelPrimaryText = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.PrimaryText};
`
export const StyledLabelBrand2Text = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.UI.NeutralText};
`
export const StyledLabelPrimaryTextSmall = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[1]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.PrimaryText};
`
export const StyledFormLabelHeading = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${TextSizes[8]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const StyledFormLabel = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[8]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`

export const StyledLabelLarge = styled.div`
  font-size: ${SIZES[4]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`

export const StyledLabelError = styled.div`
  color: ${COLORS.Text.Danger};
  font-size: ${TextSizes[2]};
`
export const StyledLabelCardDashboard = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${TextSizes[4]};
  letter-spacing: 0.025rem;
  color: ${COLORS.Text.Brand2};
`
export const StyledLabelWhite = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  color: ${COLORS.Text.NeutralInverted};
  letter-spacing: 0.025rem;
`
export const StyledLabelDisabled = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => COLORS.Text.DisabledText};
`
const StyledLabelText = styled.div`
  font-weight: ${TextWeight[0]};
  font-size: ${TextSizes[3]};
  line-height: ${SIZES[4]};
  color: ${COLORS.Text.Neutral};
`
const StyledLabelContentHeader = styled.div`
  font-weight: ${TextWeight[3]};
  font-size: ${TextSizes[7]};
  line-height: ${SIZES[6]};
  font-style: normal;
  color: ${COLORS.Text.Brand2};
`
const StyledHead = styled.div`
  font-weight: ${TextWeight[2]}; /*600*/
  font-size: ${TextSizes[7]}; /*26px*/
  line-height: ${TextSizes[8]}; /*32px*/
  color: ${COLORS.Background.PrimaryBgStrongActive};
`
const StyledContactLabel = styled.div`
  font-weight: ${TextWeight[0]}; /*400*/
  font-size: ${TextSizes[4]}; /*18px*/
  line-height: ${TextSizes[5]}; /*24px*/
  color: ${COLORS.Text.Black};
`
const StyledOrderEntryButtonTextSubHeading = styled.div`
  font-weight: ${TextWeight[0]}; /*400*/
  font-size: ${TextSizes[3]}; /*16px*/
  line-height: ${TextSizes[5]}; /*24px*/
  color: ${COLORS.Text.Black};
`
export const WDLabel = ({ children, className, htmlFor }: LabelProps) => {
  return (
    <StyledLabel className={className ? className : ''} htmlFor={htmlFor}>
      {children}
    </StyledLabel>
  )
}

export const WDLabelBlack = ({ children }: LabelProps) => {
  return <StyledLabelBlack>{children}</StyledLabelBlack>
}

export const WDLabelBlackSemiBold = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelBlackSemiBold className={className ? className : ''}>
      {children}
    </StyledLabelBlackSemiBold>
  )
}

export const WDLabelAttachment = ({ children }: LabelProps) => {
  return <StyledLabelAttachment>{children}</StyledLabelAttachment>
}

export const WDLabelSmall = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelSmall className={className ? className : ''}>
      {children}
    </StyledLabelSmall>
  )
}
export const WDLabelDetailsGap = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelSmallOne className={className ? className : ''}>
      {children}
    </StyledLabelSmallOne>
  )
}

export const WDLabelLarge = ({ children }: LabelProps) => {
  return <StyledLabelLarge>{children}</StyledLabelLarge>
}

export const WDLabelClient = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelClient className={className ? className : ''}>
      {children}
    </StyledLabelClient>
  )
}

export const WDLabelCardHeader = ({ children }: LabelProps) => {
  return <StyledLabelCardHeader>{children}</StyledLabelCardHeader>
}

export const WDLabelSearchHeader = ({ children }: LabelProps) => {
  return <StyledLabelSearchHeader>{children}</StyledLabelSearchHeader>
}

export const WDLabelDashboardCard = ({ children }: LabelProps) => {
  return <StyledLabelDashboardCard>{children}</StyledLabelDashboardCard>
}

export const WDLabelHeader = ({ children }: LabelProps) => {
  return <StyledLabelHeader>{children}</StyledLabelHeader>
}
export const WDLabelSemiBold = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelSemiBold className={className ? className : ''}>
      {children}
    </StyledLabelSemiBold>
  )
}

export const WDLabelBold = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelBold className={className ? className : ''}>
      {children}
    </StyledLabelBold>
  )
}

export const WDLabelPDFHeader = ({ children }: LabelProps) => {
  return <StyledLabelPDFHeader>{children}</StyledLabelPDFHeader>
}

export const WDLabelHeaderNeutralText = ({ children }: LabelProps) => {
  return <StyledLabelHeaderNeutralText>{children}</StyledLabelHeaderNeutralText>
}
export const WDLabelGray = ({ children }: LabelProps) => {
  return <StyledLabelGray>{children}</StyledLabelGray>
}

export const WDLabelPrimaryText = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelPrimaryText className={className ? className : ''}>
      {children}
    </StyledLabelPrimaryText>
  )
}

export const WDLabelTextBrand2 = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelBrand2Text className={className ? className : ''}>
      {children}
    </StyledLabelBrand2Text>
  )
}

export const WDLabelPrimaryTextSmall = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryTextSmall>{children}</StyledLabelPrimaryTextSmall>
}

export const WDFormLabelHeading = ({ children }: LabelProps) => {
  return <StyledFormLabelHeading>{children}</StyledFormLabelHeading>
}

export const WDFormLabel = ({ children }: LabelProps) => {
  return <StyledFormLabel>{children}</StyledFormLabel>
}

export const WDLabelNeutral = ({ children }: LabelProps) => {
  return <StyledLabelNeutral>{children}</StyledLabelNeutral>
}
export const WDLabelNeutralWeakSmall = ({ children }: LabelProps) => {
  return <StyledLabelNeutralWeakSmall>{children}</StyledLabelNeutralWeakSmall>
}
export const WDLabelNeutralWeak = ({ children }: LabelProps) => {
  return <StyledLabelNeutralWeak>{children}</StyledLabelNeutralWeak>
}

export const WDLabelError = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelError className={className ? className : ''}>
      {children}
    </StyledLabelError>
  )
}

export const WDStyledLabelCardDashboard = ({
  children,
  className,
}: LabelProps) => {
  return (
    <StyledLabelCardDashboard className={className ? className : ''}>
      {children}
    </StyledLabelCardDashboard>
  )
}

export const WDLabelAreaName = ({ children }: LabelProps) => {
  return <StyledLabelAreaName>{children}</StyledLabelAreaName>
}

export const WDLabelFormHeading = ({ children }: LabelProps) => {
  return <StyledFormHeading>{children}</StyledFormHeading>
}

export const WDLabelStyledCardHeader = ({ children }: LabelProps) => {
  return <StyledCardHeader>{children}</StyledCardHeader>
}

export const WDLabelDetails = ({ children }: LabelProps) => {
  return <StyledLabelDetails>{children}</StyledLabelDetails>
}

export const WDLabelWhite = ({ children, className }: LabelProps) => {
  return (
    <StyledLabelWhite className={className ? className : ''}>
      {children}
    </StyledLabelWhite>
  )
}

export const WDLabelDisabled = ({ children }: LabelProps) => {
  return <StyledLabelDisabled>{children}</StyledLabelDisabled>
}

export const WDTextLabel = ({ children, ...props }: LabelProps) => {
  return <StyledLabelText {...props}>{children}</StyledLabelText>
}

export const WDLabelContentHeader = ({
  children,
  className,
  ...props
}: LabelProps) => {
  return (
    <StyledLabelContentHeader {...props} className={className ? className : ''}>
      {children}
    </StyledLabelContentHeader>
  )
}

export const WDStyledHead = ({ children, ...props }: LabelProps) => {
  return <StyledHead {...props}>{children}</StyledHead>
}

export const WDLabelLogo = ({ children }: LabelProps) => {
  return <StyledLabelLogo>{children}</StyledLabelLogo>
}

export const WDStyledContact = ({ children }: LabelProps) => {
  return <StyledContactLabel>{children}</StyledContactLabel>
}

export const WDStyledOrderEntryButtonTextSubHeading = ({
  children,
  ...props
}: LabelProps) => {
  return (
    <StyledOrderEntryButtonTextSubHeading {...props}>
      {children}
    </StyledOrderEntryButtonTextSubHeading>
  )
}
export const WDStyledApprovalHeader = ({ children, ...props }: LabelProps) => {
  return (
    <StyledLabelApprovalHeader {...props}>{children}</StyledLabelApprovalHeader>
  )
}
