import { useContext, useEffect, useState } from 'react'
import {
  FeatureInfoColumn,
  FormContainer,
  ImageWrapper,
  RowWrapper,
  SocItemWrapper,
  SocWrapper,
} from './style'

import Lottie from 'lottie-react'

import Loader from '../../../../assets/lottiefiles/loader.json'
import { ResponsiveWrapper } from '../../../../pages/accountApplicationPage/styles'
import { StyledDocumentPanel } from '../../accountInformation/styles'
import {
  TitleGrouping,
  ToastWrapper,
  WipHeadingWrapper,
} from '../../formWip/styles'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../components/svg/SvgChevron'
import { COLORS } from '../../../../theme/Colors'
import {
  WDLabel,
  WDLabelClient,
  WDLabelError,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../components/ui/WDLabel'
import { Toast } from '../../../../components/toast/Toast'
import { ApplicationInfoContext } from '../../../../services/applicationContext/ApplicationPageContext'
import { SvgProductType } from '../../../../components/svg/SvgHeaders'
import { DividerWrapper } from '../../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import Divider from '../../../../components/divider/Divider'
import Label from '../../../../components/label/Label'
import { WDTextboxBorderRadius } from '../../../../components/ui/WDTextbox'
import { Textbox } from '../../../../components/textbox/Textbox'
import {
  AccFeatureWrapper,
  AccountWrapper,
  Row,
} from '../../accountForms/formIndividual/tabs/AccountFeature/styles'
import {
  ButtonWrapper,
  CheckBoxGroup,
  RadioWrapper,
  SectionWrapper,
} from '../styles'
import CheckBoxNew from '../../../../components/checkbox/CheckBoxNew'
import {
  WDButtonOutLine,
  WDButtonPrimaryOutlined,
} from '../../../../components/ui/WDButton'
import Button from '../../../../components/button/Button'
import React from 'react'
import {
  FundingButtonWrapper,
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../electronicPrefs/styles'
import Radio from '../../../../components/extra/radio/Radio'
import { getXrefDatabyDomainCode } from '../../../../shared/Comman'
import { DomnCode } from '../../../../constant/DomnCode'
import { OptionProps } from '../../../selectDropdown/ISelectDropDown'
import ToastContext from '../../../../services/toast/ToastContext'
import { GetWipRequestInterface } from '../../formWip/IFormWip'
import { getWipDetails } from '../../../../services/formRequest/FormRequestAPI'
import { SpinnerWrapper } from '../../accountForms/formIndividual/tabs/ProductFeatures/style'
import { LottieWrapper } from '../../../interceptor/styles'

const SourceOfContribution = () => {
  const {
    wipId,
    readOnly,
    xrefDetails,
    sourceData,
    setSourceData,
    amtData,
    setAmtData,
    autoSaveFunction,
    newModelData,
    setNewModelData,
  } = useContext(ApplicationInfoContext)
  const [toggleHeader, setToggleHeader] = useState(true)
  const { setToast } = useContext(ToastContext)

  // const [verifyCheck, setVerifyCheck] = useState(false)
  const [verifyCheck, setVerifyCheck] = useState({
    newFun: false,
    conOfAss: false,
    transferAss: false,
    rollOfQua: false,
    rollOfIra: false,
  })
  const [isFocused, setIsFocused] = useState(false)
  // const [sourceData, setSourceData] = useState<RadioProps>({
  //   value: '',
  //   id: '',
  // })
  // const [amtData, setAmtData] = useState<SocProps[]>([{ year: '', amount: '' }])
  const [loader, setLoader] = useState(false)
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const [sourceRadioData, setSourceRadioData] = useState<OptionProps[]>([])
  const bindAllDropDownsWithXref = () => {
    const RISKTOLOBJ = getXrefDatabyDomainCode(
      xrefDetails,
      DomnCode.SOURCECONTRIBUTIONS
    )
    const localSourceData: OptionProps[] = []
    RISKTOLOBJ.map((element) => {
      localSourceData.push({
        label: element.value,
        value: element.code,
      })
    })
    setSourceRadioData(localSourceData)
  }
  useEffect(() => {
    bindAllDropDownsWithXref()
  }, [xrefDetails])

  const handleSocYearAmt = (e: any, index: number) => {
    const updatedScoYear = [...amtData]
    updatedScoYear[index] = e
    setAmtData([...updatedScoYear])
  }
  const handleNewValue = () => {
    if (!amtData.some((item) => item.year === '' || item.amount === '')) {
      setAmtData([...amtData, { year: '', amount: '' }])
    }
  }
  const handleDelete = (index: number) => {
    const updatedScoYear = [...amtData]
    const list = updatedScoYear.splice(index, 1)
    setAmtData(list)
  }
  const formatCurrency = (val: any) => {
    const numberValue = parseFloat(val.replace(/[^0-9.]/g, ''))
    return numberValue
      ? numberValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      : ''
  }
  const handleDisable = () => {
    return Object.values(verifyCheck).every((value) => value === false)
  }
  const [radioData, setRadioData] = useState([
    {
      title: 'New Funds Contribution($)',
      checked: false,
    },
    {
      title: 'Conversion of Assets',
      checked: false,
    },
    {
      title: 'Transfer of Assets',
      checked: false,
    },
    {
      title: 'Rollover of Qualified Plan Trust Assets',
      checked: false,
    },
    {
      title: 'Rollover of IRA Assets',
      checked: false,
    },
  ])

  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0]) {
      const getSourecData = JSON.parse(
        newModelData?.wipAccountDetailslist?.[0]?.sourceofcontributions
      )
      if (getSourecData) {
        setSourceData({
          id: getSourecData?.id,
          value: getSourecData?.value,
        })
        if (getSourecData?.amountdetails) {
          setAmtData(getSourecData?.amountdetails)
        }
      }
    }
  }, [newModelData])

  const [isValid, setIsValid] = useState(false)
  const validate = () => {
    let validate = true
    if (sourceData.id === '1') {
      const filterLength = amtData?.filter((obj) => !obj.year || !obj.amount)
      if (filterLength.length > 0) {
        validate = false
        setIsValid(true)
      }
    }
    return validate
  }

  const onSave = () => {
    if (validate()) {
      setLoader(true)
      autoSaveFunction().then((data) => {
        if (data === 200) {
          setLoader(false)
          setToast('success', 'Source Contributions Saved')
          const payload: GetWipRequestInterface = {
            appId: '1',
            wipId: parseInt(wipId),
          }
          getWipDetails(payload)
            .then((getWipRecord) => {
              setNewModelData(getWipRecord.data)
            })
            .catch((error) => {
              console.log('error', error)
            })
        }
      })
    }
  }
  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel>
        <WipHeadingWrapper>
          <TitleGrouping
            onClick={() => {
              setToggleHeader(!toggleHeader)
            }}
          >
            <SvgProductType />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Source of Initial Contributions'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>

          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
            }}
          >
            {!toggleHeader && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            {loader ? (
              <SpinnerWrapper>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
              </SpinnerWrapper>
            ) : (
              <AccFeatureWrapper>
                <AccountWrapper>
                  <FormContainer id="FormContainer">
                    <RowWrapper>
                      <SectionWrapper>
                        <FeatureInfoColumn className="source">
                          <RadioWrapper>
                            {sourceRadioData?.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Radio
                                    key={index?.toString()}
                                    onChange={() => {
                                      setSourceData({
                                        id: item.value,
                                        value: item.label,
                                      })
                                      if (item.value !== '1') {
                                        setAmtData([{ year: '', amount: '' }])
                                        setIsValid(false)
                                      }
                                    }}
                                    checked={sourceData.id === item.value}
                                    title={item.label}
                                    disabled={readOnly}
                                  />
                                  {sourceData.id === '1' &&
                                    sourceData.id === item.value && (
                                      <SectionWrapper>
                                        <SocWrapper>
                                          {amtData.map((item, index) => (
                                            <div
                                              style={{
                                                display: 'flex',
                                                gap: readOnly
                                                  ? '1.5rem'
                                                  : '1rem',
                                              }}
                                              key={index}
                                            >
                                              <FeatureInfoColumn className="text-arr">
                                                <Row id="read_amount">
                                                  <WDLabelClient>
                                                    <Label>{'Amount'}</Label>
                                                  </WDLabelClient>
                                                  {!amtData[index].amount &&
                                                    isValid &&
                                                    !readOnly && (
                                                      <WDLabelError className="label_responsive">
                                                        {'Required'}
                                                      </WDLabelError>
                                                    )}
                                                </Row>
                                                {readOnly ? (
                                                  <WDLabelClient>
                                                    <Label>
                                                      {formatCurrency(
                                                        item.amount
                                                      )}
                                                    </Label>
                                                  </WDLabelClient>
                                                ) : (
                                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                                    <Textbox
                                                      type={'text'}
                                                      // disabled={radioData.every(
                                                      //   (item) =>
                                                      //     item.checked === false
                                                      // )}
                                                      value={
                                                        isFocused
                                                          ? item.amount
                                                          : formatCurrency(
                                                              item.amount
                                                            )
                                                      }
                                                      onChange={(value) => {
                                                        const newSocYear = [
                                                          ...amtData,
                                                        ]
                                                        newSocYear[
                                                          index
                                                        ].amount = value
                                                        setAmtData(newSocYear)
                                                      }}
                                                      onBlur={() => {
                                                        setIsFocused(false)
                                                      }}
                                                      onFocus={() => {
                                                        setIsFocused(true)
                                                      }}
                                                      border={
                                                        !amtData[index]
                                                          .amount && isValid
                                                          ? COLORS.Text.Danger
                                                          : ''
                                                      }
                                                    />
                                                  </WDTextboxBorderRadius>
                                                )}
                                              </FeatureInfoColumn>
                                              <FeatureInfoColumn className="text-arr">
                                                <Row>
                                                  <WDLabelClient>
                                                    <Label>{'Year'}</Label>
                                                  </WDLabelClient>
                                                  {!amtData[index].year &&
                                                    isValid &&
                                                    !readOnly && (
                                                      <WDLabelError className="label_responsive">
                                                        {'Required'}
                                                      </WDLabelError>
                                                    )}
                                                </Row>
                                                {readOnly ? (
                                                  <WDLabelClient>
                                                    <Label>{item.year}</Label>
                                                  </WDLabelClient>
                                                ) : (
                                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                                    <Textbox
                                                      type={'number'}
                                                      value={item.year}
                                                      // disabled={radioData.every(
                                                      //   (item) =>
                                                      //     item.checked === false
                                                      // )}
                                                      onChange={(value) => {
                                                        const newSocYear = [
                                                          ...amtData,
                                                        ]
                                                        if (
                                                          /^.{0,4}$/.test(value)
                                                        ) {
                                                          newSocYear[
                                                            index
                                                          ].year = value
                                                          setAmtData(newSocYear)
                                                        }
                                                      }}
                                                      border={
                                                        !amtData[index].year &&
                                                        isValid
                                                          ? COLORS.Text.Danger
                                                          : ''
                                                      }
                                                    />
                                                  </WDTextboxBorderRadius>
                                                )}
                                              </FeatureInfoColumn>
                                            </div>
                                          ))}
                                        </SocWrapper>
                                        {amtData.length != 2 && !readOnly && (
                                          <ButtonWrapper>
                                            <WDButtonPrimaryOutlined>
                                              <Button
                                                type="button"
                                                disabled={
                                                  amtData.filter(
                                                    (item) =>
                                                      !item.year || !item.amount
                                                  )?.length > 0
                                                    ? true
                                                    : false
                                                }
                                                onClick={handleNewValue}
                                              >
                                                <Label>{'Add'}</Label>
                                              </Button>
                                            </WDButtonPrimaryOutlined>
                                          </ButtonWrapper>
                                        )}
                                        {!readOnly && amtData.length > 1 ? (
                                          <ButtonWrapper>
                                            <WDButtonPrimaryOutlined>
                                              <Button
                                                type="button"
                                                onClick={handleDelete}
                                              >
                                                <Label>{'Remove'}</Label>
                                              </Button>
                                            </WDButtonPrimaryOutlined>
                                          </ButtonWrapper>
                                        ) : null}
                                      </SectionWrapper>
                                    )}
                                </React.Fragment>
                              )
                            })}
                          </RadioWrapper>
                        </FeatureInfoColumn>
                      </SectionWrapper>
                    </RowWrapper>
                    {apiPreviewStatus.status !== '' && (
                      <ToastWrapper>
                        <Toast
                          text={apiPreviewStatus.text}
                          type={apiPreviewStatus.status}
                          openStatusCallback={(status: boolean) =>
                            setAPIPreviewStatus({
                              status: '',
                              text: '',
                            })
                          }
                        />
                      </ToastWrapper>
                    )}
                  </FormContainer>
                </AccountWrapper>
              </AccFeatureWrapper>
            )}
            <FundingButtonWrapper>
              {!readOnly && (
                <WDButtonOutLine>
                  <Button
                    type={'button'}
                    onClick={() => {
                      onSave()
                    }}
                  >
                    {loader ? (
                      <SpinnerButtonWrapper>
                        <LottieButtonWrapper>
                          <Lottie animationData={Loader} loop={true} />
                        </LottieButtonWrapper>
                      </SpinnerButtonWrapper>
                    ) : (
                      <WDLabelPrimaryText className="label_responsive">
                        {'Save'}
                      </WDLabelPrimaryText>
                    )}
                  </Button>
                </WDButtonOutLine>
              )}
            </FundingButtonWrapper>
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default SourceOfContribution
