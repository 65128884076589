import Lottie from 'lottie-react'
import { useContext, useEffect, useState } from 'react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { ItemWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { SubProductModel } from '../../../services/applicationContext/IApplicationPageContext'
import { LottieWrapper, SpinnerWrapper } from '../../interceptor/styles'
import ApplicantInfoPage from '../accountForms/formIndividual/tabs/ApplicantInfo/ApplicantInfo'
import FundingInfo from '../accountForms/formIndividual/tabs/FundingInfo/FundingInfo'
import ProductFeatures from '../accountForms/formIndividual/tabs/ProductFeatures/ProductFeatures'
import ContactInformation from '../contactInfo/ContactInfo'
import ElectronicPreferences from '../electronicPrefs/ElectronicPreferences'
import FormWip from '../formWip/FormWip'
import TrustedContact from '../trustedContact/TrustedContact'
import InvestmentCard from '../accountForms/formIndividual/tabs/InvestmentInfo/InvestmentCard'
import ToastContext from '../../../services/toast/ToastContext'
import IndustryAffiliation from '../finalReviewInformation/IndustryAffiliation'

interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
}

const AccountInformation = ({ stage, setStage }: FormWipProps) => {
  const {
    wipId,
    setWipId,
    setSelectedAccount,
    accountInfoLoader,
    setIsDashboardNavigate,
    isDashboardNavigate,
    newModelData,
    tabLoader,
  } = useContext(ApplicationInfoContext)
  const [tabValue, setTabValue] = useState([] as any[])

  const { setToast } = useContext(ToastContext)
  useEffect(() => {
    if (!wipId) {
      setWipId(wipId)
      setSelectedAccount({} as SubProductModel)
    }
  }, [setSelectedAccount, setWipId, wipId])
  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0].accountinfo?.length) {
      setTabValue(newModelData?.wipAccountDetailslist?.[0].accountinfo)
    }
  }, [newModelData?.wipAccountDetailslist?.[0]])

  if (!wipId) {
    if (isDashboardNavigate) {
      setIsDashboardNavigate(false)
    }
    return (
      <>
        {accountInfoLoader && (
          <SpinnerWrapper>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
          </SpinnerWrapper>
        )}
        <ItemWrapper sm={6} md={4} lg={3} id={'left_col'}>
          <FormWip />
        </ItemWrapper>
        <ItemWrapper sm={6} md={4} lg={3} id={'right_col'}>
          <ApplicantInfoPage />
        </ItemWrapper>
      </>
    )
  }
  return (
    // <Container>
    //   <FormWip />
    //   <ApplicantInfoPage />
    //   {wipId && <ProductFeatures />}
    //   {wipId && <ElectronicPreferences />}
    //   {wipId && <ContactInformation />}
    //   {wipId && <FundingInfo />}
    //   {wipId && <TrustedContact />}
    // </Container>
    <>
      {/* <nav>
        <FormWip />
        <ApplicantInfoPage />
      </nav>
      <main>
        {wipId && <ProductFeatures />}
        {wipId && <ContactInformation />}
        {wipId && <ElectronicPreferences />}
        {wipId && <FundingInfo />}
        {wipId && <TrustedContact />}
      </main> */}
      {accountInfoLoader && (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      )}
      <ItemWrapper
        sm={6}
        md={4}
        lg={3}
        id={!wipId ? 'full_col' : 'left_col'}
        // className={!wipId ? 'new_wip_padding' : 'enable_scroll'}
      >
        <div id="left_grp_next">
          <FormWip />
          <ApplicantInfoPage />
        </div>
      </ItemWrapper>
      {wipId && (
        <ItemWrapper
          sm={6}
          md={4}
          lg={3}
          id="right_col"
          className={'enable_scroll'}
        >
          <div id="left_grp_next">
            {tabLoader ? (
              <SpinnerWrapper>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
              </SpinnerWrapper>
            ) : (
              tabValue &&
              tabValue.map((item, index) => {
                if (wipId) {
                  switch (item) {
                    case 'Product Features':
                      return <ProductFeatures key={index} />
                    case 'Contact Information':
                      return <ContactInformation key={index} />
                    case 'Investment Profile':
                      return <InvestmentCard key={index} />
                    case 'Electronic Preferences':
                      return <ElectronicPreferences key={index} />
                    case 'Funding Details':
                      return <FundingInfo key={index} />
                    case 'IndustryAffiliations':
                      return <IndustryAffiliation key={index} />
                    case 'Trusted Contact Information':
                      return <TrustedContact key={index} />
                    default:
                      return <></>
                  }
                }
              })
            )}
          </div>
        </ItemWrapper>
      )}
    </>
  )
}

export default AccountInformation
