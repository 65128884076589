import styled from 'styled-components'
import { COLORS } from '../theme/Colors'

export const MainPane = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  grid-column: 1/13;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
export const CardSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
`
export const BodyPane = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  grid-column: 1/13;
  background-color: ${COLORS.Background.NeutralBackgroundMedium};
  height: calc(100vh - 1rem);
  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    padding: 3rem 0 1rem 0;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399.98px) {
    padding: 3rem 5% 1rem 5%;
  }

  @media only screen and (min-width: 1400px) {
    padding: 3rem 10% 1rem 10%;
  }
`
export const WelcomeMsgWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  margin-top: 1 rem;
  width: 100%;
`
export const WelcomeMsgRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1rem;
  height: auto;
`
export const StatusHeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
