import axios from 'axios'
import ENDPOINTS from '../httpEndpoints'
import {
  AddUpdateFundingResponseModel,
  GetFundingResponseModel,
  finalfundingObj,
} from '../../shared/models/IFunding'

export const GetFunding = (wipacctid: number) => {
  return axios.get<GetFundingResponseModel[]>(
    ENDPOINTS.GET_FUNDING + '=' + wipacctid
  )
}

export const AddFunding = (payload: finalfundingObj) => {
  return axios.post<AddUpdateFundingResponseModel>(
    ENDPOINTS.ADD_FUNDING,
    payload
  )
}

export const UpdateFunding = (payload: finalfundingObj) => {
  return axios.post<AddUpdateFundingResponseModel>(
    ENDPOINTS.UPDATE_FUNDING,
    payload
  )
}
