import styled from 'styled-components'
import { COLORS } from './../../theme/Colors'
import { CheckboxProps } from './ICheckbox'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`

export const StyledCheckbox = styled.input<CheckboxProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${COLORS.Border.Primary70};
  accent-color: ${COLORS.Background.Primary50};
  outline: none;
  &:disabled {
    background: ${COLORS.Background.Primary80};
    border-radius: 4px;
    cursor: no-drop;
  }
  &:checked {
    //accent-color: ${COLORS.Background.Primary50};
    accent-color: ${COLORS.UI.PrimaryText};
    border-radius: 4px;
  }
`
export const StyledLabel = styled.label<CheckboxProps>`
  font-size: 0.875rem;
  &:disabled {
    color: ${COLORS.Text.Gray40};
    cursor: no-drop;
  }
`
export const CheckBoxWrapper = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  gap:10px;
  border-radius: 4px;
  align-items: centers;
  input {
    position: relative;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #FFF;
    border: 1px solid ${COLORS.Border.Neutral};
    border-radius: 4px;
  }
  &.custom-checkbox input:checked ~ .checkmark {
    background-color: ${COLORS.Border.PrimaryText};
  }
  &.custom-checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  &.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  &.custom-checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 2.5px;
    height: 9px;
    border: solid #FFF;
    border-width: 0 1.8px 1.8px 0;
    transform: rotate(45deg);
  }
`
export const CheckboxInput = styled.input<CheckboxProps>``