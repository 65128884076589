import styled from 'styled-components'
import { COLORS } from './../../../theme/Colors'
import { RadioProps } from './IRadio'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  input[type='radio'] {
    margin: 0;
    transform: translateY(-0.025rem);
    display: grid;
    place-content: center;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    :checked::before {
      -webkit-appearance: none;
      appearance: none;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0.5);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 2em 2em ${COLORS.Border.White};
      border: 8px solid
        ${(props: {disabled:boolean}) => {
          if (props.disabled) {
            return `${COLORS.Background.BorderHover}`
          } else {
            return `${COLORS.Border.PrimaryText}`
          }
        }};
    }
  }
`

export const StyledCheckbox = styled.input<RadioProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  //border: 0.0625rem solid ${COLORS.Border.Primary70};
  accent-color: ${COLORS.Background.PrimaryActive};
  outline: none;
  &:disabled {
    background: ${COLORS.Background.Primary80};
    border-radius: 4px;
    cursor: no-drop;
  }
  &:checked {
    accent-color: ${COLORS.Background.PrimaryActive};
    border-radius: 4px;
  }
`
export const StyledLabel = styled.label<RadioProps>`
  &:disabled {
    color: ${COLORS.Text.Gray40};
    cursor: no-drop;
  }
`
