export enum form {
  WIPDETAILS = 'wipdetail',
  ACCOUNTINFO = 'accountinfo',
  ROLES = 'roles',
  INVESTMENTPROFILE = 'investmentprofile',
  DOCUMENTS = 'Documents',
  INDIVIDUAL = 'individual',
  JOINT = 'joint',
  INDIVIDUAL_TOD = 'individual_tod',
  ACCOUNT_DETAILS = 'individual',
  REVIEW = 'review',
  DISCLOSURE = 'disclosure',
}

export enum formLabel {
  WIPDETAILS = 'WIP Details',
  ACCOUNTINFO = 'Account Information',
  ROLES = 'Roles',
  INVESTMENTPROFILE = 'Investment Profile',
  DOCUMENTS = 'Documents',
  INDIVIDUAL = 'Individual',
  JOINT = 'Joint',
  INDIVIDUAL_TOD = 'Individual TOD',
  ACCOUNT_DETAILS = 'Account Details',
  REVIEW = 'Approval',
  DISCLOSURE = 'Disclosure',
}

export enum toastType {
  CANCEL = 'cancel',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}
export enum numericType {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  NEGONE = '-1',
  NEGTWO = '-2',
  ZEROTWO = '02',
  ZEROONE = '01',
  LENGTH = '20',
}
export enum acntFeature {
  YES = 'Yes',
  NO = 'No',
  SELECT = 'Select',
  CASHACCOUNT = 'Cash Account & Fdic Insured Cash Sweep',
  MARGINACCOUNT = 'Margin Account & Fdic Insured Cash Sweep',
  CREDITPLUSOBJ = 'iscreditplusacct',
  OPTIONOBJ = 'isoptionacct',
  WEDBUSHDEBIT = 'iswedbushdebitcard',
  MARGINACCOBJ = 'ismarginaccount',
  AFFIRMOBJ = 'affrmaccttype',
  LABELCREDITPLUS = 'Select Credit Plus Account',
}

export enum funding {}
