import { Dispatch, createContext, useMemo, useState } from 'react'
import { GetLobOfficeCodeAPI, getAdvisorDataByRepcode } from './LobOfficeAPI'
import {
  GetAdvisorDataRepCodeRequest,
  GetAdvisorDataRepCodeResponse,
  LobOfcCodeRequest,
  LobOfcCodeResponse,
} from './ILobOfficeContext'
interface GetLobCodeFn {
  (payload: LobOfcCodeRequest): Promise<any>
}
interface GetAdvisorRepCodeFn {
  (payload: GetAdvisorDataRepCodeRequest): Promise<any>
}
export interface LobInfoContextInterface {
  lobOfcCode: LobOfcCodeResponse[]
  setLobOfcCode: Dispatch<React.SetStateAction<LobOfcCodeResponse[]>>
  GetLobOfcCode: GetLobCodeFn
  advisorDataByRepCode: GetAdvisorDataRepCodeResponse
  setAdvisorDataByRepCode: Dispatch<
    React.SetStateAction<GetAdvisorDataRepCodeResponse>
  >
  GetAdvisorDataByRepcode: any
  lookupDetails: any
}
export const LobInfoContext = createContext<LobInfoContextInterface>(
  {} as LobInfoContextInterface
)
interface LobInfoContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}
export const LobInfoContextProvider = ({
  children,
}: LobInfoContextProviderProps) => {
  const lookupDetails: any = [
    {
      id: 1,
      name: 'Adult',
      tablename: 'Retirement Owner',
    },
    {
      id: 2,
      name: 'Minor',
      tablename: 'Retirement Owner',
    },
    {
      id: 3,
      name: 'Corporation',
      tablename: 'Retirement Owner',
    },
    {
      id: 4,
      name: 'Estate',
      tablename: 'Retirement Owner',
    },
    {
      id: 5,
      name: 'Trust',
      tablename: 'Retirement Owner',
    },
  ]
  const [lobOfcCode, setLobOfcCode] = useState<LobOfcCodeResponse[]>([])
  const [advisorDataByRepCode, setAdvisorDataByRepCode] = useState<any>({})

  const GetLobOfcCode = async (payload: LobOfcCodeRequest) => {
    try {
      const response = await GetLobOfficeCodeAPI(payload)
      setLobOfcCode(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const GetAdvisorDataByRepcode = async function (
    payload: GetAdvisorDataRepCodeRequest
  ) {
    try {
      const response = await getAdvisorDataByRepcode(payload)
      setAdvisorDataByRepCode(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        console.log(error)
      }
    }
  }
  const values = useMemo(
    () => ({
      lobOfcCode,
      setLobOfcCode,
      GetLobOfcCode,
      advisorDataByRepCode,
      setAdvisorDataByRepCode,
      GetAdvisorDataByRepcode,
      lookupDetails,
    }),
    [
      lobOfcCode,
      setLobOfcCode,
      GetLobOfcCode,
      advisorDataByRepCode,
      setAdvisorDataByRepCode,
      GetAdvisorDataByRepcode,
      lookupDetails,
    ]
  )
  return (
    <LobInfoContext.Provider value={values}>{children}</LobInfoContext.Provider>
  )
}
