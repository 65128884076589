export const setSessionItem = (itemKey: string, value: string) => {
  try {
    sessionStorage.setItem(itemKey, value)
  } catch (err) {
    console.error(err)
  }
}

export const getSessionItem = (itemKey: string) => {
  return sessionStorage.getItem(itemKey) || ''
}

export const removeSessionItem = (itemKey: string) => {
  return sessionStorage.removeItem(itemKey)
}
