import React, { useContext } from 'react'
import { ItemWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import ContingentBeneficiary from '../retirementForm/ContingentBeneficiary/ContingentBeneficiary'
import PrimaryBeneficiary from '../retirementForm/PrimaryBeneficiary/PrimaryBeneficiary'
import BestInterest from '../retirementForm/BestInterest/BestInterest'
import SourceOfContribution from '../retirementForm/SourceOfContribution/SourceOfContribution'
import PersonalInformation from '../retirementForm/PersonalInformation/PersonalInfo'
import TrustBeneficiary from './TrustCards'

interface FormWipProps {
  setStage?: (value: number) => void
  stage?: number
}
const TrustForm = ({ stage, setStage }: FormWipProps) => {
  const { getWipResponse } = useContext(ApplicationInfoContext)
  const showCard = () => {
    const subTypeArr = [93, 85, 86, 87, 92, 91]
    if (
      getWipResponse &&
      subTypeArr.includes(getWipResponse?.wipaccount?.[0]?.subaccounttype)
    )
      return true
    else return false
  }

  return (
    <ItemWrapper
      sm={6}
      md={4}
      lg={3}
      id={'full_col'}
      // className={!wipId ? 'new_wip_padding' : 'enable_scroll'}
    >
      <div id="left_grp_next">
        
        <PrimaryBeneficiary key={'trust'} type={'trust'}  header={'Trust settlers/Granters/Makers'}/>
        <PrimaryBeneficiary key={'trustBene'} type={'trustBene'}  header={'Trust Beneficiary'}/>
        <PrimaryBeneficiary key={'trustEe'} type={'trustEe'}  header={'Trustee'}/>
        <PrimaryBeneficiary key={'successorTrustee'} type={'successorTrustee'}  header={'Name of Successor Trustee'}/>
       
      </div>
    </ItemWrapper>
  )
}

export default TrustForm
