import styled from 'styled-components'
import { COLORS } from '../../../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../../../theme/Sizes'

export const FeatureInfoRow = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 10px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: row;

  @media screen and (min-width: 1440px) {
    gap: 12px !important;
  }
`
export const RowWrapper = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 10px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 0 1 100%;
  &#secondRow {
    flex: 0 1 40%;
  }
`;
export const CenterWrapper = styled.div`
  flex: 0 1 40%;
`

export const FundingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: calc(67vh-2rem);
  /* padding: 0.25rem; */
  //overflow-y: scroll;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`

export const FundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem 0;
  gap: 1rem;
`
export const DocumentRowContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  &#secondRow {
    margin-top: 8px;
    flex: 0 1 40%;
  }
`

export const ImageWrapper = styled.div``
export const ColumnOne = styled.div`
  &#isFlag {
    flex: 0 1 60%;
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  //height: 65vh;
  gap: 1rem;
  padding: 10px;
  // overflow-y: scroll;
  // ::-webkit-scrollbar {
  //   background: ${COLORS.Background.NeutralBackgroundMedium};
  //   width: 9px;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   height: 8px;
  //   background: ${COLORS.Background.StrongBackground};
  //   top: 8px;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: rgba(167, 175, 188, 0.3);
  // }
  // ::-webkit-scrollbar-track {
  //   border-radius: 8px;
  // }
`
export const ClientSearchCard = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 107.2%;
  left: 0px;
  width: 100%;
  gap: 0.25rem;
  align-items: center;
  background: rgb(248, 250, 252);
  box-shadow: rgba(0, 36, 93, 0.3) 0px 2px 6px 1px;
  justify-content: center;
  align-self: center;
  height: fit-content;
  z-index: 9999;
`
export const ClientSearchWrapperForBeneficiary = styled.div`
display: flex;
padding: 0.5rem 0 0;
input {
  width: 15rem;
  border-radius:  8px 0 0 8px;
  ::placeholder {
    color: ${COLORS.Text.Gray};
  }
}
`
export const SocItemWrapper = styled.div`
display: flex;
gap: 0;
button{
  height:2rem !important;
  
}`
export const StyledButtonDisabled = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: ${COLORS.Background.Neutral};
border-radius: 0 0.25rem 0.25rem 0;
cursor: pointer;
button {
  border: ${COLORS.Border.Transparent};
  background: none;
  color: ${COLORS.Text.DisabledText};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${SIZES[6]};
  padding: ${SIZES[0]} ${SIZES[3]};
  font-size: ${TextSizes[2]};
  line-height: ${TextSizes[6]};
  font-weight: ${TextWeight[0]};
}
`
export const DangerText = styled.div`
  color: ${COLORS.Text.Danger};
  font-size: ${TextSizes[2]};
  line-height: ${TextSizes[6]};
  font-weight: ${TextWeight[0]};
  display: flex;
  width: 100%;
  justify-content: end;
`
