import { TextareaProps } from './ITextarea'
import { Wrapper, StyledTextarea } from './styles'

export const Textarea = ({
  placeholder,
  onChange,
  onBlur,
  innerRef,
  border,
  disabled,
  required,
  name,
  className,
}: TextareaProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }
  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlur?.(e.target.value)
  }

  return (
    <Wrapper>
      <StyledTextarea
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={innerRef}
        name={name}
        border={border}
        disabled={disabled}
        required={required}
        className={className}
      />
    </Wrapper>
  )
}
