import { DividerProps } from './IDivider'
import { DividerLine } from './styles'

const Divider = ({ horizontal, thickness, bgColor,height,id }: DividerProps) => {
  return (
    <DividerLine
      bgColor={bgColor}
      horizontal={horizontal}
      thickness={thickness}
      height={height}
      id={id}
    ></DividerLine>
  )
}

export default Divider
