import { createContext } from 'react'
import {
  createNewAccount,
  createNewAccountFeature,
  getAccountConfig,
  getAccountDetails,
  updateAccountDetails,
} from './AccountFeatureAPI'
import {
  ContextProps,
  PartyResponseModel,
} from '../../composites/forms/formWip/IFormWip'
import {
  GetAccountConfigPayload,
  ProductFeaturesRequestInterface,
  ProductFeaturesResponseInterface,
} from './IAccountFeatures'

export interface IAccountFeatureStore {
  accountdetail: PartyResponseModel | null
  getAccountContextDetails: (accountid: any, appid: any) => Promise<any>
  getAccountConfig: (payload: GetAccountConfigPayload) => Promise<any>
  // getProductFeatures: (
  //   payload: ProductFeaturesRequestInterface
  // ) => Promise<ProductFeaturesResponseInterface>
  createNewAccountFeature: (payload: any) => Promise<any>
  updateAccountDetails: (payload: any) => Promise<any>
  createNewAccount: (payload: any) => Promise<any>
  productFeatures: ProductFeaturesResponseInterface
  setProductFeatures: (payload: ProductFeaturesResponseInterface) => void
}
const accountFeatureStore: IAccountFeatureStore = {
  accountdetail: null,
  productFeatures: {} as ProductFeaturesResponseInterface,
  setProductFeatures: (Details: ProductFeaturesResponseInterface) => {
    accountFeatureStore.productFeatures = Details
  },
  getAccountContextDetails: async (accountid, appid) => {
    const { data } = await getAccountDetails(accountid, appid)
    return data
  },

  getAccountConfig: async (payload: GetAccountConfigPayload) => {
    const data = await getAccountConfig(payload)
    return data
  },

  // getProductFeatures: async (payload: ProductFeaturesRequestInterface) => {
  //   const data = await getProductFeaturesAPI(payload)
  //   return data.data
  // },

  updateAccountDetails: async (payload) => {
    const updateAccountfeatureResponse = await updateAccountDetails(payload)
    return updateAccountfeatureResponse
  },

  createNewAccount: async (payload) => {
    const createNewAccountResponse = await createNewAccount(payload)
    return createNewAccountResponse
  },

  createNewAccountFeature: async (payload) => {
    const createNewAccountFeatureResponse = await createNewAccountFeature(
      payload
    )
    return createNewAccountFeatureResponse
  },
}
const AccountFeatureContext = createContext(accountFeatureStore)

export const AccountInfoContextProvider = (props: ContextProps) => {
  return (
    <AccountFeatureContext.Provider value={accountFeatureStore}>
      {props.children}
    </AccountFeatureContext.Provider>
  )
}

export default AccountFeatureContext
