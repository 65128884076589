import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  enableDisclosureAPI,
  getDisclosureAPI,
  updateDisclosureAPI,
} from './DisclosureAPI'
import {
  EnableDisclosureRequest,
  GetDisclosureRequestInterface,
  UpdateDisclosureRequestInterface,
  UpdateDisclosureResponseInterface,
  getDisclosureList,
} from './IDisclosure'
import { ApplicationInfoContext } from '../applicationContext/ApplicationPageContext'

interface GetDisclosureFn {
  (payload: GetDisclosureRequestInterface): void
}
interface UpdateDisclosureFn {
  (
    payload: UpdateDisclosureRequestInterface
  ): Promise<UpdateDisclosureResponseInterface>
}
interface UpdateEnableDisclosureFn {
  (payload: EnableDisclosureRequest): Promise<
    EnableDisclosureRequest | undefined
  >
}
export interface DisclosureInterface {
  getResponse: getDisclosureList[]
  setGetResponse: Dispatch<React.SetStateAction<getDisclosureList[]>>
  coAppDiscs: getDisclosureList[]
  setCoAppDiscs: Dispatch<React.SetStateAction<getDisclosureList[]>>
  upDisclosure: UpdateDisclosureResponseInterface
  setUpDisclosure: Dispatch<
    React.SetStateAction<UpdateDisclosureResponseInterface>
  >
  getDisclosure: GetDisclosureFn
  getCoAppDisclosures: GetDisclosureFn
  updateDisclosure: UpdateDisclosureFn
  enableDisclosure: UpdateEnableDisclosureFn
  templateData: any
  setTemplateData: any
  disclosureDocs: any
  setDisclosureDocs: any
  disableCondition: any
  disableConditionDocuments: any
}
interface ContextProps {
  children?: ReactNode
}
export const DisclosureContext = createContext<DisclosureInterface>(
  {} as DisclosureInterface
)
export const DisclosureContextProvider = ({ children }: ContextProps) => {
  const [apiError, setApiError] = useState<object | null | string>(null)
  const [getResponse, setGetResponse] = useState<getDisclosureList[]>([])
  const [coAppDiscs, setCoAppDiscs] = useState<getDisclosureList[]>([])
  const [templateData, setTemplateData] = useState<any>([])
  const [disclosureDocs, setDisclosureDocs] = useState<any[]>([])
  const { uploadedFiles, newModelData } = useContext(ApplicationInfoContext)
  const [upDisclosure, setUpDisclosure] =
    useState<UpdateDisclosureResponseInterface>(
      {} as UpdateDisclosureResponseInterface
    )

  const getDisclosure = useCallback(
    async (payload: GetDisclosureRequestInterface) => {
      try {
        const response = await getDisclosureAPI(payload)
        // response.data.filter((obj) => obj.ischecked)
        setGetResponse(response.data)
        return response.data
      } catch (error) {
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    },
    []
  )
  const getCoAppDisclosures = async function (
    payload: GetDisclosureRequestInterface
  ) {
    try {
      const response = await getDisclosureAPI(payload)
      setCoAppDiscs(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }
  const updateDisclosure = async function (
    payload: UpdateDisclosureRequestInterface
  ) {
    try {
      const response = await updateDisclosureAPI(payload)
      setUpDisclosure(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }

  const enableDisclosure = async function (payload: EnableDisclosureRequest) {
    try {
      const response = await enableDisclosureAPI(payload)
      setUpDisclosure(response.data)
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        setApiError(error)
      }
    }
  }
  const disableCondition = () => {
    const getDisclosures = uploadedFiles.filter((file) => file.isdisclosure)
    const disclosureDocsWithout27 = disclosureDocs.filter((doc) => doc.docid !== 27);
    return getDisclosures.length === disclosureDocsWithout27.length - 1 ||
      uploadedFiles?.find((file) => file?.docid == 27)
      ? true
      : false
  }

  const disableConditionDocuments = () => {
    const relationlist = newModelData?.wipAccountDetailslist?.[0]?.parties
      .filter((obj) => obj?.relationshipTypeId <= 2)
      .map((obj) => obj?.relationshipTypeId)
    const MatchingRelationshipId = relationlist?.every((id) =>
      uploadedFiles?.some(
        (obj) => obj?.relationshipid === id && !obj.isdisclosure
      )
    )
    if (MatchingRelationshipId) {
      return true
    } else {
      const parties = newModelData?.wipAccountDetailslist?.[0]?.parties
      const uploadedFilesRelationshipIds = uploadedFiles.map(
        (file) => !file.isdisclosure && file.relationshipid
      )

      return parties?.every((party) => {
        return uploadedFilesRelationshipIds.includes(party.relationshipTypeId)
      })
    }
  }
  const values = useMemo(
    () => ({
      apiError,
      getResponse,
      getDisclosure,
      setGetResponse,
      upDisclosure,
      updateDisclosure,
      setUpDisclosure,
      enableDisclosure,
      coAppDiscs,
      setCoAppDiscs,
      getCoAppDisclosures,
      templateData,
      setTemplateData,
      disclosureDocs,
      setDisclosureDocs,
      disableCondition,
      disableConditionDocuments,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getResponse,
      upDisclosure,
      templateData,
      setTemplateData,
      disclosureDocs,
      setDisclosureDocs,
      disableCondition,
      disableConditionDocuments,
    ]
  )
  return (
    <DisclosureContext.Provider value={values}>
      {children}
    </DisclosureContext.Provider>
  )
}
