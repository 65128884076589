export const AccessList = {
  createWip: false,
  deleteWip: false,
  updateWip: false,
  productFeatures: false,
  contactInfo: false,
  invProfile: false,
  elecPreferences: false,
  funding: false,
  trustedContact: false,
  uploadDocuments: false,
  deleteDocs: false,
  viewDocs: false,
  submitApproval: false,
  approveOptions: false,
  approveWip: false,
  sendToClient: false,
  recall: false,
  home: false,
  dashboard: false,
  accountInfo: false,
  documents: false,
  review: false,
  approvals: false,
}
