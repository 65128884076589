import styled from 'styled-components'
import { COLORS } from '../../../../../../theme/Colors'

export const DdlRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`
export const DdlColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const FeatureInfoRow = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 4px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
  &.fullWidth {
    flex: 0 1 100%;
  }
  @media screen and (min-width: 1440px) {
    gap: 12px !important;
  }
`
export const RowWrapper = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 4px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  /* flex: 0 1 100%; */
  /* &#recurring {
    z-index: 99999;
  } */
`
export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 78vh;
  overflow-y: scroll;
`

export const FundingButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0.5rem !important;
  gap: 0.5rem;
  position: relative;
  z-index: 9999;
  button {
    z-index: 99;
    position: relative;
    padding: 0.5rem 1.5rem;
  }
`

export const FundingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: calc(67vh-2rem);
  /* padding: 0.25rem; */
  //overflow-y: scroll;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  gap: 0.6rem;
`

export const FundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem 0;
  gap: 1rem;
`
export const DocumentRowContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 0.5rem);
  &#secondRow {
    margin-top: 8px;
    flex: 0 1 50%;
  }
`
export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const ImageWrapper = styled.div``

export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  //flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  @media screen and (min-width: 1440px) {
    row-gap: 30px !important;
  }
`
export const ErrorMsg = styled.span`
  width: 16px;
`
