import axios from 'axios'
import appConfig from '../../settings/appConfig'
import ENDPOINTS from '../httpEndpoints'
import {
  AddLOBRequestInterface,
  CreateAccountRequestInterface,
  IGetSubAcctType,
  UpdateAccountRequestInterface,
  UpdateAccountResponseInterface,
} from './IApplicationPageContext'

const host = appConfig.extapiEndPoint

export const createAccountRequestAPI = (
  payload: CreateAccountRequestInterface
) => {
  return axios.post(host + ENDPOINTS.CREATE_REQUEST, payload)
}

export const submitApplicationAPI = (payload: number) => {
  return axios.get(
    host + ENDPOINTS.CREATE_ACCOUNT.replace('{wipid}', payload.toString())
  )
}

export const updateApplicationAPI = (
  payload: UpdateAccountRequestInterface
) => {
  return axios.post<UpdateAccountResponseInterface>(
    host + ENDPOINTS.UPDATE_ACCOUNT,
    payload
  )
}
const host1 = ''
export const GetSubAcctType = (payload: IGetSubAcctType) => {
  return axios.get(
    host1 +
    ENDPOINTS.GET_SUBACCT_TYPE.replace(
      '{accid}',
      payload.accttypeid.toString()
    )
      .replace('{lb}', payload.lob.toString())
      .replace('{ec}', payload.entitycode.toString())
  )
}

export const getAllLOB = () => {
  return axios.get<any>(host + ENDPOINTS.GET_ALL_LOB)
}

export const addLOBAPI = (payload: AddLOBRequestInterface) => {
  return axios.post<any>(host + ENDPOINTS.ADD_LOB, payload)
}

export const getStateist = () => {
  return axios.get<any>(host + ENDPOINTS.GET_STATE_LIST)
}

export const getUserEntitlement = () => {
  return axios.get<string[]>(host + ENDPOINTS.GET_USER_ENTITLEMENT)
}