import { ISvg } from './ISvg'

const SvgEye = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6133 5.73317C12.2667 2.6065 9.73332 0.666504 6.99998 0.666504C4.26665 0.666504 1.73332 2.6065 0.38665 5.73317C0.349941 5.81728 0.330994 5.90806 0.330994 5.99984C0.330994 6.09161 0.349941 6.18239 0.38665 6.2665C1.73332 9.39317 4.26665 11.3332 6.99998 11.3332C9.73332 11.3332 12.2667 9.39317 13.6133 6.2665C13.65 6.18239 13.669 6.09161 13.669 5.99984C13.669 5.90806 13.65 5.81728 13.6133 5.73317ZM6.99998 9.99984C4.88665 9.99984 2.88665 8.47317 1.73332 5.99984C2.88665 3.5265 4.88665 1.99984 6.99998 1.99984C9.11332 1.99984 11.1133 3.5265 12.2667 5.99984C11.1133 8.47317 9.11332 9.99984 6.99998 9.99984ZM6.99998 3.33317C6.47257 3.33317 5.95699 3.48957 5.51846 3.78258C5.07993 4.0756 4.73814 4.49208 4.5363 4.97935C4.33447 5.46662 4.28166 6.0028 4.38456 6.52008C4.48745 7.03736 4.74143 7.51252 5.11437 7.88546C5.48731 8.2584 5.96246 8.51237 6.47974 8.61526C6.99703 8.71816 7.5332 8.66535 8.02047 8.46352C8.50774 8.26168 8.92422 7.91989 9.21724 7.48136C9.51025 7.04283 9.66665 6.52725 9.66665 5.99984C9.66665 5.29259 9.3857 4.61432 8.8856 4.11422C8.3855 3.61412 7.70723 3.33317 6.99998 3.33317ZM6.99998 7.33317C6.73628 7.33317 6.47849 7.25497 6.25922 7.10846C6.03996 6.96195 5.86906 6.75372 5.76814 6.51008C5.66723 6.26645 5.64082 5.99836 5.69227 5.73972C5.74372 5.48108 5.8707 5.2435 6.05717 5.05703C6.24364 4.87056 6.48122 4.74357 6.73986 4.69212C6.9985 4.64068 7.26659 4.66708 7.51023 4.768C7.75386 4.86891 7.9621 5.03981 8.10861 5.25908C8.25512 5.47834 8.33332 5.73613 8.33332 5.99984C8.33332 6.35346 8.19284 6.6926 7.94279 6.94265C7.69274 7.19269 7.35361 7.33317 6.99998 7.33317Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SvgEye
