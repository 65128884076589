import Lottie from 'lottie-react'
import { PartyComponent } from 'partwidget'
import { useContext, useEffect, useRef, useState } from 'react'
import Loader from '../../assets/lottiefiles/loader.json'
import Button from '../../components/button/Button'
import SvgSearch from '../../components/svg/SvgSearch'
import { Textbox } from '../../components/textbox/Textbox'
import {
  WDButtonDisabled,
  WDButtonGrayTransparent,
  WDButtonPrimaryOutlined,
} from '../../components/ui/WDButton'
import { WDLabelError } from '../../components/ui/WDLabel'
import { WDTextbox } from '../../components/ui/WDTextbox'
import { AuthContext } from '../../services/AuthContext'
import RetirementDetailsContext from '../../services/Retirement/RetirementContext'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'
import ClientAndAccountSearchContext from '../../services/clientAndAccountSearch/ClientAndAccountSearchContext'
import {
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
} from '../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import appConfig from '../../settings/appConfig'
import { COLORS } from '../../theme/Colors'
import { ClientWrapper } from '../forms/contactInfo/styles'
import { ClientSearchTable } from './ClientSearchTable'
import {
  ClientSearchProps,
  ClientSearchRequestInterface,
} from './IClientSearch'
import {
  ButtonWrapper,
  ClientFooterWrapper,
  ClientToolbarWrapper,
  FormContainer,
  LottieWrapperClientSearch,
  ModalWrapper,
  SearchButtonWrapper,
  SearchTerm,
} from './styles'
// import TrustContext from '../../services/Trust/TrustContext'

export const ClientSearch = ({
  closeModalCallbackFn,
  searchValue,
  trustedContact,
  clientDetailsCallBackFn,
  isPrimaryBeneficiary,
  isContingentBeneficiary,
  isBeneOwner,
  isAddiParticipant,
  isTrust,
  isTrustEe,
  isTrustBene,
  isSuccessorTrust,
}: // isTrustGranters,
ClientSearchProps) => {
  const form = useRef<HTMLFormElement>(null)
  // const [modal, setModal] = useState(false)
  const [clientSearchTextError, setClientSearchTextError] = useState(false)
  const { accessToken, loggedUser } = useContext(AuthContext)
  const [isChange, setIsChange] = useState(true)
  const [isLoading, setLoader] = useState(false)
  const { searchClients, clients, selectClient, setClients } = useContext(
    ClientAndAccountSearchContext
  )
  const [filters, setFilters] = useState<ClientSearchRequestInterface>({
    name: '',
  })

  const checkIfFilterChange = (updatedFilter: ClientSearchRequestInterface) => {
    return filters !== updatedFilter
  }
  const { setSelectedClient } = useContext(ApplicationInfoContext)
  const {
    setSelectedClientContingent,
    setSelectedClientPrimary,
    setSelectedClientBeneOwner,
    setSelectedClientAddiParticipant,
     setSelectedTrustItems
  } = useContext(RetirementDetailsContext)

  const closeModal = () => {
    closeModalCallbackFn(false)
  }

  const openModal = () => {
    const formElement = form.current?.elements as HTMLFormControlsCollection
    const clientName = (formElement.namedItem('clientName') as HTMLInputElement)
      .value
    if (clientName.length > 1) {
      const updatedValue: ClientSearchByNamePayloadInterface = {
        searchName: clientName,
        repCode: [],
      }
      setLoader(true)
      searchClients(updatedValue)
        .then(() => setLoader(false))
        .catch(() => setLoader(false))
    }
  }

  const submitForm = (event: SubmitEvent) => {
    event.preventDefault()
    openModal()
  }

  const resetValues = () => {
    form.current?.reset()
    const updatedValue: ClientSearchRequestInterface = {
      name: '',
    }
    const formElement = form.current?.elements as HTMLFormControlsCollection
    const clientElement = formElement.namedItem(
      'clientName'
    ) as HTMLInputElement
    clientElement.value = ''
    setFilters(updatedValue)
    setIsChange(true)
  }

  useEffect(() => {
    if (searchValue && searchValue.length > 1) {
      const updatedValue: ClientSearchByNamePayloadInterface = {
        searchName: searchValue,
        repCode: [],
      }
      setLoader(true)
      searchClients(updatedValue)
        .then(() => setLoader(false))
        .catch(() => setLoader(false))
    }
  }, [searchClients, searchValue])

  useEffect(() => {
    setClients([])
  },[setClients])

  const getSelectedClient = (
    currentClient: ClientSearchByNameResponseInterface
  ) => {
    if (!trustedContact) {
      if (isPrimaryBeneficiary) {
        setSelectedClientPrimary(currentClient)
        selectClient(currentClient)
      }
      if (isContingentBeneficiary) {
        setSelectedClientContingent(currentClient)
        selectClient(currentClient)
      }
      if (isBeneOwner) {
        setSelectedClientBeneOwner(currentClient)
        selectClient(currentClient)
      }
      if (isAddiParticipant) {
        setSelectedClientAddiParticipant(currentClient)
        selectClient(currentClient)
      }
      if (isTrust) {
        setSelectedTrustItems((prevTrustItems) => ({ ...prevTrustItems, trust: currentClient }));
        selectClient(currentClient);
      }
      if (isTrustEe) {
        setSelectedTrustItems((prevTrustItems) => ({ ...prevTrustItems, trustEe: currentClient }));
        selectClient(currentClient);
      }
      if (isTrustBene) {
        setSelectedTrustItems((prevTrustItems) => ({ ...prevTrustItems, trustBene: currentClient }));
        selectClient(currentClient);
      }
      if (isSuccessorTrust) {
        setSelectedTrustItems((prevTrustItems) => ({ ...prevTrustItems, successorTrustee: currentClient }));
        selectClient(currentClient);
      }
      // if (isTrustGranters) {
      //   setSelectedClientGranters(currentClient)
      //   selectClient(currentClient)
      // }
      else {
        setSelectedClient(currentClient)
        selectClient(currentClient)
      }
    }
    clientDetailsCallBackFn?.(currentClient)
  }
  return (
    <ModalWrapper>
      <ClientWrapper>
        <PartyComponent
          accessToken={accessToken}
          user={loggedUser}
          apiUrl={appConfig.extapiEndPoint}
        />
      </ClientWrapper>
      <ClientFooterWrapper>
        {trustedContact && (
          <ClientToolbarWrapper>
            <FormContainer ref={form} onSubmit={submitForm}>
              <SearchTerm>
                <WDTextbox>
                  <SearchButtonWrapper>
                    <WDButtonGrayTransparent>
                      <Button
                        type={'button'}
                        onClick={openModal}
                        prefixedIcon={<SvgSearch fillColor={COLORS.UI.Gray} />}
                      ></Button>
                    </WDButtonGrayTransparent>
                  </SearchButtonWrapper>
                  <Textbox
                    placeholder={'Client Name...'}
                    type={'text'}
                    name="clientName"
                    onChange={(value: string) => {
                      value.length > 1
                        ? setClientSearchTextError(false)
                        : setClientSearchTextError(true)
                      checkIfFilterChange({
                        ...filters,
                        name: value,
                      })
                        ? setIsChange(false)
                        : setIsChange(true)
                    }}
                  />
                </WDTextbox>
                {clientSearchTextError && (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <WDLabelError>
                      {'Enter at least 2 characters to search.'}
                    </WDLabelError>
                  </div>
                )}
              </SearchTerm>
              <ButtonWrapper>
                {!isChange ? (
                  <WDButtonPrimaryOutlined>
                    <Button type="button" onClick={resetValues}>
                      {'Reset'}
                    </Button>
                  </WDButtonPrimaryOutlined>
                ) : (
                  <WDButtonDisabled>
                    <Button type="button" onClick={resetValues}>
                      {'Reset'}
                    </Button>
                  </WDButtonDisabled>
                )}
              </ButtonWrapper>
            </FormContainer>
          </ClientToolbarWrapper>
        )}
        {!isLoading ? (
          <ClientSearchTable
            rowData={clients ? clients : []}
            closeModalCallbackFn={closeModal}
            clientDetailsCallBackFn={(details) => getSelectedClient(details)}
            isLoading={clients && clients?.length > 0 ? true : true}
          />
        ) : (
          <LottieWrapperClientSearch>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapperClientSearch>
        )}
      </ClientFooterWrapper>
    </ModalWrapper>
  )
}
