import { Dispatch, createContext } from 'react'
import {
  GetFundingResponseModel,
  finalfundingObj,
} from '../shared/models/IFunding'
import {
  AddFunding,
  UpdateFunding,
  GetFunding,
} from '../services/Funding/Funding'
import { ContextProps } from '../composites/forms/formWip/IFormWip'

export interface FundingContextInterface {
  fundingInfoData: GetFundingResponseModel
  setFundingInfoData: Dispatch<React.SetStateAction<GetFundingResponseModel>>
}
const FundingStore = {
  fundingDetails: null,
  fundingInfoData: {} as GetFundingResponseModel,
  setFundingInfoData: (Details: any) => {
    FundingStore.fundingInfoData = Details
  },
  getFunding: async (wipacctid: number) => {
    const { data } = await GetFunding(wipacctid)
    FundingStore.setFundingInfoData(data)
    return data
  },

  AddFunding: async (payload: finalfundingObj) => {
    const addFundingResponse = await AddFunding(payload)
    return addFundingResponse
  },
  UpdateFunding: async (payload: finalfundingObj) => {
    const updateFundingResponse = await UpdateFunding(payload)
    return updateFundingResponse
  },
}

const FundingContext = createContext(FundingStore)

export const FundingContextProvider = (props: ContextProps) => {
  return (
    <FundingContext.Provider value={FundingStore}>
      {props.children}
    </FundingContext.Provider>
  )
}

export default FundingContext
