import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const MasterWrapper = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  /* position: absolute;
  left: 32%;
  top: 14%;
  right: 20%; */
  flex-direction: column;
  background-color: ${COLORS.Background.White};
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${COLORS.Border.Neutral};
`
export const ClientHeaderWrapper = styled.div`
  background: ${COLORS.Background.PrimaryText};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  gap: 12.25rem;
`
export const ClientFooterWrapper = styled.div`
  border: 1px solid ${COLORS.Border.NeutralWeak};
  padding: 0.5rem;
  background-color: ${COLORS.Background.White};
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  box-shadow: 0px 8px 16px -8px ${COLORS.BoxShadow.Shadow1};
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: ${COLORS.Text.Black};
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const ClientToolbarWrapper = styled.div`
  background: ${COLORS.Background.NeutralMedium};
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: row;
  padding: 0.85rem;
`

export const FormContainer = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
`
export const SearchButtonWrapper = styled.div`
  right: 1rem;
  position: absolute;
  display: flex;
  align-items: center;
  height: inherit;
`
export const SearchTerm = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  color: ${COLORS.Text.Black};
  height: 2.5rem;
  gap: 0.25rem;
  position: relative;
  input {
    width: 10rem;
    padding-right: 2rem;
    border: 0.0625rem solid ${COLORS.Text.DisabledText};
    border-radius: 4px;
    &:hover {
      border: 0.0625rem solid ${COLORS.Border.PrimaryText};
      color: ${COLORS.Text.Black};
    }
    &:focus {
      border: 0.0625rem solid ${COLORS.Border.PrimaryText};
      color: ${COLORS.Text.Black};
    }
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
`
export const LottieWrapperClientSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 5rem;
  padding: 0.5rem;
  margin: auto;
`
