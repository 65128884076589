import axios, { AxiosResponse } from 'axios'
import {
  CreateWipRequestInterface,
  CreateWipResponseInterface,
  GetWipRequestInterface,
  IGetAccTypeRequest
} from '../../composites/forms/formWip/IFormWip'
import { AccountTypesResponseType } from '../applicationContext/IApplicationPageContext'
import ENDPOINTS from '../httpEndpoints'

export const addWipDetails = (payload: CreateWipRequestInterface) => {
  return axios.post<CreateWipResponseInterface>(ENDPOINTS.CREATE_WIP, payload)
}

export const getWipDetails = (payload: GetWipRequestInterface) => {
  return axios.post<any>(ENDPOINTS.GET_WIP_DETAILS, payload)
}

export const getAccountTypes = (
  payload: IGetAccTypeRequest
): Promise<AxiosResponse<AccountTypesResponseType>> => {
  return axios.get<AccountTypesResponseType>(
    ENDPOINTS.GET_ACCOUNT_TYPES.replace('{lob}', payload.lob)
  )
}

export const GetClientJSON = (partyid: number, wipacctid: number) => {
  return axios.get(
    ENDPOINTS.GET_CLIENT_DATA.replace('{partyid}', partyid.toString()).replace(
      '{wipacctid}',
      wipacctid.toString()
    )
  )
}

export const UpdateClientProfileAPI = (payload: any) => {
  return axios.post(ENDPOINTS.UPDATE_CLIENT_PROFILE, payload)
}

export const getPdfTemplate = (payload: any) => {
  return axios.post(ENDPOINTS.GET_PDF_DATA, payload, { responseType: 'blob' })
}
