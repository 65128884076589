import styled from 'styled-components'
import { COLORS } from '../../../../theme/Colors'

export const FeatureInfoRow = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 10px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: row;

  @media screen and (min-width: 1440px) {
    gap: 12px !important;
  }
`
export const RowWrapper = styled.div`
  display: flex;
  /* padding: 0.5rem; */
  gap: 10px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 0 1 100%;
  gap: 10px;
  &#nowrap {
    white-space: nowrap;
  }
  textarea {
    resize: none;
    height: 90px;
    border-radius: 8px;
  }
`
export const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
  width: 100%;
`
export const CenterWrapper = styled.div`
  flex: 0 1 40%;
`

export const FundingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: calc(67vh-2rem);
  /* padding: 0.25rem; */
  //overflow-y: scroll;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: ${COLORS.Background.StrongBackground};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`

export const FundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem 0;
  gap: 1rem;
`
export const DocumentRowContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  &#secondRow {
    margin-top: 8px;
    flex: 0 1 40%;
  }
`
export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const ImageWrapper = styled.div``
export const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  //height: 65vh;
  gap: 1rem;
  padding: 10px;
  // overflow-y: scroll;
  // ::-webkit-scrollbar {
  //   background: ${COLORS.Background.NeutralBackgroundMedium};
  //   width: 9px;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   height: 8px;
  //   background: ${COLORS.Background.StrongBackground};
  //   top: 8px;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: rgba(167, 175, 188, 0.3);
  // }
  // ::-webkit-scrollbar-track {
  //   border-radius: 8px;
  // }
`
export const SectionWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  &#best_of_interest_read_only {
    gap: 0rem !important;
  }
 
`
export const RadioOptionWrap = styled.div``
export const RadioTitle = styled.div`
  width: 46%;
`
