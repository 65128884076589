import { Icons } from '../../shared/GlobalStyle'
import { COLORS } from '../../theme/Colors'
import Button from '../button/Button'
import { SvgCalender } from '../svg/SvgCalendar'
import { InputBoxProps } from './ICustomCalendar'
import { InputWrapper, StyledInput } from './styles'

const InputBox = ({
  name,
  placeholder,
  value,
  onClick,
  disabled,
  fillColor
}: InputBoxProps) => {
  return (
    <InputWrapper>
      <StyledInput
        readOnly
        onClick={onClick}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={() => value}
        disabled={disabled}
      />
      <Button
        bgColor={'transparent'}
        color={'transparent'}
        type="button"
        borderColor={'transparent'}
        prefixedIcon={<SvgCalender fillColor={`${fillColor || COLORS.Text.PrimaryText}`} />}
        onClick={onClick}
      />
    </InputWrapper>
  )
}

export default InputBox
