import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'
import { TextSizes, TextWeight } from '../../../theme/Sizes'

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-column: 1 / 13;
`
export const StyledDocumentPanel = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.Background.White};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border: 1px solid ${COLORS.Background.White};
  /* border-radius: 8px; */
  width: 100%;
  padding: 0.5rem;
  &#remove {
    box-shadow: none !important;
    background: none !important;
    border: 0px !important;
  }
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  }
  @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`
export const WipHeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`
export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 80%;
`
export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 1 90%;
  padding: 1rem;
`
export const CheckBoxWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${COLORS.UI.PrimaryText};
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${COLORS.UI.PrimaryText};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export const CheckboxInput = styled.input``
export const CheckMark = styled.span``
export const ContainerOne = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  .custom-checkbox input:checked ~ .checkmark {
    background-color: #93C5FD !important;
    cursor: no-drop;
  }
`
export const ContainerTwo = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const DisclosureGrid = styled.div`
  display: grid;
  grid-column: 1/13;
`
export const FormListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const WDLabelClientLabel = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
export const WDLabelClientValues = styled.div`
  font-size: 0.75rem;
  font-weight: ${TextWeight[1]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${COLORS.Text.NeutralText};
`
