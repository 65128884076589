import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { ISelected } from './IAccountOpeningScreen'
import { SIZES } from '../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  width: 100%;
  height: 120px;
  /* padding: 1.5rem 1rem 1rem; */
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.Background.Primary10};
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const DashboardContentWrapperOne = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin: 0.5rem;
  padding: 0 0.5rem 0.5rem 0.5rem;
  background-color: ${COLORS.Background.White};
  flex: 0 1 100%; */
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.Background.White};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  gap: 0.5rem;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
`
export const WrapperTwo = styled.div`
  &.table_loader {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &#table_column {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
  }
`
export const TableWrapper = styled.div`
  &.flex {
    display: flex;
  }
  width: 100%;
  height: calc(100vh - 28rem);
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    width: 9px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.Background.Primary10};
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  table {
    table-layout: flex;
    border-collapse: collapse;
    min-width: 100%;
    flex-direction: column;
    border: none;
    text-align: left;

    thead {
      font-family: 'SFMono-Regular';
      font-style: normal;

      tr {
        display: table-row;
        align-items: center;
        box-shadow: inset 0px -1px 0px ${() => COLORS.UI.Gray30};

        th {
          display: table-cell;
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
          padding: 0.25rem 0 0.25rem 0.5rem;
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }

    tbody {
      font-family: 'SFMono-Regular';
      font-style: normal;

      &:nth-child(2n + 1) {
        background-color: ${() => COLORS.Background.Gray130};
      }

      tr {
        display: table-row;
        font-family: 'SFMono-Regular';

        &.description {
          border-bottom: 0.25rem solid ${() => COLORS.Border.NeutralMedium};
        }

        &.selected {
          background-color: ${() => COLORS.Background.Gray90};
          border-bottom: 1px solid ${() => COLORS.Border.Primary5};
          td {
            color: ${() => COLORS.UI.PrimaryText};
            font-weight: 600;
          }
        }
        td {
          display: table-cell;
          flex-wrap: wrap;
          // word-break: break-word;
          width: fit-content;
          align-items: center;
          padding: 0.5rem;
          gap: 0.5rem;
          box-shadow: inset 0 -1px 0 ${() => COLORS.UI.Gray30};
          font-size: 0.875rem;
          line-height: 1.25rem;
          &.Attribute {
            font-family: 'SFMono-Regular';
          }
          &#R {
            color: ${() => COLORS.UI.Negative2};
          }
          &#O {
            color: ${() => COLORS.UI.Success2};
          }
          &#C {
            color: ${() => COLORS.UI.PrimaryText};
            &.Status {
              color: ${() => COLORS.UI.NeutralText};
              font-weight: 700;
              font-size: 1rem;
              line-height: 1.25rem;
            }
            &#R {
              color: ${() => COLORS.UI.Negative2};
            }
            &#O {
              color: ${() => COLORS.UI.Success2};
            }
            &#C {
              color: ${() => COLORS.UI.PrimaryText};
            }
            &#Description {
              width: 3.125rem;
            }
          }
        }
      }

      tfoot {
        font-family: 'SFMono-Regular';
        font-style: normal;

        tr {
          display: table-row;
          align-items: center;

          td {
            display: table-cell;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`

export const TableHead = styled.thead`
  th {
    position: sticky;
    padding-right: 10px; /* Add padding to create space for the vertical line */

    background-color: ${() => COLORS.Border.Gray50};
  }

  th:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0.5rem;
    right: 0;
    height: 50%; /* Adjust the percentage value to control the height of the line */
    width: 1px;
    background-color: ${() => COLORS.Border.Gray50};
  }
  // th {
  //     border-right: 1px solid #000;

  // }
  // th:last-child {
  //     border-right:none;
  // }

  position: sticky;
  top: -2px;
`

export const TableHeader = styled.th`
  padding: 16px;
  border: 1px solid #ddd;
  font-weight: bold;
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr``

export const TableCell = styled.td`
  padding: 8px;
  max-width: 200px;
  white-space: no-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // border-bottom: 1px solid #ddd;
`
export const WrapperThree = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 90%;
`
export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
  margin-right: 1rem;
  gap: 0.25rem;
`

export const FirstTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
`
export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 20%;
`
export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const TextBoxButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
  margin-right: 1rem;
  gap: 0.25rem;
  padding-top: 1.7rem;
  max-width: 6rem;
`

// Style for the divider
export const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
`

// Style for the highlighted row
export const HighlightRow = styled.div`
  display: grid;
  grid-column: span 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

export const KpiTab = styled.div`
  flex: 1;
  display: flex;
  flex: 0 1 100%;
  padding: 0.25rem;
  align-items: center;
  background-color: ${(props: ISelected) =>
    props.isSelected ? '#ddeaf9' : '#fff'};
  border: ${(props: ISelected) =>
    props.isSelected ? `2px solid ${COLORS.UI.Brand1}` : ''};
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
`
export const KpiNewTab = styled.div`
  display: grid;
  grid-column: span 1;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.3rem;
  border: 1px solid
    ${(props: { border: string }) => (props.border ? props.border : 'none')};
  background-color: ${(props: { bgColor: string }) =>
    props.bgColor ? props.bgColor : 'none'};
`
export const IconWrapper = styled.div`
  display: flex;
  flex: 0 1 20%;
  justify-content: center;
  align-items: center;
`
export const StatusHeadWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
export const ProgressWrapper = styled.div`
  display: grid;
  grid-column: span 1;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
`
export const KpiValue = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
  color: ${COLORS.Text.Brand2};
`

// Styles for the applications header
export const ApplicationsHeader = styled.h2`
  font-size: 24px;
  margin: 0.5rem;
  text-align: left;
`

export const MasterWrapperModel = styled.div`
  background: ${COLORS.Background.White};
  box-sizing: border-box;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Background.OverlayBackground};
  z-index: 999999;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 30%;
  top: 40%;
  right: 30%;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #fff7ed;
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 40%;
  border: 1px solid #fb923c;
`

export const ModelButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
`
export const DashBoardHeaderWrapper = styled.div`
  //padding: 0 10px 10px 10px;
`
export const StyledHead = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: ${COLORS.UI.PrimaryTextStrong};
`
export const CountWrapper = styled.div`
  color: #2e844a;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding-top: 2px;
`
export const ProgressCount = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
export const NavHeaderWrapper = styled.div`
  display: flex;
  padding: 0.5rem 1rem 0rem 0.5rem;
  color: #fff;
  background: ${COLORS.UI.PrimaryText};
  //border-top-left-radius: 0.5rem;
  //border-top-right-radius: 0.5rem;
  width: 100%;
  height: 2rem;
  justify-content: space-between;
`

export const NavTitleWrapper = styled.div`
  line-height: 37px;
  width: 70%;
  display: flex;
  align-self: center;
  padding: 0%.5rem;
`
export const NavCreateAccount = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
  padding: 2px 0;
`
export const NavDivider = styled.div`
  padding-top: 8px;
`
export const NavSearchWrapper = styled.div`
  display: flex;
  grid-auto-flow: column;
  justify-content: flex-end;
  gap: 0.5rem;
`
export const NavButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 2.5rem;
  height: 32px;
`
export const TextBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 85%;
  position: relative;
  height: 2rem;
  &#search_button > div:nth-child(1) {
    padding-left: 10px;
    position: absolute;
  }
  div:nth-child(2) > div {
    height: 32px;
  }
  input {
    border-radius: 4px !important;
  }
  img {
    position: absolute;
    left: 0.5rem;
  }
  .input {
    position: absolute;
  }
`
export const ButtonContainer = styled.div`
  border: none;
  button {
    padding: 0;
  }
  &.repcode_drop {
    .css-1n6sfyn-MenuList {
      overflow-y: auto;
      max-height: 200px;
      ::-webkit-scrollbar {
        background: ${COLORS.Background.NeutralBackgroundMedium};
        width: 5px;
        border-radius: 8px;
      }
      ::-webkit-scrollbar-thumb {
        height: 100px;
        background: ${COLORS.UI.Brand1};
        top: 8px;
        border-radius: 8px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(167, 175, 188, 0.3);
      }
      ::-webkit-scrollbar-track {
        background: ${COLORS.Background.White};
      }
      ::-webkit-scrollbar-thumb {
        background: ${COLORS.Background.Primary10};
        border-radius: 4px;
      }
    }
  }
`
export const MoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
`
export const HighlightOuter = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
`
export const CardSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
`
export const HeaderWrapper = styled.div`
  display: flex;
  color: #fff;
  justify-content: space-between;
  background: ${COLORS.Background.PrimaryText};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  padding: 20px 16px;
  align-items: center;
`
