import { Dispatch, createContext } from 'react'
import { ContextProps } from '../composites/forms/formWip/IFormWip'
import {
  DeleteDoc,
  DownloadDoc,
  FetchReqDocs,
  GetDocDetails,
  UpdateDocs,
  UploadDocs,
} from '../services/DocumentUpload/DocUploadService'
import {
  DelteFile,
  DocumentsResponseInterface,
  DownloadFile,
  GetFileDetails,
  UpdateFiles,
} from '../shared/models/IFormDocuments'

export interface IDocumentService {
  documentsDetails: DocumentsResponseInterface
  setDocumentsDetails: Dispatch<React.SetStateAction<DocumentsResponseInterface>>
  uploadedFiles:DownloadFile[]
  setUploadedFiles:Dispatch<React.SetStateAction<DownloadFile[]>>
}
export const FormDocStore = {
  UploadDocs: async (payload: FormData) => {
    const uploaddocs = await UploadDocs(payload)
    return uploaddocs
  },

  UpdateDocs: async (payload: UpdateFiles) => {
    const updatedocs = await UpdateDocs(payload)
    return updatedocs
  },

  documentsDetails: {} as DocumentsResponseInterface,
  setDocumentsDetails: (Details: any) => {
    FormDocStore.documentsDetails = Details
  },
  uploadedFiles: [] as DownloadFile[],
  setUploadedFiles: (Files: any) => {
    FormDocStore.uploadedFiles = Files
  },
  GetDocDetails: async (payload: GetFileDetails) => {
    const getDocDetails = await GetDocDetails(payload)
    FormDocStore.setDocumentsDetails(getDocDetails)
    return getDocDetails
  },

  DownloadDoc: async (payload: DownloadFile) => {
    const downloaddoc = await DownloadDoc(payload)
    return downloaddoc
  },
  FetchReqDocs: async (payload: GetFileDetails) => {
    const fetchReqDocs = await FetchReqDocs(payload)
    return fetchReqDocs
  },
  DeleteDoc: async (payload: DelteFile) => {
    const deleteDocs = await DeleteDoc(payload)
    return deleteDocs
  },
}

const FormDocsContext = createContext(FormDocStore)

export const FormDocsContextProvider = (props: ContextProps) => {
  return (
    <FormDocsContext.Provider value={FormDocStore}>
      {props.children}
    </FormDocsContext.Provider>
  )
}

export default FormDocsContext
