import { useContext } from 'react'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { SvgApproveSmall } from '../../../components/svg/SvgApprove'
import { WDLabel, WDLabelSemiBold } from '../../../components/ui/WDLabel'
import { COLORS } from '../../../theme/Colors'
import { LottieWrapper, SpinnerWrapper } from '../../interceptor/styles'
import {
  ListDocsWrapper,
  RequiredWrapper,
  TitleDoc,
  TitleGrouping,
  UserWrapper,
} from './styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { DownloadFile } from '../../../shared/models/IFormDocuments'

interface ListDocumentProps {
  listTitle: string
  loaderList: boolean
  documentList: any
  uploadedDocs: DownloadFile[]
  coApplicant: any
  isDisclosure?: boolean
  isCustodian?: boolean
}

const ListofDocuments = ({
  listTitle,
  loaderList,
  documentList,
  isDisclosure,
  uploadedDocs,
  coApplicant,
  isCustodian,
}: ListDocumentProps) => {
  const { newModelData } = useContext(ApplicationInfoContext)
  const getAvatar = (coApplicant: any) => {
    let clientName = ''
    if (isCustodian) {
      //need to check
      clientName = coApplicant?.partyname
    } else {
      clientName = coApplicant?.partyname
    }
    const full_name = clientName.split(' ')
    let subAvatar = full_name[0][0]
    if (clientName) {
      if (full_name.length >= 2 && full_name[1]) {
        subAvatar += full_name[1][0]
      }
    } else {
      subAvatar = ''
    }
    return subAvatar.toUpperCase()
  }
  const getName = (coApplicant: any) => {
    let clientName = ''
    if (isCustodian) {
      clientName = coApplicant?.partyname
    } else {
      clientName = coApplicant?.partyname
    }
    return clientName
  }

  return (
    <ListDocsWrapper>
      {(newModelData &&
        newModelData?.wipAccountDetailslist &&
        newModelData?.wipAccountDetailslist[0]?.parties.some(item=>item.relationshipTypeId === 2)) ?
         (
        <TitleGrouping>
          <UserWrapper
            id={coApplicant?.relationshipTypeId == 1 ? '' : 'secondary'}
          >
            <WDLabelSemiBold>{getAvatar(coApplicant)}</WDLabelSemiBold>
          </UserWrapper>
          <WDLabelSemiBold>{`${getName(coApplicant)} ${
            coApplicant.relationshipTypeId == 1 ? '(Primary)' : '(Co-Applicant)'
          }`}</WDLabelSemiBold>
        </TitleGrouping>
      ) : null}

      {/* {(getWipResponse &&
        getWipResponse?.wipaccount &&
        getWipResponse?.wipaccount[0]?.subaccounttype === 4) ||
      getWipResponse?.wipaccount[0]?.subaccounttype === 105 ? (
        <TitleGrouping>
          <UserWrapper id={coApplicant?.iscoapplicant ? 'secondary' : ''}>
            <WDLabelSemiBold>
              {getAvatar(
                getWipResponse &&
                  getWipResponse?.wipaccount &&
                  getWipResponse?.wipaccount[0]?.subaccounttype
              )}
            </WDLabelSemiBold>
          </UserWrapper>
          <WDLabelSemiBold> {'Custodian'}</WDLabelSemiBold>
        </TitleGrouping>
      ) : null} */}

      <TitleDoc id="List-doc" className="header_responsive">
        {listTitle}
      </TitleDoc>
      {!loaderList ? (
        <>
          {documentList.length > 0 &&
            documentList?.map((doc: any, index: any) => {
              return (
                <RequiredWrapper key={index}>
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      {isDisclosure ? (
                        doc.value !== '27' ? (
                          <>
                            <WDLabel>{doc.label}</WDLabel>
                            {uploadedDocs.some(
                              (uploadedDoc) =>
                                uploadedDoc.docid == doc.value &&
                                uploadedDoc.relationshipid == doc.relationshipid
                            ) && (
                              <SvgApproveSmall
                                fillColor={COLORS.UI.Quaternary}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ textAlign: 'center' }}>
                                <span>(or)</span>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <WDLabel>{doc.label}</WDLabel>
                                {uploadedDocs.some(
                                  (uploadedDoc) =>
                                    uploadedDoc.docid == doc.value
                                ) && (
                                  <SvgApproveSmall
                                    fillColor={COLORS.UI.Quaternary}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <WDLabel>{doc.docname}</WDLabel>
                          {uploadedDocs.some(
                            (uploadedDoc) => uploadedDoc.docid == doc.docid
                          ) && (
                            <SvgApproveSmall fillColor={COLORS.UI.Quaternary} />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </RequiredWrapper>
              )
            })}
        </>
      ) : (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      )}
    </ListDocsWrapper>
  )
}
export default ListofDocuments
