import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const FullContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`
export const RadioContent = styled.div``
export const Wrapper = styled.div`
  gap: 20px;
`
export const FieldOne = styled.div``
export const FieldTwo = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`
export const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  &#width {
    width: 25% !important;
  }
  gap: 16px;

  &.radio_space {
    gap: 3rem;
  }
  &#client {
    display: flex;
    gap: 0.5rem;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    width: 100%;
  }
`
export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-right: 1rem;
  gap: 1rem;
`
export const FeatureInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* height: auto; */
  gap: 0.5rem;
`

export const FeatureInfoRow = styled.div`
  display: flex;
  gap: 4px;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: column;
`
export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 100%;
  flex: 0 1 10%;
  button {
    height: 2rem;
  }
`
export const CheckBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  /* padding: 1rem 0; */
`
export const TextAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  textarea {
    resize: none;
    height: 90px;
  }
`
export const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100vh - 19rem);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.Gray30};
    width: 5px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const AreaWrapper = styled.div`
  display: flex;
  input {
    width: 100%;
    height: 2rem;
  }
`
export const SearchWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  width: 100%;
`
export const ClientSearchWrapper = styled.div`
  display: flex;
  /* gap: 0.25rem; */
  padding: 0.5rem 0 0;
  position: relative;
  input {
    width: 15rem;
    ::placeholder {
      color: ${COLORS.Text.Gray};
    }
  }
`
export const BtnWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 35%;
`
export const ItemWrapper = styled.div`
  row-gap: 16px;
  display: grid;
  height: inherit;
  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 8px 2px 16px;
  position: sticky;
  top: 6%;
  &#full_col {
    z-index: 999;
  }
  #left_grp_next {
    row-gap: 16px;
    display: grid;
    height: fit-content;
  }
  ::-webkit-scrollbar {
    background: ${COLORS.Background.Gray30};
    width: 5px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &.new_wip_padding {
    padding: 0px 16px 16px 16px !important;
  }
  @media screen and (min-width: 1400px) {
    &#doc_scr {
      padding: 0px 0px 16px 0px;
      grid-column: 2 / span 10;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    &#left_col {
      padding: 0px 8px 18px 16px;
      grid-column: span 4;
      height: inherit;
      max-height: inherit;
    }
    &#right_col {
      padding: 0px 8px 18px 0px;
      grid-column: span 8;
    }
    &#full_col {
      padding: 0px 0px 16px 0px;
      grid-column: span 12;
    }
    &#doc_scr {
      padding: 0px 0px 16px 0px;
      grid-column: 2 / span 10;
    }
  }
  @media screen and (min-width: 1400px) {
    &#left_col {
      padding: 2px 8px 2px 16px;
      grid-column: span 4;
    }
    &#right_col {
      padding: 2px 8px 2px 0px;
      grid-column: span 8;
    }
    &#full_col {
      padding: 2px 8px 2px 16px;
      grid-column: span 12;
    }
  }
  ${({ sm, md, lg }: any) => `
    @media screen and (max-width: 768px) {
      grid-column: span ${sm} !important;
      padding: 0px 16px !important;
    }
 
    @media screen and (max-width: 576px) {
      grid-column: span ${md} !important;
    }
 
    @media screen and (max-width: 375px) {
      grid-column: span ${lg} !important;
    }
  `}
`
