import GetResponsiveSvg from '../../composites/forms/accountInformation/GetResponsiveSvg'
import { StyledDiv } from './styles'

export const SvgProfile = () => {
  const svgDimension = GetResponsiveSvg()
  return (
    <StyledDiv>
      <svg
        width={svgDimension}
        height={svgDimension}
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.85714 3.80952C2.85714 4.81987 3.2585 5.78884 3.97293 6.50326C4.68735 7.21769 5.65632 7.61905 6.66667 7.61905C7.67702 7.61905 8.64598 7.21769 9.36041 6.50326C10.0748 5.78884 10.4762 4.81987 10.4762 3.80952C10.4762 2.79918 10.0748 1.83021 9.36041 1.11578C8.64598 0.401359 7.67702 0 6.66667 0C5.65632 0 4.68735 0.401359 3.97293 1.11578C3.2585 1.83021 2.85714 2.79918 2.85714 3.80952ZM5.66964 9.76786L6.22322 10.6905L5.23214 14.378L4.16071 10.006C4.10119 9.76488 3.86905 9.60714 3.62798 9.66964C1.54464 10.1905 0 12.0774 0 14.3244C0 14.8304 0.410714 15.2381 0.913691 15.2381H12.4196C12.9256 15.2381 13.3333 14.8274 13.3333 14.3244C13.3333 12.0774 11.7887 10.1905 9.70536 9.66964C9.46429 9.61012 9.23214 9.76786 9.17262 10.006L8.10119 14.378L7.11012 10.6905L7.66369 9.76786C7.85417 9.44941 7.625 9.04762 7.25595 9.04762H6.08036C5.71131 9.04762 5.48214 9.45238 5.67262 9.76786H5.66964Z"
          fill="#C2410C"
        />
      </svg>
    </StyledDiv>
  )
}
