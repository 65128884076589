import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`
export const StyledDocumentPanel = styled.div`
  display: flex;
  background: ${COLORS.Background.White};
  border: 1px solid ${COLORS.Background.Border};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 4px;
  width: 100%;
  padding: 3rem;
  /* @media (min-width: 1920px) {
    width: 1072px;
  } */
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0;
  align-items: center;
  align-content: center;
  width: 100%;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 18px;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
`
export const FirstParagraph = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ContactWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
