import { useContext, useEffect, useState } from 'react'
import Image from '../../components/image/Image'
import Divider from '../../components/divider/Divider'
import Button from '../../components/button/Button'
import { Icons } from '../../shared/GlobalStyle'
import {
  DividerWrapper,
  MasterWrapper,
  ModalWrapper,
  OverlayWrapper,
  LottieWrapper,
  SpinnerWrapper,
  DocViewErrWrapper,
} from './styles'
import Loader from '../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { ViewDocumentProps } from './IViewDocument'
import { COLORS } from '../../theme/Colors'
import { WDButtonGrayTransparent } from '../../components/ui/WDButton'
import PreviewDocumentPage from '../previewDocument/PreviewDocument'
import FormDocsContext from '../../store/FormDocsContext'
import { ClientHeaderWrapper } from '../clientSearch/styles'
import SvgClose from '../../components/svg/SvgClose'

export const ViewDocument = ({
  setModalCallBack,
  rowData,
}: ViewDocumentProps) => {
  const [modal, setModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const formDocsContext = useContext(FormDocsContext)
  const [currentFile, setCurrentFile] = useState<string>("")
  const [blobValue, setBlobValue] = useState<Blob>()

  const [currFileName, setCurrFileName] = useState<string>('')
  const [fileType, setFileType] = useState<string>('')
  const [docViewErr, setDocViewErr] = useState<string>('')

  const base64toBlob = (dataList: string, contentType: string) => {
    const byteString = atob(dataList)
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i)
    }
    const newBlob = new Blob([ab], {
      type: contentType,
    })
    return newBlob
  }

  const findContentType = (fileType: string) => {
    let contentType = ''
    switch (fileType) {
      case 'pdf':
        contentType = 'application/pdf'
        break
      case 'jpg':
        contentType = 'image/jpg'
        break
      case 'jpeg':
        contentType = 'image/jpeg'
        break
      case 'txt':
        contentType = 'text/plain'
        break
      case 'png':
        contentType = 'image/png'
        break
      case 'docx':
        contentType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        break
      case 'xlsx':
        contentType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        break
      case 'zip':
        contentType = 'application/zip'
        break
    }
    return contentType
  }

  useEffect(
    () => {
      async function getDownloadFile(fileContentType: string) {
        setLoading(true)
        try {
          formDocsContext.DownloadDoc(rowData).then((response: any) => {
            if (response) {
              const file = URL.createObjectURL(
                base64toBlob(response?.data, fileContentType)
              )
              setBlobValue(base64toBlob(response?.data, fileContentType))
              setCurrentFile(file)
              setLoading(false)
            }
          })
        } catch (error: any) {
          setLoading(false)
          setDocViewErr(error?.response?.data)
        }
      }
      if (rowData.docid !== undefined && rowData) {
        const currSelectedRow = rowData
        const getTypeDoc = findContentType(
          currSelectedRow.file.split('/').reverse()[0].split('.')[1]?.toLowerCase() || ''
        )
        setFileType(getTypeDoc)
        getDownloadFile(fileType)
        setCurrFileName(rowData.file.split('/')[1])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const toggleModal = () => {
    setModal(!modal)
    setModalCallBack(modal)
  }

  return (
    <MasterWrapper>
      {!docViewErr && (
        <OverlayWrapper>
          <ModalWrapper>
            <ClientHeaderWrapper>
              <WDButtonGrayTransparent>
                <Button
                  type={'button'}
                  onClick={toggleModal}
                  prefixedIcon={<SvgClose fillColor={COLORS.Text.White} />}
                ></Button>
              </WDButtonGrayTransparent>
            </ClientHeaderWrapper>
            <DividerWrapper>
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Border.Gray50}`}
                thickness={'0.0825rem'}
              />
            </DividerWrapper>
            {blobValue && (
              <PreviewDocumentPage
                fileURL={currentFile}
                blob={blobValue}
                fileName={currFileName}
                fileType={fileType}
              />
            )}
          </ModalWrapper>
        </OverlayWrapper>
      )}
      {docViewErr && (
        <OverlayWrapper>
          <ModalWrapper>
            <WDButtonGrayTransparent>
              <Button type={'button'} onClick={toggleModal}>
                <Image image={Icons.CancelLarge} alt={'Cancel'} />
              </Button>
            </WDButtonGrayTransparent>
            <DividerWrapper>
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Border.Gray50}`}
                thickness={'0.0825rem'}
              />
            </DividerWrapper>
            <DocViewErrWrapper>{docViewErr}</DocViewErrWrapper>
          </ModalWrapper>
        </OverlayWrapper>
      )}
      {isLoading && (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      )}
    </MasterWrapper>
  )
}
