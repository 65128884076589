import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import Loader from '../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { LottieWrapper, SpinnerWrapper, ToastWrapper } from './styles'
import { Toast } from '../../components/toast/Toast'
import ToastContext from '../../services/toast/ToastContext'
import appConfig from '../../settings/appConfig'
import { useAccount, useMsal } from '@azure/msal-react'
import { AuthContext } from '../../services/AuthContext'

const baseURL = appConfig.extapiEndPoint
// const removeSessionData = () => {
//   sessionStorage.clear()
//   localStorage.clear()
// }

let failedApiCall = true
// export const RefreshToken = async () => {
//   const msalInstance = new PublicClientApplication(msalConfig)
//   const getAcct: any = localStorage.getItem('AcctInfo')
//   const newRequestToken = await msalInstance.acquireTokenSilent({
//     scopes: protectedResources.api.scopes,
//     account: JSON.parse(getAcct),
//     forceRefresh: true,
//   })
//   localStorage.setItem('AcctInfo', JSON.stringify(newRequestToken.account))
//   localStorage.setItem('AccessToken', newRequestToken.accessToken)
//   return newRequestToken.accessToken
// }

const Interceptor = () => {
  const { toastOpen, toastMessage, setToast } = useContext(ToastContext)
  const [isLoading, setLoading] = useState(false)
  const { accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const authCtx = useContext(AuthContext)
  // const navigate = useNavigate()

  // useEffect(() => {
  //   const requestStack: number[] = []

  //   const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  //     requestStack.push(requestStack.length + 1)
  //     requestStack.length === 1 && setLoading(true)

  //     if (config.url) {
  //       if (config.url.includes('http') == false) {
  //         config.url = baseURL ? baseURL.concat(config.url) : config.url
  //       }
  //     }

  //     config.headers = { ...config.headers, 'Access-Control-Allow-Origin': '*' }

  //     // Sets the Authorization header with the token stored in localStorage
  //     config.headers.Authorization = `Bearer ${localStorage.getItem(
  //       'AccessToken'
  //     )}`
  //     return config
  //   }

  //   const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  //     requestStack.pop()
  //     requestStack.length === 0 && setLoading(false)
  //     setToast(false, error.message)
  //     return Promise.reject(error)
  //   }

  //   const onResponse = (response: AxiosResponse): AxiosResponse => {
  //     requestStack.pop()
  //     requestStack.length === 0 && setLoading(false)
  //     if(response.data.status=='400'){
  //       setToast(false,response.data.detail)
  //       navigate('/home')
  //     }
  //     return response
  //   }

  //   const onResponseError = (error: AxiosError) => {
  //     setLoading(false)
  //     const prevRequest: any = error?.config
  //     const expDate =
  //         account && account.idTokenClaims && account.idTokenClaims.exp
  //           ? new Date(account?.idTokenClaims?.exp * 1000)
  //           : new Date(),
  //       now = new Date()
  //     const forceRefreshPage = expDate < now
  //     if (forceRefreshPage) {
  //       failedApiCall = true
  //     }
  //     if (error?.response?.status === 401 && !prevRequest?.sent) {
  //       prevRequest.sent = true
  //       if (failedApiCall && authCtx.b2cRefreshToken) {
  //         failedApiCall = false
  //         authCtx?.b2cRefreshToken()
  //         prevRequest.headers.Authorization = `Bearer ${authCtx?.accessToken}`
  //         return axios(prevRequest)
  //       } else {
  //         prevRequest.headers.Authorization = `Bearer ${authCtx?.accessToken}`
  //         return axios(prevRequest)
  //       }
  //     }
  //     requestStack.pop()
  //     requestStack.length === 0 && setLoading(false)
  //     // removeSessionData()
  //     setToast(false, error.message)
  //     return Promise.reject(error)
  //   }

  //   axios.interceptors.request.use(onRequest, onRequestError)
  //   axios.interceptors.response.use(onResponse, onResponseError)
  // }, [setToast])

  useEffect(
    () => {
      const requestIntercept = axios.interceptors.request.use(
        (config) => {
          if (config.url) {
            if (config.url.includes('http') == false) {
              config.url = baseURL ? baseURL.concat(config.url) : config.url
            }
          }
          if (!config.headers?.Authorization) {
            //setLoading(true)
            //config.headers = {}
            config.headers.Authorization = `Bearer ${localStorage.getItem(
              'AccessToken'
            )}`
          }
          return config
        },
        (error) => Promise.reject(error)
      )

      const responseIntercept = axios.interceptors.response.use(
        (response) => {
          setLoading(false)
          return response
        },
        async (error) => {
          setLoading(false)
          const prevRequest = error?.config
          const expDate = account?.idTokenClaims?.exp
              ? new Date(account?.idTokenClaims?.exp * 1000)
              : new Date(),
            now = new Date()
          const forceRefreshPage = expDate < now
          if (forceRefreshPage) {
            failedApiCall = true
          }
          if (error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true
            if (failedApiCall && authCtx.b2cRefreshToken) {
              await authCtx?.b2cRefreshToken()
              failedApiCall = false
              prevRequest.headers.Authorization = `Bearer ${localStorage.getItem(
                'AccessToken'
              )}`
              return axios(prevRequest)
            } else if (!failedApiCall) {
              prevRequest.headers.Authorization = `Bearer ${localStorage.getItem(
                'AccessToken'
              )}`
              return axios(prevRequest)
            }
          } else if (!toastOpen) {
            //setToast(false, 'Could not load,please try again')
          }
          return Promise.reject(error)
        }
      )
      return () => {
        axios.interceptors.request.eject(requestIntercept)
        axios.interceptors.response.eject(responseIntercept)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, account?.idTokenClaims?.exp, authCtx, authCtx?.accessToken]
  )

  return (
    <>
      {isLoading && (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      )}
      {toastOpen !== null && (
        <ToastWrapper>
          <Toast
            text={toastMessage}
            type={toastOpen}
            openStatusCallback={() => setToast(null, '')}
          />
        </ToastWrapper>
      )}
    </>
  )
}

export default Interceptor
