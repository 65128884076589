import React from 'react';
import styled from 'styled-components';
 
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
 
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }
 
  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(4, 1fr);
  }
 
  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
 
const Item = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  grid-column: span 6;
  ${({ sm, md, lg }:any) => `
    @media screen and (max-width: 768px) {
      grid-column: span ${sm};
    }
 
    @media screen and (max-width: 576px) {
      grid-column: span ${md};
    }
 
    @media screen and (max-width: 375px) {
      grid-column: span ${lg};
    }
  `}
`;
 
const GridExample = () => {
  return (
    <Container>
      <Item sm={6} md={4} lg={3}>Item 1 (sm: 6, md: 4, lg: 3)</Item>
      <Item sm={6} md={4} lg={3}>Item 2 (sm: 6, md: 4, lg: 3)</Item>
      <Item sm={6} md={4} lg={3}>Item 3 (sm: 6, md: 4, lg: 3)</Item>
    </Container>
  );
};
 
export default GridExample;