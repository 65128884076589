import GetResponsiveSvg from '../../composites/forms/accountInformation/GetResponsiveSvg'
import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgBin = ({ fillColor }: ISvg) => {
  const svgDimension = GetResponsiveSvg()

  return (
    <StyledDiv>
      <svg
        width={svgDimension}
        height={svgDimension}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3333 3.99998H10.6667V3.33331C10.6667 2.80288 10.456 2.29417 10.0809 1.9191C9.70581 1.54403 9.1971 1.33331 8.66667 1.33331H7.33333C6.8029 1.33331 6.29419 1.54403 5.91912 1.9191C5.54405 2.29417 5.33333 2.80288 5.33333 3.33331V3.99998H2.66667C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665C2 4.84346 2.07024 5.01303 2.19526 5.13805C2.32029 5.26308 2.48986 5.33331 2.66667 5.33331H3.33333V12.6666C3.33333 13.1971 3.54405 13.7058 3.91912 14.0809C4.29419 14.4559 4.8029 14.6666 5.33333 14.6666H10.6667C11.1971 14.6666 11.7058 14.4559 12.0809 14.0809C12.456 13.7058 12.6667 13.1971 12.6667 12.6666V5.33331H13.3333C13.5101 5.33331 13.6797 5.26308 13.8047 5.13805C13.9298 5.01303 14 4.84346 14 4.66665C14 4.48984 13.9298 4.32027 13.8047 4.19524C13.6797 4.07022 13.5101 3.99998 13.3333 3.99998ZM6.66667 3.33331C6.66667 3.1565 6.7369 2.98693 6.86193 2.86191C6.98695 2.73688 7.15652 2.66665 7.33333 2.66665H8.66667C8.84348 2.66665 9.01305 2.73688 9.13807 2.86191C9.2631 2.98693 9.33333 3.1565 9.33333 3.33331V3.99998H6.66667V3.33331ZM11.3333 12.6666C11.3333 12.8435 11.2631 13.013 11.1381 13.1381C11.013 13.2631 10.8435 13.3333 10.6667 13.3333H5.33333C5.15652 13.3333 4.98695 13.2631 4.86193 13.1381C4.7369 13.013 4.66667 12.8435 4.66667 12.6666V5.33331H11.3333V12.6666Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
