import { createContext, Dispatch, ReactNode, useCallback, useMemo, useState } from 'react'

export interface ToastContextInterface {
  toastOpen: string | null
  toastMessage: string
  setToastOpen: Dispatch<React.SetStateAction<string | null>>
  setToast: (isOpen: string | null, message: string) => void
}

const ToastContext = createContext<ToastContextInterface>(
  {} as ToastContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const ToastContextProvider = ({ children }: ContextProps) => {
  const [toastOpen, setToastOpen] = useState<string | null>(null)
  const [toastMessage, setToastMessage] = useState<string>('')
  const [isLoading, setLoader] = useState(false)

  const setToast = useCallback(
    (isOpen: any, message: string) => {
      const getCurrentType =
        typeof isOpen === 'boolean' && isOpen
          ? 'success'
          : typeof isOpen === 'boolean'
          ? 'error'
          : isOpen
      setToastOpen(getCurrentType ? getCurrentType.toString() : '')
      setToastMessage(message)
    },
    [setToastMessage, setToastOpen]
  )
  const setFullLoader = useCallback(
    (isLoading: boolean) => {
      setLoader(isLoading)
    },
    [setLoader]
  )
  const values = useMemo(
    () => ({
      toastOpen,
      isLoading,
      toastMessage,
      setToast,
      setLoader,
      setToastOpen,
      setToastMessage,
      setFullLoader,
    }),
    [isLoading, setFullLoader, setToast, toastMessage, toastOpen]
  )

  return (
    <ToastContext.Provider
      value={values}
    >
      {children}
    </ToastContext.Provider>
  )
}

export default ToastContext
