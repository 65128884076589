import { createContext, Dispatch, ReactNode, useMemo, useState } from 'react'
import {
  Beneficiary,
  ClientSearchByNameResponseInterface,
} from './IRetirementContext'

interface TrustTotalState {
  trust: number;
  trustEe: number;
  trustBene: number;
  successorTrustee: number;
}
interface TrustTotalBeneficiaries {
  trust: Beneficiary[];
  trustEe: Beneficiary[];
  trustBene: Beneficiary[];
  successorTrustee: Beneficiary[];
}
export interface RetirmentDetailsContextInterface {
  //Primary Bene
  selectedClientPrimary: ClientSearchByNameResponseInterface
  setSelectedClientPrimary: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface>
  >
  selectedPrimaryBeneficiary?: any
  setSelectedPrimaryBeneficiary?:
    | Dispatch<React.SetStateAction<any>>
    | undefined
  selectedDataTotal?: number
  setSelectedDataTotal?: Dispatch<React.SetStateAction<number>> | undefined
  //Contingent Bene
  selectedClientContingent: ClientSearchByNameResponseInterface
  setSelectedClientContingent: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface>
  >
  selectedDataContingentTotal?: number
  setSelectedContingentDataTotal?:
    | Dispatch<React.SetStateAction<number>>
    | undefined
  selectedContingentBeneficiary?: any
  setSelectedContingentBeneficiary?:
    | Dispatch<React.SetStateAction<any>>
    | undefined
  //Bene Owner
  selectedClientBeneOwner: ClientSearchByNameResponseInterface
  setSelectedClientBeneOwner: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface>
  >
  selectedBeneOwner?: any
  setSelectedBeneOwner?: Dispatch<React.SetStateAction<any>> | undefined
  beneOwnerTotal?: number
  setBeneOwnerTotal?: Dispatch<React.SetStateAction<number>> | undefined

  //AdditionalParticipants
  selectedClientAddiParticipant: ClientSearchByNameResponseInterface
  setSelectedClientAddiParticipant: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface>
  >
  selectedAddiParticipant?: any
  setSelectedAddiParticipant?: Dispatch<React.SetStateAction<any>> | undefined
  addiParticipantTotal?: number
  setAddiParticipantTotal?: Dispatch<React.SetStateAction<number>> | undefined
  trustTotal:TrustTotalState 
  setTrustTotal: Dispatch<React.SetStateAction<TrustTotalState>> 
  selectedTrustClient: TrustTotalBeneficiaries;
  setSelectedTrustClient: Dispatch<React.SetStateAction<TrustTotalBeneficiaries>> ;
  selectedTrustItems: {
    trust: ClientSearchByNameResponseInterface;
    trustEe: ClientSearchByNameResponseInterface;
    trustBene: ClientSearchByNameResponseInterface;
    successorTrustee: ClientSearchByNameResponseInterface;
  }
  setSelectedTrustItems: Dispatch<
  React.SetStateAction<{
    trust: ClientSearchByNameResponseInterface;
    trustEe: ClientSearchByNameResponseInterface;
    trustBene: ClientSearchByNameResponseInterface;
    successorTrustee: ClientSearchByNameResponseInterface;
  }>
>
}

const RetirementDetailsContext =
  createContext<RetirmentDetailsContextInterface>(
    {} as RetirmentDetailsContextInterface
  )

interface ContextProps {
  children?: ReactNode
}

export const RetirementDetailsContextProvider = ({
  children,
}: ContextProps) => {
  const [selectedDataTotal, setSelectedDataTotal] = useState(0)
  const [selectedDataContingentTotal, setSelectedContingentDataTotal] =
    useState(0)
  const [beneOwnerTotal, setBeneOwnerTotal] = useState(0)
  const [addiParticipantTotal, setAddiParticipantTotal] = useState(0)
  const [trustTotal, setTrustTotal] = useState({
    trust:0,
    trustEe:0,
    trustBene:0,
    successorTrustee:0
  })
  const [selectedTrustClient, setSelectedTrustClient] = useState<TrustTotalBeneficiaries>({
    trust: [
      {
        partyId: 0,
        name: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        relationship: '',
        percentageOfBenefits: 0,
      },
    ],
    trustEe: [
      {
        partyId: 0,
        name: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        relationship: '',
        percentageOfBenefits: 0,
      },
    ],
    trustBene: [
      {
        partyId: 0,
        name: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        relationship: '',
        percentageOfBenefits: 0,
      },
    ],
    successorTrustee: [
      {
        partyId: 0,
        name: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        relationship: '',
        percentageOfBenefits: 0,
      },
    ],
  });
  const [selectedTrustItems, setSelectedTrustItems] = useState({
    trust: {} as ClientSearchByNameResponseInterface,
    trustEe: {} as ClientSearchByNameResponseInterface,
    trustBene: {} as ClientSearchByNameResponseInterface,
    successorTrustee: {} as ClientSearchByNameResponseInterface,
  })
  const [selectedPrimaryBeneficiary, setSelectedPrimaryBeneficiary] = useState<
    Beneficiary[]
  >([
    {
      partyId: 0,
      name: '',
      socialSecurityNumber: '',
      dateOfBirth: '',
      relationship: '',
      percentageOfBenefits: 0,
    },
  ])
  const [selectedBeneOwner, setSelectedBeneOwner] = useState<Beneficiary[]>([
    {
      partyId: 0,
      name: '',
      socialSecurityNumber: '',
      dateOfBirth: '',
      relationship: '',
      percentageOfBenefits: 0,
    },
  ])
  const [selectedAddiParticipant, setSelectedAddiParticipant] = useState<
    Beneficiary[]
  >([
    {
      partyId: 0,
      name: '',
      socialSecurityNumber: '',
      dateOfBirth: '',
      relationship: '',
      percentageOfBenefits: 0,
    },
  ])

  const [selectedContingentBeneficiary, setSelectedContingentBeneficiary] =
    useState<Beneficiary[]>([
      {
        name: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        relationship: '',
        percentageOfBenefits: 0,
      },
    ])
  const [selectedClientPrimary, setSelectedClientPrimary] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const [selectedClientContingent, setSelectedClientContingent] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const [selectedClientBeneOwner, setSelectedClientBeneOwner] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const [selectedClientAddiParticipant, setSelectedClientAddiParticipant] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const value = useMemo(
    () => ({
      selectedClientPrimary,
      setSelectedClientPrimary,
      selectedClientContingent,
      setSelectedClientContingent,
      selectedDataTotal,
      setSelectedDataTotal,
      selectedDataContingentTotal,
      setSelectedContingentDataTotal,
      selectedPrimaryBeneficiary,
      setSelectedPrimaryBeneficiary,
      selectedContingentBeneficiary,
      setSelectedContingentBeneficiary,
      selectedClientBeneOwner,
      setSelectedClientBeneOwner,
      selectedClientAddiParticipant,
      beneOwnerTotal,
      setBeneOwnerTotal,
      selectedBeneOwner,
      setSelectedBeneOwner,
      setSelectedClientAddiParticipant,
      selectedAddiParticipant,
      setSelectedAddiParticipant,
      addiParticipantTotal,
      setAddiParticipantTotal,
      trustTotal, setTrustTotal,
      selectedTrustClient, setSelectedTrustClient,
      selectedTrustItems, setSelectedTrustItems
    }),
    [
      selectedClientPrimary,
      selectedClientContingent,
      selectedDataTotal,
      selectedDataContingentTotal,
      selectedPrimaryBeneficiary,
      selectedContingentBeneficiary,
      selectedClientBeneOwner,
      beneOwnerTotal,
      selectedBeneOwner,
      selectedClientAddiParticipant,
      selectedAddiParticipant,
      addiParticipantTotal,
      trustTotal, setTrustTotal,
      selectedTrustClient, setSelectedTrustClient,
      selectedTrustItems, setSelectedTrustItems
    ]
  ) // Remove the extra arguments

  return (
    <RetirementDetailsContext.Provider value={value}>
      {children}
    </RetirementDetailsContext.Provider>
  )
}

export default RetirementDetailsContext
