import { FC, useContext } from 'react'
import { childProps } from '../ILayout'
import StyledContainer from './styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'

const ContainerLayout: FC<childProps> = ({ children }) => {
  const { wipId } = useContext(ApplicationInfoContext)
  return (
    <StyledContainer id={wipId ? 'inside_page' : ''}>
      {children}
    </StyledContainer>
  )
}

export default ContainerLayout
