import React, { useContext, useEffect, useState } from 'react'
import {
  DocumentRowContainer,
  FeatureInfoColumn,
  FeatureInfoRow,
  FormContainer,
  FundingButtonWrapper,
  GrpHeaders,
  ImageWrapper,
} from './style'
import { ResponsiveWrapper } from '../../../../pages/accountApplicationPage/styles'
import { StyledDocumentPanel } from '../../accountInformation/styles'
import { TitleGrouping, WipHeadingWrapper } from '../../formWip/styles'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../components/svg/SvgChevron'
import { COLORS } from '../../../../theme/Colors'
import {
  WDLabelBold,
  WDLabelClient,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../components/ui/WDLabel'
import { ApplicationInfoContext } from '../../../../services/applicationContext/ApplicationPageContext'
import { DividerWrapper } from '../../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import Divider from '../../../../components/divider/Divider'
import Label from '../../../../components/label/Label'
import { WDTextboxBorderRadius } from '../../../../components/ui/WDTextbox'
import { Textbox } from '../../../../components/textbox/Textbox'
import SelectDropdown from '../../../selectDropdown/SelectDropdown'
import {
  AccFeatureWrapper,
  AccountWrapper,
} from '../../accountForms/formIndividual/tabs/AccountFeature/styles'
import { SvgProfile } from '../../../../components/svg/SvgProfile'
import { WDButtonOutLine } from '../../../../components/ui/WDButton'
import Button from '../../../../components/button/Button'
import {
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../electronicPrefs/styles'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import CustomCalendar from './../../../../components/customCalendar/CustomCalendar'
import { SpinnerWrapper } from '../../accountForms/formIndividual/tabs/ProductFeatures/style'
import { LottieWrapper } from '../../../interceptor/styles'
import { Question } from '../../accountForms/formIndividual/tabs/ProductFeatures/IProductFeatures'
import ToastContext from '../../../../services/toast/ToastContext'
import { OptionProps } from '../../../selectDropdown/ISelectDropDown'
import { OptionsInterface } from '../../../dynamicForm/IDynamicFormControls'
import { getXrefDatabyDomainCode } from '../../../../shared/Comman'
import XrefContext from '../../../../store/XrefContext'

const PersonalInformation = () => {
  const {
    readOnly,
    newModelData,
    retirementPersonalInfo,
    setRetirementPersonalInfo,
    autoSaveFunction,
    setNewModelData,
  } = useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [tableLoader, setTableLoader] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState<OptionsInterface>({})
  const xrefStore = useContext(XrefContext)

  const formattingDate = (paramDate: string) => {
    let formattedDate = ''
    if (paramDate) {
      formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
    return formattedDate
  }

  useEffect(() => {
    const newModelDataKeys = newModelData?.wipAccountDetailslist?.[0]
      ? Object.entries(newModelData.wipAccountDetailslist[0])
      : []
    const updatedPersonalInfo = retirementPersonalInfo.map(
      (val: { controlid: string; value: any }) => {
        const matchingValue = newModelDataKeys.find(
          ([key]) => key === val.controlid
        )?.[1]

        return {
          ...val,
          value: matchingValue || val.value,
        }
      }
    )

    setRetirementPersonalInfo(updatedPersonalInfo)
  }, [newModelData])

  const onChangeVal = (controlid: string, value: string | Date) => {
    const updatedData = {
      ...newModelData,
      wipAccountDetailslist: newModelData.wipAccountDetailslist.map(
        (accountDetails) => ({
          ...accountDetails,
          [controlid]: value,
        })
      ),
    }
    setNewModelData(updatedData)
  }

  const onUpdatePersonalInfo = () => {
    setTableLoader(true)
    autoSaveFunction()
      .then((res) => {
        setTableLoader(false)
        if (res && res === 200) {
          setToast('success', 'Personal Information saved')
        } else {
          setToast('error', 'Failed to update the record.')
        }
      })
      .catch(() => {
        setTableLoader(false)
        setToast('error', 'An error occurred while updating the record.')
      })
  }

  const bindAllXrefToState = () => {
    const dropdownOps: OptionsInterface = {}

    {
      retirementPersonalInfo
        ?.sort(
          (a: { controlerseqno: number }, b: { controlerseqno: number }) =>
            a.controlerseqno - b.controlerseqno
        )
        ?.map((item: Question) => {
          switch (item.controltype) {
            case 'select':
              {
                const getDomCode = item.domaincode
                const dropdownXrefData = getXrefDatabyDomainCode(
                  xrefStore.xrefDetails,
                  getDomCode ? getDomCode : ''
                )

                const dropdownOptions: OptionProps[] = []
                dropdownXrefData.map((feature) => {
                  dropdownOptions.push({
                    label: feature.value,
                    value: feature.code,
                  })
                })
                dropdownOps[item.sectionqsnid] = dropdownOptions
              }
              break
          }
        })
    }

    setDropdownOptions(dropdownOps)
  }

  useEffect(
    () => {
      bindAllXrefToState()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [retirementPersonalInfo, xrefStore]
  )

  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel>
        <WipHeadingWrapper>
          <TitleGrouping
            id="red"
            onClick={() => {
              setToggleHeader(!toggleHeader)
            }}
          >
            <SvgProfile />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Personal Information'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>

          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
            }}
          >
            {!toggleHeader && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            <AccFeatureWrapper>
              <AccountWrapper>
                {tableLoader ? (
                  <SpinnerWrapper>
                    <LottieWrapper>
                      <Lottie animationData={Loader} loop={true} />
                    </LottieWrapper>
                  </SpinnerWrapper>
                ) : (
                  <FormContainer id="FormContainers">
                    {retirementPersonalInfo
                      ?.sort(
                        (
                          a: { controlerseqno: number },
                          b: { controlerseqno: number }
                        ) => a.controlerseqno - b.controlerseqno
                      )
                      ?.map((item: Question, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {item.controlerseqno !== 6 && !item.isgrpheader && (
                              <DocumentRowContainer key={item.sectionqsnid}>
                                <FeatureInfoRow className={'fullWidth'}>
                                  {!item.isgrpheader && (
                                    <FeatureInfoColumn>
                                      <WDLabelClient className="label_responsive">
                                        <Label>{item.qsntext}</Label>
                                      </WDLabelClient>
                                    </FeatureInfoColumn>
                                  )}
                                  {item.controltype == 'input' ? (
                                    readOnly ? (
                                      <FeatureInfoColumn>
                                        <WDLabelClient className="label_responsive">
                                          <Label>{item.value ?? '--'}</Label>
                                        </WDLabelClient>
                                      </FeatureInfoColumn>
                                    ) : (
                                      <FeatureInfoColumn>
                                        <WDTextboxBorderRadius className="inputBox_responsive">
                                          <Textbox
                                            type={'text'}
                                            value={item.value ?? ''}
                                            onChange={(value) => {
                                              let newValue = value
                                              if (item.controlerseqno === 4) {
                                                newValue = newValue
                                                  .replace(/[^0-9a-zA-Z]/g, '')
                                                  .slice(0, 8)
                                              }

                                              if (newValue !== item.value) {
                                                onChangeVal(
                                                  item.controlid,
                                                  newValue
                                                )
                                              }
                                            }}
                                          />
                                        </WDTextboxBorderRadius>
                                      </FeatureInfoColumn>
                                    )
                                  ) : item.controltype == 'select' ? (
                                    readOnly ? (
                                      <FeatureInfoColumn>
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {dropdownOptions[
                                              item.sectionqsnid
                                            ]?.find(
                                              (obj) => obj.value == item.value
                                            )?.label ?? '--'}
                                          </Label>
                                        </WDLabelClient>
                                      </FeatureInfoColumn>
                                    ) : (
                                      <FeatureInfoColumn>
                                        <SelectDropdown
                                          width={'100%'}
                                          options={
                                            dropdownOptions[item.sectionqsnid]
                                          }
                                          borderColor={COLORS.Border.Gray50}
                                          borderRadius={'0.5rem'}
                                          placeholderColor={
                                            COLORS.Text.NeutralTextWeak
                                          }
                                          padding={'0 8px'}
                                          onChange={(value) => {
                                            onChangeVal(
                                              item.controlid,
                                              value?.value ?? ''
                                            )
                                          }}
                                          value={
                                            dropdownOptions[
                                              item.sectionqsnid
                                            ]?.find(
                                              (obj) => obj.value == item.value
                                            ) || { label: '', value: '' }
                                          }
                                        />
                                      </FeatureInfoColumn>
                                    )
                                  ) : item.controltype == 'date' ? (
                                    readOnly ? (
                                      <FeatureInfoColumn>
                                        <WDLabelClient className="label_responsive">
                                          <Label>
                                            {item.value
                                              ? formattingDate(item.value)
                                              : '--'}
                                          </Label>
                                        </WDLabelClient>
                                      </FeatureInfoColumn>
                                    ) : (
                                      <FeatureInfoColumn>
                                        <WDTextboxBorderRadius className="inputBox_responsive">
                                          <CustomCalendar
                                            value={item.value}
                                            onChange={(value) => {
                                              onChangeVal(item.controlid, value)
                                            }}
                                          />
                                        </WDTextboxBorderRadius>
                                      </FeatureInfoColumn>
                                    )
                                  ) : (
                                    ''
                                  )}
                                </FeatureInfoRow>
                              </DocumentRowContainer>
                            )}
                            {item.controlerseqno === 6 && (
                              <GrpHeaders>
                                <WDLabelBold className="label_responsive">
                                  {item.qsntext}
                                </WDLabelBold>
                              </GrpHeaders>
                            )}
                          </React.Fragment>
                        )
                      })}
                  </FormContainer>
                )}
              </AccountWrapper>
            </AccFeatureWrapper>
            <FundingButtonWrapper>
              {!readOnly && (
                <WDButtonOutLine>
                  <Button
                    type={'button'}
                    onClick={() => {
                      onUpdatePersonalInfo()
                    }}
                  >
                    {tableLoader ? (
                      <SpinnerButtonWrapper>
                        <LottieButtonWrapper>
                          <Lottie animationData={Loader} loop={true} />
                        </LottieButtonWrapper>
                      </SpinnerButtonWrapper>
                    ) : (
                      <WDLabelPrimaryText className="label_responsive">
                        {'Save'}
                      </WDLabelPrimaryText>
                    )}
                  </Button>
                </WDButtonOutLine>
              )}
            </FundingButtonWrapper>
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default PersonalInformation
