import styled from 'styled-components'
import { COLORS } from '../../../../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../styles'

export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${COLORS.BoxShadow.BackgroundShadow};
  z-index: 999;
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  box-shadow: 0px 2px 6px -2px ${() => COLORS.BoxShadow.Shadow4};
  background-color: ${() => COLORS.Background.White};
  padding: 1rem;
  gap: 0.5rem;
  max-width: 700px;
  @media (min-width: 700px) and (max-width: 1023px) {
    width: 100%;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 80%;
  }
  @media (min-width: 1440px) {
    width: 60%;
  }
`
export const ModalWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  box-shadow: 0px 2px 6px -2px ${() => COLORS.BoxShadow.Shadow4};
  background-color: ${() => COLORS.Background.White};
  padding: 1rem;
  min-width: 300px;
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 100%;
  }
  @media (min-width: 1440px) {
    width: 85%;
  }
`
export const BodyWrapper = styled.div`
  padding-top: 0.725rem;
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25;
`
export const HeaderWrapper = styled.div`
  background: ${COLORS.Background.White};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`
export const WDLabelHeadingLargeNeutral = styled.div`
  font-size: ${TextSizes[7]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[5]};
  color: ${() => COLORS.Text.Neutral};
`
export const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  //flex-direction: row-reverse;
`
export const GapWrapper = styled.div`
  gap: 10rem;
`
export const MsgWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`
export const CloseBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`
