import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgAdd = ({ fillColor }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 7H9.5V1C9.5 0.734784 9.39464 0.48043 9.20711 0.292893C9.01957 0.105357 8.76522 0 8.5 0C8.23478 0 7.98043 0.105357 7.79289 0.292893C7.60536 0.48043 7.5 0.734784 7.5 1V7H1.5C1.23478 7 0.98043 7.10536 0.792893 7.29289C0.605357 7.48043 0.5 7.73478 0.5 8C0.5 8.26522 0.605357 8.51957 0.792893 8.70711C0.98043 8.89464 1.23478 9 1.5 9H7.5V15C7.5 15.2652 7.60536 15.5196 7.79289 15.7071C7.98043 15.8946 8.23478 16 8.5 16C8.76522 16 9.01957 15.8946 9.20711 15.7071C9.39464 15.5196 9.5 15.2652 9.5 15V9H15.5C15.7652 9 16.0196 8.89464 16.2071 8.70711C16.3946 8.51957 16.5 8.26522 16.5 8C16.5 7.73478 16.3946 7.48043 16.2071 7.29289C16.0196 7.10536 15.7652 7 15.5 7Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
