import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { TextboxProps } from './ITextbox'

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: stretch;
  /* justify-content: center; */
  border-radius: 8px;
  width: 100%;
  height: 2rem;
`
export const Main = styled.input`
  padding: ${(TextboxProps: TextboxProps) =>
    TextboxProps.padding ? TextboxProps.padding : '0.5rem 1rem'};
  flex: 0 1 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  background: ${(TextboxProps: TextboxProps) =>
    TextboxProps.backgroundColor
      ? TextboxProps.backgroundColor
      : COLORS.Background.White};
  border: 1px solid
    ${(TextboxProps: TextboxProps) =>
      TextboxProps.border
        ? COLORS.Text.Danger
        : TextboxProps.backgroundColor
        ? TextboxProps.borderColor
        : COLORS.Border.Gray50};

  box-sizing: border-box;
  /* border-radius: 8px; */
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${COLORS.Text.NeutralText};
  outline: none;
  &:hover {
    border: 1px solid ${COLORS.Border.Primary5};
    color: ${COLORS.Text.Gray20};
  }
  &:active {
    border: 1px solid ${COLORS.Border.Primary60};
  }
  &:disabled {
    border: 1px solid
      ${(TextboxProps: TextboxProps) =>
        TextboxProps.backgroundColor
          ? TextboxProps.disableBorder
          : COLORS.Border.Gray50};
    color: ${COLORS.Text.Gray};
    cursor: no-drop;
  }
  &:focus {
    border: 1px solid ${COLORS.Border.Primary50};
    color: ${COLORS.Text.Black};
  }
  &:required {
    &.error {
      border: 1px solid ${COLORS.Border.Danger};
    }
  }
  &:required:focus {
    &.valid {
      border: 1px solid ${COLORS.Border.Success2};
    }
  }
  width: 100%;
`
