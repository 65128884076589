import { FC } from 'react'
import { GlobalStyle } from './styles'
import ContainerLayout from '../container/ContainerLayout'
import { childProps } from '../ILayout'

const LandingPageLayout: FC<childProps> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ContainerLayout>{children}</ContainerLayout>
    </>
  )
}

export default LandingPageLayout
