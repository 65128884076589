import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

// export const Container = styled.div`
//   /* display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;
//   justify-content: center;
//   align-items: center; */
//   display: grid;
//   grid-column: 1/13;
//   grid-auto-flow: row;
//   row-gap: 1rem;
// `
export const StyledDocumentPanel = styled.div`
  display: grid;
  grid-column: 1/13;
  background: ${COLORS.Background.White};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border: 1px solid ${COLORS.Background.White};
  border-radius: 4px;
  width: 100%;
  padding: 24px;
  /* gap: 1rem; */

  &#remove {
    box-shadow: none !important;
    background: none !important;
    border: 0px !important;
  }
  @media screen and (min-width: 1440px) {
    padding: 24px !important ;
  }
  @media screen and ((min-width: 1280px) and (max-width:1440px)) {
    padding: 18px !important;
  }
  @media screen and ((min-width: 1024px) and (max-width:1280px)) {
    padding: 16px !important;
  }
`
export const StyledDocumentPanelTemp = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 95%;
  padding: 0.5rem;
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  }
  @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 32% 67%;
  padding: 24px 12px;
  column-gap: 24px; */
  width: 100%;
  display: grid;
  grid-column: 1 / 13;
`
export const MainSplitRoot = styled.div`
  display: grid;
  row-gap: 8px;
`
export const ColGroupTab = styled.div`
  display: grid;
  height: fit-content;
  row-gap: 24px;
`
