import React from 'react'
import {
  CombineWrapper,
  ErrorText,
  LabelWrapper,
  SvgCancelWrapper,
  TopContent,
  Wrapper,
} from './styles'
import { SvgError } from '../svg/SvgError'
import { WDStyledOrderEntryButtonTextSubHeading } from '../ui/WDLabel'
import { COLORS } from '../../theme/Colors'
import SvgCancel from '../svg/SvgCancel'

interface IErrorInterface {
  ErrorTitle: string
}

const ErrorToast = ({ ErrorTitle }: IErrorInterface) => {
  return (
    <Wrapper>
      <CombineWrapper>
        <TopContent>
          <SvgError />
          <LabelWrapper>
            <WDStyledOrderEntryButtonTextSubHeading>
              {ErrorTitle}
            </WDStyledOrderEntryButtonTextSubHeading>
          </LabelWrapper>
          <SvgCancelWrapper>
            <SvgCancel fillColor={COLORS.Text.NeutralTextWeak}></SvgCancel>
          </SvgCancelWrapper>
        </TopContent>
        <ErrorText>
          {
            'Errors to be resolved before you can submit application for approvals...'
          }
        </ErrorText>
      </CombineWrapper>
    </Wrapper>
  )
}

export default ErrorToast
