import styled from 'styled-components'
import { COLORS } from '../../../../../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../styles'

export const ApplicantInfoWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex-direction: column;
  width: 100%;
  //height: 100%;
  padding: 0.25rem;
  height: 180vh;
  //overflow-y: scroll;
`
export const FieldContainer = styled.div`
  display: flex;
  flex: 0 1 50%;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
`
export const TabWrapper = styled.div`
  display: flex;
`
export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  background: ${COLORS.Background.White};
`
export const DivContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.5rem;
`
export const AccountLBL = styled.div`
  flex: 100% 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledContainer = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row wrap;
`
export const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5 1 auto;
  min-height: 100vh;
`

export const MainContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
`
export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 1.25rem;
  gap: 1rem;
  width: 100%;
`
export const CardMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const CardSubWrapperInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const CardTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 25%;
  // justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  gap: 0.5rem;
  margin-left: 0.4rem;
`
export const CardText = styled.div`
  display: flex;
  //flex-wrap: wrap;
  width: 25%;
  justify-content: center;
  margin-left: -1.75rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  //line-height: 1.5rem;
  //gap: 4.5rem;
`
export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  gap: 0.5rem;
`

export const DashboardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
`

export const DashboardContentWrapperOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow1};
  background-color: ${COLORS.Background.White};
  flex: 0 1 100%;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 3.5rem;
`
export const LabelWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-start;
  gap: 0.5rem;
`

export const SubHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const PathWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
`
export const FirstTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
`
export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
  margin-right: 1rem;
  gap: 0.5rem;
`
export const SecondTextBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
  margin-left: 2.9rem;
  gap: 0.5rem;
`
export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TextInputWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  flex: 0 1 100%;
  gap: 1rem;
  margin-left: 1.5rem;
`
export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 20%;
`
export const ExternalAccountButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 20%;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`
export const MasterWrapperTwo = styled.div`
  display: flex;
  flex-direction: row;
`
export const MasterWrapperThree = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

export const VerificationCodeWrapper = styled.div`
  flex: 0 1 30%;
`
export const TextAreaWrapper = styled.div`
  flex: 0 1 65%;
  margin-bottom: 1.5rem;
  margin-top: 0.25rem;
`
export const ButtonWrapper = styled.div`
  :hover + .ToolTip {
    position: absolute;
    visibility: visible;
    margin: 13.85rem;
    margin-bottom: 1rem;
    z-index: 10;
  }
  button {
    padding: 0.5rem;
    max-width: 80%;
    height: 2rem;
  }
`
export const ParameterLabel = styled.div`
  display: flex;
  flex: 0 1 90%;
  margin-left: 0.7312rem;
`
export const CardSubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0.875rem 0.5rem 1rem;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  background-color: #f8fafc;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  // color: ${COLORS.Background.Gray110};
  background-color: ${COLORS.Background.White};
  border-radius: 8px 8px 0px 0px;
`

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  // color: ${COLORS.Background.Gray30};
  background-color: ${COLORS.Background.White};
  border-radius: 0px 0px 8px 8px;
`
export const AccountOverviewWrapper = styled.div`
  background-color: ${COLORS.Background.Neutral};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`
export const WrapperOne = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0rem 1rem;
  gap: 1.5rem;
`
export const WrapperTwo = styled.div`
  // padding: 0rem 1rem;
  display: flex;
  flex-direction: row;
  gap: 21.5rem;
`
export const WrapperThree = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18rem;
`

export const RequestButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 0.5rem;
  button {
    width: 7.688rem;
    height: 2rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
  }
`
export const RequestCodeWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex-direction: row;
`
export const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.Background.Gray110};
`

export const FrequencyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const RadioWrapper = styled.div`
  background-color: ${COLORS.Background.White};
  border: 1px solid #e2e8f0;
  padding: 8px 16px;
  border-radius: 0.3rem;
  :hover {
    background-color: ${COLORS.Background.Primary5};
  }
`
export const ApplicantInfoRow = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`
export const CountryRow = styled.div`
  display: flex;
  flex: 0 1 33.33%;
  position: relative;
  &#bottomMargin {
    margin-bottom: 16px;
  }
`
export const IDInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const IDInfoFlexRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const IDInfoFlexItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
  gap: 4px;
`
export const ApplicantInfoColumn = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 4px;
  &#checkMail {
    flex-direction: column;
  }
  &#ssnSection button {
    padding: 0px;
  }
`
export const ApplicantInfoColumnRight = styled.div`
  display: flex;
  flex: 70%;
`
export const FirstColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const SecondColumnWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 15%;
  background-color: ${COLORS.Background.White};
  position: relative;
  &#primary-applicant {
    white-space: nowrap;
  }
`
export const ClientSearchWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  padding: 0.5rem 0 0;
  input {
    width: 15rem;
    ::placeholder {
      color: ${COLORS.Text.Gray};
    }
  }
`
export const ApplicantInfoTwoColumn = styled.div`
  display: flex;
  width: 50%;
  padding: 0.5rem;
  gap: 0.5rem;
`
export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`
export const MailingAddressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.5rem;
`
export const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 0.5rem; */
  height: fit-content;
  //padding: 0.25rem;
  // gap: 0.5rem;
  //overflow-y: auto;
`
export const ContentWrapper = styled.div`
  padding: 0;
`
export const ApplicantHeader = styled.div`
  display: flex;
  align-items: center;
`
export const ApplicantTitleWrapper = styled.div`
  flex: 80%;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`
export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 0px;
  &#product_feature {
    padding-top: 10px;
  }
`
export const DashedDivider = styled.div`
  display: flex;
  flex-direction: row;
  border: none;
  border-top: 2px dashed ${COLORS.Background.Gray50};
  width: 100%;
  padding: 4px 0px;
`
export const DividerWrapperBottom = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px;
`

export const TopDividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0 16px 0;
`
export const SearchWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  width: 100%;
`
export const TextBoxWrapper = styled.div`
  right: 1%;
  bottom: 5%;
  top: 5%;
  left: 5%;
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: inherit;
  input {
    ::placeholder {
      color: ${COLORS.Text.Gray};
    }
  }
`
export const IconBoxWrapper = styled.div`
  right: 35%;
  bottom: 4%;
  top: 4%;
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: inherit;
`
export const PersonalInfoWrapper = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  flex: 100%;
  /* background-color: #eff6ff; */
  margin: 0 0.2rem;
  padding: 0.25rem 0;
`
export const IDWrapper = styled.div`
  display: flex;
  gap: 16px;
`
export const InvestTitle = styled.div`
  /* padding-bottom: 8px; */
  display: flex;
  padding-left: 1rem;
`
export const ClientSearchCard = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 107.2%;
  left: 0px;
  width: 100%;
  gap: 0.25rem;
  align-items: center;
  background: rgb(248, 250, 252);
  box-shadow: rgba(0, 36, 93, 0.3) 0px 2px 6px 1px;
  justify-content: center;
  align-self: center;
  height: fit-content;
  z-index: 9999;
`
export const TitleGrouping = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  flex: 70%;
`
export const ToolTipParentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  &#primary_text {
    flex: 10%;
    justify-content: flex-start;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  &#selected {
    background-color: ${COLORS.Background.NeutralMedium};
    padding: 0.1rem 0.5rem;
    color: ${COLORS.Background.PrimaryText};
    font-weight: 600;
    border-bottom: 3px solid ${COLORS.Background.PrimaryText};
  }
  &#not-selected {
    padding: 0.1rem 0.5rem;
    border-bottom: 3px solid ${COLORS.Background.Primary5};
  }
`

export const StyledButtonTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
`
export const ToolTipHoverWrapper = styled.div`
  :hover + .ToolTip {
    position: absolute;
    visibility: visible;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    z-index: 10;
  }
`
export const BtnWrap = styled.div`
  padding: 0.25rem;
`

export const ToolTipWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  z-index: 9999999;
  top: 2.5rem;
  left: 1rem;
  .tooltip_arrow {
    position: absolute;
    box-sizing: border-box;
    width: 0.7956rem;
    height: 0.7956rem;
    top: -0.35rem;
    left: 1rem;
    background: ${() => COLORS.UI.Gray20};
    border-radius: 2px 0px 2px 0px;
    transform: rotate(-45deg);
  }
`
export const TemplateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`
export const SvgBgWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
  position: relative;
  button {
    z-index: 99999;
    position: relative;
  }
`
export const ItemsListWrapper = styled.div`
  display: flex;
  flex: 100%;
  gap: 4px;
  &#invesexp {
    flex-wrap: wrap;
  }
`
export const ItemListChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  &#fullWidth {
    flex: 25%;
  }
  &#invesexp {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 0.3rem;
    align-items: start;
    justify-content: space-between;
    flex-basis: calc(50% - 1.5rem);
  }
`
export const ParentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  &#paScroll {
    /* overflow-y: auto;
    max-height: 34vh; */
    /* max-height: 100vh; */
    height: fit-content !important;
    row-gap: 16px;
    ::-webkit-scrollbar {
      background: ${COLORS.Background.Gray30};
      width: 5px;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      height: 100px;
      background: ${COLORS.Background.Primary10};
      top: 8px;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(167, 175, 188, 0.3);
    }
    ::-webkit-scrollbar-track {
      border-radius: 8px;
    }
  }
`
export const ParentInvestmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
