import React from 'react'
import {
  ContactWrapper,
  Container,
  ContentWrapper,
  FirstParagraph,
  HeadingWrapper,
  PhoneWrapper,
} from './styles'
import WDSpacer from '../../components/ui/WDSpacer'
import Divider from '../../components/divider/Divider'
import {
  WDLabelLarge,
  WDStyledContact,
  WDStyledHead,
  WDStyledOrderEntryButtonTextSubHeading,
} from '../../components/ui/WDLabel'
import Label from '../../components/label/Label'
import SvgMessageSent from '../../assets/illustrations/SvgMessageSent'
import SvgCallLarge from '../../components/svg/SvgCallLarge'
import { COLORS } from '../../theme/Colors'
import {StyledDocumentPanel } from '../forms/accountInformation/styles'
import { DividerWrapper } from '../forms/formWip/styles'

const ThankYouPage = () => {
  return (
    <Container>
      <StyledDocumentPanel>
        <HeadingWrapper>
          <WDSpacer bottom={'24px'}>
            <SvgMessageSent />
          </WDSpacer>
          <WDSpacer bottom={'16px'}>
            <WDStyledHead>
              {'Thank you for applying for an online account with Wedbush.'}
            </WDStyledHead>
          </WDSpacer>
          <DividerWrapper>
          <Divider
            thickness={'1px'}
            bgColor={`${COLORS.Background.Border}`}
            horizontal={true}
          />
          </DividerWrapper>
          <ContentWrapper>
            <FirstParagraph>
              <WDLabelLarge>
                <Label>
                  {
                    'We appreciate your interest in our services and are thrilled to have you.'
                  }
                </Label>
              </WDLabelLarge>
              <WDLabelLarge>
                <Label>
                  {
                    'Our team is currently processing your application and will be in touch with you shortly.'
                  }
                </Label>
              </WDLabelLarge>
            </FirstParagraph>
            <WDLabelLarge>
              <Label>
                {
                  'Should you have any questions or concerns, please do not hesitate to contact us. We are always here to assist you.'
                }
              </Label>
            </WDLabelLarge>
            <ContactWrapper>
              <SvgCallLarge />
              <PhoneWrapper>
                <WDStyledContact>{'1-800-123-1234'}</WDStyledContact>
                <WDStyledOrderEntryButtonTextSubHeading>
                  <Label>{'8am to 12am ET'}</Label>
                </WDStyledOrderEntryButtonTextSubHeading>
              </PhoneWrapper>
            </ContactWrapper>
            <WDLabelLarge>
              <Label>
                {
                  'Thank you again for choosing Wedbush for your investment needs. We look forward to working with you soon.'
                }
              </Label>
            </WDLabelLarge>
          </ContentWrapper>
          <Divider
            thickness={'1px'}
            bgColor={`${COLORS.Background.Border}`}
            horizontal={true}
          />
        </HeadingWrapper>
      </StyledDocumentPanel>
    </Container>
  )
}

export default ThankYouPage
